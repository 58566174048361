import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { replaceDecimal } from "../../utils/services";
import { usePatronRoles } from "../Api/Get/GetApi";
import { RoleDataProps } from "../NewCustomerModal/NewCustomerModal";
import { Select } from "../Select";
import "./AddablePrice.scss";
import { useProductWrapper } from "./ProductWrapper";
import produce from "immer";

export interface PriceContent {
  role_id?: number;
  primary_price?: number;
  secondary_price?: number;
}
export interface EnterRefProps {
  inputRef?: any;
}
const initialState = {
  role_id: 4,
  primary_price: undefined,
  secondary_price: undefined,
};

export const AddablePrice: React.FC<EnterRefProps> = ({ inputRef }) => {
  //useStatehooks
  const [roleData, setRoleData] = useState<Array<RoleDataProps>>([]);
  const [selectedRole, setSelectedRole] = useState<Array<number | string>>([4]);
  const [productIndex, setProductIndex] = useState(0);
  //Formik Context
  const { setFieldValue, values } = useProductWrapper();
  const [price, setPrice] = useState<Array<PriceContent>>([initialState]);

  //useQuery
  const { data: patronRoles } = usePatronRoles({});
  const handleAddDetail = (e: any, index: number) => {
    const names = e.target.name.split(".");
    const attr = names.at(-1);
    setProductIndex?.(index);
    setPrice(
      produce((cloned) => {
        let val = Number(e.target.value);
        //@ts-ignore
        cloned[index][attr] = val === "" ? "" : replaceDecimal(val);

        if (attr === "primary_price") {
          let calculatedVal =
            Number(cloned[index].primary_price) / Number(values.relation);
          let secondaryVal = calculatedVal;
          cloned[index].secondary_price =
            isNaN(calculatedVal) || calculatedVal === Infinity
              ? ""
              : replaceDecimal(secondaryVal);
        }
      })
    );
  };

  useEffect(() => {
    if (
      Number(values?.price?.[productIndex]?.primary_price) <
      Number(values?.price?.[productIndex]?.secondary_price)
    ) {
      toast.warn("Secondary price can't be greater than primary price");
      setFieldValue(
        `price[${productIndex}].secondary_price`,
        values.price[productIndex].primary_price
      );
    }
  }, [productIndex, setFieldValue, values.price, values.relation]);

  useEffect(() => {
    setPrice(
      produce((cloned) => {
        const newRelation = Number(values.relation);

        if (!isNaN(newRelation) && newRelation !== 0) {
          const arr = cloned?.map((item) => {
            return {
              ...item,
              secondary_price: Number((Number(item.primary_price) / Number(newRelation)).toFixed(3)),
            };
          });
          return arr;
        }
      })
    );
  }, [values.relation]);

  useEffect(() => {
    setFieldValue("price", price);
  }, [price, setFieldValue]);

  useEffect(() => {
    if (
      values?.price &&
      values?.price?.[0].primary_price !== undefined &&
      values?.price?.[0].primary_price !== 0
    ) {
      setPrice(values?.price);
      values?.price.map((item) => {
        if (!selectedRole.includes(item.role_id)) {
          setSelectedRole((p) => [...p, item.role_id]);
        }
      });
    }
  }, [values?.price]);

  useEffect(() => {
    if (patronRoles) {
      setRoleData(patronRoles);
    }
  }, [patronRoles]);

  const handleAdd = () => {
    setPrice((p) => {
      return [
        ...p,
        {
          role_id: undefined,
          primary_price: undefined,
          secondary_price: undefined,
        },
      ];
    });
    setSelectedRole((p) => [...p, ""]);
  };
  const removeInput = (index: number) => {
    const cloned = [...price];
    cloned.splice(index, 1);
    setPrice(cloned);
    const clonedRole = [...selectedRole];
    clonedRole.splice(index, 1);
    setSelectedRole(clonedRole);
  };

  const selectedRoleFilter = (index: number, value: number) => {
    const role = [...selectedRole];
    role[index] = Number(value);
    setSelectedRole(role);
  };

  const getFilteredRoleData = (index: number) => {
    const removedItems = [...selectedRole];
    const filteredData = roleData.filter((item) => {
      return !removedItems.includes(item.id) || selectedRole[index] === item.id;
    });
    return filteredData;
  };

  return (
    <div className="addable-price">
      {values?.price?.map((inp, index) => {
        return (
          <div
            className={`${isMobile ? "mobile-view-body row" : "price-body d-flex"
              }`}
            key={index}
          >
            <div className={`${isMobile ? "role-block" : "body-section"}`}>
              <label className="input-label">Role</label>

              <Select
                disabled={index === 0}
                className="input-field"
                name={`price.${index}.role_id`}
                value={inp.role_id}
                ref={inputRef}
                onChange={(e: any) => {
                  handleAddDetail(e, index);
                  selectedRoleFilter(index, Number(e.target.value));
                }}
              >
                {index === 0 && (
                  <option id="4" selected>
                    Vendor
                  </option>
                )}
                <option id="">Select Role</option>
                {getFilteredRoleData(index)?.map((opt, idx) => {
                  return (
                    <option key={idx} value={opt.id}>
                      {opt?.name}
                    </option>
                  );
                })}
              </Select>
              {values.is_price_fixed && (
                <ErrorMessage
                  name={`price[${index}].role_id`}
                  component="div"
                  className="error"
                />
              )}
            </div>

            <div className={`${isMobile ? "col-md-6" : "body-section"}`}>
              <label className="input-label">Primary Price</label>
              <Field
                className="input-field"
                placeholder="Enter primary price"
                min={0}
                type="number"
                ref={inputRef}
                name={`price[${index}].primary_price`}
                value={Number(values?.price[index]?.primary_price)}
                onKeyPress={(e: any) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onFocus={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
                onChange={(e: any) => {
                  handleAddDetail(e, index);
                }}
                onWheel={(e: any) => {
                  (e.target as HTMLInputElement).blur();
                }}
              />
              {values.is_price_fixed && (
                <ErrorMessage
                  name={`price[${index}].primary_price`}
                  component="div"
                  className="error"
                />
              )}
            </div>
            <div className={`${isMobile ? "col-md-6" : "body-section no-gap"}`}>
              <label className="input-label">Secondary Price</label>
              <Field
                className="input-field"
                placeholder="Enter secondary price"
                ref={inputRef}
                min={0}
                type="number"
                value={Number(values?.price[index]?.secondary_price)}
                onKeyPress={(e: any) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                name={`price[${index}].secondary_price`}
                onFocus={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
                s
                onChange={(e: any) => {
                  handleAddDetail(e, index);
                }}
                onWheel={(e: any) => {
                  (e.target as HTMLInputElement).blur();
                }}
              />
              {values.is_price_fixed && (
                <ErrorMessage
                  name={`price[${index}].secondary_price`}
                  component="div"
                  className="error"
                />
              )}
            </div>
            {price.length > 1 &&
              (index === 0 ? (
                ""
              ) : (
                <div className="remove-block">
                  <button
                    onClick={() => removeInput(index)}
                    className={`${isMobile ? "mobile-position remove-btn" : "remove-btn"
                      }`}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="remove"
                        d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                        fill="#B11D1D"
                      />
                      <path
                        className="remove"
                        d="M5.80715 5.8074C5.86521 5.74919 5.93418 5.70301 6.01011 5.67151C6.08604 5.64 6.16744 5.62378 6.24965 5.62378C6.33186 5.62378 6.41326 5.64 6.48919 5.67151C6.56513 5.70301 6.6341 5.74919 6.69215 5.8074L9.99965 9.11615L13.3072 5.8074C13.3653 5.74929 13.4342 5.70319 13.5102 5.67174C13.5861 5.64029 13.6675 5.62411 13.7497 5.62411C13.8318 5.62411 13.9132 5.64029 13.9891 5.67174C14.0651 5.70319 14.134 5.74929 14.1922 5.8074C14.2503 5.86551 14.2964 5.93449 14.3278 6.01042C14.3593 6.08634 14.3754 6.16772 14.3754 6.2499C14.3754 6.33208 14.3593 6.41345 14.3278 6.48938C14.2964 6.5653 14.2503 6.63429 14.1922 6.6924L10.8834 9.9999L14.1922 13.3074C14.2503 13.3655 14.2964 13.4345 14.3278 13.5104C14.3593 13.5863 14.3754 13.6677 14.3754 13.7499C14.3754 13.8321 14.3593 13.9135 14.3278 13.9894C14.2964 14.0653 14.2503 14.1343 14.1922 14.1924C14.134 14.2505 14.0651 14.2966 13.9891 14.3281C13.9132 14.3595 13.8318 14.3757 13.7497 14.3757C13.6675 14.3757 13.5861 14.3595 13.5102 14.3281C13.4342 14.2966 13.3653 14.2505 13.3072 14.1924L9.99965 10.8836L6.69215 14.1924C6.63404 14.2505 6.56506 14.2966 6.48913 14.3281C6.41321 14.3595 6.33183 14.3757 6.24965 14.3757C6.16747 14.3757 6.0861 14.3595 6.01017 14.3281C5.93425 14.2966 5.86526 14.2505 5.80715 14.1924C5.74904 14.1343 5.70295 14.0653 5.6715 13.9894C5.64005 13.9135 5.62386 13.8321 5.62386 13.7499C5.62386 13.6677 5.64005 13.5863 5.6715 13.5104C5.70295 13.4345 5.74904 13.3655 5.80715 13.3074L9.1159 9.9999L5.80715 6.6924C5.74895 6.63434 5.70277 6.56537 5.67126 6.48944C5.63975 6.41351 5.62354 6.33211 5.62354 6.2499C5.62354 6.16769 5.63975 6.08629 5.67126 6.01035C5.70277 5.93442 5.74895 5.86545 5.80715 5.8074Z"
                        fill="#B11D1D"
                      />
                    </svg>
                  </button>
                </div>
              ))}
          </div>
        );
      })}
      <div className="add-button">
        <button
          className="light-primary content"
          onClick={handleAdd}
          disabled={price.length === roleData.length ? true : false}
        >
          <span>
            <img alt="plus" src={require("../../assets/images/plus-16.png")} />
          </span>
          Add Another Price
        </button>
      </div>
    </div>
  );
};
