import { useState } from "react"
import {
  fomratColumns,
  handleNoData,
  parenthesisInNegativeValue,
  sliceDate,
  tableIndexNumber,
  totalPages,
} from "../../utils/services"
import { useCbmsData } from "../Api/Get/GetApi"
import { Table } from "../CustomReactTable/CustomReactTable"
import { FilterDate } from "../SalesList/SalesListPage"
import { CbmsDataResponse } from "./types"
import "./IRDMaterializedView.scss"

export const IRDMaterializedView = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" })
  const [queryPageIndex, setQueryPageIndex] = useState(0)
  const [queryPageSize, setQueryPageSize] = useState(10)

  const { data: cbmsData, isLoading } = useCbmsData(
    queryPageIndex,
    queryPageSize,
    date
  )
  const reqHeaders = [
    "SN",
    "Fiscal Year",
    "Bill No",
    "Customer Name",
    "Customer Pan",
    "Bill Date",
    "Amount",
    "Discount",
    "Taxable Amount",
    "Tax Amount",
    "Total Amount",
    "Sync With IRD",
    "Is Bill Printed",
    "Is Bill Active",
    "Printed Time",
    "Printed By",
    "Entered By",
    "Is Realtime",
    "Payment Method",
    "Vat Refund Amount",
    "Transaction Id",
  ]

  const FormatTable = (
    array: CbmsDataResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          sn: tableIndexNumber(pageIndex, pageSize, index),
          customer_name: item.buyer_name,
          customer_pan: item.buyer_pan,
          fiscal_year: sliceDate(item.fiscal_year),
          bill_date: sliceDate(item.invoice_date),
          bill_no: item.invoice_number,
          is_realtime: String(item.isrealtime),
          transaction_id: item.transaction_main_id
            ? item.transaction_main_id
            : "None",
          tax_amount: parenthesisInNegativeValue(item.vat),
          printed_time: sliceDate(item.printed_date_time),
          printed_by: item.printed_by,
          entered_by: item.entered_by,
          sync_with_ird: item.sync_with_ird,
          is_bill_printed: item.is_bill_print,
          is_bill_active: item.is_bill_active,
          payment_method: handleNoData(item.payment_method, "None"),
          vat_refund_amount: parenthesisInNegativeValue(item.vat_refund_amount),
          total_amount: parenthesisInNegativeValue(item.total_sales),
          taxable_amount: parenthesisInNegativeValue(item.taxable_sales_vat),
          discount: item.discount ? item.discount : 0,
          amount: item.sub_total ? parenthesisInNegativeValue(item.sub_total) : 0,
        }
      })
      return formatData
    }
    return []
  }

  return (
    <>
      <div className="row">
        <div className="head-section">
          <div className="col-md-6">
            <div className="small-title">Materialized View</div>
          </div>
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={fomratColumns(reqHeaders)}
        data={
          cbmsData
            ? FormatTable(cbmsData.data, queryPageIndex, queryPageSize)
            : []
        }
        totalPages={
          cbmsData && totalPages(queryPageSize, cbmsData.pagination.total_count)
        }
        showFooter={false}
        showDate={true}
        setDate={setDate}
        totalEntries={cbmsData && cbmsData.pagination.total_count}
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
        excelDownload={true}
        date={date}
      />
    </>
  )
}
