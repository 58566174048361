import { Location } from "react-router-dom";
import * as XLSX from "xlsx";
import { UserInfoResponse } from "../components/context/auth/types";
import { FilterDate } from "../components/SalesList/SalesListPage";
import { PatronRoles } from "../EnumValues";
import {
  CheckRoute,
  CheckUnwantedColumnExist,
  HeaderUpdate,
  nestedColumnCounts,
  NestedColumnOptions,
} from "../utils/services";
export interface ColumnData {
  Header: string;
  accessor: string;
}

export const DeterminePatronRoles = (roleId: PatronRoles) => {
  let role = "";

  switch (roleId) {
    case PatronRoles.Retailer:
      role = "Retailer";
      break;
    case PatronRoles.Vendor:
      role = "Vendor";
      break;
    case PatronRoles.WholeSaler:
      role = "Wholesaler";
      break;
    default:
      return;
  }
  return role;
};

export const handlePrint = (
  columns: ColumnData[],
  businessUserInfo: UserInfoResponse,
  date: FilterDate,
  tableIdName: string,
  location: Location,
  roleId?: PatronRoles
) => {
  //Accessing the  table
  const originalTable = document.getElementById(tableIdName);
  var requiredTable = originalTable?.cloneNode(true);
  //This adds the Titles
  const additionHtml = `<div id='print-head' style="width: 100%;
    text-align: center;
    margin-bottom:3rem;
    ">
    <style>
    @media print {
      ${
        CheckUnwantedColumnExist(columns, "Action") &&
        ` table td:last-child {display:none}
      table th:last-child {display:none}`
      }
     
      @page{
        size:A4 landscape;
      }
      table{
        border-collapse: collapse;
        caption-side: bottom;
        width: 100%;
        font-family: 'Poppins', sans-serif;
      }
      tr{
        border-collapse: collapse;
      }
      tfoot 
        tr:nth-last-child(1) {
        display: none;
        
      }
      thead{
        background: #f9f9f9;
      }
      th {
        background-color: #f9f9f9;
        border: 1px solid black !important;
        padding: 6px 8px;
        background: #f9f9f9;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #666666;
      }
      .nowrap-unset{
        white-space: unset;
      }
      td {
        border: 1px solid black !important;
        font-size: 10px;
        color: #000;
        font-weight: 500;
        padding: 6px 8px;
      }
          
    }
    </style>
    <div style=" display: inline-block; margin: 0 auto; padding: 2px;">
      <div style='display:flex; gap:10px;' >
      ${
        businessUserInfo?.business_info.logo
          ? `<img style='border-radius:50%; height: 30px; width: 30px;' src=${businessUserInfo?.business_info.logo} alt="hello" />`
          : `<div style="border-radius:50%; height: 30px; width: 30px; background-color:#1db195; color:#fff; display: flex;
          justify-content: center;
          align-items: center;">
          <div style='margin-top: 3px; font-weight: 600;'>
          ${businessUserInfo?.business_info.name.charAt(0).toUpperCase()}</div>
          </div>`
      }
        <div style="font-size: 20px; font-weight:600;">
          ${businessUserInfo?.business_info.name}
        </div>
      </div>
      <div style="margin-top:5px; line-height: 14px; font-family: 'Poppins', sans-serif;font-size: 12px;font-weight: 600;>
        ${businessUserInfo?.business_info.address}
      </div>
      <div style="margin-top:5px; line-height: 14px; font-family: 'Poppins', sans-serif; font-size: 12px;font-weight: 600;
        ${businessUserInfo?.business_info.mobile_no}
      </div>
      <div>

      ${
        date?.dateFrom && date.dateTo
          ? `
      <div style="margin-top:15px; line-height: 14px;font-size: 12px;font-weight: 600;">
      Date:
      <span style="font-family: 'Poppins', sans-serif; line-height: 14px;font-size: 12px;font-weight: 500;">
       ${date?.dateFrom}
      </span> 
      to
      <span style="font-family: 'Poppins', sans-serif;line-height: 14px;font-size: 12px;font-weight: 500;">
        ${date?.dateTo}
      </span> 
     </div>
      `
          : ""
      }

      </div>
      <div style="font-size:20px; font-weight:800; margin-top:10px; margin-bottom:8px;" >
      ${HeaderUpdate(location.pathname.slice(1), "-")}         ${
    roleId ? `(For ${DeterminePatronRoles(roleId)})` : ""
  }

      </div>
      
    
      </div>
    ${
      CheckRoute(location.pathname)
        ? `
    </div>
    <div style='display:flex; justify-content:space-around; align-items: center; font-size:16px; font-weight:800;         padding-top:10px;' >
      <div>Name of Firm: ${businessUserInfo?.business_info.name}</div>

      <div>PAN: ${businessUserInfo?.business_info.pan}</div>
    
      <div style='display:flex;'>
        <div>Duration:</div> 
        <div style=' margin-left: 10px; '>Month: ${
          date?.dateFrom.split("-")[1]
        } </div> 
        <div style=' margin-left: 10px; '>Year: ${
          date?.dateFrom.split("-")[0]
        }  </div> 
      </div>
    </div>   
    
    `
        : ""
    }
 
    `;
  //Adds the additional titles
  (requiredTable as HTMLElement).insertAdjacentHTML("afterbegin", additionHtml);
  //@ts-ignore
  //Accessing the rows
  // var allRows = requiredTable.rows;
  // //This loop delete the column if column is action
  // for (var i = 0; i < allRows[0].cells.length; i++) {
  //   if (i === allRows[0].cells.length - 1) {
  //     if (allRows[0].cells[i].innerHTML === "Action")
  //       for (var i = 0; i < allRows.length; i++) {
  //         allRows[i].deleteCell(-1);
  //       }
  //   }
  // }
  let newWin = window.open("");
  (newWin as Window).document.write((requiredTable as HTMLElement).outerHTML);
  //@ts-ignore
  newWin.print();
  //@ts-ignore
  newWin.close();
};
export const financialReportPrint = (
  columns: ColumnData[],
  businessUserInfo: UserInfoResponse,
  date: any,
  tableIdName: string,
  location: Location,
  roleId?: PatronRoles
) => {
  //Accessing the  table
  const originalTable = document.getElementById(tableIdName);
  var requiredTable = originalTable?.cloneNode(true);
  //This adds the Titles
  const additionHtml = `<div id='print-head' style="width: 100%;
    text-align: center;
    margin-bottom:3rem;
    ">
    <style>
    @media print {
      table{
        border-collapse: collapse;
        caption-side: bottom;
        width: 100%;
      }
      tr{
        border-collapse: collapse;
      }
      tfoot 
        tr:nth-last-child(1) {
        display: none;
      }
      th {
        border: 1px solid #000;
        padding: 6px 8px;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #666666;
        text-align: left;
      }
      .nowrap-unset{
        white-space: unset;
      }
      td {
        border: 1px solid #000;
        font-size: 10px;
        color: #000;
        font-weight: 500;
        padding: 6px 8px;
        vertical-align: top;
        border-bottom: none;
      }
      .checkbox-none{
        display:none;
      }
      .d-flex{
        display: flex;
        flex-direction:row;
      } 
      .width-20{
        width:20%;
      }  
      .width-60{
        width:60%
      }
      .vertical-bottom{
        vertical-align: bottom !important;
      }
      .padd-left{
        padding-left: 40px !important;
      }  
      .mini-table td {
        border: none
      }
      .mini-table th {
        border: none;
      }
      .footer-table td {
        border: none
      }
      .small-table td{
        border: none
      }
      svg{
        display: none
      }
      .footer-content{
        font-size: 14px;
      }
      .total{
        font-size: 14px;
      }
      .content-title{
        border: none;
      }
    }
    </style>
    <div style=" display: inline-block; margin: 0 auto; padding: 2px;">
      <div style='display:flex; gap:10px;margin-left:40px' >
      ${
        businessUserInfo?.business_info.logo
          ? `<img style='border-radius:50%; height: 30px; width: 30px;' src=${businessUserInfo?.business_info.logo} alt="hello" />`
          : `<div style="border-radius:50%; height: 30px; width: 30px; background-color:#1db195; color:#fff; display: flex;
          justify-content: center;
          align-items: center;">
          <div style='margin-top: 3px; font-weight: 600;'>
          ${businessUserInfo?.business_info.name.charAt(0).toUpperCase()}</div>
          </div>`
      }
        <div style="font-size: 20px; font-weight:600;">
          ${businessUserInfo?.business_info.name}
        </div>
      </div>
      <div>
      ${businessUserInfo?.business_info.address}
      </div>
      
      <div style="font-size:20px; font-weight:800; margin-top:10px; margin-bottom:8px;" >
      ${HeaderUpdate(location.pathname.slice(1), "-")}         
      ${roleId ? `(For ${DeterminePatronRoles(roleId)})` : ""}

      <div style="margin-top:10px;">
      ${
        date?.dateFrom && date.dateTo
          ? `
      <span style="font-family: 'Poppins', sans-serif; line-height: 14px;font-size: 12px;font-weight: 500;">     
      For the period from  ${date?.dateFrom} to ${date?.dateTo}
      </span> `
          : ` <span style="font-family: 'Poppins', sans-serif; line-height: 14px;font-size: 12px;font-weight: 500;">     
          ${date ? ` As on  ${date}` : ""}
          </span> `
      }
      </div>

    ${
      CheckRoute(location.pathname)
        ? `
    </div>
    `
        : ""
    }
 
    `;
  (requiredTable as HTMLElement).insertAdjacentHTML("afterbegin", additionHtml);
  let newWin = window.open("");
  (newWin as Window).document.write((requiredTable as HTMLElement).outerHTML);
  //@ts-ignore
  newWin.print();
  //@ts-ignore
  newWin.close();
};

export function ExcelColumnWidth(sheet: XLSX.Sheet) {
  //@ts-ignore
  const maxCol = sheet?.["!ref"].split(":")[1].charCodeAt(0) - 65;
  for (let i = 0; i <= maxCol; i++) {
    sheet["!cols"][i] = { wch: 16 };
  }
  //@ts-ignore
  // const maxRow = sheet['!ref'].split(':')[1].slice(1) - 1;
  // for (let i = 0; i <= maxRow; i++) {
  //   sheet[`${XLSX.utils.encode_row(i)}`] = { ht: 30 };
  // }
}

export const nestedTableExcel = (
  idName: string,
  location: Location,
  businessUserInfo: UserInfoResponse,
  fromDate: string,
  toDate: string,
  col_Span: number,
  uptoDate?: string
) => {
  const table = document.getElementById(idName);
  const additionHtml = `
  <div>
  <style>
    table thead th {
      text-align: center;
    }
  </style>

  
  <table>
    <thead>
      <th colspan=${col_Span}></th>
      <tr>
        <th style='text-align: center;' colspan=${col_Span} rowspan='2' >${
    businessUserInfo?.business_info.name
  }</th>
      </tr>
    </thead>
  </table>
  <table>
    <tr>
      <th style='text-align: center;' colspan=${col_Span}>
        ${businessUserInfo?.business_info.address}
      </th>
    </tr>
  </table>
  <table>
    <thead>
      <tr>
        <th style='text-align: center;'  colspan=${col_Span} rowspan='2' >${HeaderUpdate(
    location.pathname.slice(1),
    "-"
  )}
        </th>
      </tr>
    <thead>
  </table>
  <table>
    <thead>
    <tr>
        <th style='text-align: center;'  colspan=${col_Span}> ${
    uptoDate !== ""
      ? `As an ${uptoDate} `
      : `For the period from ${fromDate} to ${toDate}`
  } 
        </th>
      </tr>
    <thead>
   
  </table>

</div>
`;

  // @ts-ignore
  const html = table?.outerHTML;
  const finalHTML = additionHtml + html;
  const url = "data:application/vnd.ms-excel;base64," + btoa(finalHTML);
  const link = document.createElement("a");
  const fileName = HeaderUpdate(location.pathname.slice(1), "-") + ".xls";
  link.download = fileName;
  link.href = url;
  link.click();
};

export const exportToExcel = (
  idName: string,
  columns: NestedColumnOptions[],
  location: Location,
  businessUserInfo: UserInfoResponse,
  date: FilterDate,
  roleId?: PatronRoles
) => {
  const table = document.getElementById(idName);
  const newTable = table?.cloneNode(true);
  const header = (newTable as HTMLTableElement)?.tHead?.rows[
    nestedColumnCounts(columns)
  ].cells;
  const secondrow = (newTable as HTMLTableElement)?.tHead?.rows[
    nestedColumnCounts(columns)
  ];
  for (let i = 0; i < Number(header?.length); i++) {
    if (header?.[i].textContent?.toLowerCase() === "action") {
      const columnIndex = i;
      const rows = (newTable as HTMLTableElement).tBodies[0].rows;
      for (let j = 0; j < rows.length; j++) {
        rows[j].deleteCell(columnIndex);
      }
      secondrow?.deleteCell(secondrow.cells.length - 1);
    }
  }

  const requireColSpan = secondrow?.cells.length;
  const dividedCols = Number(requireColSpan) / 3;

  const additionHtml = `
  <div>
  <style>
  table thead th {
    text-align: center;
  }
  
  </style>
    <thead>
    <td  colspan=${requireColSpan}></td>
  </thead>
</table>
    <table>
      <thead>
        <th style='text-align: center;' colspan=${requireColSpan} rowspan='2' >${
    businessUserInfo?.business_info.name
  }</th>
      </thead>
    </table>
    <table>
    <thead>
    <td  colspan=${requireColSpan}></td>
    </table>

  </thead>
    <table>
      <thead>
        <th style='text-align: center;'  colspan=${requireColSpan} rowspan='2' >      ${HeaderUpdate(
    location.pathname.slice(1),
    "-"
  )}
        ${roleId ? `(For ${DeterminePatronRoles(roleId)})` : ""}
        </th>
      </thead>
    </table>
    <thead>
    <td  colspan='4'></td>
    </table>

    ${
      date?.dateFrom && date.dateTo
        ? `
        <table>
        <thead>
        <th style='padding:10px; text-align: center;' colSpan=${requireColSpan}>
        From:${date.dateFrom} to ${date.dateTo}
        </th>

      </thead>
      </table>
    `
        : ""
    }

    <table>
      <thead>
      <th colspan=${Math.floor(dividedCols)} style='text-align: center;' >
      Name of Firm:${businessUserInfo?.business_info.name}
      </th>
    </th>

    <th colspan=${Math.floor(dividedCols)} style='text-align: center;'>
      PAN:${businessUserInfo?.business_info.pan}
    </th>

    <th
    colspan=${Math.ceil(dividedCols)}
      style='text-align: center;'

     
    >
      <span className="mx-5">Duration:</span>
      <span>Month: ______ </span>
      <span> Year: ______ </span>
    </th>
      </thead>
    </table>

    <table>
    <thead>
      <td  colspan='4'></td>
    </thead>
  </table>
  </div>
   
    `;
  //Adds the additional titles

  (newTable as HTMLElement).insertAdjacentHTML("afterbegin", additionHtml);

  const sheet1 = XLSX.utils.table_to_sheet(newTable);
  ExcelColumnWidth(sheet1);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet1, "Sheet1");

  const fileName =
    HeaderUpdate(location.pathname.slice(1), "-") +
    new Date().toLocaleDateString();
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
