import { useLocation } from "react-router-dom"
import { Navigate } from "react-router-dom"
import { ROLE } from "../../EnumValues"
import { AccessDeniedPage } from "../AccessDeniedPage/AccessDeniedPage"
import { useAuth } from "../context/auth/auth"

export const PrivateRoute = ({
  children,
  roles,
}: {
  children: JSX.Element
  roles: Array<ROLE>
}) => {
  let location = useLocation()
  const { isAuthenticated, isLoading, businessUserInfo } = useAuth()
  // const { showErrorMessage } = useToast()

  if (isLoading) {
    return <p className="container">Checking auth..</p>
  }

  const userHasRequiredRole =
    businessUserInfo && roles.includes(businessUserInfo.user_info.role[0].id)
      ? true
      : false

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return (
      <>
        <AccessDeniedPage />
      </>
    ) // build your won access denied page (sth like 404)
  }

  return children
}
