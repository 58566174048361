import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import { checkNumber, replaceDecimal } from "../../utils/services"
import {
  NewBillFormValues,
  useNewBillFormikContext,
} from "../BillPage/BillFormikWrapper";
import { ProductEdit } from "../Api/Get/GetApi";
import { PopupContext } from "../PopUp/Popup-Context";
import "./UpdatePrice.scss";
import { BatchesContext } from "../context/BatchesContext/BatchesContext";
import { UpdateAmount } from "../ProductPage/ProductFormikPage";
import { Loader } from "../Loader/Loader";
import { ReportType } from "../../EnumValues";

export interface UpdatePriceProps {
  productId?: number;
  priPrice: number;
  secPrice: number;
  relation?: number;
  alterPrimaryAndSecondary?: (
    primaryValue: string,
    secondaryValue: string
  ) => void;
  values: NewBillFormValues;
  index: number;
}

export interface UpdatedValProps {
  primary_price: string;
  secondary_price: string;
}

export const UpdatePrice: React.FC<UpdatePriceProps> = ({
  productId,
  priPrice,
  secPrice,
  relation,
  alterPrimaryAndSecondary,
  values,
  index,
}) => {
  const { setIsModalOpen, closeModal } = useContext(PopupContext);
  const [priVal, setPriVal] = useState(priPrice);
  const [secVal, setSecVal] = useState(secPrice);
  const [isLoading, setIsLoading] = useState(false);

  const { setFieldValue } = useNewBillFormikContext();
  const {
    selectedBatches,
    setSelectedBatches,
  } = useContext(BatchesContext);

  const UpdatePrices = (primaryVal: any, secondaryVal: any) => {
    const cloned = [...values.bill_details];
    cloned[index].rate.primary_price = Number(primaryVal);
    cloned[index].rate.secondary_price = Number(secondaryVal);
    cloned[index].amt = UpdateAmount(index, cloned).amount;
    cloned[index].sub_amt = UpdateAmount(index, cloned).netAmount;
    setFieldValue("bill_details", cloned);
  };

  const updateBatchValue = (
    index: number,
    primary: number,
    secondary: number
  ) => {
    const cloned = [...selectedBatches];
    cloned[index].product_price[0].primary_price = primary;
    cloned[index].product_price[0].secondary_price = secondary;
    setSelectedBatches(cloned);
  };

  const handleUpdate = () => {
    const data = {
      primary_price: priVal,
      secondary_price: secVal,
    };
    setIsLoading(true);
    ProductEdit(productId, data)
      .then((res) => {
        toast.success("Product price updated successfully");
        alterPrimaryAndSecondary?.(priVal.toString(), secVal.toString());
        if (values.transaction_type === ReportType.Sales) {
          updateBatchValue(index, priVal, secVal);
        }
        UpdatePrices(priVal.toString(), secVal.toString());
        closeModal();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to submit updated product";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.status.status_message) {
          errorMessage = err.response.data.status.status_message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="price-update">
        <div className="row">
          <div className="col-md-6">
            <div>
              <label className="input-label">Primary Price</label>
              <input
                className="input-field"
                placeholder="Enter updated price"
                pattern="[0-9]*"
                defaultValue={replaceDecimal(priPrice)}
                name="primary_price"
                onFocus={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
                value={priVal}
                onChange={(e: any) => {
                  let val = checkNumber(e.target.value, "decimalToo");
                  let sec = Number(val) / (relation ?? 0);
                  setPriVal(replaceDecimal(val));
                  setSecVal(replaceDecimal(sec));
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label className="input-label">Secondary Price</label>
              <input
                disabled={relation === 1}
                className="input-field"
                placeholder="Enter updated price"
                pattern="[0-9]*"
                defaultValue={values?.bill_details[index]?.rate.secondary_price}
                name="secondary_price"
                onFocus={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
                max={`${Number(priVal)}`}
                value={secVal}
                onChange={(e) => {
                  if (parseFloat(e.target.value) > Number(priVal)) {
                    toast.warn("Secondary price can't exceed primary price");
                    e.preventDefault();
                    return false;
                  }
                  let val = checkNumber(e.target.value, "decimalToo");
                  setSecVal(replaceDecimal(val));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-right">
        <div className="d-end">
          <div>
            <button
              className="light-secondary mr-16"
              type="button"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="main-button"
              type="submit"
              disabled={isLoading}
              onClick={() => {
                handleUpdate();
              }}
            >
              {isLoading ? (
                <div>
                  <Loader loaderType="Spinner" />
                </div>
              ) : (
                "Update Price"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
