import { ErrorMessage, Field } from "formik";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  capitalCase,
  formatSelectOptions,
  replaceDecimal,
} from "../../utils/services";
import { AddablePrice } from "../AddablePrice/AddablePrice";
import { useProductWrapper } from "../AddablePrice/ProductWrapper";
import { PopupContext } from "../PopUp/Popup-Context";
import "./NewProductModal.scss";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useCategories, GetProducts } from "../Api/Get/GetApi";
import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import { Loader } from "../Loader/Loader";
import { Select } from "../Select";
import { CategoryResponse } from "../Api/types";
import CreatableSelect from "react-select/creatable";
import { SelectOptions } from "../../Pages/SalesReturn/types";

function useFocusNext() {
  const controls = useRef([]);

  const handler = (event: any) => {
    if (event.keyCode === 13) {
      //@ts-ignore
      const index = controls.current.indexOf(event.target);
      const next = controls.current[index + 1];
      const current = controls.current[index];
      //@ts-ignore
      if (current.type !== "submit") {
        //@ts-ignore
        next && next.focus();
        event.preventDefault();
      }
    }
  };

  return useCallback((element: any) => {
    //@ts-ignore
    if (element && !controls.current.includes(element)) {
      //@ts-ignore
      controls.current.push(element);
      element.addEventListener("keydown", handler);
    }
  }, []);
}

export interface CreateblaProductOption {
  value: number;
  label: string;
}

export interface ProductsListResonse {
  created_at: string;
  name: string;
  secondary_unit: string;
  is_damage_reclaimable: boolean;
  updated_null: string | null;
  id: number;
  primary_unit: string;
  relation: number;
  is_price_fixed: boolean;
  product_category_id: number;
  product_category_data: {
    id: number;
    name: string;
  };
  tax: number;
}

interface NewProductModalProps {
  mode: "edit" | "new";
}

export const NewProductModal: React.FC<NewProductModalProps> = ({ mode }) => {
  const [claimable, setClaimable] = useState(false);
  // const [fixPrice, setFixPrice] = useState(true);
  const { setIsModalOpen } = useContext(PopupContext);
  const { getFieldProps, handleSubmit, setFieldValue, values, isSubmitting } =
    useProductWrapper();
  const focusNextRef = useFocusNext();
  const [productValues, setProductValues] = useState<ProductsListResonse[]>();
  const [editableUnits, setEditableUnits] = useState(false);
  const [inputString, setInputString] = useState("");
  const [productNameEdit, setProductNameEdit] = useState(false);
  const [categoryString, setCategoryString] = useState("");
  const [categoryInput, setCategoryInput] = useState<SelectOptions | null>();
  const [selectedProd, setSelectedProd] = useState<SelectOptions[] | null>();
  const { data: categories } = useCategories();
  const formatOptions = (productList: ProductsListResonse[]) => {
    const tempOptions = productList.map((val) => {
      return {
        value: val.id,
        label: val.name,
      };
    });
    return tempOptions;
  };

  // const formatCategories = (data: CategoryResponse[]) => {
  //   if (data) {
  //     const formatedOption = data.map((val) => {
  //       return { value: val.id, label: val.name };
  //     });
  //     return formatedOption;
  //   }
  //   return [];
  // };

  const AllProducts = (prodName: string) => {
    return GetProducts(prodName).then((res) => {
      setProductValues(res.data.data);
      return formatOptions(res.data.data);
    });
  };

  const updateUnits = (id: string | number) => {
    // typeof id is checked for creatable value input or selected search value
    if (typeof id === "number") {
      const findProduct = productValues?.find((val) => val.id === id);
      if (mode === "new") {
        setEditableUnits(true);
      } else {
        setEditableUnits(false);
      }
      setFieldValue("primary_unit", findProduct?.primary_unit);
      setFieldValue("secondary_unit", findProduct?.secondary_unit);
      setFieldValue("relation", findProduct?.relation);
      setFieldValue(
        "is_damage_reclaimable",
        findProduct?.is_damage_reclaimable
      );
      setFieldValue("is_price_fixed", findProduct?.is_price_fixed);
      setFieldValue(
        "product_category_id",
        findProduct?.product_category_data.id
      );

      setCategoryInput((p) => ({
        ...p,
        label: findProduct?.product_category_data.name as string,
        value: findProduct?.product_category_data.id,
      }));
    } else {
      setEditableUnits(false);
      setFieldValue("primary_unit", "");
      setFieldValue("secondary_unit", "");
      setFieldValue("relation", "");
      setFieldValue("is_damage_reclaimable", false);
      setFieldValue("is_price_fixed", true);
      setFieldValue("product_category_id", "");
      setCategoryInput(null);
    }
  };

  useEffect(() => {
    if (values?.name && values.name.length > 0 && !productNameEdit) {
      AllProducts(values?.name)
        .then((res) => {
          setSelectedProd(res);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [values?.name]);

  useEffect(() => {
    if (selectedProd && selectedProd?.length > 0) {
      if (selectedProd?.[0]?.value) {
        updateUnits(selectedProd?.[0]?.value);
      }
    }
  }, [selectedProd]);

  const HandleCategory = (e: SelectOptions | null) => {
    if (e) {
      setCategoryInput(e);
      if (typeof e?.value === "string") {
        const item = capitalCase(e?.label as string);
        setFieldValue("product_category_id", item);
      } else {
        setFieldValue("product_category_id", e?.value);
      }
    } else {
      setCategoryInput(null);
      setFieldValue("product_category_id", "");
    }
  };

  return (
    <>
      <div className="new-product">
        <div className="">
          <div className="row">
            <div className="col-md-6">
              <label className="input-label">Product Name </label>
              {mode === "edit" ? (
                <>
                  <Field
                    disabled={editableUnits}
                    className="input-field capitalize"
                    placeholder="Enter Product Name"
                    value={values.name}
                    onChange={(e: any) => {
                      setFieldValue("name", e.target.value);
                      setProductNameEdit(true);
                    }}
                    ref={focusNextRef}
                  />
                  <ErrorMessage
                    name={"name"}
                    component="div"
                    className="error"
                  />
                </>
              ) : (
                <AsyncCreatableSelect
                  isClearable={true}
                  loadOptions={AllProducts}
                  // value={selectedProd}
                  inputValue={inputString}
                  onInputChange={(e) => {
                    const item = capitalCase(e);
                    setInputString(item);
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                  }}
                  onChange={(e) => {
                    updateUnits(e?.value as string | number);
                    if (
                      typeof e?.value === "string" &&
                      typeof e?.label === "string"
                    ) {
                      const item = capitalCase(e?.label);
                      setFieldValue("name", item);
                    } else {
                      setFieldValue("name", e?.value);
                    }
                  }}
                />
              )}
              <ErrorMessage name={"name"} component="div" className="error" />
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="input-label">Category Name </label>
                <CreatableSelect
                  isDisabled={editableUnits}
                  isClearable={true}
                  options={formatSelectOptions<CategoryResponse>(
                    categories as CategoryResponse[]
                  )}
                  inputValue={categoryString}
                  onInputChange={(e) => {
                    const item = capitalCase(e);
                    setCategoryString(item);
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                  }}
                  placeholder="Select Category"
                  value={categoryInput}
                  onChange={(e) => {
                    HandleCategory(e);
                  }}
                />

                <ErrorMessage
                  name={"product_category_id"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="mg-btm-15"></div>
            <div className={`${isMobile ? "col-md-6" : "col-md-4"}`}>
              <label className="input-label">Primary Unit</label>
              <Field
                className="input-field capitalize"
                placeholder="Enter primary unit"
                disabled={editableUnits}
                onKeyDown={(event: any) => {
                  if (!/[A-Za-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...getFieldProps("primary_unit")}
                ref={focusNextRef}
              />
              <ErrorMessage
                name={"primary_unit"}
                component="div"
                className="error"
              />
            </div>
            <div className={`${isMobile ? "col-md-6" : "col-md-4"}`}>
              <label className="input-label">Secondary Unit</label>
              <Field
                disabled={editableUnits}
                className="input-field capitalize"
                placeholder="Enter secondary unit"
                onKeyPress={(event: any) => {
                  if (!/[A-Za-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...getFieldProps("secondary_unit")}
                ref={focusNextRef}
              />
              <ErrorMessage
                name={"secondary_unit"}
                component="div"
                className="error"
              />
            </div>
            <div className={`${isMobile ? "relation-block" : "col-md-4"}`}>
              <label className="input-label">Relation</label>
              <Field
                disabled={editableUnits}
                className="input-field"
                placeholder="Enter relation"
                pattern="[0-9]*"
                ref={focusNextRef}
                onKeyPress={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
                value={values.relation}
                onChange={(e: any) => {
                  setFieldValue(
                    "relation",
                    replaceDecimal(
                      e.target.value <= 0 ||
                        e.target.value === undefined ||
                        isNaN(e.target.value) === true
                        ? ""
                        : e.target.value
                    )
                  );
                }}
              />
              <ErrorMessage
                name={"relation"}
                component="div"
                className="error"
              />
            </div>
          </div>
          <div className="line-border mt-3">
            <div className="col-md-3 mb-4">
              <label className="input-label">Product Bar Code</label>
              <Field
                className="input-field"
                placeholder="Enter Product Bar Code"
                onKeyPress={(event: any) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...getFieldProps("product_bar_code")}
                ref={focusNextRef}
              />
            </div>
            <div className="col-md-3 mb-4">
              <label className="input-label">Tax</label>
              <Select
                name="tax"
                className="input-field"
                value={values.tax}
                onChange={(e: any) => {
                  setFieldValue("tax", Number(e.target.value));
                }}
              >
                <option value="" disabled>
                  Select Vat
                </option>
                <option value="13">13%</option>
                <option value="0">No Vat</option>
              </Select>
            </div>
            <div className="check-box-area">
              <div className="d-flex" onClick={() => setClaimable(!claimable)}>
                <input
                  type="checkbox"
                  checked={values.is_damage_reclaimable}
                  {...getFieldProps("is_damage_reclaimable")}
                />
                <label className="content">Is damage reclaimable?</label>
              </div>
            </div>
          </div>

          <div className="fix-price">
            {/* <div
              className="check-box-area d-flex"
              onClick={() => setFixPrice(!fixPrice)}
            >
              <input
                type="checkbox"
                checked={values.is_price_fixed}
                {...getFieldProps("is_price_fixed")}
              />
              <label className="content">Is price fixed?</label>
            </div> */}
            <div>
              <div className="d-flex justify-content-between">
                <div className="batch-detail">
                  <label className="input-label">Batch</label>
                  <input
                    className="input-field"
                    placeholder="Enter batch name"
                    {...getFieldProps("batch")}
                    ref={focusNextRef}
                  />
                  <ErrorMessage
                    name={"batch"}
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-5">
                  <div className="calender-section">
                    <label htmlFor="date" className="input-label">
                      Product Expiry Date
                    </label>
                    <NepaliDatePicker
                      className="input-field"
                      defaultDate={""}
                      placeholder="Select a date"
                      showResetDateButton={true}
                      onDateSelect={(value: any) => {
                        setFieldValue("expiry_date", value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <AddablePrice inputRef={focusNextRef} />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-right">
        <div className="d-end">
          <div>
            <button
              className="light-secondary mr-16"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              disabled={isSubmitting}
              className="main-button"
              type="submit"
              ref={focusNextRef}
              onClick={() => {
                handleSubmit();
              }}
            >
              {isSubmitting ? (
                <div>
                  <Loader loaderType="Spinner" universalCenter={false} />
                </div>
              ) : (
                <>{mode === "edit" ? "Update Product" : "Add Product"}</>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
