import { useLocation } from "react-router-dom"
import { HeaderUpdate } from "../../utils/services"
import { useAuth } from "../context/auth/auth"
import "./PrintTable.scss"

interface PrintTableProps {
  columns?: string[]
  data?: any[]
  myRef?: any
}

export const PrintTable: React.FC<PrintTableProps> = ({
  columns,
  data,
  myRef,
}) => {
  const { businessUserInfo } = useAuth()
  const colSpans = columns && Math.floor(Number(columns.length) / 3)

  const location = useLocation()

  return (
    <>
      <div className="print_table" ref={myRef} style={{ display: "none" }}>
        <table className="user-img-wrap">
          {/* <td  className="logo-section">
            <img src={businessUserInfo?.business_info.logo} alt="icon" />
          </td> */}
          <th colSpan={columns?.length} rowSpan={2} className="logo-title">
            {businessUserInfo?.business_info.name}
          </th>
        </table>
        <table>
          <th colSpan={columns?.length} rowSpan={2}>
            {HeaderUpdate(location.pathname.slice(1), "-")}
          </th>
        </table>

        {/* <div className="text-center">
          <div className="block-title bolder">
            Date: <span className="lighter">457</span>
          </div>
          <div className="block-title bolder">
            to <span className="lighter">sdf</span>
          </div>
        </div> */}
        <table className="top-section">
          <th colSpan={colSpans} rowSpan={2}>
            Name of Firm:{businessUserInfo?.business_info.name}
          </th>

          <th colSpan={colSpans} rowSpan={2}>
            PAN:{businessUserInfo?.business_info.pan}
          </th>

          {/* <p className="d-flex"> */}
          {/* <th colSpan={1} rowSpan={2}>
              Duration:
            </th> */}
          <th
            colSpan={colSpans}
            rowSpan={2}
            style={{
              display: "flex",
              gap: "2",
              justifyContent: "space-between",
            }}
            className="mg-left duration"
          >
            <span className="mx-5">Duration:</span>
            <span>Month: ______ </span>
            <span> Year: ______ </span>
          </th>
          {/* <th
            colSpan={Math.floor(Number(colSpans) / 2)}
            rowSpan={2}
            className="mg-left"
          >
            Year: ______{" "}
          </th> */}
          {/* </p> */}
        </table>
        <div className="table">
          <table id="my-table" className="table table-hover table-bordered">
            <thead className="tablehead ">
              <tr>
                {data && data?.length > 0 ? (
                  <>
                    {Object.keys(data?.[0]).map((val, id) => {
                      if (val.toLowerCase() === "action") return
                      return <th key={id}>{HeaderUpdate(val)}</th>
                    })}
                  </>
                ) : (
                  <>
                    {columns?.map((val, id) => {
                      if (val.toLowerCase() === "action") return
                      return <th key={id}>{val}</th>
                    })}
                  </>
                )}

                {/* <th>S.N</th>
                <th>customer name</th>
                <th>customer pan no</th>
                <th>goods</th>
                <th>unit</th> */}
              </tr>
            </thead>

            <tbody className="table-body-content">
              {data?.map((val, index) => {
                return (
                  <tr className="tablerow">
                    {Object.entries(val).map(([key, value]) => {
                      if (key === "action") {
                        return
                      }
                      //@ts-ignore
                      return <td>{value}</td>
                    })}
                  </tr>
                )
              })}
              {/* <td>1</td>
                <td>bimesh</td>
                <td>2022</td>
                <td>gfhjl</td>
                <td>fy</td> */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
