import { useContext, useRef, useState } from "react";
import { UpdatePrintCount } from "../Api/Get/GetApi";
import { useReactToPrint } from "react-to-print";
import { ToWords } from "to-words";
import { SalesReturnInvoiceResponse } from "../Api/Get/GetApiTypes";
import { BillGenerate } from "../BillGenerate/BillGenrate";
import { PopupContext } from "../PopUp/Popup-Context";
import "./Receipt.scss";
import {
  englishToNepaliAmountConverter,
  englishToNepaliConverter,
} from "../../utils/services";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { DocumentContext } from "../BillPage/Document-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { BillReturnType } from "../../EnumValues";
import { convertToNepaliWords } from "../../utils/num-to-nepaliwords";

interface CreditNotePageProps {
  invoiceData?: SalesReturnInvoiceResponse;
  isLoading?: boolean;
  returnType?: BillReturnType;
}
export const CreditNotePage: React.FC<CreditNotePageProps> = ({
  invoiceData,
  isLoading,
  returnType = BillReturnType.Sales,
}) => {
  const componentRef = useRef(null);
  const { closeModal } = useContext(PopupContext);
  const [loading, setLoading] = useState(false);
  const { documentNumber, setResStatus, langData, language } =
    useContext(DocumentContext);

  const toWords = new ToWords({
    converterOptions: { currency: true, ignoreDecimal: false },
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { showErrorMessage } = useToast();

  const currentTime = new Date();

  const handlePrintCount = (document_number: string) => {
    setLoading(true);
    UpdatePrintCount(document_number)
      .then((res) => {
        handlePrint();
        setResStatus?.(false);
      })
      .catch((err) => {
        showErrorMessage("Something Went wrong while printing");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isNepali = language === "ne";

  return (
    <>
      {isLoading ? (
        <>
          <BillGenerate />
        </>
      ) : (
        <div className="receipt">
          <div
            className={`print-page ${isNepali && "nepali-text"}`}
            ref={componentRef}
          >
            <div className="receipt-section-mg-btn"></div>
            <div className="receipt-section">
              <div className="p-receipt">
                {returnType === BillReturnType.Sales && (
                  <div className="d-space-between">
                    <div className="logo-detail">
                      <div className="d-flex logo-section">
                        {invoiceData?.business_info.logo ? (
                          <div className="logo">
                            <img
                              src={invoiceData?.business_info.logo}
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div className="large-title">
                            {invoiceData?.business_info.name
                              .charAt(0)
                              .toUpperCase()}
                          </div>
                        )}
                      </div>

                      <div>
                        <h2 className="small-title">
                          {invoiceData?.business_info.name}
                        </h2>
                        <p className="content">
                          {invoiceData?.business_info.address}
                        </p>

                        <div className="div-flex">
                          <div className="bold-content">
                            {langData.customerDetails.phone}:{" "}
                            <span className="content">
                              {englishToNepaliConverter(
                                String(
                                  `+977-${invoiceData?.business_info?.mobile_no}`
                                )
                              )}
                            </span>
                          </div>
                          <div className="bold-content pan-box">
                            {/* <div className="vertical-line"></div> */}
                            {langData.customerDetails.panNo}:
                            <span className="content">
                              {" "}
                              {englishToNepaliConverter(
                                String(invoiceData?.business_info?.pan)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="receipt-detail-block d-end">
                      <h2 className="large-title">
                        {returnType === BillReturnType.Sales
                          ? "Credit"
                          : "Debit"}{" "}
                        Note
                      </h2>

                      {/* {titleCopy? <div className="">Copy of Original 1</div>:""} */}
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th className="bold-content" scope="row">
                              {returnType === BillReturnType.Sales
                                ? langData.billDetails.credit
                                : langData.billDetails.debit}{" "}
                              {langData.footer.note} {langData.billDetails.no}.
                            </th>
                            <td className="content">
                              {returnType === BillReturnType.Sales
                                ? invoiceData?.bill_user_info.document_no
                                : invoiceData?.bill_user_info.bill_no}
                            </td>
                          </tr>

                          <tr>
                            <th className="bold-content" scope="row">
                              {langData.billDetails.invoiceNo}:
                            </th>
                            <td className="content">
                              {returnType === BillReturnType.Sales
                                ? invoiceData?.bill_user_info
                                    .ref_invoice_document_no
                                : invoiceData?.bill_user_info.ref_invoice_no}
                            </td>
                          </tr>
                          <tr>
                            <th className="bold-content" scope="row">
                              {langData.billDetails.billMiti}:
                            </th>
                            <td className="content">
                              {englishToNepaliConverter(
                                String(
                                  invoiceData?.bill_user_info?.bill_miti
                                    .split("/")
                                    .join(" - ")
                                )
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div
                  className={
                    returnType === BillReturnType.Sales
                      ? "d-space-between header-wrapper"
                      : "d-space-between"
                  }
                >
                  <div className="receipt-header">
                    <div className="receipt-header-block">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th className="bold-content" scope="row">
                              {langData.customerDetails.customerName}:
                            </th>
                            <td className="content">
                              {invoiceData?.customer_user_info.name}
                            </td>
                          </tr>
                          {invoiceData?.customer_user_info.additional_info
                            .address ||
                          invoiceData?.customer_user_info.additional_info
                            .additionalProp1 ? (
                            <tr>
                              <th className="bold-content" scope="row">
                                {langData.customerDetails.address}:
                              </th>
                              <td className="content">
                                {invoiceData?.customer_user_info.additional_info
                                  .address ||
                                  invoiceData?.customer_user_info
                                    .additional_info.additionalProp1}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          <tr>
                            <th className="bold-content" scope="row">
                              {langData.customerDetails.phone}:
                            </th>
                            <td className="content">
                              {englishToNepaliConverter(
                                String(
                                  `+977-${invoiceData?.customer_user_info?.mobile_no}`
                                )
                              )}
                            </td>
                          </tr>
                          {invoiceData?.customer_user_info.pan && (
                            <tr>
                              <th className="bold-content" scope="row">
                                {langData.customerDetails.panNo}:
                              </th>
                              <td className="content">
                                {englishToNepaliConverter(
                                  String(invoiceData?.customer_user_info?.pan)
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="last-block">
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th className="bold-content" scope="row">
                            {langData.billDetails.transactionDate}:
                          </th>
                          <td className="content">
                            {" "}
                            {englishToNepaliConverter(
                              String(
                                invoiceData?.bill_user_info?.bill_miti_ad
                                  .split("/")
                                  .join(" - ")
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="bold-content" scope="row">
                            {langData.billDetails.printDateTime}:
                          </th>
                          <td className="content">
                            {englishToNepaliConverter(
                              String(currentTime.toLocaleString() + "")
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="bold-content" scope="row">
                            {langData.billDetails.IssuedBy}:
                          </th>
                          <td className="content">
                            {" "}
                            {invoiceData?.bill_user_info.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="full-table">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th rowSpan={2} className="header-align-center sn-head">
                          {langData.productHeads.SN}
                        </th>
                        <th
                          rowSpan={2}
                          className="header-align-center name-sec"
                        >
                          {langData.productHeads.productName}
                        </th>
                        <th
                          rowSpan={2}
                          className="end-align header-align-center"
                        >
                          {langData.productHeads.batchNo}
                        </th>

                        <th
                          rowSpan={2}
                          className="end-align header-align-center"
                        >
                          {langData.productHeads.expiryDate}
                        </th>

                        <th colSpan={2} className="center-align">
                          {langData.productHeads.quantity}
                        </th>

                        <th colSpan={2} className="center-align">
                          {langData.productHeads.rate}
                        </th>
                        <th
                          rowSpan={2}
                          className="end-align header-align-center"
                        >
                          {langData.productHeads.discount}
                        </th>
                        <th
                          rowSpan={2}
                          className="end-align header-align-center"
                        >
                          {langData.productHeads.tax}
                        </th>
                        <th
                          rowSpan={2}
                          className="end-align header-align-center"
                        >
                          {langData.productHeads.amount}
                        </th>
                        {/* <th
                          rowSpan={2}
                          className="end-align header-align-center"
                        >
                          {langData.productHeads.netAmount}
                        </th> */}
                      </tr>
                      <tr className="center-align">
                        <th>{langData.productHeads.pri}</th>
                        <th>{langData.productHeads.sec}</th>

                        <th>{langData.productHeads.pri}</th>
                        <th>{langData.productHeads.sec}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData?.bill_detail &&
                        Array(16)
                          .fill(undefined)
                          ?.map((_, index) => {
                            const item = invoiceData?.bill_detail[index];
                            return (
                              <tr
                                style={{
                                  visibility: item ? "visible" : "hidden",
                                }}
                                key={index}
                              >
                                <td className="padd-5">
                                  {item ? index + 1 : ""}
                                </td>
                                <td className="padd-5 name-sec">
                                  {item?.product}
                                </td>
                                <td className="padd-5"> </td>

                                <td className="padd-5"> </td>

                                <td className="padd-5">
                                  {item?.primary_unit > 0
                                    ? englishToNepaliConverter(
                                        String(item?.primary_unit)
                                      ) + item?.primary_unit_measure
                                    : "-"}
                                </td>
                                <td className="padd-5">
                                  {item?.secondary_unit > 0
                                    ? englishToNepaliConverter(
                                        String(item?.secondary_unit)
                                      ) + item?.secondary_unit_measure
                                    : "-"}
                                </td>

                                <td className="padd-5">
                                  {item?.primary_unit > 0
                                    ? englishToNepaliAmountConverter(
                                        item?.primary_price
                                      )
                                    : "-"}
                                </td>
                                <td className="padd-5">
                                  {item?.secondary_unit > 0
                                    ? englishToNepaliAmountConverter(
                                        item?.secondary_price
                                      )
                                    : "-"}
                                </td>

                                <td className="padd-5 end-col">
                                  {englishToNepaliAmountConverter(
                                    invoiceData?.bill_user_info
                                      .is_total_discount
                                      ? 0
                                      : item?.discount
                                  )}
                                </td>
                                <td className="padd-5 end-col">
                                  {englishToNepaliAmountConverter(
                                    String(item?.tax > 0 ? "13" : "0")
                                  )}
                                  %
                                </td>
                                <td className="padd-5 end-col">
                                  {englishToNepaliAmountConverter(
                                    item?.sub_total
                                  )}
                                </td>
                                {/* <td className="padd-5 end-col">
                                  {englishToNepaliAmountConverter(
                                    item?.total
                                  )}
                                </td> */}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <div className="footer-div">
                  <div className="receipt-info">
                    <div className="receipt-info-section">
                      <div className="receipt-info-block">
                        <h4 className="block-title">
                          {" "}
                          {langData.transactionDetails.inWords}:
                        </h4>
                        <p className="small-content capitalize">
                          {" "}
                          {isNepali
                            ? convertToNepaliWords(
                                invoiceData?.amount_detail.total_amount || 0
                              )
                            : toWords.convert(
                                invoiceData?.amount_detail.total_amount || 0
                              )}
                        </p>
                      </div>
                    </div>
                    <div className="receipt-info-section ">
                      <div className="receipt-info-block">
                        <h4 className="block-title capitalize">
                          {" "}
                          {langData.footer.note}:
                        </h4>
                        <p className="small-content">Thank You</p>
                      </div>
                    </div>
                    <div className="receipt-info-block">
                      <h4 className="block-title">
                        {" "}
                        {langData.signatures.authorized}:
                      </h4>
                      <p className="small-content customers-sign">
                        ----------------------------------
                      </p>
                    </div>
                  </div>

                  <div className="receipt-calculation">
                    <div className="receipt-calculation-block ">
                      <p className="small-content">
                        {langData.transactionDetails.subTotal}
                      </p>
                      <p className="small-content text-right">
                        {langData.transactionDetails.rupees}.{" "}
                        {englishToNepaliAmountConverter(
                          invoiceData?.amount_detail.sub_total || 0
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="small-content">
                        {langData.transactionDetails.discount}
                      </p>
                      <p className="small-content text-right">
                        {langData.transactionDetails.rupees}.{" "}
                        {englishToNepaliAmountConverter(
                          invoiceData?.bill_user_info.is_total_discount
                            ? invoiceData?.amount_detail.discount_received
                            : 0
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="bold-content">
                        {langData.transactionDetails.taxableAmt}
                      </p>
                      <p className="small-content text-right">
                        {langData.transactionDetails.rupees}.{" "}
                        {englishToNepaliAmountConverter(
                          Number(invoiceData?.amount_detail.taxable_amount)
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="small-content">
                        {langData.transactionDetails.vat} @{" "}
                        {langData.transactionDetails.vatPer}%
                      </p>
                      <p className="small-content text-right">
                        {langData.transactionDetails.rupees}.{" "}
                        {englishToNepaliAmountConverter(
                          invoiceData?.amount_detail.tax_paid || 0
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="bold-content ">
                        {langData.transactionDetails.total} (
                        {langData.transactionDetails.npr})
                      </p>
                      <p className="content text-right">
                        {langData.transactionDetails.rupees}.{" "}
                        {englishToNepaliAmountConverter(
                          invoiceData?.amount_detail.total_amount || 0
                        )}
                      </p>
                    </div>
                    <div className="receipt-info-block width-21">
                      <h4 className="block-title text-right">
                        {langData.signatures.customer}:
                      </h4>
                      <p className="small-content customers-sign text-right">
                        ----------------------------
                      </p>
                    </div>
                  </div>
                </div>

                {/* //This is statice for now */}

                <div className="receipt-info payment-information">
                  <div className="receipt-info-block ">
                    <h4 className="block-title">Payment Information</h4>
                    <p className="small-content">Bank Name:RBB</p>
                    <p className="small-content">Cheque No.: 789456</p>
                    <p className="small-content">
                      Cheque Issued Date: 2022-2-6
                    </p>
                    <p className="small-content">Amount: Rs. 40000</p>
                  </div>
                </div>
                <div className="receipt-info">
                  <div className="receipt-info-block receipt-footer">
                    <p className="small-content">
                      {langData.footer.computerGenerated}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-right">
            <div className="d-end">
              <div>
                <button
                  className="light-secondary mr-16"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                <ButtonComponent
                  isLoading={loading}
                  title="Print"
                  onClick={() => {
                    handlePrintCount(documentNumber);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
