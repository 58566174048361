import { CallIcon, PanIcon } from "../../assets/images/svg/svg";
import { useUserProfile } from "../Api/Get/GetApi";
import { ProfileHeader } from "../BusinessProfileHeader/ProfileHeader";
import { Loader } from "../Loader/Loader";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import "./BusinessProfilePage.scss";

interface PersonalDetailsProps {
  showPersonalDetails?: boolean;
}

export const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  showPersonalDetails,
}) => {
  const { data, isLoading } = useUserProfile();

  return (
    <>
      {isLoading ? (
        <>
          <Loader loaderType="Spinner" />
        </>
      ) : (
        <div className="profile-page">
          <div>
            <ProfileHeader
              showPersonalDetails={showPersonalDetails}
              profileData={data}
            />
          </div>
          <div className="profile-body">
            {data ? (
              <div className="row">
                <div className="col-md-6">
                  <div className="field-group">
                    <div className="icon-section">
                      <CallIcon />
                    </div>
                    <div className="desc-wrap">
                      <label className="input-label">Mobile Number:</label>
                      <p className="name content">{data?.mobile_no}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-group">
                    <div className="icon-section">
                      <PanIcon />
                    </div>
                    <div className="desc-wrap">
                      <label className="input-label">Pan number:</label>
                      <p className="name content">{data?.pan}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataPage />
            )}
          </div>
        </div>
      )}
    </>
  );
};
