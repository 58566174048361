import { useContext, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { EyeIcon, ThreeDotsIcon } from "../../assets/images/svg/svg";
import {
  useSalesReturnInvoice,
  useSalesReturnReport,
} from "../../components/Api/Get/GetApi";
import {
  SalesReturnDataResponse,
  Search,
} from "../../components/Api/Get/GetApiTypes";
import { DocumentContext } from "../../components/BillPage/Document-Context";
import { Table } from "../../components/CustomReactTable/CustomReactTable";
import { PopUp } from "../../components/PopUp/PopUp";
import { PopupContext } from "../../components/PopUp/Popup-Context";
import { CreditNotePage } from "../../components/Receipt/CreditNotePage";
import { FilterDate } from "../../components/SalesList/SalesListPage";
import {
  FormatDate,
  fixedValue,
  handleNoData,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";

export const SalesReturnReport = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [openView, setOpenView] = useState(false);
  
  const [searchTerm, setSearchTerm] = useState<Search>({
    customer_name: "",
    document_no: "",
  });

  const { openModal } = useContext(PopupContext);
  const { documentNumber, setDocumentNumber } = useContext(DocumentContext);
  const { data: creditNoteDetail, isLoading: creditNoteLoading } =
    useSalesReturnInvoice(documentNumber);

  const {
    data: auditReports,
    isLoading,
    refetch,
  } = useSalesReturnReport(
    searchTerm as Search,
    queryPageIndex,
    queryPageSize,
    date
  );

  const HandleView = (value: any) => {
    setOpenView(true);
    setDocumentNumber(value);
    openModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: "s_n",
      },
      {
        Header: "मिति",
        accessor: "date",
      },
      {
        Header: "बीजक",
        columns: [
          {
            Header: "बीजक नम्बर",
            accessor: "invoice_bill",
          },
          {
            Header: "खरिदकर्ताको नाम",
            accessor: "name",
          },
          {
            Header: "खरिदकर्ताको स्थायी लेखा नम्बर",
            accessor: "pan",
          },
          {
            Header: "वस्तु वा सेवाको नाम",
            accessor: "product_category",
          },
          {
            Header: "वस्तु वा सेवाको परिमाण",
            accessor: "quantity",
          },
          {
            Header: "वस्तु वा सेवाको एकाई",
            accessor: "unit",
          },
        ],
      },
      {
        Header: "जम्मा फिर्ता (रु)",
        accessor: "total",
        Cell: ({ value }: { value: number }) => {
          return parenthesisInNegativeValue(value);
        },
      },
      {
        Header: "स्थानीय कर छुटको फिर्ता  मूल्य (रु)",
        accessor: "tax_exempted_amount",
        Cell: ({ value }: { value: number }) => {
          return parenthesisInNegativeValue(value);
        },
      },
      {
        Header: "करयोग्य फिर्ता",
        columns: [
          {
            Header: "मूल्य (रु)",
            accessor: "taxable_amount",
            Cell: ({ value }: { value: number }) => {
              return parenthesisInNegativeValue(value);
            },
          },

          {
            Header: "कर (रु)",
            accessor: "tax",
            Cell: ({ value }: { value: number }) => {
              return parenthesisInNegativeValue(value);
            },
          },
        ],
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }: { value: any }) => {
          return (
            <div className="action-section">
              <Dropdown>
                <Dropdown.Toggle className="dropdown-icon" id="dropdown-basic">
                  <ThreeDotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      HandleView(value);
                    }}
                  >
                    <div>
                      <span className="icon">
                        <EyeIcon />
                      </span>
                      View
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const FormatTable = (
    array: SalesReturnDataResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          name: handleNoData(item.user_info.name),
          invoice_bill: item.transaction_main_detail[0].document_number,
          date: FormatDate(item.miti),
          discount: fixedValue(item.discount),
          tax: fixedValue(item.tax),
          taxable_amount: fixedValue(item.taxable_amount),
          total: fixedValue(item.grand_total ? item.grand_total : 0),
          pan: handleNoData(item.user_info.pan),
          action: item.document_number,
          product_category: item.product_category,
          quantity: "-",
          unit: "-",
          tax_exempted_amount: fixedValue(
            item.non_taxable_amount
          ),
        };
      });
      return formatData;
    }
    return [];
  };
  
  return (
    <>
      <div className="cbms-report">
        <div className="head-section">
          <div className="col-md-6">
            <div className="small-title">Sales Return Reports</div>
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          data={
            auditReports
              ? FormatTable(auditReports?.data, queryPageIndex, queryPageSize)
              : []
          }
          totalPages={
            auditReports &&
            totalPages(queryPageSize, auditReports.pagination.total_count)
          }
          showDate={true}
          setDate={setDate}
          totalEntries={auditReports && auditReports.pagination.total_count}
          queryPageIndex={queryPageIndex}
          setQueryPageIndex={setQueryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageSize={setQueryPageSize}
          date={date}
          excelDownload={true}
          searchArray={["customer_name", "document_no"]}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {documentNumber &&
          openView &&
          creditNoteDetail &&
          !creditNoteLoading && (
            <PopUp
              popupWidth={"80%"}
              renderComponent={
                <CreditNotePage
                  invoiceData={creditNoteDetail}
                  isLoading={creditNoteLoading}
                />
              }
            />
          )}
      </div>
    </>
  );
};
