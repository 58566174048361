import { TriangleDown, TriangleRignt } from "../../assets/images/svg/svg";
import { isJson, parenthesisInNegativeValue } from "../../utils/services";
import {
  IProfitLossList,
  ITradingAccountList,
  ITradingAccountSubList,
  ProfitLossList,
  TradingAccountList,
} from "../Api/Get/GetApiTypes";
import { Loader } from "../Loader/Loader";
import "./TradingAccount.scss";

export interface TableHeaderContentGroupProps {
  columns?: any;
  loading?: boolean;
  roleId?: number;
}
interface Props {
  checkedValues: number[];
  toggleAccordion: (id: any) => void;
  handleOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IProfitLossList
  ) => void;
  handleDoubleClick: (id: number) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: ITradingAccountList
  ) => void;
  tading_data: TradingAccountList | undefined;
  isLoading: boolean;
  data: ProfitLossList | undefined;
}

export const VerticalPLAccount: React.FC<Props> = ({
  checkedValues,
  toggleAccordion,
  handleOnChange,
  handleDoubleClick,
  handleChange,
  tading_data,
  isLoading,
  data,
}) => {
  return (
    <table className="main-table">
      <tbody>
        {/* Trading Account */}
        {/* Direct Income */}
        <tr>
          <td className="border-bottom-0 width-50">
            <table className="mini-table">
              <thead>
                <th className="width-60 text-left">Trading Account</th>
                <th className="width-40" colSpan={2}>
                  Amount
                </th>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <div className="loder-wrapper">
                        <Loader loaderType="Spinner" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {tading_data?.credit_data_list?.map((item, index) => {
                    const exists = checkedValues.find((val) => val === item.id);
                    return (
                      <>
                        {item.id !== null && (
                          <tr
                          // onDoubleClick={() => {
                          //   handleDoubleClick(item.id);
                          // }}
                          >
                            <td className="black-content">
                              <div className="d-flex">
                                <input
                                  className="checkbox-none"
                                  type="checkbox"
                                  id={`credit-checkbox-${index}`}
                                  name={item.ledger_name}
                                  value={item.ledger_name}
                                  checked={Boolean(exists)}
                                  onChange={(e) => handleChange(e, item)}
                                />
                                <div
                                  onClick={() => {
                                    toggleAccordion(item.id);
                                  }}
                                >
                                  {Boolean(exists) ? (
                                    <TriangleDown />
                                  ) : (
                                    <TriangleRignt />
                                  )}
                                </div>
                                <label htmlFor={`credit-checkbox-${index}`}>
                                  {item.ledger_name}
                                </label>
                              </div>
                            </td>
                            <td></td>
                            <td className="black-content">
                              {parenthesisInNegativeValue(item.amount)}
                            </td>
                          </tr>
                        )}

                        {item.child_ledger?.map(
                          (itm: ITradingAccountSubList, idx: number) => {
                            let creditParsedJsonData = isJson(itm.ledger_name);
                            return (
                              <>
                                {creditParsedJsonData ? (
                                  <>
                                    {JSON.parse(itm.ledger_name).map(
                                      (
                                        jsonItem: ITradingAccountSubList,
                                        jsonIndex: number
                                      ) => {
                                        return (
                                          <>
                                            <tr
                                              className={
                                                Boolean(exists)
                                                  ? "show"
                                                  : "dontShow"
                                              }
                                              onDoubleClick={() => {
                                                handleDoubleClick(jsonItem.id);
                                              }}
                                            >
                                              <td
                                                className="content width-60 padd-left"
                                                title="Double click to view ledger report"
                                              >
                                                {jsonItem.ledger_name}
                                              </td>
                                              <td className="content width-20">
                                                {parenthesisInNegativeValue(
                                                  jsonItem.amount
                                                )}
                                              </td>
                                              <td className="width-20"></td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {itm.amount === 0 ? (
                                      ""
                                    ) : (
                                      <tr
                                        className={
                                          Boolean(exists) ? "show" : "dontShow"
                                        }
                                        onDoubleClick={() => {
                                          handleDoubleClick(itm.id);
                                        }}
                                      >
                                        <td
                                          className="content width-60 padd-left"
                                          title="Double click to view ledger report"
                                        >
                                          {itm.ledger_name}
                                        </td>
                                        <td className="content width-20">
                                          {parenthesisInNegativeValue(
                                            itm.amount
                                          )}
                                        </td>
                                        <td className="width-20"></td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          }
                        )}
                      </>
                    );
                  })}
                </tbody>
              )}
            </table>
            <div className="vertical-bottom border-top-0">
              <table className="footer-table">
                {tading_data?.credit_data_list?.map((item) => {
                  return (
                    <>
                      {item.id === null && item.amount !== 0 && (
                        <tr>
                          <td
                            className={
                              item.ledger_name === "Total"
                                ? "width-60 total"
                                : "width-60"
                            }
                            colSpan={2}
                          >
                            {item.ledger_name}
                          </td>
                          <td className="width-20"></td>

                          <td
                            className={
                              item.ledger_name === "Total"
                                ? "width-20 total"
                                : "width-20"
                            }
                          >
                            {parenthesisInNegativeValue(item.amount)}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </table>
            </div>
          </td>
        </tr>
        {/* Direct Expenses */}
        <tr>
          <td className="border-bottom-0 width-50">
            <table className="mini-table">
              {isLoading ? (
                <tr>
                  <td colSpan={4}>
                    <div className="loder-wrapper">
                      <Loader loaderType="Spinner" />
                    </div>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {tading_data?.debit_data_list?.map((item, index) => {
                    const exists = checkedValues.find((val) => val === item.id);
                    return (
                      <>
                        {item.id !== null && (
                          <tr>
                            <td className="black-content">
                              <div className="d-flex">
                                <input
                                  className="checkbox-none"
                                  type="checkbox"
                                  id={`debit-checkbox-${index}`}
                                  name={item.ledger_name}
                                  value={item.ledger_name}
                                  checked={Boolean(exists)}
                                  onChange={(e) => handleChange(e, item)}
                                />
                                <div
                                  onClick={() => {
                                    toggleAccordion(item.id);
                                  }}
                                >
                                  {Boolean(exists) ? (
                                    <TriangleDown />
                                  ) : (
                                    <TriangleRignt />
                                  )}
                                </div>
                                <label htmlFor={`debit-checkbox-${index}`}>
                                  {item.ledger_name}
                                </label>
                              </div>
                            </td>
                            <td></td>
                            <td className="black-content">
                              {parenthesisInNegativeValue(item.amount)}
                            </td>
                          </tr>
                        )}
                        {item.child_ledger?.map(
                          (itm: ITradingAccountSubList, idx: number) => {
                            let debitParsedJsonData = isJson(itm.ledger_name);
                            return (
                              <>
                                {debitParsedJsonData ? (
                                  <>
                                    {JSON.parse(itm.ledger_name).map(
                                      (
                                        jsonItem: ITradingAccountSubList,
                                        jsonIndex: number
                                      ) => {
                                        return (
                                          <>
                                            {jsonItem.amount === 0 ? (
                                              ""
                                            ) : (
                                              <tr
                                                className={
                                                  Boolean(exists)
                                                    ? "show"
                                                    : "dontShow"
                                                }
                                              >
                                                <td
                                                  className="content width-60 padd-left"
                                                  title="Double click to view ledger report"
                                                >
                                                  {jsonItem.ledger_name}
                                                </td>
                                                <td className="content `width-20`">
                                                  {parenthesisInNegativeValue(
                                                    jsonItem.amount
                                                  )}
                                                </td>
                                                <td className="width-20"></td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {itm.amount === 0 ? (
                                      ""
                                    ) : (
                                      <tr
                                        className={
                                          Boolean(exists) ? "show" : "dontShow"
                                        }
                                      >
                                        <td
                                          className="content width-60 padd-left"
                                          title="Double click to view ledger report"
                                        >
                                          {itm.ledger_name}
                                        </td>
                                        <td className="content width-20">
                                          {parenthesisInNegativeValue(
                                            itm.amount
                                          )}
                                        </td>
                                        <td className="width-20"></td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          }
                        )}
                      </>
                    );
                  })}
                </tbody>
              )}
            </table>
            <div className="vertical-bottom border-top-0">
              <table className="footer-table">
                {tading_data?.debit_data_list?.map((item, index) => {
                  return (
                    <>
                      {item.id === null && item.amount !== 0 ? (
                        <tr>
                          <td
                            className={
                              item.ledger_name === "Total"
                                ? "width-60 total"
                                : "width-60"
                            }
                            colSpan={2}
                          >
                            {item.ledger_name}
                          </td>
                          <td className="width-20"></td>
                          <td
                            className={
                              item.ledger_name === "Total"
                                ? "width-20 total"
                                : "width-20"
                            }
                          >
                            {parenthesisInNegativeValue(item.amount)}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </table>
            </div>
          </td>
        </tr>
        {/* End Trading Account */}

        {/*  Income Statement */}
        {/* Indirect Income */}
        <tr>
          <td className="border-bottom-0">
            <table className="mini-table">
              <thead>
                <th className="width-60 text-left">Income Statement</th>
                <th className="width-40" colSpan={2}>
                  Amount
                </th>
              </thead>
              <tbody>
                {data?.credit_profit_loss?.map(
                  (item: IProfitLossList, index) => {
                    let creditProfitJsonData = isJson(item.child_ledger);
                    const exists = checkedValues.find((val) => val === item.id);
                    return (
                      <>
                        {item.amount === 0 || item.ledger_name === "Total" ? (
                          ""
                        ) : (
                          <tr
                          // onDoubleClick={() => {
                          //   handleDoubleClick(item.id);
                          // }}
                          >
                            <td className="black-content">
                              <div className="d-flex">
                                {item?.child_ledger?.length > 0 ? (
                                  <>
                                    <input
                                      className="checkbox-none"
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      name={item.ledger_name}
                                      value={item.ledger_name}
                                      checked={Boolean(exists)}
                                      onChange={(e) => handleOnChange(e, item)}
                                    />
                                    <div
                                      onClick={() => {
                                        toggleAccordion(item.id);
                                      }}
                                    >
                                      {Boolean(exists) ? (
                                        <TriangleDown />
                                      ) : (
                                        <TriangleRignt />
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <label htmlFor={`custom-checkbox-${index}`}>
                                  {item.ledger_name}
                                </label>
                              </div>
                            </td>
                            <td className="width-20"></td>

                            <td className="black-content width-20">
                              {parenthesisInNegativeValue(item.amount)}
                            </td>
                          </tr>
                        )}
                        {creditProfitJsonData ? (
                          <>
                            {JSON.parse(item.child_ledger)?.map(
                              (
                                jsonItem: ITradingAccountSubList,
                                jsonIndex: number
                              ) => {
                                return (
                                  <>
                                    {jsonItem.amount === 0 ? (
                                      ""
                                    ) : (
                                      <tr
                                        key={jsonIndex}
                                        className={
                                          Boolean(exists) ? "show" : "dontShow"
                                        }
                                        onDoubleClick={() => {
                                          handleDoubleClick(jsonItem.id);
                                        }}
                                      >
                                        <td
                                          className="content width-60 padd-left"
                                          title="Double click to view ledger report"
                                        >
                                          {jsonItem.ledger_name}
                                        </td>
                                        <td className="content ">
                                          {parenthesisInNegativeValue(
                                            jsonItem.amount
                                          )}
                                        </td>
                                        <td></td>
                                      </tr>
                                    )}
                                  </>
                                );
                              }
                            ) ?? ""}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
            <div className="vertical-bottom border-top-0">
              <table className="footer-table">
                {data?.debit_profit_loss?.map((item) => {
                  return (
                    <>
                      {item.ledger_name === "Total" && item.amount !== 0 ? (
                        <tr>
                          <>
                            <td
                              className={
                                item.ledger_name === "Total"
                                  ? "width-60 total"
                                  : "width-60"
                              }
                              colSpan={2}
                            >
                              {item.ledger_name}
                            </td>
                            <td className="width-20"></td>
                            <td
                              className={
                                item.ledger_name === "Total"
                                  ? "width-20 total"
                                  : "width-20"
                              }
                            >
                              {parenthesisInNegativeValue(item.amount)}
                            </td>
                          </>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </table>
            </div>
          </td>
        </tr>
        {/* Indirect Expenses */}
        <tr>
          <td className="border-bottom-0">
            <table className="mini-table">
              <tbody>
                {data?.debit_profit_loss?.map(
                  (item: IProfitLossList, index) => {
                    if (item.child_ledger !== null) {
                    }
                    let creditProfitJsonData = isJson(item.child_ledger);
                    const exists = checkedValues.find((val) => val === item.id);
                    return (
                      <>
                        {item.amount === 0 || item.ledger_name === "Total" ? (
                          ""
                        ) : (
                          <tr
                          // onDoubleClick={() => {
                          //   handleDoubleClick(item.id);
                          // }}
                          >
                            <td>
                              <div className="d-flex">
                                {item?.child_ledger?.length > 0 ? (
                                  <>
                                    <input
                                      className="checkbox-none"
                                      type="checkbox"
                                      id={`custom-checkbox-${index}`}
                                      name={item.ledger_name}
                                      value={item.ledger_name}
                                      checked={Boolean(exists)}
                                      onChange={(e) => handleOnChange(e, item)}
                                    />
                                    <div
                                      onClick={() => {
                                        toggleAccordion(item.id);
                                      }}
                                    >
                                      {Boolean(exists) ? (
                                        <TriangleDown />
                                      ) : (
                                        <TriangleRignt />
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <label htmlFor={`custom-checkbox-${index}`}>
                                  {item.ledger_name}
                                </label>
                              </div>
                            </td>
                            <td className="width-20"></td>

                            <td className="black-content width-20">
                              {parenthesisInNegativeValue(item.amount)}
                            </td>
                          </tr>
                        )}
                        {creditProfitJsonData ? (
                          <>
                            {JSON.parse(item.child_ledger)?.map(
                              (
                                jsonItem: ITradingAccountSubList,
                                jsonIndex: number
                              ) => {
                                return (
                                  <>
                                    {jsonItem.amount === 0 ? (
                                      ""
                                    ) : (
                                      <tr
                                        key={jsonIndex}
                                        className={
                                          Boolean(exists) ? "show" : "dontShow"
                                        }
                                        onDoubleClick={() => {
                                          handleDoubleClick(jsonItem.id);
                                        }}
                                      >
                                        <td
                                          className="content width-60 padd-left"
                                          title="Double click to view ledger report"
                                        >
                                          {jsonItem.ledger_name}
                                        </td>
                                        <td className="content ">
                                          {parenthesisInNegativeValue(
                                            jsonItem.amount
                                          )}
                                        </td>
                                        <td></td>
                                      </tr>
                                    )}
                                  </>
                                );
                              }
                            ) ?? ""}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
            <div className="vertical-bottom border-top-0">
              <table className="footer-table">
                {data?.credit_profit_loss?.map((item) => {
                  return (
                    <>
                      {item.ledger_name === "Total" && item.amount !== 0 ? (
                        <tr>
                          <>
                            <td
                              className={
                                item.ledger_name === "Total"
                                  ? "width-60 total"
                                  : "width-60"
                              }
                              colSpan={2}
                            >
                              {item.ledger_name}
                            </td>
                            <td
                              className={
                                item.ledger_name === "Total"
                                  ? "width-20 total"
                                  : "width-20"
                              }
                            >
                              {parenthesisInNegativeValue(item.amount)}
                            </td>
                          </>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
