// import { NoDataIcon } from "../../assets/images/svg/svg";
import "./NoDataPage.scss"

interface NoDataPageProps {
  title?: string
  description?: string
  iconDirectory?: string
  icon?: any
}
export const NoDataPage: React.FC<NoDataPageProps> = ({
  title = "No Data Available",
  description = "Looks like there are no entries here yet. Try removing filters or come back later",
  iconDirectory,
  icon,
}) => {
  return (
    <div className="error-page">
      {/* <NoDataIcon /> */}
      <div className="icon-section">
        {icon ? (
          icon
        ) : iconDirectory ? (
          <img src={iconDirectory} alt="icon" />
        ) : (
          <img src={require("../../assets/images/no_data.png")} alt="icon" />
        )}
      </div>
      <div className="mid-page">
        <div className="text-section">
          <h2 className="small-title error-title">{title}</h2>
          <p className="sub-text">{description}</p>
        </div>
      </div>
    </div>
  )
}
