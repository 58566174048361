import { Form, Formik, FormikConfig, useFormikContext } from "formik";
import NepaliDate from "nepali-date-converter";
import { useContext } from "react";
import * as Yup from "yup";
import { deepClone, HandleError } from "../../utils/services";
import { OpeningAdjustmentVoucher } from "../Api/Post/PostApi";
import { DocumentContext } from "../BillPage/Document-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { useLedgerForOpeningAdjustment } from "../Api/Get/GetApi";

export interface OpeningAdjustmentValue {
  ledger_id: string;
  debit: number | null;
  credit: number | null;
}
export interface OpeningAdjustmentInitial {
  ledger_info: OpeningAdjustmentValue[];
  date: string;
}
export const initialValues: OpeningAdjustmentInitial = {
  ledger_info: [
    { ledger_id: "", debit: 0, credit: 0 },
    { ledger_id: "", debit: 0, credit: 0 },
  ],
  date: new NepaliDate().format("YYYY-MM-DD"),
};

const validationSchema = Yup.object().shape({
  date: Yup.string().required("Please Select Date"),
  ledger_info: Yup.array().of(
    Yup.object().shape({
      ledger_id: Yup.string().required("Please select a ledger"),
      debit: Yup.number().test(
        "debit-or-credit",
        "Debit or credit must be greater than 0",
        function (value) {
          const { credit } = this.parent;
          return Number(value) > 0 || credit > 0;
        }
      ),
      credit: Yup.number().test(
        "debit-or-credit",
        "Debit or credit must be greater than 0",
        function (value) {
          const { debit } = this.parent;
          return Number(value) > 0 || debit > 0;
        }
      ),
    })
  ),
});

export const useOpeningAdjustmentWrapper = () => {
  const formik = useFormikContext<OpeningAdjustmentInitial>();
  if (!formik) {
    throw new Error("OpeningAdjustmentWrapper must be used within a Formik");
  }
  return formik;
};

interface OpeningAdjustmentWrapperProps {
  children: React.ReactNode;
}

export const OpeningAdjustmentWrapper: React.FC<
  OpeningAdjustmentWrapperProps
> = ({ children }) => {
  const { showErrorMessage, showSuccessMessage } = useToast();
  const { setIsSubmitted, isSubmitted } = useContext(DocumentContext);
  const { refetch } = useLedgerForOpeningAdjustment();

  const handleSubmit: FormikConfig<OpeningAdjustmentInitial>["onSubmit"] = (
    values,
    { setSubmitting, resetForm }
  ) => {
    setSubmitting(true);
    const data = deepClone(values);

    OpeningAdjustmentVoucher(data)
      .then((res) => {
        showSuccessMessage("Opening Adjustment Created");
        setIsSubmitted(!isSubmitted);
        refetch();
        resetForm();
      })
      .catch((err: any) => {
        const ErrorMessage = HandleError(err);
        showErrorMessage(ErrorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>{children}</Form>
    </Formik>
  );
};
