import { useEffect, useMemo, useState } from "react";
import { useAuditTrialReport } from "../../components/Api/Get/GetApi";
import { AuditTrialResponse } from "../../components/Api/Get/GetApiTypes";
import { Table } from "../../components/CustomReactTable/CustomReactTable";
import { FilterDate } from "../../components/SalesList/SalesListPage";
import {
  FormatDate,
  handleNoData,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";

export const AuditTrialReport = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);

  const {
    data: auditReports,
    isLoading,
    refetch,
  } = useAuditTrialReport(queryPageIndex, queryPageSize, date);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: "s_n",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Transaction Id",
        accessor: "transaction_main_id",
      },
      {
        Header: "Effect",
        accessor: "effect",
      },
      {
        Header: "Event",
        accessor: "event",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Ip Address",
        accessor: "ip_address",
      },
    ],
    []
  );

  const FormatTable = (
    array: AuditTrialResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item: AuditTrialResponse, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          user: handleNoData(item.user),
          date: FormatDate(item.miti || ""),
          event: handleNoData(item.event),
          effect: String(item.effect),
          description: handleNoData(item.description),
          ip_address: handleNoData(item.ip_address),
          transaction_main_id: handleNoData(item.transaction_main_id),
        };
      });
      return formatData;
    }
    return [];
  };
  useEffect(() => {
    refetch();
  }, [date.dateFrom, date.dateTo]);
  return (
    <>
      <div className="head-section">
        <div className="col-md-6">
          <div className="small-title">Audit Trail Reports</div>
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        data={
          auditReports
            ? FormatTable(auditReports.data, queryPageIndex, queryPageSize)
            : []
        }
        totalPages={
          auditReports &&
          totalPages(queryPageSize, auditReports.pagination.total_count)
        }
        showDate={true}
        setDate={setDate}
        totalEntries={auditReports && auditReports.pagination.total_count}
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
        date={date}
        excelDownload={true}
      />
    </>
  );
};
