import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import { ErrorMessage } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import { fixedValue, formatSelectOptions } from "../../utils/services";
import { useCustomerOptions, usePayslip } from "../Api/Get/GetApi";
import { CustomerListResponse } from "../Api/types";
import { DocumentContext } from "../BillPage/Document-Context";
import { Ledger } from "../Ledger/Ledger";
import { useReciptWrapper } from "../ReciptSlip/ReciptFormikWrapper";
import { ReciptSlip } from "../ReciptSlip/ReciptSlip";
import "./PayslipPage.scss";
import { ReceiptPage } from "../ReceiptPage/ReceiptPage";
import { PopUp } from "../PopUp/PopUp";

export type chipState = "Receipt" | "Ledger" | "Transaction" | "";

export const PayslipPage = () => {
  const [reciptActive, setReciptActive] = useState(false);
  const [ledgerActive, setLedgerActive] = useState(false);
  const [activeState, setActiveState] = useState<chipState>("");
  const [patronList, setPatronList] = useState<CustomerListResponse[]>();
  const { values, setFieldValue } = useReciptWrapper();
  const [dateNew, setDateNew] = useState("");

  const {
    selectedUserObj,
    setSelectedUserObj,
    selectedPatron,
    setSelectedAPIType,
    setSelectedPatron,
    showReceipt,
    receiptNumber,
  } = useContext(DocumentContext);

  const { data } = useCustomerOptions();
  const { data: payslipData } = usePayslip(selectedPatron?.value);

  useEffect(() => {
    setFieldValue("date", dateNew);
  }, [dateNew, values.date]);

  useEffect(() => {
    if (data?.data) {
      setPatronList(data.data);
    }
  }, [data]);

  useEffect(() => {
    setSelectedPatron(null);
  }, []);

  useMemo(() => {
    activeState === "Ledger" ? (
      <>
        {setLedgerActive(true)}
        {setReciptActive(false)}
      </>
    ) : activeState === "Receipt" ? (
      <>
        {setLedgerActive(false)}
        {setReciptActive(true)}
      </>
    ) : (
      <></>
    );
  }, [activeState]);

  useEffect(() => {
    if (payslipData) {
      setSelectedUserObj(payslipData);
    }
  }, [payslipData]);

  return (
    <>
      {showReceipt && receiptNumber !== "" && (
        <PopUp
          popupWidth={"100%"}
          title="Receipt"
          renderComponent={<ReceiptPage />}
        />
      )}
      <div className="pay-slip-page">
        <div>
          <div className="head-section">
            <div className="small-title">Payslip</div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <label className="input-label">Customer Name</label>
              <div className="search-field-customer auto-complete-search-wrap">
                <Select
                  isClearable={true}
                  value={selectedPatron}
                  maxMenuHeight={150}
                  options={formatSelectOptions<CustomerListResponse>(
                    patronList as CustomerListResponse[],
                    "additional_info",
                    "address" || "additionalProp1"
                  )}
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                  }}
                  onChange={(e) => {
                    setSelectedPatron(e as SelectOptions);
                    setSelectedAPIType("paySlipApi");
                  }}
                />
              </div>
              <div className="bill-info">
                <div className="amount-data d-flex">
                  <div className="small-content uppercase">Due Amount:</div>
                  <div className="extra-small-title">
                    Rs.{" "}
                    {selectedPatron && selectedUserObj?.credit_total > 0
                      ? fixedValue(selectedUserObj?.credit_total)
                      : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="date" className="input-label">
                  Date:
                </label>
                <NepaliDatePicker
                  className="input-field"
                  defaultDate={true}
                  onDateSelect={(value: any) => {
                    setDateNew(value);
                  }}
                />
                <ErrorMessage name={`date`} component="div" className="error" />
              </div>
            </div>

            <div className="d-flex tag-wrapper">
              <button
                className={`chip-tag recipt-bar small-content ${
                  reciptActive ? "active" : ""
                }`}
                disabled={
                  selectedUserObj?.credit_total === undefined || null
                    ? true
                    : false
                }
                onClick={() => {
                  setActiveState("Receipt");
                  setFieldValue("hasBill", "paySlip");
                  setFieldValue("apiType", "paySlipApi");
                  setFieldValue("bill_number", "");
                  setFieldValue("cash", 0);
                  setSelectedAPIType("paySlipApi");
                }}
              >
                Receipt
              </button>
              <button
                className={`chip-tag ledger-bar small-content ${
                  ledgerActive ? "active" : ""
                }`}
                disabled={
                  selectedUserObj?.credit_total === undefined || null
                    ? true
                    : false
                }
                onClick={() => {
                  setActiveState("Ledger");
                  setFieldValue("hasBill", "paySlip");
                }}
              >
                Ledger
              </button>
            </div>
            {activeState === "Receipt" && selectedPatron && (
              <>
                {selectedUserObj?.user_role_id !== undefined && (
                  <ReciptSlip
                    creatable={true}
                    LedgerType={"payslipLedger"}
                    selectedUserObj={selectedUserObj}
                    setSelectedUserObj={setSelectedUserObj}
                  />
                )}
              </>
            )}
            {activeState === "Ledger" && selectedPatron && (
              <>
                {(values.cash ?? 0) <= 0 ? true : false}
                <Ledger
                  userRole={selectedUserObj?.user_role_id ?? 0}
                  LedgerType={"payslipLedger"}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
