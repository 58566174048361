import { ErrorMessage, Field, FieldArray } from "formik";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { DeleteBinIcon, PlusIcon } from "../../assets/images/svg/svg";

import { SelectOptions } from "../../Pages/SalesReturn/types";
import {
  checkNumber,
  FilterArray,
  fixedValue,
  UpdatedFormatOptions,
} from "../../utils/services";
import { useLedgerForOpeningAdjustment } from "../Api/Get/GetApi";
import { LedgerHeadResponse } from "../Api/Get/GetApiTypes";
import { DocumentContext } from "../BillPage/Document-Context";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { useOpeningAdjustmentWrapper } from "./OpeningWrapper";
import "./OpeningAdjustment.scss";
import { OpeningAdjustmentValue } from "./OpeningWrapper";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

enum ActionType {
  Add = 1,
  Remove = 2,
}

export const OpeningAdjustment = () => {
  const [selectedLedger, setSelectedLedger] = useState<
    SelectOptions[] | null[]
  >([null]);

  const { setFieldValue, values, isSubmitting } = useOpeningAdjustmentWrapper();
  const { data } = useLedgerForOpeningAdjustment();
  const { isSubmitted } = useContext(DocumentContext);

  const UpdateTheTableValues = (
    key: keyof OpeningAdjustmentValue,
    value: any,
    index: number
  ) => {
    const cloned = [...values.ledger_info];
    //@ts-ignore
    cloned[index][key] = value;
    setFieldValue("ledger_info", cloned);
  };

  const ActionInArray = (actionType: ActionType, index?: number) => {
    let cloned = [...values.ledger_info];
    let newArr = [...selectedLedger];

    switch (actionType) {
      case ActionType.Add:
        cloned.push({
          ledger_id: "",
          debit: 0,
          credit: 0,
        });
        //This is to handle the ledger select type
        newArr.push(null);
        setSelectedLedger(newArr as SelectOptions[]);
        break;
      case ActionType.Remove:
        cloned.splice(Number(index), 1);
        //LedgerDataUpdate
        newArr.splice(Number(index), 1);
        break;
      default:
        return;
    }
    setFieldValue("ledger_info", cloned);
    setSelectedLedger(newArr as SelectOptions[]);
  };

  useEffect(() => {
    const tempArr = [null];
    setSelectedLedger(tempArr);
  }, [isSubmitted]);

  return (
    <div className="opening_adjustment">
      <>
        <div className="head-section">
          <div className="main-title">Opening Balance</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="calendar-section">
              <label className="input-label">Select Date</label>
              <NepaliDatePicker
                currentDate={true}
                onDateSelect={(value: any) => {
                  setFieldValue(`date`, value);
                }}
              />
              <ErrorMessage name="date" component="div" className="error" />
            </div>
          </div>
        </div>
        <div className="mg-btm-30"></div>
        <table className="table table-hover table-add">
          <thead>
            <tr>
              <th className="width-10" scope="col">
                S.N
              </th>
              <th className="width-40" scope="col">
                Ledger
              </th>

              <th className="width-20" scope="col">
                Debit Amount
              </th>
              <th className="width-20" scope="col">
                Credit Amount
              </th>
              <th className="width-10" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldArray
              name="ledger_info"
              render={(arrayHelpers) => (
                <>
                  {values.ledger_info.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="display-block">
                            <Select
                              isClearable={true}
                              maxMenuHeight={100}
                              minMenuHeight={50}
                              options={
                                data &&
                                UpdatedFormatOptions<LedgerHeadResponse>(
                                  FilterArray<
                                    LedgerHeadResponse,
                                    OpeningAdjustmentValue
                                  >(
                                    data as LedgerHeadResponse[],
                                    values.ledger_info,
                                    "id",
                                    "ledger_id"
                                  ),
                                  "id",
                                  "ledger_name"
                                )
                              }
                              value={selectedLedger[index] || []}
                              onChange={(e) => {
                                const newArr = [...selectedLedger];
                                newArr[index] = e;
                                setSelectedLedger(newArr as SelectOptions[]);
                                setFieldValue(
                                  `ledger_info.${index}.ledger_id`,
                                  e !== null ? e.value : ""
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`ledger_info.${index}.ledger_id`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td>

                        <td>
                          <div className="display-block">
                            <Field
                              disabled={
                                values.ledger_info[index] &&
                                Number(values.ledger_info[index].credit) > 0
                              }
                              value={values.ledger_info[index].debit}
                              placeholder="Enter debit amount"
                              className="input-field"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              type="text"
                              onChange={(e: any) => {
                                let value = checkNumber(
                                  e.target.value,
                                  "decimalToo"
                                );
                                UpdateTheTableValues("debit", value, index);
                              }}
                              onFocus={(event: any) => {
                                if (parseInt(event.target.value) === 0) {
                                  event.target.value = "";
                                }
                              }}
                              autoFocus
                            />
                            <ErrorMessage
                              name={`ledger_info.${index}.debit`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="display-block">
                            <Field
                              className="input-field"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              disabled={
                                values.ledger_info[index] &&
                                Number(values.ledger_info[index].debit) > 0
                              }
                              type="text"
                              value={values.ledger_info[index].credit}
                              placeholder="Enter credit amount"
                              onChange={(e: any) => {
                                let value = checkNumber(
                                  e.target.value,
                                  "decimalToo"
                                );
                                UpdateTheTableValues("credit", value, index);
                              }}
                              autoFocus
                              onFocus={(event: any) => {
                                if (parseInt(event.target.value) === 0) {
                                  event.target.value = "";
                                }
                              }}
                            />

                            <ErrorMessage
                              name={`ledger_info.${index}.credit`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td>

                        {index > 0 ? (
                          <td
                            className="del-icon text-center align-middle"
                            onClick={() => {
                              ActionInArray(ActionType.Remove, index);
                            }}
                          >
                            <div className="display-block">
                              {/* <DeleteBinIcon /> */}
                              <img
                                src={require("../../assets/images/trash.png")}
                                alt="icon"
                              />
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    );
                  })}
                </>
              )}
            />
          </tbody>
        </table>
        <div className="table-footer">
          <button
            className="light-primary content mg-btm-15"
            type="button"
            onClick={() => {
              ActionInArray(ActionType.Add);
            }}
          >
            <span className="plus-icon">
              <PlusIcon />
            </span>
            Add
          </button>
        </div>
      </>

      <ButtonComponent type="submit" isLoading={isSubmitting} />
    </div>
  );
};
