import { ErrorMessage, Field, FieldArray } from "formik";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { PlusIcon } from "../../assets/images/svg/svg";

import { SelectOptions } from "../../Pages/SalesReturn/types";
import {
  checkNumber,
  parenthesisInNegativeValue,
  replaceDecimal,
} from "../../utils/services";
import {
  GetLedgerClosingBalance,
  useLedgerForManualVoucher,
  useManualVoucherCustomer,
} from "../Api/Get/GetApi";
import {
  LedgerHeadResponse,
  ManualVoucherCustomerResponse,
} from "../Api/Get/GetApiTypes";
import { DocumentContext } from "../BillPage/Document-Context";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { LedgerForm } from "../LedgerTable/LedgerForm";
import { NewCustomerModal } from "../NewCustomerModal/NewCustomerModal";
import { NewCustomeWrapper } from "../NewCustomerModal/NewCustomerWrapper";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import "./ManaualVoucher.scss";
import {
  ManualVoucherValue,
  useManualVoucherWrapper,
} from "./ManualVoucherWrapper";

enum ActionType {
  Add = 1,
  Remove = 2,
}

function UpdatedFormatOptions<T>(
  data: T[],
  valueKey: keyof T,
  labelKey: keyof T
): SelectOptions[] {
  return (
    data?.map((item) => {
      const label = item[labelKey] as unknown as string;
      const parts = label.split("<-");
      const firstPart = parts[0].trim();
      const lastPart = parts.length > 1 ? `(${parts[1].trim()})` : "";
      return {
        value: item[valueKey] as any,
        label: `${firstPart} ${lastPart}`,
      } as SelectOptions;
    }) || []
  );
}

type popupType = "ledger" | "patron";

export const ManaualVoucher = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedLedger, setSelectedLedger] = useState<SelectOptions[]>([]);
  const { openModal } = useContext(PopupContext);
  const { setFieldValue, values, isSubmitting } = useManualVoucherWrapper();
  const { data: customerList } = useManualVoucherCustomer();

  const { data, refetch: getLedgerForManualVoucher } =
    useLedgerForManualVoucher();
  const { isSubmitted } = useContext(DocumentContext);
  const [exists, setExists] = useState(false);
  const UpdateDebitCreditTotal = (ledgerData: ManualVoucherValue[]) => {
    const totalCredit = ledgerData.reduce((a, b) => {
      return a + Number(b.credit);
    }, 0);
    const totalDebit = ledgerData.reduce((a, b) => {
      return a + Number(b.debit);
    }, 0);
    setFieldValue(
      "credit_total",
      totalCredit ? replaceDecimal(totalCredit) : 0
    );
    setFieldValue("debit_total", totalDebit ? replaceDecimal(totalDebit) : 0);
  };
  const [popupType, setPopupType] = useState<popupType>();

  const UpdateTheTableValues = (
    key: keyof ManualVoucherValue,
    value: any,
    index: number
  ) => {
    let newValue = value;
    //Number input check only in debit and credit field
    if (key !== "description") {
      newValue = checkNumber(value, "decimalToo");
    }
    const cloned = [...values.ledger_info];
    //@ts-ignore
    cloned[index][key] = newValue;
    setFieldValue("ledger_info", cloned);
    UpdateDebitCreditTotal(cloned);
  };
  useEffect(() => {
    if (Array.isArray(selectedLedger)) {
      const newArray = selectedLedger?.find((item: SelectOptions) => {
        const val = item?.label?.toString();
        return (
          val?.startsWith("Account Payable") ||
          val?.startsWith("Account Receivable")
        );
      });
      setExists(Boolean(newArray));
    }
  }, [selectedLedger]);
  const initialLedger: SelectOptions = {
    label: "Select...",
    value: undefined,
  };
  const ActionInArray = (actionType: ActionType, index?: number) => {
    let cloned = [...values.ledger_info];
    let newArr = [...selectedLedger];

    switch (actionType) {
      case ActionType.Add:
        cloned.push({
          ledger_id: "",
          debit: 0,
          credit: 0,
          description: "",
          closing_balance: 0,
        });
        //This is to handle the ledger select type
        newArr = [...selectedLedger, initialLedger];
        setSelectedLedger(newArr as SelectOptions[]);
        break;
      case ActionType.Remove:
        cloned.splice(Number(index), 1);
        newArr.splice(Number(index), 1);
        break;
      default:
        return;
    }
    setFieldValue("ledger_info", cloned);
    setSelectedLedger(newArr as SelectOptions[]);
    UpdateDebitCreditTotal(cloned);
  };

  useEffect(() => {
    const tempArr: SelectOptions[] = [];
    setSelectedLedger(tempArr);
    setSelectedCustomer(null);
    const clonedSelectedLedger: SelectOptions[] = [...selectedLedger];
    clonedSelectedLedger[0] = { label: "Select...", value: undefined };
    clonedSelectedLedger[1] = { label: "Select...", value: undefined };
    setSelectedLedger(clonedSelectedLedger as SelectOptions[]);
  }, [isSubmitted]);

  const handleClear = (index: number) => {
    const clonedLedgerInfo = [...values.ledger_info];
    const clonedSelectedLedger: SelectOptions[] = [...selectedLedger];
    clonedLedgerInfo[index].ledger_id = "";
    setFieldValue(`ledger_info`, clonedLedgerInfo);
    setFieldValue(`ledger_info.${index}.description`, "");
    setFieldValue(`ledger_info.${index}.debit`, 0);
    setFieldValue(`ledger_info.${index}.credit`, 0);
    setFieldValue(`ledger_info.${index}.closing_balance`, 0);
    clonedSelectedLedger[index] = { label: "Select...", value: undefined };
    setSelectedLedger(clonedSelectedLedger as SelectOptions[]);
  };

  useEffect(() => {
    UpdateDebitCreditTotal(values.ledger_info);
  }, [values.ledger_info]);

  const handleCheckboxChange = (value: string | null) => {
    setFieldValue(`link_report_type`, value);
  };
  const checkExistLinkType = (link_report_type: string, exists: boolean) => {
    switch (link_report_type) {
      case "SAL":
        return true;
      case "PUR":
        return true;

      default:
        if (exists) {
          return true;
        } else {
          return false;
        }
    }
  };

  const getClosingBalance = (index: number, closingId: number) => {
    GetLedgerClosingBalance(closingId).then((res) => {
      setFieldValue(
        `ledger_info.${index}.closing_balance`,
        res?.closing_balance
      );
    });
  };

  const handleLedgerOnChange = (selectedObj: any, index: number) => {
    const newArr = [...selectedLedger];
    newArr[index] = selectedObj as SelectOptions;
    setSelectedLedger(newArr as SelectOptions[]);
    setFieldValue(
      `ledger_info.${index}.ledger_id`,
      selectedObj !== null ? selectedObj.value : ""
    );
    setFieldValue(`ledger_label`, selectedObj?.label);
    setFieldValue(`ledger_exist`, exists);
    getClosingBalance(index, selectedObj?.value || 0);
  };

  return (
    <div className="manaual-voucher">
      <>
        <div className="head-section">
          <div className="small-title">Manual Voucher</div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2">
              <div className="calendar-section">
                <label className="input-label">Select Date</label>
                <NepaliDatePicker
                  value={values.date}
                  className="col-md-12"
                  inputClassName="form-control"
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                  onChange={(value: any) => {
                    setFieldValue(`date`, value);
                  }}
                />
                <ErrorMessage name="date" component="div" className="error" />
              </div>
            </div>
            <div className="col-md-offset-4 col-md-10">
              <div className="row mt-3">
                <div className="col-md-2">
                  <input
                    type="radio"
                    name="accountType"
                    className="check-group"
                    value="PUR"
                    checked={values.link_report_type === "PUR"}
                    onChange={() => handleCheckboxChange("PUR")}
                  />
                  <label className="input-label">Link खरिद खाता</label>
                </div>
                <div className="col-md-2">
                  <input
                    type="radio"
                    name="accountType"
                    value="SAL"
                    className="check-group"
                    checked={values.link_report_type === "SAL"}
                    onChange={() => handleCheckboxChange("SAL")}
                  />
                  <label className="input-label">Link बिक्री खाता</label>
                </div>
                <div className="col-md-2">
                  <input
                    type="radio"
                    name="accountType"
                    className="check-group"
                    checked={values.link_report_type === null}
                    onChange={() => handleCheckboxChange(null)}
                  />
                  <label className="input-label">None</label>
                </div>
                <div className="col-md-3">
                  <button
                    className="light-primary c-button"
                    onClick={() => {
                      setPopupType("ledger");
                      openModal();
                    }}
                    type="button"
                  >
                    <span className="plus-icon">
                      <PlusIcon />
                    </span>
                    Add New Ledger
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    className="light-primary c-button"
                    onClick={() => {
                      setPopupType("patron");
                      openModal();
                    }}
                    type="button"
                  >
                    <span>
                      <img
                        alt="plus"
                        src={require("../../assets/images/plus-16.png")}
                      />
                    </span>
                    Add New Patron
                  </button>
                </div>
              </div>
            </div>
            {popupType === "ledger" && (
              <PopUp
                closeOnClickOutside={true}
                showCrossIcon={true}
                popupWidth={500}
                title="Ledger Form"
                renderComponent={
                  <LedgerForm
                    refetch={getLedgerForManualVoucher}
                    mode="create"
                  />
                }
              />
            )}
            {popupType === "patron" && (
              <PopUp
                title="New Patron"
                popupWidth={800}
                renderComponent={
                  <NewCustomeWrapper>
                    <NewCustomerModal />
                  </NewCustomeWrapper>
                }
              />
            )}
          </div>
        </div>
        {checkExistLinkType(values.link_report_type || "", exists) && (
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="calendar-section">
                <label className="input-label">Customer (Optional)</label>
                <Select
                  isClearable={true}
                  maxMenuHeight={155}
                  options={
                    data &&
                    UpdatedFormatOptions<ManualVoucherCustomerResponse>(
                      customerList as ManualVoucherCustomerResponse[],
                      "id",
                      "name"
                    )
                  }
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                  }}
                  value={selectedCustomer}
                  onChange={(e: any) => {
                    if (e == null) {
                      setSelectedCustomer(null);
                      setFieldValue("customer_id", null);
                    } else {
                      setSelectedCustomer(e);
                      setFieldValue("customer_id", e.value);
                    }
                  }}
                />
              </div>
              <ErrorMessage
                name={`customer_id`}
                component="div"
                className="error"
              />
            </div>

            {values.link_report_type === "PUR" && (
              <div className="col-md-3">
                <div className="col-mc-12">
                  <label className="input-label">Bill No.</label>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Enter bill no"
                    value={values.bill_number}
                    onChange={(e: any) => {
                      setFieldValue(`bill_number`, e.target.value);
                    }}
                    autoFocus
                  />
                  <ErrorMessage
                    name={`bill_number`}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mg-btm-30"></div>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="width-30" scope="col">
                Ledger
              </th>
              <th className="width-10" scope="col">
                Closing Balance
              </th>
              <th className="width-15" scope="col">
                Debit
              </th>
              <th className="width-15" scope="col">
                Credit
              </th>
              <th className="width-5" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldArray
              name="ledger_info"
              render={(arrayHelpers) => (
                <>
                  {values.ledger_info.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="display-block">
                            <Select
                              isClearable={
                                !selectedLedger[index] ||
                                selectedLedger[index].value !== undefined
                              }
                              maxMenuHeight={155}
                              isOptionDisabled={(option, _) => {
                                var optionSelected = option as SelectOptions;
                                return optionSelected.disabled ?? false;
                              }}
                              options={
                                data && [
                                  {
                                    label: "Select Legder",
                                    value: undefined,
                                    disabled: true,
                                  },
                                  ...UpdatedFormatOptions<LedgerHeadResponse>(
                                    data as LedgerHeadResponse[],
                                    "id",
                                    "ledger_name"
                                  ),
                                ]
                              }
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  fontSize: "12px",
                                }),
                                menu: (base) => ({
                                  ...base,
                                  fontSize: "12px",
                                }),
                              }}
                              value={selectedLedger[index]}
                              onChange={(selectedObj) => {
                                handleLedgerOnChange(selectedObj, index);
                              }}
                            />
                            <ErrorMessage
                              name={`ledger_info.${index}.ledger_id`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td>
                        <td>
                          <span className="closing-bln-span">
                            {parenthesisInNegativeValue(
                              values.ledger_info[index]?.closing_balance || 0
                            )}
                          </span>
                        </td>
                        {/* <td>
                          <div className="display-block">
                            <Field
                              value={values.ledger_info[index].description}
                              onChange={(e: any) => {
                                UpdateTheTableValues(
                                  "description",
                                  e.target.value,
                                  index
                                );
                              }}
                              placeholder="Add Description"
                              className="input-field"
                            />
                            <ErrorMessage
                              name={`ledger_info.${index}.description`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td> */}
                        <td>
                          <div className="display-block">
                            <Field
                              disabled={
                                values.ledger_info[index] &&
                                Number(values.ledger_info[index].credit) > 0
                              }
                              type="text"
                              value={values.ledger_info[index].debit}
                              placeholder="Enter debit"
                              className="input-field"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              onChange={(e: any) => {
                                UpdateTheTableValues(
                                  "debit",
                                  e.target.value,
                                  index
                                );
                              }}
                              onFocus={(event: any) => {
                                if (parseInt(event.target.value) === 0) {
                                  event.target.value = "";
                                }
                              }}
                            />
                            <ErrorMessage
                              name={`ledger_info.${index}.debit`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="display-block">
                            <Field
                              className="input-field"
                              type="text"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              disabled={
                                values.ledger_info[index] &&
                                Number(values.ledger_info[index].debit) > 0
                              }
                              value={values.ledger_info[index].credit}
                              placeholder="Enter credit"
                              onChange={(e: any) => {
                                UpdateTheTableValues(
                                  "credit",
                                  e.target.value,
                                  index
                                );
                              }}
                              onFocus={(event: any) => {
                                if (parseInt(event.target.value) === 0) {
                                  event.target.value = "";
                                }
                              }}
                            />

                            <ErrorMessage
                              name={`ledger_info.${index}.credit`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </td>
                        {(values.ledger_info[index].ledger_id !== "" ||
                          (values.ledger_info[index].credit ||
                            values.ledger_info[index].debit ||
                            values.ledger_info[index].description.length) > 0 ||
                          values.ledger_info.length > 1) && (
                          <td
                            className="del-icon text-center align-middle"
                            onClick={() => {
                              values.ledger_info.length > 1
                                ? ActionInArray(ActionType.Remove, index)
                                : handleClear(index);
                            }}
                          >
                            <div className="display-block ">
                              <img
                                src={require("../../assets/images/trash.png")}
                                alt="icon"
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </>
              )}
            />
          </tbody>
        </table>

        <div className="footer-btn-section">
          <button
            className="light-primary content"
            type="button"
            onClick={() => {
              ActionInArray(ActionType.Add);
            }}
          >
            <span className="plus-icon">
              <PlusIcon />
            </span>
            Add
          </button>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <div className="calendar-section">
                <label className="input-label">Narration*</label>
                <textarea
                  className="input-field text-area-field"
                  value={values.main_description}
                  rows={4}
                  onChange={(e: any) => {
                    setFieldValue(`main_description`, e.target.value);
                  }}
                  style={{ resize: "none" }}
                />
                <ErrorMessage
                  name="main_description"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="calendar-section">
                <label className="input-label">Customer Note</label>
                <textarea
                  className="input-field text-area-field"
                  value={values.customer_notes}
                  rows={4}
                  onChange={(e: any) => {
                    setFieldValue(`customer_notes`, e.target.value);
                  }}
                  style={{ resize: "none" }}
                />
                <ErrorMessage
                  name="customer_notes"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="small-table">
                <div className="top-head">Total</div>
                <div className="mid-part">
                  <div className="sub-part border-right">
                    <label className="input-label">Debit</label>
                    <div>{values.debit_total}</div>
                  </div>
                  <div className="sub-part">
                    <label className="input-label">Credit</label>
                    <div>{values.credit_total}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="footer-btn-section">
        <ButtonComponent type="submit" isLoading={isSubmitting} />
      </div>
    </div>
  );
};
