import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  HandleError,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";

import { Dropdown } from "react-bootstrap";
import { CrossIcon, EyeIcon, ThreeDotsIcon } from "../../assets/images/svg/svg";
import { useJournalEntry, useManualVoucheList } from "../Api/Get/GetApi";
import { ManualVoucherReportCancel } from "../Api/Post/PostApi";
import { DocumentContext } from "../BillPage/Document-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { Table } from "../CustomReactTable/CustomReactTable";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { Search } from "../Api/Get/GetApiTypes";
import { ManualInvoice } from "../Receipt/ManualInvoicePage";
import { ConfirmationPopup } from "../PopUp/ConfirmationPopup";
import { PasswordPopup } from "../PopUp/PasswordPopup";
import { JournalDetail } from "../JournalDetail/JournalDetail";


export interface rolesProps {
  user_role_id: number;
  role_id: number;
  role_name: string;
}

export interface FilterDate {
  dateTo: string;
  dateFrom: string;
}

export type popupType = "view" | "cancel";
export interface ManualVoucherUpdateResponse {
  id:number;
  nepali_date: string;
  document_number: string;
  billed_business_id: string;
  main_description: string;
  debit_amount: number;
  credit_amount: number;
  customer_info: {
    name: string;
    pan: number;
    mobile_no: string;
  };
}

export interface ManualVoucherResponse {
  nepali_date: string;
  document_number: string;
  main_description: string;
  debit_amount: number;
  credit_amount: number;
  customer_info: {
    name: string;
    pan: number;
    mobile_no: string;
  };
}

export const AllTotal = (info: any, accessor: string) => {
  let total = React.useMemo(
    () =>
      info.rows.reduce((sum: any, row: any) => row.values[accessor] + sum, 0),
    [info.rows]
  );

  return <>Total: {Number.isInteger(total) ? total : total.toFixed(3)}</>;
};

export const ManualVoucherListPage = () => {
  //useState Hooks
  const [printModal, setPrintModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState<Search>({
    invoice_no: "",
    customer_name: "",
    pan: "",
    mobile_no: "",
  });
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  //useContext Hooks
  const { setDocumentNumber, setCustomerId, documentNumber, customerId } =
    useContext(DocumentContext);
  const { openModal } = useContext(PopupContext);
  const [reason, setReason] = useState("");

  const {
    data: manualVoucheLists,
    refetch,
    isLoading,
  } = useManualVoucheList(
    queryPageIndex,
    queryPageSize,
    searchTerm as Search,
    date
  );
  const { showErrorMessage, showSuccessMessage } = useToast();
  const { closeModal } = useContext(PopupContext);
  const [popupType, setPopupType] = useState<popupType>();
  const [cancelPopup, setCancelPopUp] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [requireId, setRequireId] = useState<{
    journalId: number | undefined;
  }>({
    journalId: undefined,
  });
    const [popupTypes, setPopupTypes] = useState({
    journal: false,
  });
  const handleViewClick = (row: ManualVoucherUpdateResponse) => {
    setRequireId((p) => ({ ...p, journalId: row.id }));
    setPopupTypes((p) => ({ ...p, journal: true }));
    openModal();
  };


  const HandleCancelIcon = (value: any) => {
    setPopupType("cancel");
    setDocumentNumber(value.document_number);
    setCustomerId(value.customer_id);
    setPasswordPopup(true);
    setCancelPopUp(false);
    setPrintModal(false);
    openModal();
  };
  const { data: journalData } = useJournalEntry(requireId.journalId);
  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "s_n",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Document Number",
        accessor: "document_number",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Pan",
        accessor: "pan",
      },
      {
        Header: "Mobile Number",
        accessor: "mobile_no",
      },
      {
        Header: "Narration",
        accessor: "main_description",
      },
      {
        Header: "Debit Amount",
        accessor: "debit_amount",
      },
      {
        Header: "Credit Amount",
        accessor: "credit_amount",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }: { value: any }) => {
          return (
            <div className="action-section">
              <Dropdown>
                <Dropdown.Toggle className="dropdown-icon" id="dropdown-basic">
                  <ThreeDotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      handleViewClick(value);
                    }}
                  >
                    <div>
                      <span className="icon">
                        <EyeIcon />
                      </span>
                      View
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      HandleCancelIcon(value);
                    }}
                  >
                    <div>
                      <span className="icon">
                        <CrossIcon />
                      </span>
                      Cancel
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const FormatTable = (
    array: ManualVoucherUpdateResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array.length < 1) return [];
    const formatData = array?.map(
      (item: ManualVoucherUpdateResponse, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          date: item.nepali_date || "",
          document_number: item.document_number,
          main_description: item.main_description,
          debit_amount: parenthesisInNegativeValue(item.debit_amount || 0),
          credit_amount: parenthesisInNegativeValue(item.credit_amount || 0),
          action: {
            document_number: item.document_number,
            customer_id: item.billed_business_id,
            id:item?.id
          },
          customer_name: item?.customer_info?.name,
          pan: item?.customer_info?.pan,
          mobile_no: item?.customer_info?.mobile_no,
        };
      }
    );
    return formatData;
  };

  const billCancelation = (
    document_number: string,
    reason: string,
    id: number
  ) => {
    if (!reason) {
      showErrorMessage("Please specify reason");
      return;
    }
    const data = {
      reason_to_cancel: reason,
      customer_id: id,
    };
    ManualVoucherReportCancel(document_number, data)
      .then((res) => {
        showSuccessMessage("Bill cancel successfully");
        refetch();
        setCancelPopUp(false);
        closeModal();
      })
      .catch((err) => {
        let erroMessage = "";
        if (err.response.data) {
          erroMessage = HandleError(err);
        }

        showErrorMessage(erroMessage || "Something went wrong");
      });
  };

  useEffect(() => {
    refetch();
  }, [date.dateFrom, date.dateTo]);

  return (
    <>
      <div className="manual-voucher-page">
        
        <div className="row">
          <div className="head-section">
            <div className="col-md-6">
              <div className="small-title">Manual Voucher Report</div>
            </div>
          </div>
        </div>
        <Table
          excelDownload={true}
          loading={isLoading}
          columns={columns}
          data={
            manualVoucheLists
              ? FormatTable(
                  manualVoucheLists.data as ManualVoucherUpdateResponse[],
                  queryPageIndex,
                  queryPageSize
                )
              : []
          }
          totalPages={
            manualVoucheLists &&
            totalPages(queryPageSize, manualVoucheLists.pagination.total_count)
          }
          showFooter={true}
          showDate={true}
          totalEntries={
            manualVoucheLists && manualVoucheLists.pagination.total_count
          }
          setDate={setDate}
          queryPageIndex={queryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageIndex={setQueryPageIndex}
          setQueryPageSize={setQueryPageSize}
          date={date}
          searchArray={["invoice_no", "customer_name", "pan", "mobile_no"]}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>

      {cancelPopup && (
        <PopUp
          title="Confirmation"
          renderComponent={
            <ConfirmationPopup
              handleCancel={() => {
                billCancelation(documentNumber, reason, customerId as number);
              }}
              showReasonField={true}
              reason={reason}
              setReason={setReason}
              confirmationQuestion="Are you sure you want to cancel bill?"
            />
          }
        />
      )}
      {passwordPopup && (
        <PopUp
          title="Sign-In Required"
          renderComponent={
            <PasswordPopup
              setPasswordPopup={setPasswordPopup}
              setCancelPopUp={setCancelPopUp}
            />
          }
        />
      )}
      {popupTypes.journal &&
        requireId.journalId !== undefined &&
        journalData && (
          <PopUp
            showCrossIcon={true}
            popupType="full"
            popupWidth={"100%"}
            showHeader={true}
            popupHeight={"100%"}
            renderComponent={<JournalDetail journalData={journalData} />}
          />
        )}
    </>
  );
};
