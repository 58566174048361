import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./NavBar.scss";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import {
  BillIcon,
  CategoryIcon,
  CustomerIcon,
  DashboardIcon,
  LedgerIcon,
  PaymentVoucherIcon,
  PayslipIcon,
  ProductIcon,
  ProfileIcon,
  PurchaseIcon,
  PurchaseReturnIcon,
  ReturnIcon,
  SalesReturnIcon,
} from "../../assets/images/svg/svg";
import { useState } from "react";
import { RoleWiseComponents, sameOrderArray } from "../../utils/services";
import { useAuth } from "../context/auth/auth";
import { Tooltip } from "react-bootstrap";

export interface SideBarComponentsKeys {
  name: string;
  link: string;
  icon: any;
}

export const NavBar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const { businessUserInfo } = useAuth();

  const RoleWiseSideBar = {
    Admin: [
      "Dashboard",
      "Bill",
      "Sales Return",
      "Purchase Return",
      "Manual Voucher",
      "Payment Voucher",
      "Payslip",
      "Report",
      "Category",
      "Products",
      "Patron",
      "Staff Management",
    ],
    Staff: [
      "Dashboard",
      "Bill",
      "Sales Return",
      "Purchase Return",
      "Manual Voucher",
      "Payslip",
      "Report",
      "Category",
      "Products",
      "Patron",
    ],
  };

  const SideBarComponents = [
    {
      name: "Dashboard",
      link: "/",
      icon: <DashboardIcon />,
    },
    {
      name: "Bill",
      link: "/bill",
      icon: <BillIcon />,
    },

    {
      name: "Payment Voucher",
      link: "/payment-voucher",
      icon: <PaymentVoucherIcon />,
    },
    {
      name: "Payslip",
      link: "/payslip",
      icon: <PayslipIcon />,
    },
    {
      name: "Products",
      link: "/products",
      icon: <ProductIcon />,
    },

    {
      name: "Report",
      link: "/report",
      icon: <LedgerIcon />,
    },
    {
      name: "Sales Return",
      link: "/sales-return",
      icon: <SalesReturnIcon />,
    },
    {
      name: "Purchase Return",
      link: "/purchase-return",
      icon: <PurchaseReturnIcon />,
    },
    {
      name: "Category",
      link: "/category",
      icon: <CategoryIcon />,
    },
    {
      name: "Patron",
      link: "/patron-list",
      icon: <CustomerIcon />,
    },

    {
      name: "Staff Management",
      link: "/staff-management",
      icon: <ProfileIcon />,
    },

    {
      name: "Manual Voucher",
      link: "/manual-voucher",
      icon: <BillIcon />,
    },
  ];

  const roleWiseContents =
    businessUserInfo?.user_info &&
    RoleWiseComponents(
      SideBarComponents,
      RoleWiseSideBar[
      businessUserInfo.user_info.role[0].name as keyof typeof RoleWiseSideBar
      ]
    );

  return (
    <div className="nav-header">
      <div style={{ display: "flex", height: "100%" }}>
        <Sidebar>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: active ? "#f5d9ff" : "#7a7a7a",
                    backgroundColor: active ? "#fff" : "transparent",
                    borderRadius: active ? "5px" : "",
                    // color: active ? "#99dccf" : "transparent",
                  };
              },
            }}
          >
            {businessUserInfo &&
              sameOrderArray(
                RoleWiseSideBar[
                businessUserInfo.user_info.role[0]
                  .name as keyof typeof RoleWiseSideBar
                ],
                roleWiseContents as SideBarComponentsKeys[]
              ).map((val) => {
                return (
                  <div className="nav-list" key={val.link}>
                    <MenuItem
                      rootStyles={{}}
                      active={active === val.link}
                      component={
                        <Link
                          to={val.link}
                          onClick={() => {
                            setActive(val.link);
                          }}
                        />
                      }
                    >
                      <div className="icon-bg">{val.icon}</div>

                      {val.name}
                      <Tooltip
                        placement="right"
                        title={val.name}
                        style={{ position: "absolute" }}
                      />
                    </MenuItem>
                  </div>
                );
              })}
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
};
