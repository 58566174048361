import { Formik, FormikConfig, useFormikContext } from "formik";
import NepaliDate from "nepali-date-converter";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { HandleError, updateSecondaryQuantity } from "../../utils/services";
import { PostPurchaseData, PostSalesData } from "../Api/Post/PostApi";
import { BatchesContext } from "../context/BatchesContext/BatchesContext";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { DocumentContext } from "./Document-Context";
import { PopupContext } from "../PopUp/Popup-Context";
import { ReportType } from "../../EnumValues";

export interface UnitsProps {
  primary_unit: number;
  secondary_unit: number;
}
export interface PriceProps {
  primary_price: number;
  secondary_price: number;
}
export interface BillDetailProps {
  id: number;
  product_bar_code: string;
  qty: UnitsProps;
  rate: PriceProps;
  free_qty: UnitsProps;
  amt: number;
  discount: number;
  sub_amt: number;
  product_id: number;
  relation?: number;
  tax: number;
}

export interface BankDataProps {
  cheque_no: string;
  amount: string | number;
  cheque_issue_date: string;
  bank_id: number | string;
}

export interface NewBillFormValues {
  user_name: string;
  bill_number?: string;
  date?: string;
  transaction_date?: string;
  billed_business_id: number;
  role_id: number | "";
  transaction_type?: ReportType;
  bill_details: Array<BillDetailProps>;
  sub_total: number;
  discount: number;
  non_taxable: number;
  taxable: number;
  tax: number;
  total: number;
  customer_notes: string;
  bill_terms: string;
  cash: number;
  credit: number;
  bank: Array<BankDataProps>;
  pay_by_check: boolean;
  description: string;
}

export const initialValues: NewBillFormValues = {
  pay_by_check: false,
  description: "",
  user_name: "",
  bill_number: "",
  date: new NepaliDate().format("YYYY-MM-DD"),
  transaction_date: new NepaliDate().format("YYYY-MM-DD"),
  billed_business_id: 0,
  role_id: "",
  transaction_type: ReportType.Sales,
  bill_details: [
    {
      id: 0,
      product_bar_code: "",
      relation: 0,
      product_id: 0,
      amt: 0,
      discount: 0,
      sub_amt: 0,
      qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      free_qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      rate: {
        primary_price: 0,
        secondary_price: 0,
      },
      tax: 0,
    },
  ],
  sub_total: 0,
  discount: 0,
  taxable: 0,
  non_taxable: 0,
  tax: 0,
  total: 0,
  customer_notes: "",
  bill_terms: "",
  cash: 0,
  credit: 0,
  bank: [
    {
      cheque_no: "",
      amount: 0,
      cheque_issue_date: "",
      bank_id: 0,
    },
  ],
};

const validationSchema = Yup.object().shape({
  // description: Yup.string().required("Required"),
  billed_business_id: Yup.number().positive("Please select patron"),
  bill_number: Yup.string().when(["transaction_type"], {
    is: ReportType.Purchase,
    then: Yup.string().required("Invoice number is required"),
  }),
  date: Yup.string().when(["transaction_type"], {
    is: ReportType.Purchase,
    then: Yup.string().required("Date  required"),
  }),
  transaction_date: Yup.string().when(["transaction_type"], {
    is: ReportType.Purchase,
    then: Yup.string().required("Transaction date  required"),
  }),
  transaction_type: Yup.string().required("Please enter a word count"),
  bill_details: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.number().positive("Please select product"),
      qty: Yup.object().shape({
        primary_unit: Yup.string().matches(/^\d+(.\d{1,3})?$/, "Invalid input"),
        secondary_unit: Yup.string().matches(
          /^\d+(.\d{1,3})?$/,
          "Invalid input"
        ),
      }),
      rate: Yup.object().shape({
        primary_price: Yup.string()
          .matches(/^\d+(.\d{1,3})?$/, "Invalid input")
          .required("Required"),
        secondary_price: Yup.string()
          .matches(/^\d+(.\d{1,3})?$/, "Invalid input")
          .required("Required"),
      }),
      free_qty: Yup.object().shape({
        primary_unit: Yup.number().required("Please enter primary unit"),
        secondary_unit: Yup.number().required("Please enter secondary unit"),
      }),
      discount: Yup.string().matches(/^\d+(.\d{1,3})?$/, "Invalid input"),
      amt: Yup.number().positive("Invalid amount"),
    })
  ),
  sub_total: Yup.number().required("Please enter sub total"),
  discount: Yup.number(),
  total: Yup.number().required("Please enter total amount"),
  customer_notes: Yup.string(),
  bill_terms: Yup.string(),
  cash: Yup.string(),
  credit: Yup.string(),
  bank: Yup.array().when(["pay_by_check"], {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        cheque_no: Yup.string().required("Please enter cheque number"),
        amount: Yup.number().required("Please enter amount"),
        cheque_issue_date: Yup.string().required("Please select issue date"),
        bank_id: Yup.string().required("Please select bank name"),
      })
    ),
  }),
});

export const useNewBillFormikContext = () => {
  const formik = useFormikContext<NewBillFormValues>();
  if (!formik) {
    throw new Error("useNewOrderFormikContext must be used within a Formik");
  }
  return formik;
};
interface BillFormikWrappereProps {
  children: React.ReactNode;
}

export const BillFormikWrapper: React.FC<BillFormikWrappereProps> = ({
  children,
}) => {
  const [nextState, setNextState] = useState("Ledger");

  const {
    setDocumentNumber,
    setResStatus,
    setSearchStrings,
    setIsSubmitted,
    isSubmitted,
  } = useContext(DocumentContext);
  const { openModal } = useContext(PopupContext);

  const { showErrorMessage } = useToast();
  const { setActiveBatch, setSelectedBatches } = useContext(BatchesContext);

  const handleSubmit: FormikConfig<NewBillFormValues>["onSubmit"] = (
    values,
    { setSubmitting, resetForm }
  ) => {
    setSubmitting(true);
    const data = {
      ...values,
    };
    if (values.transaction_type === ReportType.Purchase && !values.bill_number) {
      showErrorMessage("Please fill invoice number");
      setSubmitting(false);
      return;
    }
    const Api =
      values.transaction_type === ReportType.Purchase ? PostPurchaseData : PostSalesData;
    if (values.transaction_type === ReportType.Sales) {
      delete data.bill_number;
      delete data.date;
    }
    if (!values.pay_by_check) {
      data.bank = []
    }
    //This function is made to sync the value of rate with price when product with  only primary unit and no secondary unit is sold
    data.bill_details = updateSecondaryQuantity(values.bill_details);
    const { transaction_type, ...rest } = data
    Api({ ...rest })
      .then((res) => {
        openModal();
        toast.success("Bill information submitted successfully");
        window.scrollTo(0, 0);
        setSubmitting(false);
        setDocumentNumber(res.data.data.document_no);
        setSearchStrings([{ title: "" }]);
        setActiveBatch(null);
        setSelectedBatches([]);
        setNextState(nextState);
        setIsSubmitted(!isSubmitted);
        if (transaction_type === ReportType.Sales) {
          //For Bill print receipt popup
          setResStatus?.(true);
        }
      })
      .catch((err: any) => {
        setResStatus?.(false);
        const errorMsg = HandleError(err);
        showErrorMessage(errorMsg);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
