import React, { useContext, useEffect, useState } from "react";
import { ROLE } from "../../EnumValues";
import { EditIcon, PasswordIcon } from "../../assets/images/svg/svg";
import { UserProfileData } from "../Api/Get/GetApiTypes";
import { BusinessInfoForm } from "../BusinessInfoForm/BusinessInfoForm";
import {
  BusinessInfoWrapper,
  BusinessValue,
} from "../BusinessInfoForm/BusinessInfoWrapper";
import { PersonalForm } from "../BusinessInfoForm/PersonalForm";
import {
  PersonalDetailsWrapper,
  PersonalProfile,
} from "../BusinessInfoForm/PersonalInfoWrapper";
import { ConfirmPw } from "../ConfrimPw/ConfirmPw";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import "./ProfileHeader.scss";

interface BusinessProfileProps {
  businessData?: BusinessValue | undefined;
  showPersonalDetails?: boolean;
  profileData?: UserProfileData | undefined;
}

export type profilePopupType =
  | "password"
  | "edit_personal_detail"
  | "edit_company_detail";

export const ProfileHeader: React.FC<BusinessProfileProps> = ({
  showPersonalDetails = false,
  businessData,
  profileData,
}) => {
  const { openModal } = useContext(PopupContext);
  const [personalDetail, setPersonalDetail] = useState<PersonalProfile>();
  const [popupTypes, setPopupType] = useState<profilePopupType>();

  useEffect(() => {
    if (profileData) {
      setPersonalDetail((p) => ({
        ...p,
        name: profileData?.name as string,
        email: profileData?.email as string,
        mobile_no: profileData?.mobile_no,
        pan: profileData?.pan as number,
        additional_info: {
          address: profileData?.additional_info?.address,
        },
      }));
    }
  }, [profileData]);

  return (
    <div className="business-profile">
      <div className="head-section">
        {showPersonalDetails ? (
          <h2 className="small-title">Profile Details</h2>
        ) : (
          <h2 className="small-title">Company Details</h2>
        )}
        {profileData?.roles[0]?.role?.id === ROLE.Admin ? (
          <div className="add-new-button">
            {showPersonalDetails && (
              <div className="mg-right-10">
                <button
                  className="light-primary "
                  onClick={() => {
                    setPopupType("password");
                    openModal();
                  }}
                >
                  <span className="icon-mg">
                    <PasswordIcon />
                  </span>
                  Change Password
                </button>
                {popupTypes === "password" && (
                  <PopUp
                    // title="Change Password"
                    popupWidth={400}
                    closeOnClickOutside={false}
                    renderComponent={
                      <ConfirmPw
                        reqOldPassword={true}
                        isStaff={false}
                        id={profileData?.id}
                      />
                    }
                  />
                )}
              </div>
            )}
            {showPersonalDetails ? (
              <button
                className="light-primary "
                onClick={() => {
                  setPopupType("edit_personal_detail");
                  openModal();
                }}
              >
                <span className="icon-mg">
                  <EditIcon />
                </span>
                Edit Details
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="add-new-button">
            {showPersonalDetails ? (
              <button
                className="light-primary "
                onClick={() => {
                  openModal();
                }}
              >
                <span>
                  <img
                    src={require("../../assets/images/plus-16.png")}
                    alt="plus"
                  />
                </span>
                Edit Details
              </button>
            ) : (
              <button
                className="light-primary "
                onClick={() => {
                  setPopupType("edit_company_detail");
                  openModal();
                }}
              >
                <span className="icon-mg">
                  <EditIcon />
                </span>
                <p className="mg-btm-0">
                  {businessData ? "Edit Company Details" : "Add Business Info"}
                </p>
              </button>
            )}
          </div>
        )}
      </div>
      <div className="profile-detail">
        <div className="d-flex profile-wrap">
          {showPersonalDetails ? (
            <>
              {profileData?.photo ? (
                <img
                  className="logo-image"
                  src={profileData?.photo ?? null}
                  alt=" "
                />
              ) : (
                <div className="person-img">
                  <p className="large-title">
                    {profileData?.name.charAt(0).toUpperCase()}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {businessData?.image_url ? (
                <img
                  className="logo-image"
                  src={businessData?.image_url ?? null}
                  alt=" "
                />
              ) : (
                <div className="person-img">
                  <p className="large-title">
                    {businessData?.name.charAt(0).toUpperCase()}
                  </p>
                </div>
              )}
            </>
          )}

          <div className="d-space-between text-description">
            <div className="user-detail">
              {showPersonalDetails ? (
                <p className="name small-title">{profileData?.name}</p>
              ) : (
                <p className="name small-title">{businessData?.name}</p>
              )}

              <p className="email content">
                <span>
                  <img
                    src={require("../../assets/images/mail-16.svg").default}
                    alt="mail"
                  />
                </span>
                {showPersonalDetails ? (
                  <span className="content">{profileData?.email ?? "N/A"}</span>
                ) : (
                  <span className="content">
                    {businessData?.email ?? "N/A"}
                  </span>
                )}
              </p>
              <p className="location small-content">
                <span>
                  <img
                    src={require("../../assets/images/ico-location.png")}
                    alt="loc"
                  />
                </span>
                {showPersonalDetails ? (
                  <span className="small-content">
                    {profileData?.additional_info?.address ?? "N/A"}
                  </span>
                ) : (
                  <span className="small-content">
                    {businessData?.address ?? "N/A"}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* edit_personal_detail */}
      {showPersonalDetails ? (
        <>
          {popupTypes === "edit_personal_detail" && (
            <PopUp
              popupType="full"
              title="Personal Form"
              renderComponent={
                <PersonalDetailsWrapper
                  intialValuesFromProps={personalDetail}
                  userId={profileData?.id}
                >
                  <PersonalForm />
                </PersonalDetailsWrapper>
              }
            />
          )}
        </>
      ) : (
        <>
          {/* details */}
          {popupTypes === "edit_company_detail" && (
            <PopUp
              popupType="full"
              title="Business Form"
              renderComponent={
                <BusinessInfoWrapper intialValuesFromProps={businessData}>
                  <BusinessInfoForm businessData={businessData} />
                </BusinessInfoWrapper>
              }
            />
          )}
        </>
      )}
    </div>
  );
};
