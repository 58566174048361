import { Formik, FormikConfig, useFormikContext } from "formik";
import { useContext } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { SignIn, useBusinessData, useUserProfile } from "../Api/Get/GetApi";
import { UpdateUser } from "../Api/Put/PutApi";
import { PopupContext } from "../PopUp/Popup-Context";

export interface PersonalProfile {
  pan: string | number;
  mobile_no: string;
  email: string;
  name: string;
  additional_info: { address: string } | string;
  photo?: any;
}

export const initialValues: PersonalProfile = {
  pan: "",
  mobile_no: "",
  email: "",
  name: "",
  additional_info: { address: "" },
  photo: "",
};
const validationSchema = Yup.object().shape({
  pan: Yup.string()
    .required("Please enter your PAN Number")
    .length(9, "Pan number must be 9 digit"),
  mobile_no: Yup.string()
    .required("Please enter your Mobile Number")
    .length(10, "Mobile number must be 10 digit"),
  name: Yup.string().required("Please enter Customer Name"),
  email: Yup.string().required("Please enter your Email"),
  additional_info: Yup.object().shape({
    address: Yup.string().required("Please enter your Address"),
  }),
});

export const usePersonalDetailsInfo = () => {
  const formik = useFormikContext<PersonalProfile>();
  if (!formik) {
    throw new Error("usePersonalDetailsInfo must be used within a Formik");
  }
  return formik;
};
interface PersonalDetailsWrapperProps {
  children: React.ReactNode;
  intialValuesFromProps?: PersonalProfile;
  userId?: number;
}

export const PersonalDetailsWrapper: React.FC<PersonalDetailsWrapperProps> = ({
  children,
  intialValuesFromProps,
  userId,
}) => {
  const { closeModal } = useContext(PopupContext);
  const { refetch } = useUserProfile();

  const handleSubmit: FormikConfig<PersonalProfile>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);

    const data = { ...values };
    data.additional_info = JSON.stringify(data.additional_info);

    UpdateUser(userId as number, data as any)
      .then((res) => {
        toast.success("Information updated successfully");
        setSubmitting(false);
        refetch();
        closeModal();
        SignIn();

      })
      .catch((err: any) => {
        toast.error("Failed to update data");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={intialValuesFromProps || initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
