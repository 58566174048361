import axios from "axios";
import { domain } from "../config/url";


export const Axios = axios.create({
  baseURL: domain ,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,

});

export const MultiPartAxios = axios.create({
  baseURL: domain ,
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,

});
