import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ExcelIcon,
  PrintIcon,
  TriangleDown,
  TriangleRignt,
} from "../../assets/images/svg/svg";
import {
  BalanceSheetSameOrder,
  parenthesisInNegativeValue,
  replaceDecimal,
} from "../../utils/services";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { useOpeningTableList } from "../Api/Get/GetApi";
import { IBalanceSheet } from "../Api/Get/GetApiTypes";
import { LedgerForm } from "../LedgerTable/LedgerForm";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import "./OpeningAdjustmentReport.scss";
import { LedgerListResponse } from "../Api/types";
import { BalanceSheetOrderName } from "../../EnumValues";

export const RecursiveOpeningInfo = (
  data: IBalanceSheet[],
  groupId: number
): IBalanceSheet[] => {
  const result: IBalanceSheet[] = [];
  if (data && data.length > 0) {
    for (const item of data) {
      if (item.group_code === groupId) {
        const children = RecursiveOpeningInfo(data, item.id);
        if (children.length > 0) {
          (item.childInfo as IBalanceSheet[]) = children;
        }
        result.push(item);
      }
    }
  }

  return result;
};

export const OpeningAdjustmentReport = () => {
  const location = useLocation();
  const { businessUserInfo } = useAuth();
  const { data, refetch, isLoading } = useOpeningTableList();
  const liability_data = data?.liabilities;
  const asset_data = data?.assets;

  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);
  const { openModal } = useContext(PopupContext);
  const [editPopUp, setEditPopUp] = useState(false);
  const [ledgerItem, setLedgerItem] = useState<
    LedgerListResponse | undefined
  >();
  const assetVal: number = useMemo(() => {
    if (asset_data && asset_data?.length > 0) {
      return asset_data[0]?.total;
    } else {
      return 0;
    }
  }, [asset_data]);
  const liabVal: number = useMemo(() => {
    return liability_data ? liability_data[0]?.total : 0;
  }, [liability_data]);

  const CalculateDifference = (asset: number, liabilities: number) => {
    if (asset && liabilities) {
      return {
        grandTotal:
          asset > liabilities
            ? replaceDecimal(asset)
            : replaceDecimal(liabilities),
        difference: replaceDecimal(Math.abs(asset - liabilities)),
      };
    } else {
      return {
        grandTotal: 0,
        difference: 0,
      };
    }
  };
  const toDate = "defult date";
  const fromDate = "defult date";
  const col_Span = 4;
  const uptoDate = "0";
  const startingliabilitiesId = liability_data?.[0]?.id || 0;
  const startingAssetsId = asset_data?.[0]?.id || 0;

  const liabilitiesOrder = BalanceSheetOrderName.orderLiabilities;
  const assetsOrder = BalanceSheetOrderName.orderAssets;
  const handleRefetch = () => {
    refetch();
  };
  const handleDoubleClick = (is_editable: "YES" | "NO" | null | undefined) => {
    if (is_editable?.toLowerCase() === "yes") {
      setEditPopUp(true);
      openModal();
    }
  };
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt  + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const newliabilitiesArr =
            liability_data?.map((item) => {
              return item.id;
            }) || [];

          const newAssetArr =
            asset_data?.map((item) => {
              return item.id;
            }) || [];

          const concatenatedArr = newliabilitiesArr.concat(newAssetArr);

          setCheckedValues(concatenatedArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, keyDownChecked]);
  const handleOnChange = (e: any, item: IBalanceSheet) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };

  return (
    <>
      <div className="openinge_report">
        <div className="head-section">
          <div className="main-title">Opening Balance Report</div>
        </div>
        <div className="row">
          <div className="col-md-10 "></div>
          <div className="col-md-2">
            <div className="d-flex gap-3 float-right">
              <button
                className="d-flex btn btn-light"
                onClick={() => {
                  if (uptoDate !== undefined) {
                    // newExportTableToExcel()
                    nestedTableExcel(
                      "exportTable",
                      location,
                      businessUserInfo as UserInfoResponse,
                      fromDate,
                      toDate,
                      col_Span,
                      uptoDate
                    );
                  }
                }}
              >
                <span className="export-title"> Export</span>
                <ExcelIcon />
              </button>
              <button
                className="d-flex btn btn-light my-4 "
                onClick={() => {
                  financialReportPrint(
                    [],
                    businessUserInfo as UserInfoResponse,
                    "",
                    "exportOpeningBalanceReport",
                    location
                  );
                }}
              >
                <span style={{ marginRight: "10px" }} className="export-title">
                  Print{" "}
                </span>
                <PrintIcon />
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-section" id="exportOpeningBalanceReport">
              <table className="main-table table">
                <thead className="table-header">
                  <tr>
                    <th className="content-main-title width-50 border-right">
                      Liabilities
                    </th>
                    <th className="content-main-title width-50 text-right">
                      Assets
                    </th>
                  </tr>
                </thead>

                <tr>
                  <td>
                    <table className="small-table table mg-top-12">
                      <thead className="table-header">
                        <tr>
                          <th className="content-title width-60 ">
                            Particular
                          </th>
                          <th className="content-title text-center">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {BalanceSheetSameOrder(
                            liabilitiesOrder,
                            RecursiveOpeningInfo(
                              liability_data as IBalanceSheet[],
                              startingliabilitiesId
                            )
                          )?.map((item, index) => {
                            return (
                              <>
                                {RecursiveOpeningeBalanceShow(
                                  item?.childInfo as IBalanceSheet[],
                                  item,
                                  index,
                                  0,
                                  14,
                                  500,
                                  "#000",
                                  "normal",
                                  checkedValues,
                                  handleOnChange,
                                  handleRefetch,
                                  handleDoubleClick,
                                  setEditPopUp,
                                  editPopUp,
                                  ledgerItem
                                )}
                              </>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table className="small-table table">
                      <thead className="table-header">
                        <tr>
                          <th className="content-title width-60 ">
                            Particular
                          </th>
                          <th className="content-title text-center">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BalanceSheetSameOrder(
                          assetsOrder,
                          RecursiveOpeningInfo(
                            asset_data as IBalanceSheet[],
                            startingAssetsId
                          )
                        )?.map((item, index) => {
                          return (
                            <>
                              {RecursiveOpeningeBalanceShow(
                                item?.childInfo as IBalanceSheet[],
                                item,
                                index,
                                0,
                                14,
                                500,
                                "#000",
                                "normal",
                                checkedValues,
                                handleOnChange,
                                handleRefetch,
                                handleDoubleClick,
                                setEditPopUp,
                                editPopUp,
                                ledgerItem
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
                {/* for total */}
                <tr style={{ borderTop: "1px solid #dcdcdc" }}>
                  <td style={{ verticalAlign: "bottom" }}>
                    <table className="table footer-table">
                      {liabVal < assetVal ? (
                        <tr>
                          <td className="black-content">
                            Difference in opening balance
                          </td>
                          <td className="black-content text-right">
                            {parenthesisInNegativeValue(
                              CalculateDifference(assetVal, liabVal).difference
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )}
                      <tr>
                        <td className="footer-content width-60 border-right">
                          Total
                        </td>
                        <td className="footer-content text-right">
                          {parenthesisInNegativeValue(
                            CalculateDifference(assetVal, liabVal).grandTotal
                          )}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ verticalAlign: "bottom" }}>
                    <table className="table footer-table">
                      {assetVal < liabVal ? (
                        <tr>
                          <td className="black-content  border-bottom">
                            Difference in opening balance
                          </td>
                          <td className="black-content text-right">
                            {parenthesisInNegativeValue(
                              CalculateDifference(assetVal, liabVal).difference
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )}
                      <tr>
                        <td className="footer-content width-60 border-right">
                          Total
                        </td>
                        <td className="footer-content text-right">
                          {parenthesisInNegativeValue(
                            CalculateDifference(assetVal, liabVal).grandTotal
                          )}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RecursiveOpeningeBalanceShow = (
  childInfo: IBalanceSheet[],
  item: IBalanceSheet,
  index: number,
  padding: number,
  fontSize: number,
  fontWeight: number,
  color: string,
  fontStyle: string,
  checkedValues: Array<number>,
  handleOnChange: (e: any, item: IBalanceSheet) => void,
  handleRefetch: () => void,
  handleDoubleClick: (is_editable: "YES" | "NO" | null | undefined) => void,
  setEditPopUp: React.Dispatch<React.SetStateAction<boolean>>,
  editPopUp: boolean,
  ledgerItem: LedgerListResponse | undefined
): JSX.Element | null => {
  if (childInfo?.length <= 0) {
    return null;
  }

  // Sort the childInfo array in alphabetical order by ledger_name

  const exists = checkedValues?.find((val) => val === item.id);

  const sortedChildInfo = childInfo?.sort((a, b) =>
    a.ledger_name.localeCompare(b.ledger_name)
  );

  return (
    <>
      {editPopUp && (
        <PopUp
          closeOnClickOutside={true}
          showCrossIcon={true}
          popupWidth={500}
          title="Edit Form"
          renderComponent={
            <LedgerForm
              ledgerId={item.id}
              mode="edit"
              refetch={handleRefetch}
            />
          }
        />
      )}
      <tr
        onDoubleClick={() => {
          handleDoubleClick(item?.is_editable);
        }}
      >
        <td className="border-right">
          <p
            style={{
              paddingLeft: `${padding}px`,
              fontSize: `${fontSize}px`,
              fontWeight: `${fontWeight}`,
              color: `${color}`,
              fontStyle: `${fontStyle}`,
            }}
          >
            <div className="d-flex">
              <input
                className="checkbox-none"
                type="checkbox"
                id={`custom-checkbox-${item.id}`}
                name={item.ledger_name}
                value={item.ledger_name}
                checked={Boolean(exists)}
                onChange={(e) => {
                  handleOnChange(e, item);
                }}
              />
              <label htmlFor={`custom-checkbox-${item.id}`}>
                {sortedChildInfo?.length > 0 && (
                  <>{Boolean(exists) ? <TriangleDown /> : <TriangleRignt />}</>
                )}
                {item.ledger_name}
              </label>
            </div>
          </p>
        </td>
        <td>
          <p
            className="text-right"
            style={{
              paddingLeft: `${padding}px`,
              fontSize: `${fontSize}px`,
              fontWeight: `${fontWeight}`,
              color: `${color}`,
              fontStyle: `${fontStyle}`,
              paddingRight: `${padding + padding}px`,
            }}
          >
            {parenthesisInNegativeValue(item?.total || 0)}
          </p>
        </td>
      </tr>

      {Boolean(exists) ? (
        <>
          {sortedChildInfo?.map((val, idx) => {
            return (
              <>
                {RecursiveOpeningeBalanceShow(
                  val?.childInfo as IBalanceSheet[],
                  val,
                  idx,
                  padding + 20,
                  fontSize === 12 ? 12 : fontSize - 2,
                  fontWeight,
                  (color = "#666666"),
                  (fontStyle = "italic"),
                  checkedValues,
                  handleOnChange,
                  handleRefetch,
                  handleDoubleClick,
                  setEditPopUp,
                  editPopUp,
                  ledgerItem
                )}
              </>
            );
          })}
        </>
      ) : (
        ""
      )}
    </>
  );
};
