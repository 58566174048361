import { useState } from "react";
import { ProfileTabs } from "../../EnumValues";
import { BusinessProfilePage } from "./BusinessProfilePage";
import { PersonalDetails } from "./PersonalDetails";
import "./ProfileTab.scss";

export const ProfileTab = () => {
  const [activeState, setActiveState] = useState<ProfileTabs>(
    ProfileTabs.Profile_Details
  );

  return (
    <div className="profile-page">
      <div>
        <div className="small-title">Profile</div>
        <div className="d-flex tag-wrapper">
          <button
            className={`chip-tag recipt-bar small-content ${
              activeState === ProfileTabs.Profile_Details ? "active" : ""
            }`}
            onClick={() => {
              setActiveState(ProfileTabs.Profile_Details);
            }}
          >
            Personal Details
          </button>
          <button
            className={`chip-tag ledger-bar small-content ${
              activeState === ProfileTabs.Company_Details ? "active" : ""
            }`}
            onClick={() => {
              setActiveState(ProfileTabs.Company_Details);
            }}
          >
            Company Details
          </button>
        </div>
        {activeState === ProfileTabs.Profile_Details && (
          <>
            <PersonalDetails showPersonalDetails={true} />
          </>
        )}
        {activeState === ProfileTabs.Company_Details && (
          <>
            <BusinessProfilePage />
          </>
        )}
      </div>
    </div>
  );
};
