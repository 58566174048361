import { useLocation } from "react-router-dom";
import { useCashFlowList } from "../Api/Get/GetApi";
import "./CashFlow.scss";
import { useAuth } from "../context/auth/auth";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { UserInfoResponse } from "../context/auth/types";
import {
  ExcelIcon,
  PrintIcon,
  TriangleDown,
  TriangleRignt,
} from "../../assets/images/svg/svg";
import { useEffect, useState } from "react";
import { isCurrentFiscalYear, parenthesisInNegativeValue } from "../../utils/services";
import { Loader } from "../Loader/Loader";
import { ICashFlowList } from "../Api/Get/GetApiTypes";
import { FilterDate } from "../SalesList/SalesListPage";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export const CashFlow = () => {
  const { data: cashData, isLoading } = useCashFlowList();
  const location = useLocation();
  const { businessUserInfo } = useAuth();

  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });

  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [activeItem, setActiveItem] = useState(null);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);

  const formattedDate = businessUserInfo?.fiscal_year_info?.first_fiscal_date
    .split("/")
    .join("-");

  const lastFiscalDate: any =
    businessUserInfo?.fiscal_year_info?.last_fiscal_date.split("/").join("-");

  const currentFiscalYear = isCurrentFiscalYear(businessUserInfo?.fiscal_year_info?.current_fiscal_year)

  const handleOnChange = (e: any, item: ICashFlowList) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };
  const toggleAccordion = (id: any) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const concatenatedArr =
            cashData?.map((item) => {
              return item.id;
            }) || [];

          setCheckedValues(concatenatedArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [cashData, keyDownChecked]);

  // const grandTotal = data?.grand_total;
  const col_Span = 3;
  const uptoDate = "";

  return (
    <div className="cash-flow">
      <div className="head-section">
        <h2 className="small-title">Cash flow</h2>
      </div>
      <div className="col-md-12 d-flex">
        <div className="col-md-10"></div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group mg-btm-16">
              <label htmlFor="date" className="input-label">
                From Date
              </label>
              <NepaliDatePicker
                defaultDate={formattedDate as string}
                onDateSelect={(value: any) => {
                  setDate?.((p) => ({
                    ...p,
                    dateFrom: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group mg-btm-16">
              <label htmlFor="date" className="input-label">
                To Date
              </label>
              <NepaliDatePicker
                currentDate={currentFiscalYear as boolean}
                defaultDate={currentFiscalYear ? "" : lastFiscalDate}
                onDateSelect={(value: any) => {
                  setDate?.((p) => ({
                    ...p,
                    dateTo: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2">
            <div className="d-flex gap-3 float-right">
              <button
                className="d-flex btn btn-light"
                onClick={() =>
                  nestedTableExcel(
                    "exportTable",
                    location,
                    businessUserInfo as UserInfoResponse,
                    date?.dateFrom,
                    date?.dateTo,
                    col_Span,
                    uptoDate
                  )
                }
              >
                <span className="export-title"> Export</span>
                <ExcelIcon />
              </button>
              <button
                className="d-flex btn btn-light my-4 "
                onClick={() => {
                  financialReportPrint(
                    [],
                    businessUserInfo as UserInfoResponse,
                    date as FilterDate,
                    "exportTable",
                    location
                  );
                }}
              >
                <span style={{ marginRight: "10px" }} className="export-title">
                  Print{" "}
                </span>
                <PrintIcon />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="top-head">
          <h2 className="small-title">
            {businessUserInfo?.business_info.name}
          </h2>
          <p className="flex bold-content">
            {businessUserInfo?.business_info.address}
          </p>

          <div className="div-flex bold-content">
            <div className="small-text">Cash Flow</div>

            <p className="black-text">
              For the period from {date?.dateFrom} to {date?.dateTo}
            </p>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="table-page">
          <div className="table-wrapper" id="exportTable">
            <table className="table mg-top-12">
              <thead className="table-header">
                <th className="content width-70">Particular</th>
                <th className="content width-30 amt-col right-text" colSpan={2}>
                  Amount
                </th>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <div className="loder-wrapper">
                        <Loader loaderType="Spinner" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {cashData?.map((item, index) => {
                    const exists = checkedValues.find((val) => val === item.id);
                    return (
                      <>
                        {/* Separate elements with IDs 4, 5, and 6 */}
                        {item.id >= 4 ? (
                          <tr key={index}>
                            <td className="content-black" colSpan={2}>
                              {item.title}
                            </td>
                            <td className="content-black">
                              {parenthesisInNegativeValue(
                                item.data_list[0].amount || 0
                              )}
                            </td>
                          </tr>
                        ) : (
                          <>
                            {/* title loop */}
                            {item.id === null ? (
                              ""
                            ) : (
                              <>
                                <tr key={index}>
                                  <td className="content-black" colSpan={3}>
                                    <div className="d-flex">
                                      <input
                                        className="checkbox-none"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name={item.title}
                                        value={item.title}
                                        checked={Boolean(exists)}
                                        onChange={(e) =>
                                          handleOnChange(e, item)
                                        }
                                      />
                                      <div
                                        onClick={() => {
                                          toggleAccordion(item.id);
                                        }}
                                      ></div>
                                      <label
                                        htmlFor={`custom-checkbox-${index}`}
                                      >
                                        {Boolean(exists) ? (
                                          <TriangleDown />
                                        ) : (
                                          <TriangleRignt />
                                        )}
                                        {item.title}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )}
                            {/* bold id = null data */}

                            {item?.data_list?.map((itm, idx) => {
                              return (
                                <>
                                  {itm.id === null ? (
                                    <tr
                                      key={idx}
                                      className={
                                        Boolean(exists) ? "show" : "dontShow"
                                      }
                                    >
                                      <td className="content-black extra-padd">
                                        {itm.title}
                                      </td>
                                      <td className="content-black"></td>
                                      <td className="content-black">
                                        {parenthesisInNegativeValue(
                                          itm.amount || 0
                                        )}
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr
                                      key={idx}
                                      className={
                                        Boolean(exists) ? "show" : "dontShow"
                                      }
                                    >
                                      <td className="content extra-padd">
                                        {itm.title}
                                      </td>
                                      <td className="content">
                                        {parenthesisInNegativeValue(
                                          itm.amount || 0
                                        )}
                                      </td>
                                      <td className="content"></td>
                                    </tr>
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
