import React, { useContext, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ToWords } from "to-words";
import { SalesReturnInvoiceResponse } from "../Api/Get/GetApiTypes";
import { BillGenerate } from "../BillGenerate/BillGenrate";
import { PopupContext } from "../PopUp/Popup-Context";
import { DocumentContext } from "../BillPage/Document-Context";
import "./Receipt.scss";
import { useSubManualVoucher, UpdatePrintCount } from "../Api/Get/GetApi";
import { useAuth } from "../context/auth/auth";
import { useToast } from "../context/ToasterContext/ToasterContext";
import {
  englishToNepaliAmountConverter,
  fixedValue,
} from "../../utils/services";

export interface ManualVoucherUpdateResponse {
  id: number;
  ledger_name: string;
  debit: number;
  credit: number;
  description: string;
}

export interface ManualVoucherResponse {
  ledger_name: string;
  document_number: string;
  debit_amount: number;
  credit_amount: number;
}

interface ManualInvoiveProps {
  invoiceData?: SalesReturnInvoiceResponse;
  isLoading?: boolean;
  documentNumber: string;
  titles?: Array<string>;
}

interface ManualInvoiceReceiptProps {
  title: string;
}
export const ManualInvoice: React.FC<ManualInvoiveProps> = ({
  isLoading,
  documentNumber,
  titles = ["Manual Voucher"],
}) => {
  const componentRef = useRef(null);
  const { closeModal } = useContext(PopupContext);
  const { businessUserInfo } = useAuth();
  const { setResStatus } = useContext(DocumentContext);
  const { data: subManualVoucher } = useSubManualVoucher(documentNumber);
  const toWords = new ToWords({ converterOptions: { ignoreDecimal: false } });
  const [loading, setLoading] = useState(false);
  const { showErrorMessage } = useToast();
  const printCount = Number(
    subManualVoucher?.data?.bill_user_info?.bill_print_count
  );
  const currentTime = new Date();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setLoading(true),
    onAfterPrint: () => setLoading(false),
  });

  const handlePrintCount = (document_number: string) => {
    setLoading(true);
    UpdatePrintCount(document_number)
      .then((res) => {
        handlePrint();
        setResStatus?.(false);
      })
      .catch((err) => {
        showErrorMessage("Something Went wrong while printing");
        setLoading(false);
      });
  };

  const ManualInvoiceReceipt: React.FC<ManualInvoiceReceiptProps> = ({
    title,
  }) => {
    return (
      <>
        <div className="receipt new-receipt">
          <div className="receipt-section-mg-btn"></div>
          <div className="receipt-section">
            <div className="p-receipt">
              <div className="d-space-between">
                <div className="logo-detail">
                  <div className="d-flex logo-section">
                    {businessUserInfo?.business_info?.logo ? (
                      <div className="logo">
                        <img
                          src={businessUserInfo?.business_info?.logo}
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <div className="large-title">
                        {businessUserInfo?.business_info?.name
                          ?.charAt(0)
                          ?.toUpperCase()}
                      </div>
                    )}
                  </div>

                  <div>
                    <h2 className="small-title">
                      {businessUserInfo?.business_info?.name}
                    </h2>
                    <p className="content">
                      {businessUserInfo?.business_info?.address}
                    </p>

                    <div className="bold-content">
                      Phone:
                      <span className="content">
                        {" "}
                        +977-
                        {businessUserInfo?.business_info?.mobile_no}
                      </span>
                    </div>
                    <div className="bold-content">
                      PAN No.:
                      <span className="content">
                        {" "}
                        {businessUserInfo?.business_info?.pan}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="receipt-detail-block d-end">
                  <div className="print-title">
                    {printCount > 0 && title === "Invoice" && (
                      <p className="content">Copy of Original {printCount}</p>
                    )}
                  </div>
                  <p className="large-title">{title}</p>

                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <th className="bold-content" scope="row">
                          Invoice No:
                        </th>
                        <td className="content">
                          {subManualVoucher?.data?.bill_user_info?.document_no}
                        </td>
                      </tr>
                      <tr>
                        <th className="bold-content" scope="row">
                          Bill Miti:
                        </th>
                        <td className="content">
                          {subManualVoucher?.data?.bill_user_info?.bill_miti}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-space-between header-wrapper">
                {/* change */}
                <div className="receipt-header">
                  <div className="receipt-header-block">
                    <table className="table table-borderless">
                      <tbody>
                        {subManualVoucher?.data?.customer_user_info?.name && (
                          <tr>
                            <th className="bold-content" scope="row">
                              Customer Name:
                            </th>
                            <td className="content">
                              {subManualVoucher?.data?.customer_user_info?.name}
                            </td>
                          </tr>
                        )}
                        {subManualVoucher?.data?.customer_user_info
                          ?.additional_info?.address && (
                          <tr>
                            <th className="bold-content" scope="row">
                              Address:
                            </th>
                            <td className="content">
                              {
                                subManualVoucher?.data?.customer_user_info
                                  ?.additional_info?.address
                              }
                            </td>
                          </tr>
                        )}
                        {subManualVoucher?.data?.customer_user_info
                          ?.mobile_no && (
                          <tr>
                            <th className="bold-content" scope="row">
                              Phone:
                            </th>
                            <td className="content">
                              +977-
                              {
                                subManualVoucher?.data?.customer_user_info
                                  ?.mobile_no
                              }
                            </td>
                          </tr>
                        )}
                        {subManualVoucher?.data?.customer_user_info?.pan && (
                          <tr>
                            <th className="bold-content" scope="row">
                              PAN No.:
                            </th>
                            <td className="content">
                              {subManualVoucher?.data?.customer_user_info?.pan}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className="last-block"
                  style={{
                    margin: "0 0 0 auto",
                  }}
                >
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <th className="bold-content" scope="row">
                          Transaction Date:
                        </th>
                        <td className="content">
                          {subManualVoucher?.data?.bill_user_info?.bill_miti_ad}
                        </td>
                      </tr>
                      <tr>
                        <th className="bold-content" scope="row">
                          Print Date & Time:
                        </th>
                        <td className="content">
                          {currentTime.toLocaleString() + ""}
                        </td>
                      </tr>
                      <tr>
                        <th className="bold-content" scope="row">
                          Issued By:
                        </th>
                        <td className="content">
                          {subManualVoucher?.data?.bill_user_info?.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="full-table">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th className="particular-width">Particulars</th>
                      <th className=" ">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subManualVoucher?.data?.bill_detail &&
                      Array(18)
                        .fill(undefined)
                        ?.map((_, index) => {
                          const item =
                            subManualVoucher?.data?.bill_detail[index];
                          return (
                            <tr
                              style={{
                                visibility: item ? "visible" : "hidden",
                              }}
                              key={index}
                            >
                              <td
                                className="padd-5"
                                style={{
                                  visibility: item ? "visible" : "hidden",
                                }}
                              >
                                {item ? index + 1 : ""}
                              </td>
                              <td className="padd-5">{item?.ledger_name}</td>
                              <td className="padd-5">
                                {englishToNepaliAmountConverter(
                                  item?.amount || 0
                                )}
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
              <div className="footer-div">
                <div className="receipt-info">
                  <div className="receipt-info-section">
                    <div className="receipt-info-block">
                      <h4 className="block-title">In Words</h4>
                      <p className="small-content capitalize">
                        {" "}
                        {toWords.convert(
                          subManualVoucher?.data?.amount_detail?.total || 0
                        ) +
                          " " +
                          "Only"}
                      </p>
                    </div>
                  </div>
                  <div className="receipt-info-section ">
                    <div className="receipt-info-block">
                      <h4 className="block-title capitalize">Note</h4>
                      <p className="small-content">
                        {subManualVoucher?.data?.bill_user_info
                          ?.customer_notes || "Thank You"}
                      </p>
                    </div>
                  </div>
                  <div className="receipt-info-block">
                    <h4 className="block-title">Authorized Signature:</h4>
                    <p className="small-content customers-sign">
                      -----------------------
                    </p>
                  </div>
                </div>

                <div className="receipt-calculation">
                  <div className="receipt-info-section ">
                    <div className="receipt-calculation-block ">
                      <p className="small-content">Subtotal</p>
                      <p className="small-content text-right">
                        Rs.{" "}
                        {englishToNepaliAmountConverter(
                          subManualVoucher?.data?.amount_detail?.sub_total
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="small-content">Discount</p>
                      <p className="small-content text-right">
                        Rs.
                        {englishToNepaliAmountConverter(
                          subManualVoucher?.data?.amount_detail?.discount
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="bold-content"> Taxable Amount</p>
                      <p className="small-content text-right">
                        Rs.
                        {englishToNepaliAmountConverter(
                          subManualVoucher?.data?.amount_detail?.taxable
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="small-content">VAT @ 13% </p>
                      <p className="small-content text-right">
                        Rs.
                        {englishToNepaliAmountConverter(
                          subManualVoucher?.data?.amount_detail?.tax
                        )}
                      </p>
                    </div>
                    <div className="receipt-calculation-block">
                      <p className="bold-content">Total (NPR)</p>
                      <p className="content text-right">
                        Rs.
                        {englishToNepaliAmountConverter(
                          subManualVoucher?.data?.amount_detail?.total
                        )}
                      </p>
                    </div>
                    <div className="receipt-info-block width-21">
                      <h4 className="block-title text-right">
                        Customer Signature:
                      </h4>
                      <p className="small-content customers-sign text-right">
                        -----------------------
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* //This is statice for now */}

              <div className="receipt-info payment-information">
                <div className="receipt-info-block ">
                  <h4 className="block-title">Payment Information</h4>
                  <p className="small-content">Bank Name:RBB</p>
                  <p className="small-content">Cheque No.: 789456</p>
                  <p className="small-content">Cheque Issued Date: 2022-2-6</p>
                  <p className="small-content">Amount: Rs. 40000</p>
                </div>
              </div>
              <div className="receipt-info">
                <div className="receipt-info-block receipt-footer">
                  <p className="small-content">
                    This is a computer generated invoice.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-right">
            <div className="d-end">
              <div>
                <button
                  className="light-secondary mr-16"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  className="main-button"
                  type="submit"
                  onClick={() => {
                    handlePrintCount(documentNumber);
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <BillGenerate />
      ) : (
        <>
          <ManualInvoiceReceipt title={titles[titles.length - 1]} />
          <div style={{ display: "none" }}>
            <div className="print-page" ref={componentRef}>
              {printCount > 0 ? (
                <>
                  {titles.slice(-1).map((itm, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <ManualInvoiceReceipt title={itm} />
                        <div className="page-break" />
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <>
                  {titles.map((itm, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <ManualInvoiceReceipt title={itm} />
                        <div className="page-break" />
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
