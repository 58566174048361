import { ErrorMessage, Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { PatronRoles } from "../../EnumValues";
import { capitalCase, checkNumber, replaceDecimal } from "../../utils/services";
import { useGetSpecificCustomer, usePatronRoles } from "../Api/Get/GetApi";
import { BottomSheetContext } from "../BottomSheet/BottomSheetContext";
import { Loader } from "../Loader/Loader";
import { PopupContext } from "../PopUp/Popup-Context";
import "./NewCustomerModal.scss";
import { NewCustomerValue, useNewCustomerWrapper } from "./NewCustomerWrapper";

export interface RoleDataProps {
  name: string;
  motive: string;
  id: number;
  email: string;
}

interface NewCustomerModalProps {
  initialValues?: { id: number; name: string };
}

export const NewCustomerModal: React.FC<NewCustomerModalProps> = ({
  initialValues,
}) => {
  const { setIsModalOpen } = useContext(PopupContext);
  const { bottomSheetcloseModal } = useContext(BottomSheetContext);
  const [roleData, setRoleData] = useState<RoleDataProps[]>();
  const [transaction, setTransaction] = useState("dr");
  const [disabled, setDisabled] = useState(false);
  const { data: patronRoles } = usePatronRoles({ isOnlyPatron: false });
  const { data, isLoading } = useGetSpecificCustomer(initialValues?.id);
  const {
    // getFieldProps,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    values,
    isSubmitting,
  } = useNewCustomerWrapper();

  useEffect(() => {
    if (patronRoles) {
      setRoleData(patronRoles);
    }
  }, [patronRoles]);

  const DetermineDrCr = (credit: number, debit: number) => {
    return credit > debit ? "cr" : "dr";
  };

  useEffect(() => {
    if (initialValues && data) {
      setFieldValue("id", data.id);
      setFieldValue("mobile_no", data.mobile_no);
      setFieldValue("pan", data.pan);
      setFieldValue("name", data.name);
      setFieldValue("role_id", data.role_id);
      setFieldValue("additional_info.address", data.additional_info.address);
      setFieldValue("email", data.email);
      setFieldValue("amount", data.debit > 0 ? data.debit : data.credit);
      setFieldValue(
        "bill_due_notice_day",
        data.bill_due_notice_day ? data.bill_due_notice_day : 0
      );
      setFieldValue(
        "transaction_column_type",
        DetermineDrCr(data.credit, data.debit)
      );
    }
  }, [data]);

  const TransactionOptions = [
    { id: 1, label: "Debit", value: "dr" },
    { id: 2, label: "Credit", value: "cr" },
  ];

  useEffect(() => {
    if (values.role_id === PatronRoles.Vendor) {
      setTransaction("cr");
      setFieldValue("transaction_column_type", "cr");
      setDisabled(true);
    } else if (
      values.role_id === PatronRoles.WholeSaler ||
      values.role_id === PatronRoles.Retailer
    ) {
      setTransaction("dr");
      setDisabled(true);
      setFieldValue("transaction_column_type", "dr");
    } else {
      if (values.transaction_column_type === "cr") {
        setTransaction("cr");
      } else {
        setTransaction("dr");
      }
      setDisabled(false);
    }
  }, [values.role_id, values.transaction_column_type]);

  if (isLoading) {
    return <Loader loaderType="Spinner" />;
  }

  return (
    <>
      <div className="customer-modal">
        <div className="row">
          <div className="col-md-6">
            <label className="input-label">Name</label>
            <div className="data-field">
              <Field
                className={`${
                  errors.name && touched.name
                    ? "input-field error-input capitalize"
                    : "input-field capitalize"
                }`}
                value={values.name}
                placeholder="Enter name"
                // {...getFieldProps("name")}
                onChange={(e: any) => {
                  setFieldValue("name", capitalCase(e.target.value));
                }}
                autoFocus
              />
              <ErrorMessage name={"name"} component="div" className="error" />
            </div>
          </div>
          {!initialValues && (
            <div className="col-md-6">
              <label className="input-label">Role</label>
              <div className="data-field">
                <Field
                  as="select"
                  className={`${
                    errors.role_id && touched.role_id
                      ? "error-input input-field"
                      : "input-field"
                  }`}
                  value={values.role_id}
                  onChange={(e: any) => {
                    setFieldValue("role_id", Number(e.target.value));
                  }}
                >
                  <option value={""} key="">
                    --Select--
                  </option>
                  {roleData?.map((opt, index) => (
                    <option key={index} value={opt.id}>
                      {opt?.name.charAt(0).toUpperCase() + opt?.name.slice(1)}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name={"role_id"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
          )}
          <div className="col-md-6">
            <label className="input-label">Mobile No.</label>
            <div className="data-field">
              <Field
                type="text"
                className={`${
                  errors.mobile_no && touched.mobile_no
                    ? "input-field error-input"
                    : "input-field"
                }`}
                placeholder="Enter mobile number"
                maxLength={10}
                value={values.mobile_no}
                onChange={(e: any) => {
                  setFieldValue("mobile_no", checkNumber(e.target.value));
                }}
              />
              <ErrorMessage
                name={"mobile_no"}
                component="div"
                className="error"
              />
            </div>
          </div>
          <div className="col-md-6">
            <label className="input-label">Pan No.</label>
            <div className="data-field">
              <Field
                className={`${
                  errors.pan && touched.pan
                    ? "input-field error-input"
                    : "input-field"
                }`}
                onKeyPress={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
                maxLength={9}
                placeholder="Enter pan number"
                value={values.pan}
                onChange={(e: any) => {
                  setFieldValue("pan", checkNumber(e.target.value));
                }}
              />
              <ErrorMessage name={"pan"} component="div" className="error" />
            </div>
          </div>
          <div className="col-md-6">
            <label className="input-label">Address</label>
            <div className="data-field">
              <Field
                value={values.additional_info.address}
                className={` capitalize ${
                  errors.additional_info?.address &&
                  touched.additional_info?.address
                    ? "input-field error-input"
                    : "input-field"
                }`}
                placeholder="Enter your address"
                onChange={(e: any) => {
                  setFieldValue("additional_info.address", e.target.value);
                }}
              />
              <ErrorMessage
                name={"additional_info.address"}
                component="div"
                className="error"
              />
            </div>
          </div>
          <div className="col-md-6">
            <label className="input-label">Email</label>
            <Field
              className="input-field no-mg-btm"
              placeholder="Enter your email"
              onChange={(e: any) => {
                setFieldValue("email", e.target.value);
              }}
              type="text"
              name="email"
            />
            <ErrorMessage name={"email"} component="div" className="error" />
          </div>
          {values.role_id === "" ||
            (values.role_id === PatronRoles.Vendor && (
              <div className="col-md-6">
                <label className="input-label">Bill Due Notice Days</label>
                <div className="data-field">
                  <Field
                    type="text"
                    className={`${
                      errors.bill_due_notice_day && touched.bill_due_notice_day
                        ? "input-field error-input"
                        : "input-field"
                    }`}
                    placeholder="Enter billdue notice date number"
                    value={values.bill_due_notice_day}
                    onChange={(e: any) => {
                      setFieldValue(
                        "bill_due_notice_day",
                        checkNumber(e.target.value)
                      );
                    }}
                    onFocus={(e: any) => {
                      if (parseInt(e.target.value) === 0) {
                        e.target.value = "";
                      }
                    }}
                  />
                  <ErrorMessage
                    name={"bill_due_notice_day"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            ))}
          <div className="col-md-6">
            <label className="input-label">Opening Balance</label>
            <div className="data-field">
              <Field
                pattern="[0-9]*"
                className={`${
                  errors.amount && touched.amount
                    ? "input-field error-input"
                    : "input-field"
                }`}
                placeholder="Enter amount"
                value={values.amount}
                onChange={(e: any) => {
                  setFieldValue(
                    "amount",
                    replaceDecimal(checkNumber(e.target.value))
                  );
                }}
                onWheel={(e: any) => {
                  (e.target as HTMLInputElement).blur();
                }}
                onFocus={(e: any) => {
                  if (parseInt(e.target.value) === 0) {
                    e.target.value = "";
                  }
                }}
              />
              <ErrorMessage name={"amount"} component="div" className="error" />
            </div>
            <div className="col-md-12">
              {Number(values.amount) > 0 && (
                <>
                  <div className="tab-btn">
                    {TransactionOptions.map((val, index) => {
                      return (
                        <div
                          key={index}
                          onClick={(e: any) => {
                            if (!disabled) {
                              setTransaction(val.value);
                              setFieldValue(
                                "transaction_column_type",
                                val.value
                              );
                            }
                          }}
                          className={`manual-switch ${
                            val.value === transaction ? "active-bg" : "unactive"
                          }  ${disabled ? "disabled" : ""}`}
                        >
                          {val.label}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="btn-right">
        <div className="d-end">
          <div>
            <button
              className="light-secondary mr-16"
              onClick={() => {
                isMobile ? bottomSheetcloseModal() : setIsModalOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="main-button"
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isSubmitting ? (
                <div className="">
                  <Loader loaderType="Spinner" universalCenter={false} />
                </div>
              ) : (
                <>{initialValues ? "Edit Patron" : "Add Patron"}</>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
