import { useContext, useMemo, useState } from "react";
import {
  useProductOptions,
  useStockDetailReport,
} from "../../components/Api/Get/GetApi";
import { StockDetailResponse } from "../../components/Api/Get/GetApiTypes";
import { Table } from "../../components/CustomReactTable/CustomReactTable";
import {
  fixedValue,
  FormatDate,
  formatSelectOptions,
  handleNoData,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";
import { SelectOptions } from "../SalesReturn/types";
import { PopupContext } from "../../components/PopUp/Popup-Context";
import { PopUp } from "../../components/PopUp/PopUp";
import { OpeningStockDetailEdit } from "../../components/OpeningStockDetailEdit/OpeningStockDetailEdit";

export const StockDetailReport = () => {
  //useState Hooks
  const [queryPageIndex, setQueryPageIndex] = useState<number>(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [selectValue, setSelectValue] = useState<SelectOptions>();
  const [openingEdit, setOpeningEdit] = useState(false);
  const [openingId, setOpeningId] = useState<number>();
  const [uptoDate, setUptoDate] = useState<string>();
  //useContext Hooks

  const { data: saleDetails, isLoading } = useStockDetailReport(
    selectValue?.value as number,
    queryPageIndex,
    queryPageSize,
    uptoDate || ""
  );
  const { data: productOptions } = useProductOptions();
  const { openModal } = useContext(PopupContext);

  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "s_n",
      },

      { Header: "Date", accessor: "date" },
      {
        Header: "Invoice number",
        accessor: "bill_number",
      },

      {
        Header: "In Stock",
        accessor: "in_stock",
        Cell: ({ value }: { value: any }) => {
          return (
            <>
              <div className="remaining-section">
                {
                  //@ts-ignore
                  value.secondary_stock
                    ? `${value.primary_stock} ${value.primary_unit}${
                        value.relation !== 1
                          ? ` (${value.secondary_stock} ${value.secondary_unit})`
                          : ""
                      }`
                    : "0"
                }
              </div>
            </>
          );
        },
      },

      {
        Header: "Out Stock",
        accessor: "out_stock",
      },
      {
        Header: "Unit",
        accessor: "unit",
      },
      {
        Header: "Price",
        accessor: "price",
      },

      {
        Header: "Balance",
        accessor: "balance",
      },

      {
        Header: "Remarks",
        accessor: "remarks",
      },
    ],
    []
  );

  const FormatTable = (
    array: StockDetailResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          s_n: tableIndexNumber(Number(pageIndex), pageSize, index),
          bill_number: handleNoData(item.document_number),
          date: FormatDate(item.miti || ""),
          in_stock: {
            secondary_stock: item?.stock_detail?.qty?.in_stock,
            primary_stock: (item?.stock_detail?.qty?.in_stock / item?.relation).toFixed(),
            primary_unit: item?.primary_unit,
            secondary_unit: item?.secondary_unit,
            relation: item?.relation,
          },
          out_stock: item?.stock_detail?.qty?.out_stock,
          price: parenthesisInNegativeValue(fixedValue(item.secondary_price)),
          balance: parenthesisInNegativeValue(fixedValue(item.balance)),
          remarks: "-",
          unit: item.secondary_unit,
          others: {
            document_type: item.document_type,
            id: item.id,
          },
        };
      });
      return formatData;
    }
    return [];
  };

  const handleDoubleClick = (value: any) => {
    if (value.others.document_type !== "OPN_STK") return;
    setOpeningId(value.others.id);
    setOpeningEdit(true);
    openModal();
  };

  return (
    <>
      {openingEdit && openingId !== undefined && (
        <PopUp
          popupWidth={"50%"}
          title="Edit Opening Stock"
          renderComponent={
            <OpeningStockDetailEdit
              id={openingId as number}
              productId={selectValue?.value as number}
            />
          }
        />
      )}
      <div className="salesListPage">
        <div className="row">
          <div className="head-section">
            <div className="col-md-6">
              <div className="small-title">Stock Detail Report</div>
            </div>
          </div>
        </div>

        <Table
          options={productOptions ? formatSelectOptions(productOptions) : []}
          optionlabel="Product"
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          showSelectOptions={true}
          excelDownload={true}
          showUptoDate={true}
          setUptoDate={setUptoDate}
          loading={isLoading}
          columns={columns}
          data={
            saleDetails
              ? FormatTable(
                  saleDetails.data,
                  queryPageIndex as number,
                  queryPageSize
                )
              : []
          }
          totalPages={
            saleDetails &&
            saleDetails.pagination &&
            totalPages(queryPageSize, saleDetails?.pagination.total_count)
          }
          totalEntries={
            saleDetails &&
            saleDetails.pagination &&
            saleDetails?.pagination.total_count
          }
          queryPageIndex={queryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageIndex={
            setQueryPageIndex as React.Dispatch<React.SetStateAction<number>>
          }
          setQueryPageSize={setQueryPageSize}
          onDoubleClick={handleDoubleClick}
        />
      </div>
    </>
  );
};
