import "./BillGenerate.scss"
export const BillGenerate = () => {
  return (
    <>
      <div className="bill-loader">
        <img src={require("../../assets/images/bill.gif")} alt="loading..." />
        Generating Invoice....
      </div>
    </>
  )
}
