import React from "react";
import { combineClasses } from "../../utils/css-utils";
import styles from "./Select.module.scss";

export interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  optionProps?: React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >;
  label?: string;
}

export const Select: React.FC<SelectProps> = ({
  className,
  optionProps,
  children,
  label,
  ...props
}) => {
  return (
    <div className={styles["select-field"]}>
      {label && (
        <span className={combineClasses(styles.label, styles["normal"])}>
          {label}
        </span>
      )}
      <select
        className={combineClasses(styles.select, styles["normal"], className)}
        {...props}
      >
        {children}
      </select>
    </div>
  );
};
