import { Formik, FormikConfig, useFormikContext } from "formik";
import { useContext } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useBusinessData } from "../Api/Get/GetApi";
import { CreateBusiness } from "../Api/Post/PostApi";
import { PopupContext } from "../PopUp/Popup-Context";
import { useAuth } from "../context/auth/auth";

export interface BusinessValue {
  pan: number | string;
  mobile_no: number | null;
  name: string;
  registration_no: string;
  business_type: string;
  ird_username: string | null;
  ird_password: string | null;
  address: string;
  logo: File[] | null;
  establish_date: string;
  tax: number;
  website: string;
  email: string;
  footer_text: string;
  sync_with_IRD: boolean;
  image_url: string;
  id: number | undefined;
}

export const initialValues: BusinessValue = {
  pan: "",
  mobile_no: null,
  name: "",
  address: "",
  registration_no: "",
  business_type: "",
  ird_username: "",
  ird_password: "",
  logo: null,
  establish_date: "",
  tax: 0,
  website: "",
  email: "",
  footer_text: "",
  sync_with_IRD: false,
  image_url: "",
  id: 0,
};
const validationSchema = Yup.object().shape({
  pan: Yup.string()
    .required("Please select pan number")
    .length(9, "Pan number must be 9 digit"),
  mobile_no: Yup.string()
    .required("Please select number")
    .length(10, "Mobile number must be 10 digit"),
  name: Yup.string().required("Please enter customer name"),
  registration_no: Yup.string().required("Please select registration number"),
  business_type: Yup.string().required("Please select business type"),
  ird_username: Yup.string()
    .when(["sync_with_IRD"], {
      is: true,
      then: Yup.string().required("Required"),
    })
    .nullable(),
  ird_password: Yup.string()
    .when(["sync_with_IRD"], {
      is: true,
      then: Yup.string().required("Required"),
    })
    .nullable(),
  // ird_password: Yup.string(),
  // website: Yup.string(),
  // footer_text: Yup.string(),
  establish_date: Yup.string().required("Please select established date"),
  tax: Yup.string().required("Please select tax"),
  email: Yup.string().required("Please select email"),
  sync_with_IRD: Yup.boolean(),

  // address: Yup.object().shape({
  //   city: Yup.string().required("Please select address"),
  // }),
  address: Yup.string().required("Please select address"),
});

export const useBusinessInfo = () => {
  const formik = useFormikContext<BusinessValue>();
  if (!formik) {
    throw new Error("useBusinessInfo must be used within a Formik");
  }
  return formik;
};
interface BusinessInfoWrapperProps {
  children: React.ReactNode;
  intialValuesFromProps?: BusinessValue;
}

export const BusinessInfoWrapper: React.FC<BusinessInfoWrapperProps> = ({
  children,
  intialValuesFromProps,
}) => {
  const { closeModal } = useContext(PopupContext);
  const { refetch } = useBusinessData();
  const { signIn, setBusinessPopUp } = useAuth();

  const handleSubmit: FormikConfig<BusinessValue>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);

    const data = { ...values };

    CreateBusiness(data)
      .then((res) => {
        toast.success("Business info added successfully");
        setSubmitting(false);
        //This is Set your busines profile popup
        setBusinessPopUp(false);
        refetch();
        signIn();
        closeModal();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to submit data";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.status.status_code === 422) {
          errorMessage = err?.response?.data?.status?.status_message[0].msg;
        } else if (err.response.data.status.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={intialValuesFromProps || initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
