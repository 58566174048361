import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ExcelIcon,
  PrintIcon,
  TriangleDown,
  TriangleRignt,
} from "../../assets/images/svg/svg";
import {
  isCurrentFiscalYear,
  isJson,
  parenthesisInNegativeValue,
} from "../../utils/services";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { useTradingAccountList } from "../Api/Get/GetApi";
import {
  ITradingAccountList,
  ITradingAccountSubList,
} from "../Api/Get/GetApiTypes";
import { Loader } from "../Loader/Loader";
import "../NewProfitLossAC/TradingAccount.scss";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import { FilterDate } from "../SalesList/SalesListPage";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export interface TableHeaderContentGroupProps {
  columns?: any;
  loading?: boolean;
  roleId?: number;
}

export const TradingAccount = () => {
  const location = useLocation();
  const { businessUserInfo } = useAuth();

  useEffect(() => {}, []);

  const col_Span = 6;

  const formattedDate = businessUserInfo?.fiscal_year_info?.first_fiscal_date
    .split("/")
    .join("-");

  const lastFiscalDate: any =
    businessUserInfo?.fiscal_year_info?.last_fiscal_date.split("/").join("-");

  const currentFiscalYear = isCurrentFiscalYear(
    businessUserInfo?.fiscal_year_info?.current_fiscal_year
  );

  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });

  const { data, isLoading } = useTradingAccountList(date);

  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);
  const [activeItem, setActiveItem] = useState(null);

  const uptoDate = "";

  const handleChange = (e: any, item: ITradingAccountList) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const newDebitArr =
            data?.debit_data_list?.map((item) => {
              return item.id;
            }) || [];

          const newCreditArr =
            data?.credit_data_list?.map((item) => {
              return item.id;
            }) || [];

          const concatenatedArr = newDebitArr.concat(newCreditArr);

          setCheckedValues(concatenatedArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, keyDownChecked]);

  const toggleAccordion = (id: any) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
    }
  };

  return (
    <div className="trading-balance-page">
      <div className="head-section">
        <h2 className="small-title">Trading Account</h2>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tarding-balance-table">
            <div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group mg-btm-16">
                      <label htmlFor="date" className="input-label">
                        From Date
                      </label>
                      {/* <NepaliDatePicker
                        className="input-field"
                        placeholder="Select a date"
                        showResetDateButton={false}
                        defaultDate={formattedDate as string}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateFrom: value,
                          }));
                        }}
                      /> */}
                      <NepaliDatePicker
                        defaultDate={formattedDate as string}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateFrom: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="date" className="input-label">
                        To Date
                      </label>
                      {/* <NepaliDatePicker
                        showResetDateButton={false}
                        className="input-field"
                        // value={data?.date_info.date_to}
                        placeholder="Select a date"
                        defaultDate={currentFiscalYear ? true : lastFiscalDate}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateTo: value,
                          }));
                        }}
                      /> */}
                      <NepaliDatePicker
                        currentDate={currentFiscalYear as boolean}
                        defaultDate={currentFiscalYear ? "" : lastFiscalDate}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateTo: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-5"></div>
                  <div className="col-md-3">
                    <div className="d-flex gap-3 float-right">
                      <button
                        onClick={() =>
                          nestedTableExcel(
                            "exportTable",
                            location,
                            businessUserInfo as UserInfoResponse,
                            date?.dateFrom,
                            date?.dateTo,
                            col_Span,
                            uptoDate
                          )
                        }
                        className="d-flex btn btn-light my-4 "
                      >
                        <span className="export-title"> Export </span>
                        <ExcelIcon />
                      </button>
                      <button
                        className="d-flex btn btn-light my-4 "
                        onClick={() => {
                          financialReportPrint(
                            [],
                            businessUserInfo as UserInfoResponse,
                            date as FilterDate,
                            "exportTable",
                            location
                          );
                        }}
                      >
                        <span
                          style={{ marginRight: "10px" }}
                          className="export-title"
                        >
                          Print{" "}
                        </span>
                        <PrintIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top-head">
                <h2 className="small-title">
                  {businessUserInfo?.business_info.name}
                </h2>
                <p className="flex bold-content">
                  {businessUserInfo?.business_info.address}
                </p>

                <div className="div-flex bold-content">
                  <div className="small-text">Trading Account</div>

                  <p className="black-text">
                    For the period from {date?.dateFrom}
                    to
                    {date?.dateTo}
                  </p>
                </div>
              </div>

              <div id="exportTable">
                <table className="main-table">
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <div className="loder-wrapper">
                            <Loader loaderType="Spinner" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="border-bottom-0 width-50">
                          <table className="mini-table">
                            <thead>
                              <th className="width-60 text-left">Expense</th>
                              <th className="width-40" colSpan={2}>
                                Amount
                              </th>
                            </thead>

                            <tbody>
                              {data?.debit_data_list?.map((item, index) => {
                                const exists = checkedValues.find(
                                  (val) => val === item.id
                                );
                                return (
                                  <>
                                    {item.id !== null && (
                                      <tr>
                                        <td className="black-content width-60">
                                          <div className="d-flex">
                                            <input
                                              className="checkbox-none"
                                              type="checkbox"
                                              id={`debit-checkbox-${index}`}
                                              name={item.ledger_name}
                                              value={item.ledger_name}
                                              checked={Boolean(exists)}
                                              onChange={(e) =>
                                                handleChange(e, item)
                                              }
                                            />
                                            <div
                                              onClick={() => {
                                                toggleAccordion(item.id);
                                              }}
                                            ></div>
                                            <label
                                              htmlFor={`debit-checkbox-${index}`}
                                            >
                                              {Boolean(exists) ? (
                                                <TriangleDown />
                                              ) : (
                                                <TriangleRignt />
                                              )}
                                              {item.ledger_name}
                                            </label>
                                          </div>
                                        </td>
                                        <td className="width-20"></td>
                                        <td className="black-content width-20">
                                          {parenthesisInNegativeValue(
                                            item.amount
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    {item.child_ledger?.map(
                                      (
                                        itm: ITradingAccountSubList,
                                        idx: number
                                      ) => {
                                        let debitParsedJsonData = isJson(
                                          itm.ledger_name
                                        );
                                        return (
                                          <>
                                            {debitParsedJsonData ? (
                                              <>
                                                {JSON.parse(
                                                  itm.ledger_name
                                                ).map(
                                                  (
                                                    jsonItem: ITradingAccountSubList,
                                                    jsonIndex: number
                                                  ) => {
                                                    return (
                                                      <>
                                                        {jsonItem.amount ===
                                                        0 ? (
                                                          ""
                                                        ) : (
                                                          <tr
                                                            className={
                                                              Boolean(exists)
                                                                ? "show"
                                                                : "dontShow"
                                                            }
                                                          >
                                                            <td className="content width-60 padd-left">
                                                              {
                                                                jsonItem.ledger_name
                                                              }
                                                            </td>
                                                            <td className="content `width-20`">
                                                              {parenthesisInNegativeValue(
                                                                jsonItem.amount
                                                              )}
                                                            </td>
                                                            <td className="width-20"></td>
                                                          </tr>
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {itm.amount === 0 ? (
                                                  ""
                                                ) : (
                                                  <tr
                                                    className={
                                                      Boolean(exists)
                                                        ? "show"
                                                        : "dontShow"
                                                    }
                                                  >
                                                    <td className="content width-60 padd-left">
                                                      {itm.ledger_name}
                                                    </td>
                                                    <td className="content width-20">
                                                      {parenthesisInNegativeValue(
                                                        itm.amount
                                                      )}
                                                    </td>
                                                    <td className="width-20"></td>
                                                  </tr>
                                                )}
                                              </>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                        <td className="border-bottom-0 width-50">
                          <table className="mini-table">
                            <thead>
                              <th className="width-60 text-left">Income</th>
                              <th className="width-40" colSpan={2}>
                                Amount
                              </th>
                            </thead>
                            <tbody>
                              {data?.credit_data_list?.map((item, index) => {
                                const exists = checkedValues.find(
                                  (val) => val === item.id
                                );
                                return (
                                  <>
                                    {item.id !== null && (
                                      <tr>
                                        <td className="black-content width-60">
                                          <div className="d-flex">
                                            <input
                                              className="checkbox-none"
                                              type="checkbox"
                                              id={`credit-checkbox-${index}`}
                                              name={item.ledger_name}
                                              value={item.ledger_name}
                                              checked={Boolean(exists)}
                                              onChange={(e) =>
                                                handleChange(e, item)
                                              }
                                            />
                                            <div
                                              onClick={() => {
                                                toggleAccordion(item.id);
                                              }}
                                            ></div>
                                            <label
                                              htmlFor={`credit-checkbox-${index}`}
                                            >
                                              {Boolean(exists) ? (
                                                <TriangleDown />
                                              ) : (
                                                <TriangleRignt />
                                              )}
                                              {item.ledger_name}
                                            </label>
                                          </div>
                                        </td>
                                        <td className="width-20"></td>
                                        <td className="black-content width-20">
                                          {parenthesisInNegativeValue(
                                            item.amount
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                    {item.child_ledger?.map(
                                      (
                                        itm: ITradingAccountSubList,
                                        idx: number
                                      ) => {
                                        let creditParsedJsonData = isJson(
                                          itm.ledger_name
                                        );
                                        return (
                                          <>
                                            {creditParsedJsonData ? (
                                              <>
                                                {JSON.parse(
                                                  itm.ledger_name
                                                ).map(
                                                  (
                                                    jsonItem: ITradingAccountSubList,
                                                    jsonIndex: number
                                                  ) => {
                                                    return (
                                                      <>
                                                        <tr
                                                          className={
                                                            Boolean(exists)
                                                              ? "show"
                                                              : "dontShow"
                                                          }
                                                        >
                                                          <td className="content width-60 padd-left">
                                                            {
                                                              jsonItem.ledger_name
                                                            }
                                                          </td>
                                                          <td className="content width-20">
                                                            {parenthesisInNegativeValue(
                                                              jsonItem.amount
                                                            )}
                                                          </td>
                                                          <td className="width-20"></td>
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {itm.amount === 0 ? (
                                                  ""
                                                ) : (
                                                  <tr
                                                    className={
                                                      Boolean(exists)
                                                        ? "show"
                                                        : "dontShow"
                                                    }
                                                  >
                                                    <td className="content width-60 padd-left">
                                                      {itm.ledger_name}
                                                    </td>
                                                    <td className="content width-20">
                                                      {parenthesisInNegativeValue(
                                                        itm.amount
                                                      )}
                                                    </td>
                                                    <td className="width-20"></td>
                                                  </tr>
                                                )}
                                              </>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="vertical-bottom border-top-0">
                          <table className="footer-table">
                            {data?.debit_data_list?.map((item) => {
                              return (
                                <>
                                  {item.id === null ? (
                                    <tr>
                                      {item.amount === 0 ? (
                                        ""
                                      ) : (
                                        <>
                                          <td
                                            className={
                                              item.ledger_name === "Total"
                                                ? "width-80 total"
                                                : "width-80"
                                            }
                                            colSpan={2}
                                          >
                                            {item.ledger_name}
                                          </td>
                                          <td
                                            className={
                                              item.ledger_name === "Total"
                                                ? "width-20 total"
                                                : "width-20"
                                            }
                                          >
                                            {parenthesisInNegativeValue(
                                              item.amount
                                            )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </table>
                        </td>
                        <td className="vertical-bottom border-top-0">
                          <table className="footer-table">
                            {data?.credit_data_list?.map((item) => {
                              return (
                                <>
                                  {item.id === null ? (
                                    <tr>
                                      {item.amount === 0 ? (
                                        ""
                                      ) : (
                                        <>
                                          <td
                                            className={
                                              item.ledger_name === "Total"
                                                ? "width-80 total"
                                                : "width-80"
                                            }
                                            colSpan={2}
                                          >
                                            {item.ledger_name}
                                          </td>
                                          <td
                                            className={
                                              item.ledger_name === "Total"
                                                ? "width-20 total"
                                                : "width-20"
                                            }
                                          >
                                            {parenthesisInNegativeValue(
                                              item.amount
                                            )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
