import { useState } from "react"
import { Loader } from "../Loader/Loader"
import "./ButtonComponent.scss"

interface ButtonComponentProps {
  isLoading?: boolean
  title?: string
  type?: "button" | "reset" | "submit" | undefined
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}
export const ButtonComponent: React.FC<ButtonComponentProps> = ({
  isLoading = false,
  title = "Save",
  type = "button",
  onClick,
}) => {
  return (
    <div className="btn-section">
      <button
        disabled={isLoading}
        type={type}
        className={isLoading ? "isloading" : "main-button"}
        onClick={onClick}
      >
        {isLoading ? (
          <>
            <Loader loaderType="Spinner" />
          </>
        ) : (
          `${title}`
        )}
      </button>
    </div>
  )
}
