import { parenthesisInNegativeValue } from "../../utils/services";
import { IJournalEntryResponse } from "../Api/Get/GetApiTypes";
import "./JournalDetail.scss";

interface JournalDetailProps {
  journalData?: IJournalEntryResponse;
}

export const JournalDetail: React.FC<JournalDetailProps> = ({
  journalData,
}) => {
  return (
    <div className="journal-detail">
      <div className="head-section">
        <div className="small-title">Journal Detail</div>
      </div>

      <div className="full-table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="header-align-center content">Date</th>
              <th className="header-align-center content">Particulor</th>
              <th className="header-align-center content end-align">Debit</th>
              <th className="header-align-center content end-align">Credit</th>
            </tr>
          </thead>
          <tbody>
            {journalData?.transaction_detail_info.map((val, index) => {
              return (
                <tr>
                  {index === 0 && (
                    <td
                      className="content black-text"
                      rowSpan={journalData?.transaction_detail_info.length}
                    >
                      {journalData?.nepali_date}
                    </td>
                  )}

                  <td className="content black-text name-sec">
                    <div>
                      {` ${val.credit > val.debit ? "To" : ""} ${
                        val.ledger_name
                      }`}
                    </div>
                  </td>
                  <td className="content black-text end-align">{parenthesisInNegativeValue(val.debit)} </td>
                  <td className="content black-text end-align">{parenthesisInNegativeValue(val.credit)}</td>
                </tr>
              );
            })}
            <tr className="total-row">
              <td className="content black-text " colSpan={1}>
                <span className="narration-title">Narration</span>
              </td>
              <td className="content black-text" colSpan={3}>
                {journalData?.description}
              </td>
            </tr>
            {/* </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};
