import { Axios, MultiPartAxios } from "../../lib/Axios";
import { NewCustomerValue } from "../../NewCustomerModal/NewCustomerWrapper";
import { IPostUpdateOpeningDetail } from "../Post/PostApiTypes";
import { UpdatedStaff } from "./PutApitypes";

export const UpdateUser = (id: number, data: UpdatedStaff) => {
  return MultiPartAxios.put(`/api/v1/user/update/${id}`, data);
};

export const UpdatePatronDetail = (id: number, data: NewCustomerValue) => {
  return Axios.put(`/api/v1/customer/update/${id}`, data);
};

export const UpdateSpecificOpeningStockDetail = (
  data: IPostUpdateOpeningDetail,
  id: number
) => {
  return Axios.put(`api/v1/product_service/opening/stock-update/${id}`, data);
};
