// import { PageErrorIcon } from "../../assets/images/svg/svg";
import { SelectCustomer } from "../../assets/images/svg/svg";
import "../ErrorPage/ErrorPage.scss";

interface SelectProps {
  selectParty?: boolean;
  returnType?: "Sales" | "Purchase";
  title?: {
    title1: string;
    title2: string;
  };
}

export const SelectCustomerBill: React.FC<SelectProps> = ({
  selectParty = true,
  returnType = "Sales",
  title,
}) => {
  return (
    <div className="error-page">
      <div className="icon-section">
        <SelectCustomer />
      </div>
      <div className="mid-page">
        {selectParty === true ? (
          <div className="text-section">
            <h2 className="small-title error-title">
              Please select {title?.title1 ? title.title1 : "Party"}
            </h2>
            <p className="sub-text">
              You can view {title?.title2 ? title?.title2 : "party ledger"}
              report here
            </p>
          </div>
        ) : (
          <div className="text-section">
            <h2 className="small-title error-title">
              Please select{" "}
              {returnType === "Purchase" ? "Vendor " : "Customer "}
              and bill no.
            </h2>
            <p className="sub-text">
              Start creating{" "}
              {returnType === "Purchase" ? "purchase " : "sales "}
              returns here
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
