import { useNavigate } from "react-router-dom";
import { ServerError } from "../../assets/images/svg/svg";
import "./ErrorPage.scss";

export const ServerErrorPage = () => {
  const navigate = useNavigate();
  const handelBack = () => {
    navigate(-1);
  };
  function refreshPage() {
    //@ts-ignore
    window.location.reload(false);
  }
  return (
    <div className="error-page">
      <div className="icon-section">
        <ServerError />
      </div>
      <div className="mid-page">
        <div className="text-section">
          <h2 className="small-title error-title">No Data Available</h2>
          <p className="sub-text">
            Looks like there are no entries here yet. Try removing filters or
            come back later.
          </p>
          <p className="error">Error Code : 500</p>
        </div>
        <div className="btn-mid">
          <button className="light-secondary mr-16" onClick={handelBack}>
            Back
          </button>
          <button className="light-primary" onClick={refreshPage}>
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};
