import { useContext, DetailedHTMLProps, HTMLAttributes } from "react";
import { BottomSheetContext } from "./BottomSheetContext";
import Sheet from "react-modal-sheet";
import "./BottomSheet.scss";

export interface BottomSheetProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
  content?: string;
  width?: string | number;
}
export const BottomSheetModal: React.FC<BottomSheetProps> = ({
  title = "dfghj",
  children,
  width,
}) => {
  const { isModalOpen, setIsModalOpen } = useContext(BottomSheetContext);
  return (
    <div className="bottom-sheet-wrapper">
      <div className={"smallWidth"}>
        <Sheet
          isOpen={isModalOpen}
          className="smallWidth"
          onClose={() => setIsModalOpen(false)}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className="bottom-sheet-section ">
                <div className="d-end title-section">
                  <h4 className="extra-small-title capitalize">{title}</h4>
                  <div className="cancel-icon">
                    <img
                      src={require("../../assets/images/cross.png")}
                      alt="cancel"
                      onClick={() => setIsModalOpen(false)}
                    />
                  </div>
                </div>
                {children}
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    </div>
  );
};
