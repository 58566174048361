import { ErrorMessage } from "formik";
import { usePatronList } from "../Api/Get/GetApi";
import { useUpdateCategoryWrapper } from "../NewCategoryModal/UpdateCategoryWrapper";
import "./CategoryTable.scss";

export interface SelectedCategoryPopup {
  categoryTableList: [CategoryDataListResponse];
}

export interface CategoryDataListResponse {
  id: number;
  name: string;
}

export interface CategoryFormProps {
  id: number;
  name: string;
}

// : React.FC<SelectedCategoryPopup>
export const UpdateCategoryForm = ({ id, name }: CategoryFormProps) => {
  const { setFieldValue, errors, touched, handleSubmit, values } =
    useUpdateCategoryWrapper();

  const { refetch } = usePatronList();

  return (
    <div className="category_form">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="input-label"> Category Name</label>
            <input
              className={`${
                errors.name && touched.name
                  ? "input-field error-input"
                  : "input-field capitalize"
              }`}
              defaultValue={name ?? ""}
              placeholder="Enter Category Name"
              type="text"
              onChange={(e) => {
                setFieldValue("id", id);
                setFieldValue("name", e.target.value);
              }}
            />
            <ErrorMessage name={"name"} component="div" className="error" />
          </div>
        </div>

        <div className="btn-section">
          <button className="main-button" onClick={() => handleSubmit()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
