import { ReactElement } from "react"
import "./Loader.scss"

interface LoaderProps {
  loaderType: "Spinner" | "ImageSpinner"
  universalCenter?: boolean
}

export const Loader: React.FC<LoaderProps> = ({
  loaderType,
  universalCenter = true,
}) => {
  let requiredComponent: ReactElement<any, any> = <>Loading...</>
  switch (loaderType) {
    case "ImageSpinner":
      requiredComponent = (
        <div className="pos-center">
          <div className="loader"></div>
          <img
          alt="Not Available"
            className="loader-img"
            src={require("../../assets/images/hissabLogo.png")}
          />
        </div>
      )
      break
    case "Spinner":
      requiredComponent = (
        <div className={` ${universalCenter ? "pos-center" : ""} `}>
          <div className="spinner"></div>
        </div>
      )

      break
    default:
      requiredComponent = <div>Loading...</div>
  }
  return requiredComponent
}
