import { Link } from "react-router-dom";
import { StarIcon } from "../../assets/images/svg/svg";
import { ROLE } from "../../EnumValues";
import "./Report.scss";
import { useAuth } from "../context/auth/auth";

export const Report = () => {
  const { businessUserInfo } = useAuth();

  const ReportData = {
    Sales: [
      {
        name: "Sales Report",
        link: "/sales-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Sales Detail Report",
        link: "/sales-detail-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Daily Sales Report",
        link: "/daily-sales-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Sales Return Report",
        link: "/sales-return-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
    ],
    Purchase: [
      {
        name: "Purchase Report",
        link: "/purchase-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Purchase Return Report",
        link: "/purchase-return-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
    ],
    Stock: [
      {
        name: "Stock  Report",
        link: "/stock-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Stock Detail Report",
        link: "/stock-detail-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
    ],
    IRD: [
      {
        name: "Materialized View",
        link: "/materialized-view",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Audit Trial Report",
        link: "/audit-trial-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Bill Cancel Report",
        link: "/bill-cancel-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
    ],
    General: [
      {
        name: "Manual Voucher Report",
        link: "/manual-voucher-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Opening Balance Report",
        link: "/opening-balance-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Transaction Summary",
        link: "/transaction-summary",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "VAT Report",
        link: "/vat-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Anusuchi Report",
        link: "/anusuchi-report",
        role: [ROLE.Admin, ROLE.Staff],
      },
    ],
    Financial: [
      {
        name: "Trial Balance Report",
        link: "/trial-balance",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Profit And Loss Account",
        link: "/profit-loss-account",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Trading Account",
        link: "/trading-account",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Indirect PL Account",
        link: "/indirect-profit-loss-account",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Balance Sheet",
        link: "/balance-sheet",
        role: [ROLE.Admin, ROLE.Staff],
      },
      {
        name: "Cash Flow",
        link: "/cash-flow",
        role: [ROLE.Admin, ROLE.Staff],
      },
    ],
  };

  return (
    <div className="report-page">
      <div className="head-section">
        <h2 className="small-title bill-title">Report</h2>
      </div>

      <div className="midd-section">
        <div className="row">
          {Object.entries(ReportData).map(([key, value], index) => {
            return (
              <div className="col-md-3 report-section" key={index}>
                <div className="report-header">{key}</div>
                {value.map((itm, idx) => {
                  if (
                    businessUserInfo?.user_info &&
                    itm.role.includes(
                      businessUserInfo?.user_info.role[0].id as number
                    )
                  )
                    return (
                      <div className="list" key={idx}>
                        <span className="star-icon">
                          <StarIcon />
                        </span>
                        <Link
                          to={itm.link}
                          target="_blank"
                          className="line-primary"
                        >
                          {itm.name}
                        </Link>
                      </div>
                    );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
