import { Field } from "formik";
import { useContext, useState } from "react";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import {
  SettingPageValues,
  useSettingPage,
} from "./SettingPageWrapper/SettingPageWrapper";
interface Props {
  settingData?: SettingPageValues;
}

const FinancialSetting: React.FC<Props> = ({ settingData }) => {
  const { setFieldValue, values } = useSettingPage();

  const [popupType, setPopupType] = useState<"depriciation" | "transaction" | null>(null);
  const { openModal, closeModal } = useContext(PopupContext);

  const [isCashTransaction, setIsCashTransaction] = useState(localStorage.getItem("TRANSACTION_IN") === 'cash')


  const popUps = {
    'depriciation': <div>
      Please ensure that the calculation of depreciation is conducted
      annually, specifically at the end of the fiscal year.
      <div className="btn-right d-end">
        <button
          className="light-secondary mr-16"
          type="submit"
          onClick={() => {
            setFieldValue("depreciation_type", "AUTO");
            setFieldValue(`cal_depreciation`, false);
            closeModal();
          }}
        >
          Calculate Later
        </button>
        <button
          className="main-button"
          type="submit"
          onClick={() => {
            setFieldValue("depreciation_type", "AUTO");
            setFieldValue(`cal_depreciation`, true);
            closeModal();
          }}
        >
          Calculate Now
        </button>
      </div>
    </div>,
    'transaction': <div className="input-label">
      <p className="text-center">
        You are about to change the payment method from <strong >{!isCashTransaction ? "Credit to Cash" : "Cash to Credit"}</strong>. Please note that your transaction's total amount will be adjusted accordingly.
      </p>
      <div className="btn-right d-end p-0 pt-2">
        <button
          className="light-secondary mr-16"
          type="submit"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </button>
        <button
          className="main-button"
          type="submit"
          onClick={() => {
            setIsCashTransaction(!isCashTransaction)
            localStorage.setItem("TRANSACTION_IN", isCashTransaction ? 'credit' : 'cash')
            closeModal();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  }
  return (
    <div className="col-md-12 ">
      <fieldset>
        <legend>Financial Setting</legend>

        <div className="">
          <div className="bold-text mg-top-10">Depreciation Type</div>
          <div className="check-group">
            <div className="check-box-area d-flex">
              <Field
                name="depreciation_type"
                type="radio"
                value="MANU"
                disabled={
                  settingData?.cal_depreciation === true &&
                    settingData?.depreciation_type === "MANU"
                    ? false
                    : true
                }
                onChange={() => {
                  setFieldValue("depreciation_type", "MANU");
                  setFieldValue(`cal_depreciation`, false);
                }}
              />
            </div>
            <label className="input-label">Manually Depreciation</label>
          </div>
          <div className="check-group">
            <div className="check-box-area d-flex">
              <Field
                name="depreciation_type"
                type="radio"
                value="AUTO"
                disabled={
                  settingData?.cal_depreciation === true &&
                    settingData?.depreciation_type === "AUTO"
                    ? false
                    : true
                }
                onChange={() => {
                  openModal();
                  setPopupType('depriciation')
                }}
              />
            </div>
            <label style={{ fontSize: "12px" }} className="input-label">
              Auto Depreciation
            </label>
          </div>
        </div>

        <div className="">
          <div className="bold-text mg-top-10">Stock Calculation</div>
          <div className="check-group">
            <Field
              type="radio"
              id="avg_stock"
              name="closing_stock_cal_type"
              value="FIFO_STK"
            />
            <label
              style={{ fontSize: "12px" }}
              className="input-label w-auto"
              htmlFor="fifo_stock"
            >
              FIFO Stock
            </label>
          </div>
          <div className="check-group">
            <Field
              type="radio"
              id="fifo_stock"
              name="closing_stock_cal_type"
              value="LIFO_STK"
            />
            <label
              style={{ fontSize: "12px" }}
              className="input-label w-auto"
              htmlFor="avg_stock"
            >
              LIFO Stock
            </label>
          </div>
          <div className="check-group">
            <Field
              type="radio"
              id="lifo_stock"
              name="closing_stock_cal_type"
              value="AVG_STK"
            />
            <label
              style={{ fontSize: "12px" }}
              className="input-label w-auto"
              htmlFor="lifo_stock"
            >
              Average Stock
            </label>
          </div>
        </div>
        <div className="">
          <div className="bold-text mg-top-10">Default Transaction In</div>
          <div className="tab-btn mg-top-2">
            <div
              onClick={() => {
                openModal()
                setPopupType('transaction')
              }}
              className={
                isCashTransaction
                  ? "manual-switch active-bg"
                  : "manual-switch unactive"
              }
            >
              Cash
            </div>
            <div
              onClick={() => {
                openModal()
                setPopupType('transaction')
              }}
              className={
                !isCashTransaction
                  ? "manual-switch active-bg"
                  : "manual-switch unactive"
              }
            >
              Credit
            </div>
          </div>
        </div>
      </fieldset >

      {popupType && <PopUp
        title="Do you want to proceed?"
        popupWidth={500}
        popupHeight={"90vh"}
        closeOnClickOutside={false}
        renderComponent={
          popUps[popupType]
        }
      />}
    </div >
  );
};

export default FinancialSetting;
