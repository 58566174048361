import ReactDOM from "react-dom/client"
import { ProSidebarProvider } from "react-pro-sidebar"
import { QueryClientProvider } from "react-query"
import App from "./App"
import { DocumentContextProvider } from "./components/BillPage/Document-Context"
import { ToastProvider } from "./components/context/ToasterContext/ToasterContext"
import { PopupContextProvider } from "./components/PopUp/Popup-Context"
import { queryClient } from "./queryClient"
import reportWebVitals from "./reportWebVitals"
import { ReactQueryDevtools } from "react-query/devtools"
import { AuthProvider } from "./components/context/auth/auth"
import { BrowserRouter } from "react-router-dom"
import { BatchesContextProvider } from "./components/context/BatchesContext/BatchesContext"
import { SettingPageContextProvider } from "./components/SettingPage/Context/SettingPageContext"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <ProSidebarProvider>
      <ToastProvider>
        <PopupContextProvider>
          <BatchesContextProvider>
            <DocumentContextProvider>
              <SettingPageContextProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </BrowserRouter>
              </SettingPageContextProvider>
            </DocumentContextProvider>
          </BatchesContextProvider>
        </PopupContextProvider>
      </ToastProvider>
    </ProSidebarProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
