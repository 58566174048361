import { useContext, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { EditIcon, PlusIcon, ThreeDotsIcon } from "../../assets/images/svg/svg";
import { tableIndexNumber, totalPages } from "../../utils/services";
import { useCategoryTableList } from "../Api/Get/GetApi";
import { CategoryTableListResponse } from "../Api/types";
import { Table } from "../CustomReactTable/CustomReactTable";
import { NewCategoryWrapper } from "../NewCategoryModal/NewCategoryWrapper";
import { UpdateCategoryWrapper } from "../NewCategoryModal/UpdateCategoryWrapper";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { CategoryForm } from "./CategoryForm";
import { UpdateCategoryForm } from "./UpdateCategoryForm";

export type CategoryPopupType = "add_popup" | "edit_popup";

export const CategoryList = () => {
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(20);
  const { openModal } = useContext(PopupContext);
  const [popupTypes, setPopupType] = useState<CategoryPopupType>();

  //useQuery
  const { data: categoryTableList, isLoading } = useCategoryTableList(
    queryPageIndex,
    queryPageSize
  );

  const [currentObject, setCurrentObject] = useState<
    { id: number; name: string } | undefined
  >(undefined);

  const FormatTable = (
    array: CategoryTableListResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array && array.length < 1) return [];

    const formatData = array?.map((item, index) => {
      return {
        s_n: tableIndexNumber(queryPageIndex, queryPageSize, index),
        category_name: item.name,
        action: {
          id: item.id,
          name: item.name,
        },
      };
    });

    return formatData;
  };

  const HandleEdit =(value: any) =>{
    setCurrentObject(value);
    setPopupType("edit_popup");
    openModal();
  }

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: "s_n",
      },
      {
        Header: "Category Name",
        accessor: "category_name",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }: { value: any }) => {
          return (
            <div className="action-section">
              <Dropdown>
                <Dropdown.Toggle className="dropdown-icon" id="dropdown-basic">
                  <ThreeDotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      HandleEdit( value )
                    }}
                  >
                    <div>
                      <span className="icon">
                        <EditIcon />
                      </span>
                      Edit
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="dashboard-table">
      <div className="head-section">
        <div className="small-title">Category table</div>
        <button
          className="light-primary c-button"
          onClick={() => {
            setPopupType("add_popup");
            openModal();
          }}
          type="button"
        >
          <span className="plus-icon">
            <PlusIcon />
          </span>
          Add New Category
        </button>

        {popupTypes === "edit_popup" && (
          <PopUp
            closeOnClickOutside={false}
            showCrossIcon={true}
            popupWidth={500}
            title="Edit Category"
            renderComponent={
              <UpdateCategoryWrapper>
                <UpdateCategoryForm
                  id={currentObject?.id ?? -1}
                  name={currentObject?.name ?? ""}
                />
              </UpdateCategoryWrapper>
            }
          />
        )}

        {popupTypes === "add_popup" && (
          <PopUp
            closeOnClickOutside={false}
            showCrossIcon={true}
            popupWidth={500}
            title="Add New Category"
            renderComponent={
              <NewCategoryWrapper>
                <CategoryForm />
              </NewCategoryWrapper>
            }
          />
        )}
      </div>

      <Table
        loading={isLoading}
        columns={columns}
        data={
          categoryTableList
            ? FormatTable(
                //@ts-ignore
                categoryTableList?.data,
                queryPageIndex,
                queryPageSize
              )
            : []
        }
        totalPages={
          categoryTableList &&
          totalPages(queryPageSize, categoryTableList.pagination.total_count)
        }
        totalEntries={
          categoryTableList && categoryTableList.pagination.total_count
        }
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
        showPageSize={false}
        smallPagination={true}
        // showDate={true}
        // date={date}
        // setDate={setDate}
      />
    </div>
  );
};
