import { useMemo, useState } from "react"
import { useBillCancelList } from "../../components/Api/Get/GetApi"
import {
  BillCancelListResponse,
  Search,
} from "../../components/Api/Get/GetApiTypes"
import { Table } from "../../components/CustomReactTable/CustomReactTable"
import {
  FormatDate,
  handleNoData,
  tableIndexNumber,
  totalPages,
} from "../../utils/services"

export const BillCancelReport = () => {
  //useState Hooks
  const [queryPageIndex, setQueryPageIndex] = useState(0)
  const [queryPageSize, setQueryPageSize] = useState(10)
  const [searchTerm, setSearchTerm] = useState<Search>({ invoice_no: "" })

  const {
    data: billCancel,
    isLoading,
  } = useBillCancelList(queryPageIndex, queryPageSize, searchTerm as Search)

  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "s_n",
      },
      { Header: "Issued Date", accessor: "issued_date" },
      { Header: "Cancelled Date", accessor: "cancelled_date" },

      {
        Header: "Invoice No.",
        accessor: "bill_number",
      },
      {
        Header: "Invoice Type",
        accessor: "document_type",
      },

      {
        Header: "Patron's Name",
        accessor: "name",
      },
      {
        Header: "Patron's Pan No",
        accessor: "pan",
      },
      {
        Header: "Canceled By",
        accessor: "canceled_by",
      },
    ],
    []
  )

  const FormatTable = (
    array: BillCancelListResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          name: handleNoData(item.customer_info.name),
          pan: handleNoData(item.customer_info.pan),
          bill_number:
            item.document_type === "PUR"
              ? handleNoData(item.bill_number)
              : handleNoData(item.document_number),
          issued_date: FormatDate(item.issued_nepali_date || ""),
          cancelled_date: FormatDate(item.cancelled_nepali_date || ""),
          document_type: item.document_type,
          canceled_by: item.user_info.name,
        }
      })
      return formatData
    }
    return []
  }

  return (
    <>
      <div className="purchasesListPage">
        <div className="row">
          <div className="head-section">
            <div className="col-md-6">
              <div className="small-title">Bill Cancel Report</div>
            </div>
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          data={
            billCancel
              ? FormatTable(billCancel.data, queryPageIndex, queryPageSize)
              : []
          }
          searchArray={["invoice_no"]}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          totalPages={
            billCancel &&
            totalPages(queryPageSize, billCancel.pagination.total_count)
          }
          totalEntries={billCancel && billCancel.pagination.total_count}
          queryPageIndex={queryPageIndex}
          setQueryPageIndex={setQueryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageSize={setQueryPageSize}
          excelDownload={true}
        />
      </div>
    </>
  )
}
