import "./Receipt.scss";
import { useContext, useEffect, useState } from "react";
import { useGetBillInvoice } from "../Api/Get/GetApi";
import { PopupContext } from "../PopUp/Popup-Context";
import { DocumentContext } from "../BillPage/Document-Context";
import { ToWords } from "to-words";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { BillGenerate } from "../BillGenerate/BillGenrate";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import {
  englishToNepaliConverter,
  englishToNepaliAmountConverter,
  fixedValue,
} from "../../utils/services";
import { AbbreviatedBillType, ReportType } from "../../EnumValues";
import { convertToNepaliWords } from "../../utils/num-to-nepaliwords";
interface ReciptPageProps {
  title: string;
}
export interface ChequeReceipt {
  cheque_no: string;
  created_at: string;
  cheque_withdraw: boolean;
  user_role_id: number;
  id: number;
  updated_at: string | null;
  issue_date: string;
  amount: number;
  transaction_detail_id: number;
  bank_name: string;
}
export interface billDetailsResponse {
  free_primary_unit: number;
  free_secondary_unit: number;
  secondary_unit_measure: string;
  primary_price: number;
  primary_unit: number;
  primary_unit_measure: string;
  secondary_price: number;
  secondary_unit: number;
  sub_total: number;
  discount: number;
  tax: number;
  total: number;
  product: string;
}

export interface IReceipt {
  bill_user_info: {
    pan: number;
    name: string;
    customer_note: string;
    mobile_no: number;
    bill_print_date: string;
    bill_miti: string;
    bill_miti_ad: string;
    is_total_discount: boolean | null;
    document_no: string;
    bill_no: string | null;
    bill_print_count: number;
  };

  customer_user_info: {
    pan: number;
    name: string;
    mobile_no: number;
    document_no: string;
    date: string;
    bill_no: string;
    additional_info: {
      address: string;
      city: string;
      additionalProp1: string;
    };
  };
  bill_detail: billDetailsResponse[];
  amount_detail: {
    bank: number;
    cash: number;
    credit: number;
    discount_paid: number;
    discount_received: number;
    purchase: number;
    sub_total: number;
    taxable_amount: number;
    tax_paid: number;
    tax_received: number;
    total_amount: number;
  };
  payment_type: {
    bank: number;
    cash: number;
    credit: number;
  };
  cheque_detail: Array<ChequeReceipt>;
  business_info: {
    name: string;
    pan: number;
    mobile_no: string;
    logo: string;
    id: 4;
    address: string;
  };
}
interface ReciptProps {
  reportType?: ReportType;
}

export type chipState =
  | AbbreviatedBillType.A4
  | AbbreviatedBillType.THERMAL
  | "";

export const AbbreviatedInvoice: React.FC<ReciptProps> = ({
  reportType = ReportType.Sales,
}) => {
  const title = "Abbreviated Tax Invoice";
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { closeModal } = useContext(PopupContext);
  const { documentNumber, setResStatus, langData, language } =
    useContext(DocumentContext);
  const toWords = new ToWords({
    converterOptions: { currency: true, ignoreDecimal: false },
  });
  const { data, isLoading } = useGetBillInvoice(documentNumber);
  const printTitle = [title];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setLoading(true),
    onAfterPrint: () => setLoading(false),
  });

  const userInfo = data && data?.bill_user_info;
  const billDetails = data && data?.bill_detail;
  const amountDetails = data && data?.amount_detail;
  // const bankDetails = data && data?.cheque_detail;
  const businessInfo = data && data?.business_info;
  const customerInfo = data && data?.customer_user_info;

  const isNepali = language === "ne";

  const calculateProductPrice = (
    item: billDetailsResponse,
    price: number,
    unit: string
  ) => {
    let vatAmount = 0;
    let subPrice = 0;
    let discount = item?.discount;
    if (discount && discount > 0) {
      discount =
        discount / (unit === "P" ? item.primary_unit : item?.secondary_unit);
    }

    subPrice = price - discount;
    if (item?.tax) {
      vatAmount = (subPrice * 13) / 100;
    }

    const vatAddedPrice = subPrice + vatAmount;
    return String(vatAddedPrice);
  };

  //TODO: need to fix the tax calculation
  // const ReciptPage: React.FC<ReciptPageProps> = ({ title }) => {
  //   console.log('receipt render')
  //   return (
  //     <div className={`receipt-section ${isNepali ? "nepali-text" : ""}`}>
  //       <div className="p-receipt">
  //         <div className="d-space-between">
  //           <div className="logo-detail">
  //             {reportType === ReportType.Sales && (
  //               <>
  //                 <div className="d-flex logo-section">
  //                   {businessInfo?.logo ? (
  //                     <div className="logo">
  //                       <img src={businessInfo?.logo} alt="logo" />
  //                     </div>
  //                   ) : (
  //                     <div className="large-title">
  //                       {businessInfo?.name.charAt(0).toUpperCase()}
  //                     </div>
  //                   )}
  //                 </div>
  //                 <div>
  //                   <h2 className="small-title">{businessInfo?.name}</h2>
  //                   <p className="content">{businessInfo?.address}</p>

  //                   <div className="div-flex">
  //                     {businessInfo?.mobile_no && (
  //                       <div className="bold-content">
  //                         {langData.customerDetails.phone}:{" "}
  //                         <span className="content">
  //                           {/* +977- */}
  //                           {englishToNepaliConverter(
  //                             String(`+977-${businessInfo?.mobile_no}`)
  //                           )}
  //                         </span>
  //                       </div>
  //                     )}
  //                     {businessInfo?.pan && (
  //                       <div className="bold-content pan-box">
  //                         {/* <div className="vertical-line"></div> */}
  //                         {langData.customerDetails.panNo}:
  //                         <span className="content">
  //                           {englishToNepaliConverter(
  //                             String(businessInfo?.pan)
  //                           )}
  //                         </span>
  //                       </div>
  //                     )}
  //                   </div>
  //                 </div>
  //               </>
  //             )}
  //           </div>

  //           <div className="receipt-detail-block d-end">
  //             <h2 className="large-title">{title} </h2>
  //             <table className="table table-borderless">
  //               <tbody>
  //                 <tr>
  //                   <th className="bold-content" scope="row">
  //                     {langData.billDetails.invoiceNo}:
  //                   </th>
  //                   <td className="content">
  //                     {reportType === ReportType.Purchase
  //                       ? userInfo?.bill_no
  //                       : userInfo?.document_no}
  //                   </td>
  //                 </tr>
  //                 {userInfo?.bill_miti && (
  //                   <tr>
  //                     <th className="bold-content" scope="row">
  //                       {langData.billDetails.billMiti}:
  //                     </th>

  //                     <td>
  //                       <span className="content">
  //                         {englishToNepaliConverter(
  //                           String(userInfo?.bill_miti.split("/").join(" - "))
  //                         )}
  //                       </span>
  //                     </td>
  //                   </tr>
  //                 )}
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //         <div
  //           className={
  //             reportType === ReportType.Sales
  //               ? "d-space-between header-wrapper"
  //               : "d-space-between"
  //           }
  //         >
  //           <div className="receipt-header">
  //             <div className="receipt-header-block">
  //               <table className="table table-borderless">
  //                 <tbody>
  //                   <tr>
  //                     <th className="bold-content" scope="row">
  //                       {langData.customerDetails.customerName}:
  //                     </th>
  //                     <td className="content">{customerInfo?.name}</td>
  //                   </tr>
  //                   {customerInfo?.additional_info?.address ||
  //                     customerInfo?.additional_info?.additionalProp1 ? (
  //                     <tr>
  //                       <th className="bold-content" scope="row">
  //                         {langData.customerDetails.address}:
  //                       </th>
  //                       <td className="content">
  //                         {customerInfo?.additional_info?.address ||
  //                           customerInfo?.additional_info?.additionalProp1}
  //                       </td>
  //                     </tr>
  //                   ) : (
  //                     ""
  //                   )}

  //                   {customerInfo?.mobile_no && (
  //                     <tr>
  //                       <th className="bold-content" scope="row">
  //                         {langData.customerDetails.phone}:
  //                       </th>

  //                       <td>
  //                         <span className="content">
  //                           {/* +977- */}
  //                           {englishToNepaliConverter(
  //                             String(`+977-${customerInfo?.mobile_no}`)
  //                           )}
  //                         </span>
  //                       </td>
  //                     </tr>
  //                   )}
  //                   {customerInfo?.pan && (
  //                     <tr>
  //                       <th className="bold-content" scope="row">
  //                         {langData.customerDetails.panNo}:
  //                       </th>
  //                       <td>
  //                         <span className="content">
  //                           {englishToNepaliConverter(
  //                             String(customerInfo?.pan)
  //                           )}
  //                         </span>
  //                       </td>
  //                     </tr>
  //                   )}
  //                 </tbody>
  //               </table>
  //             </div>
  //           </div>
  //           <div className="last-block">
  //             <table className="table table-borderless">
  //               <tbody>
  //                 <tr>
  //                   <th className="bold-content" scope="row">
  //                     {langData.billDetails.transactionDate}:
  //                   </th>
  //                   <td>
  //                     <span className="content">
  //                       {englishToNepaliConverter(
  //                         String(userInfo?.bill_miti_ad.split("/").join(" - "))
  //                       )}
  //                     </span>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th className="bold-content" scope="row">
  //                     {langData.billDetails.printDateTime}:
  //                   </th>
  //                   <td>
  //                     <span className="content">
  //                       {englishToNepaliConverter(
  //                         String(new Date().toLocaleString() + "")
  //                       )}
  //                     </span>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th className="bold-content" scope="row">
  //                     {langData.billDetails.IssuedBy}:{" "}
  //                   </th>
  //                   <td className="content">{userInfo?.name}</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //         <div className="full-table">
  //           <table className="table table-bordered">
  //             <thead>
  //               <tr>
  //                 <th rowSpan={2} className="header-align-center sn-head">
  //                   {langData.productHeads.SN}
  //                 </th>
  //                 <th rowSpan={2} className="header-align-center name-sec">
  //                   {langData.productHeads.productName}
  //                 </th>

  //                 <th colSpan={2} className="center-align">
  //                   {langData.productHeads.Quantity}
  //                 </th>

  //                 <th colSpan={2} className="center-align">
  //                   {langData.productHeads.rate}
  //                 </th>

  //                 {/* <th rowSpan={2} className="end-align header-align-center">
  //                   {langData.productHeads.discount}
  //                 </th> */}
  //                 <th rowSpan={2} className="end-align header-align-center">
  //                   {langData.productHeads.amount}
  //                 </th>
  //               </tr>
  //               <tr className="center-align">
  //                 <th>{langData.productHeads.pri}</th>
  //                 <th>{langData.productHeads.sec}</th>
  //                 <th>{langData.productHeads.pri}</th>
  //                 <th>{langData.productHeads.sec}</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {billDetails &&
  //                 Array(20)
  //                   .fill(undefined)
  //                   ?.map((_, index) => {
  //                     const item = billDetails[index];
  //                     return (
  //                       <tr
  //                         style={{ visibility: item ? "visible" : "hidden" }}
  //                         key={index}
  //                       >
  //                         <td className="padd-5"> {item ? index + 1 : "1"}</td>
  //                         <td className="padd-5 name-sec">{item?.product}</td>

  //                         <td className="padd-5">
  //                           <p>
  //                             {item?.primary_unit > 0
  //                               ? englishToNepaliConverter(
  //                                 String(item?.primary_unit) +
  //                                 " " +
  //                                 item?.primary_unit_measure
  //                               )
  //                               : "-"}
  //                           </p>
  //                         </td>
  //                         <td className="padd-5">
  //                           <p>
  //                             {item?.secondary_unit > 0
  //                               ? englishToNepaliConverter(
  //                                 String(item?.secondary_unit)
  //                               ) +
  //                               " " +
  //                               item?.secondary_unit_measure
  //                               : "-"}
  //                           </p>
  //                         </td>

  //                         <td className="padd-5">
  //                           <p>
  //                             {item?.primary_unit > 0
  //                               ? englishToNepaliAmountConverter(
  //                                 calculateProductPrice(
  //                                   item,
  //                                   item?.primary_price,
  //                                   "P"
  //                                 )
  //                               )
  //                               : "-"}
  //                           </p>
  //                         </td>
  //                         <td className="padd-5">
  //                           <p>
  //                             {item?.secondary_unit > 0
  //                               ? englishToNepaliAmountConverter(
  //                                 calculateProductPrice(
  //                                   item,
  //                                   item?.secondary_price,
  //                                   "S"
  //                                 )
  //                               )
  //                               : "-"}
  //                           </p>
  //                         </td>

  //                         {/* <td className="padd-5 end-col">
  //                           {!userInfo?.is_total_discount
  //                             ? englishToNepaliAmountConverter(
  //                                 String(item?.discount)
  //                               )
  //                             : englishToNepaliAmountConverter(0)}
  //                         </td> */}
  //                         <td className="padd-5 end-col">
  //                           <p>
  //                             {englishToNepaliAmountConverter(
  //                               String(item?.sub_total)
  //                             )}
  //                           </p>
  //                         </td>
  //                       </tr>
  //                     );
  //                   })}
  //             </tbody>
  //           </table>
  //         </div>
  //         <div className="footer-div">
  //           <div className="receipt-info">
  //             <div className="receipt-info-section">
  //               <div className="receipt-info-block">
  //                 <h4 className="block-title">
  //                   {langData.transactionDetails.inWords}:
  //                 </h4>
  //                 <p className="small-content capitalize">
  //                   {isNepali
  //                     ? convertToNepaliWords(
  //                       fixedValue(amountDetails?.total_amount, 2) || 0
  //                     )
  //                     : toWords.convert(
  //                       fixedValue(amountDetails?.total_amount, 2) || 0
  //                     )}
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="receipt-info-section ">
  //               <div className="receipt-info-block">
  //                 <h4 className="block-title capitalize">
  //                   {langData.footer.note}:
  //                 </h4>
  //                 <p className="small-content">
  //                   {userInfo?.customer_note
  //                     ? userInfo?.customer_note
  //                     : `Thank You`}
  //                 </p>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="receipt-calculation">
  //             <div className="receipt-calculation-block ">
  //               <p className="small-content">
  //                 {langData.transactionDetails.subTotal}
  //               </p>
  //               <p className="small-content text-right">
  //                 {langData.transactionDetails.rupees}.{" "}
  //                 {englishToNepaliAmountConverter(
  //                   String(amountDetails?.sub_total)
  //                 )}
  //               </p>
  //             </div>
  //             <div className="receipt-calculation-block">
  //               <p className="small-content">
  //                 {langData.transactionDetails.discount}
  //               </p>
  //               <p className="small-content text-right">
  //                 {langData.transactionDetails.rupees}.{" "}
  //                 {amountDetails && amountDetails?.discount_paid > 0
  //                   ? englishToNepaliAmountConverter(
  //                     String(amountDetails?.discount_paid)
  //                   )
  //                   : englishToNepaliAmountConverter(
  //                     String(amountDetails?.discount_received)
  //                   )}
  //               </p>
  //             </div>
  //             {/* <div className="receipt-calculation-block">
  //               <p className="bold-content">
  //                 {langData.transactionDetails.taxableAmt}
  //               </p>
  //               <p className="small-content text-right">
  //                 {langData.transactionDetails.rupees}.{" "}
  //                 {amountDetails &&
  //                   englishToNepaliAmountConverter(
  //                     String(amountDetails?.taxable_amount)
  //                   )}
  //               </p>
  //             </div> */}
  //             <div className="receipt-calculation-block">
  //               <p className="bold-content">
  //                 {langData.transactionDetails.total} (
  //                 {langData.transactionDetails.npr})
  //               </p>
  //               <p className="content text-right">
  //                 {langData.transactionDetails.rupees}.{" "}
  //                 {englishToNepaliAmountConverter(
  //                   String(amountDetails?.total_amount)
  //                 )}
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="receipt-info">
  //           <div className="receipt-info-block receipt-footer">
  //             <p className="small-content">
  //               {langData.footer.computerGenerated} {"Signature is not needed."}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const ThermalReceiptPage: React.FC<ReciptPageProps> = React.memo(({ title }) => {
    return (
      <div className={`thermal-receipt-section ${isNepali && "nepali-text"}`}>
        <div className="p-receipt">
          <div className="d-flex justify-content-center">
            <div className="logo-detail">
              {reportType === ReportType.Sales && (
                <>
                  <div>
                    <p className="text-center">{businessInfo?.name}</p>
                    <p className="text-center">{businessInfo?.address}</p>

                    <div>
                      {businessInfo?.pan && (
                        <p>
                          {langData.customerDetails.panNo}:{" "}
                          <span>
                            {englishToNepaliConverter(
                              String(businessInfo?.pan)
                            )}
                          </span>
                        </p>
                      )}
                    </div>
                    <p className="text-center">{title} </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="receipt-detail-block d-end">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td width="40%">{langData.billDetails.invoiceNo}:</td>
                  <td>
                    {reportType === ReportType.Purchase
                      ? userInfo?.bill_no
                      : userInfo?.document_no}
                  </td>
                </tr>
                {userInfo?.bill_miti && (
                  <tr>
                    <td width="40%">{langData.billDetails.billMiti}:</td>

                    <td>
                      <span>
                        {englishToNepaliConverter(
                          String(userInfo?.bill_miti.split("/").join(" - "))
                        )}
                      </span>
                    </td>
                  </tr>
                )}
                <tr>
                  <td width="40%">{langData.customerDetails.customerName}:</td>
                  <td>{customerInfo?.name}</td>
                </tr>
                {customerInfo?.additional_info?.address ||
                  customerInfo?.additional_info?.additionalProp1 ? (
                  <tr>
                    <td width="40%">{langData.customerDetails.address}:</td>
                    <td>
                      {customerInfo?.additional_info?.address ||
                        customerInfo?.additional_info?.additionalProp1}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {customerInfo?.mobile_no || customerInfo?.mobile_no !== 0 ? (
                  <tr>
                    <td width="40%">{langData.customerDetails.phone}:</td>
                    <td>
                      <span>
                        {englishToNepaliConverter(
                          String(customerInfo?.mobile_no)
                        )}
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {customerInfo?.pan && (
                  <tr>
                    <td width="40%">{langData.customerDetails.panNo}:</td>
                    <td>
                      <span>
                        {englishToNepaliConverter(String(customerInfo?.pan))}
                      </span>
                    </td>
                  </tr>
                )}
                <tr>
                  <td width="40%">{langData.billDetails.transactionDate}:</td>
                  <td>
                    <span>
                      {englishToNepaliConverter(
                        String(userInfo?.bill_miti_ad.split("/").join(" - "))
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="40%">{langData.billDetails.printDateTime}:</td>
                  <td>
                    <span>
                      {englishToNepaliConverter(
                        String(new Date().toLocaleString() + "")
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="40%">{langData.billDetails.IssuedBy}: </td>
                  <td>{userInfo?.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="full-table">
            <table className="table">
              <thead>
                <tr>
                  <th className="name-sec">
                    {langData.productHeads.productName}
                  </th>
                  <th>{langData.productHeads.Quantity}</th>

                  <th className="text-end header-align-center">
                    {langData.productHeads.rate}
                  </th>

                  {/* <th className="text-end header-align-center">
                    {langData.productHeads.discount}
                  </th> */}
                  <th className="text-end  header-align-center">
                    {langData.productHeads.amount}
                  </th>
                </tr>
              </thead>
              <tbody>
                {billDetails &&
                  billDetails?.map((item, index) => {
                    return (
                      <tr
                        style={{ visibility: item ? "visible" : "hidden" }}
                        key={index}
                      >
                        <td className="padd-5 name-sec">{item?.product}</td>

                        <td className="padd-5">
                          <p>
                            {item?.primary_unit > 0
                              ? englishToNepaliConverter(
                                String(item?.primary_unit) +
                                " " +
                                item?.primary_unit_measure
                              )
                              : item?.secondary_unit > 0
                                ? englishToNepaliConverter(
                                  String(item?.secondary_unit)
                                ) +
                                " " +
                                item?.secondary_unit_measure
                                : "-"}
                          </p>
                        </td>

                        <td className="padd-5 text-end">
                          <p>
                            {item?.primary_unit > 0
                              ? englishToNepaliAmountConverter(
                                calculateProductPrice(
                                  item,
                                  item?.primary_price,
                                  "P"
                                )
                              )
                              : item?.secondary_unit > 0
                                ? englishToNepaliAmountConverter(
                                  calculateProductPrice(
                                    item,
                                    item?.secondary_price,
                                    "S"
                                  )
                                )
                                : "-"}
                          </p>
                        </td>

                        {/* <td className="padd-5 end-col">
                          {!userInfo?.is_total_discount
                            ? englishToNepaliAmountConverter(
                                String(item?.discount)
                              )
                            : englishToNepaliAmountConverter(0)}
                        </td> */}
                        <td className="padd-5 end-col">
                          <p>
                            {englishToNepaliAmountConverter(
                              String(item?.total)
                            )}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="footer-div justify-content-end">
            <div className="receipt-calculation">
              <div className="receipt-calculation-block ">
                <p>{langData.transactionDetails.subTotal}</p>
                <p className="text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {englishToNepaliAmountConverter(
                    String(
                      (amountDetails?.sub_total || 0) +
                      (amountDetails?.tax_received || 0)
                    )
                  )}
                </p>
              </div>
              <div className="receipt-calculation-block">
                <p>{langData.transactionDetails.discount}</p>
                <p className="text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {amountDetails && amountDetails?.discount_paid > 0
                    ? englishToNepaliAmountConverter(
                      String(amountDetails?.discount_paid)
                    )
                    : englishToNepaliAmountConverter(
                      String(amountDetails?.discount_received)
                    )}
                </p>
              </div>
              {/* <div className="receipt-calculation-block">
                <p>{langData.transactionDetails.taxableAmt}</p>
                <p className="text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {amountDetails &&
                    englishToNepaliAmountConverter(
                      String(amountDetails?.taxable_amount)
                    )}
                </p>
              </div> */}
              <div className="receipt-calculation-block border-top-dotted">
                <p>
                  {langData.transactionDetails.total} (
                  {langData.transactionDetails.npr})
                </p>
                <p className="text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {englishToNepaliAmountConverter(
                    String(amountDetails?.total_amount)
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="receipt-info">
            <div className="receipt-info-section">
              <div className="receipt-info-block">
                <p className="capitalize">
                  {langData.transactionDetails.inWords}:{" "}
                  {isNepali
                    ? convertToNepaliWords(
                      fixedValue(amountDetails?.total_amount, 2) || 0
                    )
                    : toWords.convert(
                      fixedValue(amountDetails?.total_amount, 2) || 0
                    )}
                </p>
              </div>
            </div>
            <div className="receipt-info-section">
              <div className="receipt-info-block">
                <p>
                  {langData.footer.note}:{" "}
                  {userInfo?.customer_note
                    ? userInfo?.customer_note
                    : `Thank You`}
                </p>
              </div>
            </div>
            <div className="custom-hr" />
          </div>
          <div className="receipt-info">
            <div className="receipt-info-block receipt-footer">
              <p>
                {langData.footer.computerGenerated} {"Signature is not needed."}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      {isLoading || !data ? (
        <BillGenerate />
      ) : (
        <>
          <div className="receipt">
            <div className="receipt-top">
              <div className="d-flex justify-content-between">
                <h4 className="title mb-0">Receipt</h4>
                {/* <div className="d-flex tag-wrapper gap-2 w-100 justify-content-center">
                    <button
                      className={`chip-tag recipt-bar small-content ${
                        activeState === AbbreviatedBillType.THERMAL
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        setActiveState(AbbreviatedBillType.THERMAL);
                      }}
                    >
                      Thermal
                    </button>
                    <button
                      className={`chip-tag recipt-bar small-content ${
                        activeState === "" ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveState(AbbreviatedBillType.A4);
                      }}
                    >
                      A4
                    </button>
                  </div> */}
                <div className="cancel-icon">
                  <img
                    src={require("../../assets/images/cross.png")}
                    alt="cancel"
                    onClick={() => {
                      setResStatus?.(false);
                      closeModal();
                    }}
                  />
                </div>
              </div>
            </div>

            {/* {activeState === AbbreviatedBillType.THERMAL ? ( */}
            <ThermalReceiptPage title={title} />
            {/* ) : (
              <ReciptPage title={title} />
            )} */}
            <div style={{ display: "none" }}>
              <div className="print-page" ref={componentRef}>
                {printTitle.map((itm, idx) => {
                  return (
                    <>
                      {/* {activeState === AbbreviatedBillType.THERMAL ? ( */}
                      <React.Fragment key={idx}>
                        <ThermalReceiptPage title={itm} />
                      </React.Fragment>
                      {/* ) : (
                        <React.Fragment key={idx}>
                          <ReciptPage title={itm} />
                          <div className="page-break" />
                        </React.Fragment>
                      )} */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="btn-right">
            <div className="d-end">
              <div>
                <button
                  className="light-secondary mr-16"
                  onClick={() => {
                    closeModal();
                    setResStatus?.(false);
                  }}
                >
                  Back
                </button>
              </div>

              <div>
                <ButtonComponent
                  isLoading={loading}
                  title="Print"
                  onClick={() => {
                    // handlePrintCount(documentNumber);
                    handlePrint();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
