import { ErrorMessage, Field } from "formik";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { NewCustomerModal } from "../NewCustomerModal/NewCustomerModal";
import { NewCustomeWrapper } from "../NewCustomerModal/NewCustomerWrapper";
import { PopUp } from "../PopUp/PopUp";
import { SwitchRadio } from "../SwitchRadio/SwitchRadio";
import { useNewBillFormikContext } from "./BillFormikWrapper";
import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import { ProductWrapper } from "../AddablePrice/ProductWrapper";
import { NewProductModal } from "../NewProductModal/NewProductModal";
import { Receipt } from "../Receipt/Receipt";
import "./BillPage.scss";
import { popupType } from "./BillPageComponent";
import { PopupContext } from "../PopUp/Popup-Context";
import { DocumentContext } from "./Document-Context";
import { AddBillDataProps } from "./BillPage";
import { AddableBank } from "../AddableBank/AddableBank";
import { BottomSheetModal } from "../BottomSheet/BottomSheet";
import { BottomSheetContext } from "../BottomSheet/BottomSheetContext";
import { ReportType } from "../../EnumValues";

interface options {
  calenderLocale: string;
  valueLocale: string;
}

export const BillMobileView: React.FC<AddBillDataProps> = ({
  setDate,
  setSearchDescription,
  alterdata,
  searchData,
  roleVal,
  customerInfo,
}) => {
  const { resStatus } = useContext(DocumentContext);
  const [popupType, setPopupType] = useState<popupType>();
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    getFieldProps,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    values,
    isValid,
  } = useNewBillFormikContext();
  const { bottomSheetopenModal } = useContext(BottomSheetContext);
  const { openModal } = useContext(PopupContext);

  // const options = {
  //   calenderLocale: "ne",
  //   valueLocale: "en",
  // };

  return (
    <div className="mobile-view-bill">
      <div className="add-bill">
        <div>
          <h2 className="small-title">New Bill</h2>
          <div className="custom-card-background">
            <div className="d-vertical-start mb-bill-title">
              <div className="bill-no">
                <label className="input-label">Bill No.</label>
                <input
                  className={`${errors.bill_number && touched.bill_number
                      ? "input-field error-input"
                      : "input-field"
                    }`}
                  placeholder="Enter bill no"
                  {...getFieldProps("bill_number")}
                />
                <ErrorMessage
                  name={"bill_number"}
                  component="div"
                  className="error"
                />
              </div>
              <div className="date-section">
                <label htmlFor="date" className="input-label">
                  Date:
                </label>
                {/* <input
              className={`${
                errors.date && touched.date
                  ? "input-field error-input"
                  : "input-field"
              }`}
              type={"date"}
              {...getFieldProps("date")}
            /> */}
                <NepaliDatePicker
                  className={`${errors.date && touched.date
                      ? "input-field error-input"
                      : "input-field"
                    }`}
                  defaultDate={true}
                  onDateSelect={(value: any) => {
                    setDate?.(value);
                  }}
                // options={ {
                //   calenderLocale: "ne",
                //   valueLocale: "en",
                // } }
                />
                <ErrorMessage name={"date"} component="div" className="error" />
              </div>
            </div>
          </div>
          <div className="custom-card-background">
            <div className="d-space-between">
              <div className="extra-small-title c-title">Customer Info</div>
              <div>
                <button
                  className="light-primary c-button"
                  onClick={() => {
                    setPopupType("customer");
                    bottomSheetopenModal();
                  }}
                  type="button"
                >
                  <span>
                    <img
                      alt="plus"
                      src={require("../../assets/images/plus-16.png")}
                    />
                  </span>
                </button>
              </div>
            </div>
            {popupType === "customer" && (
              <BottomSheetModal title="New Customer">
                <NewCustomeWrapper>
                  <NewCustomerModal />
                </NewCustomeWrapper>
              </BottomSheetModal>
            )}
            <div className="col-md-3">
              <label className="input-label">Customer Name</label>
              <div className="search-wrapper d-flex">
                <div className="search-icon">
                  <img
                    alt="search"
                    src={require("../../assets/images/search.png")}
                  />
                </div>
                <input
                  className={`${errors.user_name && touched.user_name
                      ? "input-field error-input capatilize"
                      : "input-field capatilize"
                    }`}
                  // className="input-field"
                  placeholder="Search customer"
                  list="user_name"
                  name="user_name"
                  value={customerInfo?.name}
                  onChange={(e) => {
                    setSearchDescription({
                      name: e.target.name,
                      val: e.target.value,
                    });
                    alterdata(e);
                  }}
                />
                <ErrorMessage
                  name={"user_name"}
                  component="div"
                  className="error"
                />
                <datalist id="user_name" className="data-array">
                  {searchData.map((itm, index) => {
                    return (
                      <option
                        value={`${itm.name}-${itm.mobile_no}-${itm.pan}-${itm.user_role_id}-${itm.role_id}`}
                        key={index}
                        className="data-wrapper"
                      >
                        {itm.name}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
            <div className="col-md-3">
              <label className="input-label">Customer PAN</label>
              <div className="search-wrapper d-flex">
                <div className="search-icon">
                  <img
                    alt="search"
                    src={require("../../assets/images/search.png")}
                  />
                </div>
                <input
                  className="input-field"
                  placeholder="Enter customer pan"
                  name="pan"
                  list="pan_list"
                  value={customerInfo.panNo}
                  onChange={(e) => {
                    setSearchDescription({
                      name: e.target.name,
                      val: e.target.value,
                    });
                    alterdata(e);
                  }}
                />
                <datalist id="pan_list" className="data-array">
                  {searchData.map((itm, index) => {
                    return (
                      <option
                        value={`${itm.pan}-${itm.name}-${itm.mobile_no}-${itm.user_role_id}-${itm.role_id}`}
                        key={index}
                        className="data-wrapper"
                      >
                        {itm.pan}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
            <div className="col -md-3">
              <label className="input-label">Mobile Number</label>

              <div className="search-wrapper d-flex">
                <div className="search-icon">
                  <img
                    alt="search"
                    src={require("../../assets/images/search.png")}
                  />
                </div>
                <input
                  className="input-field"
                  placeholder="Enter mobile number"
                  name="mobile_no"
                  value={customerInfo.mobileNo}
                  list="mobile_list"
                  onChange={(e) => {
                    setSearchDescription({
                      name: e.target.name,
                      val: e.target.value,
                    });
                    alterdata(e);
                  }}
                />
                <datalist id="mobile_list" className="data-array">
                  {searchData.map((itm, index) => {
                    return (
                      <option
                        value={`${itm.mobile_no}-${itm.name}-${itm.pan}-${itm.user_role_id}-${itm.role_id}`}
                        key={index}
                        className="data-wrapper"
                      >
                        {itm.mobile_no}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
          </div>
          <div className="toggle-section">
            <div className="extra-small-title">Bill Type</div>
            <SwitchRadio
              options={["Purchase", "Sales"].map((str, index) => {
                return {
                  Label: str,
                  value: str,
                  name: "transaction_type",
                  onChange: (e) => {
                    setActiveIndex(index);
                    setFieldValue(
                      "transaction_type",
                      e.target.value === "Purchase" ? ReportType.Purchase : ReportType.Sales
                    );
                  },
                  checked: index === activeIndex,
                };
              })}
            />
            <div className="custom-card-background">
              <div className="d-space-between add-product">
                <div className="extra-small-title">Product Info</div>
                <div>
                  <button
                    className="light-primary c-button"
                    onClick={() => {
                      setPopupType("product");
                      bottomSheetopenModal();
                    }}
                    type="button"
                  >
                    <span>
                      <img
                        alt="plus"
                        src={require("../../assets/images/plus-16.png")}
                      />
                    </span>
                  </button>
                  {popupType === "product" && (
                    <BottomSheetModal title="New Product">
                      <ProductWrapper mode="new">
                        <NewProductModal mode="new" />
                      </ProductWrapper>
                    </BottomSheetModal>
                  )}
                </div>
              </div>
              <div className="new-line">
                <button
                  className="light-primary c-button"
                  onClick={() => {
                    setPopupType("product");
                    // bottomSheetopenModal()
                  }}
                  type="button"
                >
                  <span>
                    <img
                      alt="plus"
                      src={require("../../assets/images/plus-16.png")}
                    />
                  </span>
                  Add New Line
                </button>
              </div>
              <div className="custom-card">
                <div className="d-space-between added-product">
                  <div className="uppercase small-content">Item</div>
                  <div className="uppercase small-content balance-block">
                    Balance
                  </div>
                </div>
                <div className="product-body">
                  <div className="d-space-between">
                    <div className="product-detail">
                      <div className="block-title">Pran 1 Ltr</div>
                      <div className="extra-small-content">
                        <span>Quantity: </span>
                        12 Boxes / 20 Units
                      </div>
                      <div className="extra-small-content">
                        <span>Free: </span>1 Boxes / 20 Units
                      </div>
                      <div className="extra-small-content">
                        <span>Rate: </span>
                        Rs. 120 per box / Rs. 20 per unit
                      </div>
                    </div>
                    <div className="block-title second-section">Rs. 5000</div>
                    <div className="remove-block">
                      <button
                        // onClick={() => removeInput(index)}
                        className="remove-btn"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className="remove"
                            d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="#B11D1D"
                          />
                          <path
                            className="remove"
                            d="M5.80715 5.8074C5.86521 5.74919 5.93418 5.70301 6.01011 5.67151C6.08604 5.64 6.16744 5.62378 6.24965 5.62378C6.33186 5.62378 6.41326 5.64 6.48919 5.67151C6.56513 5.70301 6.6341 5.74919 6.69215 5.8074L9.99965 9.11615L13.3072 5.8074C13.3653 5.74929 13.4342 5.70319 13.5102 5.67174C13.5861 5.64029 13.6675 5.62411 13.7497 5.62411C13.8318 5.62411 13.9132 5.64029 13.9891 5.67174C14.0651 5.70319 14.134 5.74929 14.1922 5.8074C14.2503 5.86551 14.2964 5.93449 14.3278 6.01042C14.3593 6.08634 14.3754 6.16772 14.3754 6.2499C14.3754 6.33208 14.3593 6.41345 14.3278 6.48938C14.2964 6.5653 14.2503 6.63429 14.1922 6.6924L10.8834 9.9999L14.1922 13.3074C14.2503 13.3655 14.2964 13.4345 14.3278 13.5104C14.3593 13.5863 14.3754 13.6677 14.3754 13.7499C14.3754 13.8321 14.3593 13.9135 14.3278 13.9894C14.2964 14.0653 14.2503 14.1343 14.1922 14.1924C14.134 14.2505 14.0651 14.2966 13.9891 14.3281C13.9132 14.3595 13.8318 14.3757 13.7497 14.3757C13.6675 14.3757 13.5861 14.3595 13.5102 14.3281C13.4342 14.2966 13.3653 14.2505 13.3072 14.1924L9.99965 10.8836L6.69215 14.1924C6.63404 14.2505 6.56506 14.2966 6.48913 14.3281C6.41321 14.3595 6.33183 14.3757 6.24965 14.3757C6.16747 14.3757 6.0861 14.3595 6.01017 14.3281C5.93425 14.2966 5.86526 14.2505 5.80715 14.1924C5.74904 14.1343 5.70295 14.0653 5.6715 13.9894C5.64005 13.9135 5.62386 13.8321 5.62386 13.7499C5.62386 13.6677 5.64005 13.5863 5.6715 13.5104C5.70295 13.4345 5.74904 13.3655 5.80715 13.3074L9.1159 9.9999L5.80715 6.6924C5.74895 6.63434 5.70277 6.56537 5.67126 6.48944C5.63975 6.41351 5.62354 6.33211 5.62354 6.2499C5.62354 6.16769 5.63975 6.08629 5.67126 6.01035C5.70277 5.93442 5.74895 5.86545 5.80715 5.8074Z"
                            fill="#B11D1D"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="total-card-wrapper">
              <div className="total-card">
                <div className="d-space-between line-segment">
                  <div className="content">Sub Total</div>
                  <div className="input-space">
                    <input
                      className={`${errors.sub_total && touched.sub_total
                          ? "input-field error-input"
                          : "input-field"
                        }`}
                      type={"number"}
                      value={values.sub_total}
                      name="sub_total"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      disabled
                    />
                    <ErrorMessage
                      name={"sub_total"}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="d-space-between line-segment">
                  <div className="content">Discount</div>
                  <div className="input-space">
                    <Field
                      className={`${errors.discount && touched.discount
                          ? "input-field error-input"
                          : "input-field"
                        }`}
                      pattern="[0-9]*"
                      onKeyPress={(event: any) => {
                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter discount amount"
                      {...getFieldProps("discount")}
                      onFocus={(e: any) => {
                        if (parseInt(e.target.value) === 0) {
                          e.target.value = "";
                        }
                      }}
                      name="discount"
                    />
                    <ErrorMessage
                      name={"discount"}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="d-space-between line-segment no-space">
                  <div>
                    <div
                      className="check-box-area d-flex"
                    //   onClick={() => setAddTax(!addTax)}
                    >
                      <input
                        type="checkbox"
                      // checked={addTax}
                      />
                      <label className="content">Tax (13% VAT)</label>
                    </div>
                  </div>
                  <div className="input-space">
                    <input
                      className={`${errors.tax && touched.tax
                          ? "input-field error-input"
                          : "input-field"
                        }`}
                      disabled
                      type={"number"}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter tax amount"
                      {...getFieldProps("tax")}
                    />
                    <ErrorMessage
                      name={"tax"}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>
              <div className="d-space-between line-segment no-space total-bottom">
                <div className="content">Total</div>
                <div className="input-space">
                  <input
                    className={`${errors.total && touched.total
                        ? "input-field error-input"
                        : "input-field"
                      }`}
                    type={"number"}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.total}
                    name="total"
                    disabled
                  />
                  <ErrorMessage
                    name={"total"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="bank-detail-wrapper">
              <div className="bank-detail">
                <div className="block-title cash-title">Cash</div>
                <div className="cash-amt">
                  <label className="input-label">Amount</label>
                  <Field
                    // className={`${
                    //   errors.bill_number && touched.bill_number
                    //     ? "input-field error-input"
                    //     : "input-field"
                    // }`}
                    className="input-field"
                    type="number"
                    pattern="[0-9]*"
                    onKeyUp={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      if (parseInt(event.target.value) === 0) {
                        event.target.value = "";
                      }
                    }}
                    onWheel={(e: any) => {
                      (e.target as HTMLInputElement).blur();
                    }}
                    onFocus={(event: any) => {
                      if (parseInt(event.target.value) === 0) {
                        event.target.value = ""
                      }
                    }}
                    value={values.cash}
                  />
                  <ErrorMessage
                    name={"cash"}
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <AddableBank
                    name="Bank Details"
                    buttonName="Add Details"
                    handleAddDetail={() => { }}
                    handleToaster={() => { }}
                    salesBankId={() => { }}
                    setFieldValue={() => { }}
                  />
                </div>
              </div>
              <div className="credit-detail bank-detail">
                <div className="block-title cash-title">Credit</div>
                <div className="cash-amt">
                  <label className="input-label">Amount</label>
                  <Field
                    // className={`${
                    //   errors.bill_number && touched.bill_number
                    //     ? "input-field error-input"
                    //     : "input-field"
                    // }`}
                    className="input-field"
                    pattern="[0-9]*"
                    disabled
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      if (event.target.value > values.total) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter amount"
                    {...getFieldProps("credit")}
                  />
                  <ErrorMessage
                    name={"credit"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="custom-card-background">
              <div className="d-space-between">
                <div className="extra-small-title c-title">Bill Terms</div>
                <div className="add-section">
                  <button
                    className="light-primary c-button"
                    onClick={() => {
                      setPopupType("customer");
                      openModal();
                    }}
                    type="button"
                  >
                    <span>
                      <img
                        alt="plus"
                        src={require("../../assets/images/plus-16.png")}
                      />
                    </span>
                  </button>
                </div>
              </div>
              <div className="search-option first-search-box">
                <label className="input-label">Bill Terms</label>
                <div className="search-wrapper d-flex">
                  <div className="search-icon">
                    <img
                      alt="search"
                      src={require("../../assets/images/search.png")}
                    />
                  </div>
                  <input
                    className={`${errors.user_name && touched.user_name
                        ? "input-field error-input capatilize"
                        : "input-field capatilize"
                      }`}
                    // className="input-field"
                    placeholder="Search bill terms"
                    list="user_name"
                    name="user_name"
                    value={customerInfo?.name}
                    onChange={(e) => {
                      setSearchDescription({
                        name: e.target.name,
                        val: e.target.value,
                      });
                      alterdata(e);
                    }}
                  />
                  <ErrorMessage
                    name={"user_name"}
                    component="div"
                    className="error"
                  />
                  <datalist id="user_name" className="data-array">
                    {searchData.map((itm, index) => {
                      return (
                        <option
                          value={`${itm.name}-${itm.mobile_no}-${itm.pan}-${itm.user_role_id}-${itm.role_id}`}
                          key={index}
                          className="data-wrapper"
                        >
                          {itm.name}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
              </div>
            </div>
            <div className="custom-card-background note-area">
              <div className="text-area-section">
                <label className="input-label">Customer Notes</label>
                <div>
                  <textarea
                    className={`${errors.customer_notes && touched.customer_notes
                        ? "input-field error-input"
                        : "input-field text-area-field"
                      }`}
                    rows={3}
                    placeholder="Enter here"
                    {...getFieldProps("customer_notes")}
                  />
                  <ErrorMessage
                    name={"customer_notes"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-space">
          <div className="d-end">
            <div>
              <button className="light-secondary mr-16" type="button">
                Cancel
              </button>
            </div>
            <div>
              <button
                className="main-button"
                type="submit"
                disabled={
                  (isValid ? false : true) ||
                  (values.role_id === 0 ||
                    values.bill_details[0].product_id === 0
                    ? true
                    : false)
                }
                onClick={() => {
                  // setPopupType("receipt");
                  openModal();
                  if (values.bill_details.length > 0) {
                    values.bill_details.map((element) => {
                      if (element.qty.toString() === "") {
                        toast.error("Please enter product quantity");
                        return 0;
                      }
                      if (element.rate.toString() === "") {
                        toast.error("Please enter rate");
                        return 0;
                      }
                      if (element.free_qty.toString() === "") {
                        toast.error("Please enter free quantity");
                        return 0;
                      }
                      if (element.qty < element.free_qty) {
                        toast.error(
                          "Free quantity can't be more than quantity amount"
                        );
                        return 0;
                      }
                    });
                  }
                  // if (values.bank.length > 0) {
                  //   const err: any = [];
                  //   values.bank.forEach((element) => {
                  //     if (element.amount > 0) {
                  //       if (element.bank_id === 0) {
                  //         toast.error("Bank name is required");
                  //       } else if (element.cheque_issue_date === "") {
                  //         toast.error("Please select issue date");
                  //       } else if (element.cheque_no === "") {
                  //         toast.error("Please provide cheque number");
                  //       }
                  //     } else if (
                  //       element.bank_id > 0 ||
                  //       element.cheque_issue_date !== "" ||
                  //       element.amount > 0 ||
                  //       element.cheque_no !== ""
                  //     ) {
                  //       toast.error("Please provide bank amount");
                  //     } else if (
                  //       element.bank_id === 0 &&
                  //       element.cheque_issue_date === "" &&
                  //       element.amount === 0 &&
                  //       element.cheque_no === ""
                  //     ) {
                  //       setFieldValue("bank", err);
                  //       return err;
                  //     }
                  //   });
                  // }
                  handleSubmit();
                }}
              >
                Create Bill
              </button>
            </div>
          </div>
        </div>

        {resStatus && (
          <PopUp
            popupType="full"
            popupWidth={"100%"}
            showHeader={false}
            popupHeight={"100%"}
            renderComponent={<Receipt />}
          />
        )}
      </div>
    </div>
  );
};
