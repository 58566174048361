import { ErrorMessage, Field, Form, Formik, FormikConfig } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { DontShowEye, LockIcon, ShowEye } from "../../assets/images/svg/svg";
import { UpdatePassowrd } from "../Api/Post/PostApi";
import { PopupContext } from "../PopUp/Popup-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import "./ConfirmPw.scss";

interface ConfirmPwProps {
  id?: number;
  reqOldPassword?: boolean;
  activeStaff?: string;
  isStaff?: boolean;
}
const initialValues = {
  old_password: "",
  password: "",
  confirm_password: "",
};
const validationSchema = Yup.object({
  old_password: Yup.string().required("Please enter the old password"),
  password: Yup.string().required("Please enter the password"),
  confirm_password: Yup.string()
    .required("Please Enter Password")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const validationSchema2 = Yup.object({
  password: Yup.string().required("Please enter the password"),
  confirm_password: Yup.string()
    .required("Please Enter Password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const ConfirmPw: React.FC<ConfirmPwProps> = ({
  id,
  activeStaff,
  reqOldPassword = false,
  isStaff,
}) => {
  const { showErrorMessage, showSuccessMessage } = useToast();
  const { closeModal } = useContext(PopupContext);
  const [handleShow, setHandleShow] = useState({
    old_password: false,
    password: false,
    confirm_password: false,
  });

  const handleSubmit: FormikConfig<{
    old_password: string;
    password: string;
    confirm_password: string;
  }>["onSubmit"] = (values, { setSubmitting }) => {
    setSubmitting(true);
    const postData = { ...values };
    UpdatePassowrd(postData, id as number, isStaff as boolean)
      .then((res) => {
        setSubmitting(false);
        showSuccessMessage(`Successfully changed password  for ${activeStaff}`);
        closeModal();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to Login !!";
        if (err?.response?.data?.status?.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        showErrorMessage(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const hendlePasswordToggle = (key: keyof typeof handleShow) => {
    setHandleShow((p) => ({ ...p, [key]: !handleShow[key] }));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={reqOldPassword ? validationSchema : validationSchema2}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="confirm-pw-page">
          <div className="top-section">
            <div className="big-ellipse">
              <LockIcon />
            </div>
          </div>
          <h2 className="small-title">Change Password  {activeStaff ? `for ${activeStaff}` : ''}</h2>

          <div className="row">
            {reqOldPassword && (
              <div className="col-md-12">
                <div className="fix-field">
                  <label className="input-label">Old Password</label>
                  <Field
                    name="old_password"
                    className="input-field"
                    placeholder="Enter your Password"
                    type={handleShow.old_password ? "text" : "password"}
                  />
                  <div
                    className="eye-section"
                    onClick={() => {
                      hendlePasswordToggle("old_password");
                    }}
                  >
                    {handleShow.old_password ? <ShowEye /> : <DontShowEye />}
                  </div>
                  <ErrorMessage
                    name="old_password"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            )}

            <div className="col-md-12">
              <div className="fix-field">
                <label className="input-label">New Password</label>
                <Field
                  autoComplete="off"
                  name="password"
                  className="input-field"
                  placeholder="Enter your Password"
                  type={handleShow.password ? "text" : "password"}
                />
                <div
                  className="eye-section"
                  onClick={() => {
                    hendlePasswordToggle("password");
                  }}
                >
                  {handleShow.password ? <ShowEye /> : <DontShowEye />}
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="mg-btm-10"></div>
            <div className="col-md-12">
              <div className="fix-field">
                <label className="input-label">Confirm Password</label>
                <Field
                  name="confirm_password"
                  className="input-field"
                  placeholder="Confirm your Password"
                  type={handleShow.confirm_password ? "text" : "password"}
                />
                <div
                  className="eye-section"
                  onClick={() => {
                    hendlePasswordToggle("confirm_password");
                  }}
                >
                  {handleShow.confirm_password ? <ShowEye /> : <DontShowEye />}
                </div>
                <ErrorMessage
                  name="confirm_password"
                  component="div"
                  className="error"
                />
              </div>
            </div>
          </div>
          <div className="btn-section">
            <div>
              <button
                className="light-secondary mr-16"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
            </div>
            <button className="main-button" type="submit">
              Save
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
