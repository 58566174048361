import React, { useState, useEffect } from "react";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import { BatchInfoResponse } from "../ProductDetails/ProductDetails";
import { CustomerData } from "../ReciptSlip/ReciptSlip";
import * as languages from "../../Language/language";

export interface PatronInfoResponse {
  address: { address: string; city: string };
  created_at: string;
  id: number;
  mobile_no: string;
  name: string;
  pan: null | string | number;
  role_id: number;
  role_name: string;
  user_role_id: number;
}

export interface SearchString {
  title: string;
}

interface DocumentContextType {
  documentNumber: string;
  setDocumentNumber: React.Dispatch<React.SetStateAction<string>>;
  customerId: number | undefined;
  setCustomerId: React.Dispatch<React.SetStateAction<number | undefined>>;
  resStatus?: boolean;
  setResStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  patronInfo: PatronInfoResponse;
  setPatronInfo: React.Dispatch<React.SetStateAction<PatronInfoResponse>>;
  searchStrings: SearchString[];
  setSearchStrings: React.Dispatch<React.SetStateAction<SearchString[]>>;
  setSelectedUserObj: React.Dispatch<
    React.SetStateAction<CustomerData | undefined>
  >;
  selectedVendorObj: CustomerData | undefined;
  setSelectedVendorObj: React.Dispatch<
    React.SetStateAction<CustomerData | undefined>
  >;

  selectedUserObj: CustomerData | undefined;
  payslipSearchString: string;
  setPayslipSearchString: React.Dispatch<React.SetStateAction<string>>;
  showCreditNote: boolean;
  setShowCreditNote: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitted: boolean;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPatron: SelectOptions | null;
  setSelectedPatron: React.Dispatch<React.SetStateAction<SelectOptions | null>>;
  selectedAPIType: string | null;
  setSelectedAPIType: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  selectedVendor: SelectOptions | null;
  setSelectedVendor: React.Dispatch<React.SetStateAction<SelectOptions | null>>;
  langData: any,
  language: string
  setReceiptNumber: React.Dispatch<React.SetStateAction<string>>;
  receiptNumber: string;
  setShowReceipt: React.Dispatch<React.SetStateAction<boolean>>;
  showReceipt: boolean;
}

const initialValues = {
  documentNumber: "",
  setDocumentNumber: () => {},
  customerId: undefined,
  setCustomerId: () => {},
  resStatus: false,
  setResStatus: () => {},
  patronInfo: {},
  setPatronInfo: () => {},
  selectedUserObj: {},
  setSelectedUserObj: () => {},
  selectedVendorObj: {},
  setSelectedVendorObj: () => {},
  showCreditNote: null,
  setShowCreditNote: () => {},
  isSubmitted: null,
  setIsSubmitted: () => {},
  selectedPatrin: {},
  setSelectedPatron: () => {},
  langData: languages["en"],
  language: "",
  receiptNumber: "",
  setReceiptNumber: () => {},
  showReceipt: false,
  setShowReceipt: () => {},
};
interface DocumentProps {
  children: any;
}

export const DocumentContext = React.createContext<DocumentContextType>(
  //@ts-ignore
  initialValues
);

export type ProdBatches = {
  [key: string]: BatchInfoResponse[];
};

export const DocumentContextProvider: React.FC<DocumentProps> = ({
  children,
}) => {
  const [documentNumber, setDocumentNumber] = React.useState("");
  const [customerId, setCustomerId] = useState<number>();
  const [resStatus, setResStatus] = React.useState(false);
  const [patronInfo, setPatronInfo] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  //searchString state is initialized for holding the state of input string in react autocomplete search of productinfo
  const [searchStrings, setSearchStrings] = useState<SearchString[]>([
    {
      title: "",
    },
  ]);
  const [payslipSearchString, setPayslipSearchString] = useState("");
  const [selectedUserObj, setSelectedUserObj] = useState<CustomerData>();
  const [selectedVendorObj, setSelectedVendorObj] = useState<CustomerData>();
  const [showCreditNote, setShowCreditNote] = useState(false);
  const [selectedPatron, setSelectedPatron] = useState<SelectOptions | null>(
    null
  );
  const [selectedVendor, setSelectedVendor] = useState<SelectOptions | null>(
    null
  );
  const [selectedAPIType, setSelectedAPIType] = useState<string  | null>("");
  const [language, setLanguage] = useState("");

  useEffect(() => {
    const value = localStorage.getItem("language") || "en";
    setLanguage(value);
  }, []);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [showReceipt, setShowReceipt] = useState(false);
  //   const getDocumentNumber = (val: number) => {
  //     setDocumentNumber(val);
  //   };

  return (
    <DocumentContext.Provider
      value={{
        searchStrings,
        setSearchStrings,
        setDocumentNumber,
        documentNumber,
        customerId,
        setCustomerId,
        resStatus,
        setResStatus,
        setPatronInfo,
        //@ts-ignore
        patronInfo,
        setSelectedUserObj,
        selectedUserObj,
        payslipSearchString,
        setPayslipSearchString,
        showCreditNote,
        setShowCreditNote,
        isSubmitted,
        setIsSubmitted,
        selectedPatron,
        setSelectedPatron,
        selectedVendor,
        setSelectedVendor,
        selectedAPIType,
        setSelectedAPIType,
        selectedVendorObj,
        setSelectedVendorObj,
        //@ts-ignore
        langData: languages[language],
        language,
        receiptNumber,
        setReceiptNumber,
        showReceipt,
        setShowReceipt,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
