import { useState } from "react";
import { useQuery } from "react-query";
import { BillReturnType } from "../../../EnumValues";
import { SearchHelpers } from "../../../utils/services";
import { BusinessValue } from "../../BusinessInfoForm/BusinessInfoWrapper";
import { useToast } from "../../context/ToasterContext/ToasterContext";
import { Axios } from "../../lib/Axios";
import { ManualVoucherResponse } from "../../ManualVoucherReport/ManualVoucherReport";
import { RoleDataProps } from "../../NewCustomerModal/NewCustomerModal";
import { IReceipt } from "../../Receipt/Receipt";
import { CustomerData } from "../../ReciptSlip/ReciptSlip";
import { FilterDate } from "../../SalesList/SalesListPage";
import { SettingPageValues } from "../../SettingPage/SettingPageWrapper/SettingPageWrapper";
import { EditLedgerData } from "../Post/PostApiTypes";
import {
  BillDetailResponse,
  BillNumberResponse,
  CategoryResponse,
  ChequeListResponse,
  CreditorListResponse,
  CustomerListResponse,
  DashboardSummaryResponse,
  IOpeningProductList,
  IVendorListGroup,
  IVendorProductListGroup,
  LedgerListResponse,
  Pagination,
  PartyWiseLedgerResponse,
  ReturnCustomersResponse,
  SalesDetailDataResponse,
  StaffListResponse,
  StatusResponse,
  VandorListResponse,
  AnusuchiListResponse,
  StaffDetailResponse,
  LedgerWiseResponse,
} from "../types";
import {
  AuditTrialResponse,
  BillCancelListResponse,
  DashBoardChartResponse,
  IReceiptData,
  LedgerHeadResponse,
  PatronListResponse,
  ProductOptionsResponse,
  PurchaseReturnDataResponse,
  SalesBankNames,
  SalesReturnDataResponse,
  SalesReturnInvoiceResponse,
  Search,
  ILedgerListResponse,
  StockDetailResponse,
  IOpeningStockDetail,
  UpdatedOpeningBalanceDetail,
  ISpecificOpeningStockDetail,
  TradingAccountList,
  ProfitLossList,
  BalanceSheetData,
  TrialBalanceResponce,
  ISpecificCustomerResponse,
  IPatronTransactionResponse,
  IRemaindeNoticeResponse,
  IJournalEntryResponse,
  IVatReport,
  ManualVoucherCustomerResponse,
  ICashFlowList,
  LedgerClosingBalanceResponse,
} from "./GetApiTypes";

export const GetPatronRoles = (isOnlyPatron: boolean) => {
  const apiCall = isOnlyPatron
    ? "/api/v1/role/customer-role-list?is_only_patron=1"
    : "/api/v1/role/customer-role-list";
  return Axios.get(apiCall).then((res) => res.data.data);
};

export const usePatronRoles = ({ enabled = true, isOnlyPatron = true }) => {
  const { showErrorMessage } = useToast();
  const apiTitle = isOnlyPatron
    ? `/api/v1/role/customer-role-list?is_only_patron=1`
    : `/api/v1/role/customer-role-list`;
  return useQuery<RoleDataProps[]>(
    apiTitle,
    () => GetPatronRoles(isOnlyPatron),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch  patron roles");
      },
      enabled: enabled,
    }
  );
};

export const GetCustomerSearch = (
  searchTerm: any,
  data: any,
  transactionId: number
) => {
  return Axios.get(
    `/api/v1/customer/search-many-field/${transactionId}?${searchTerm}=${data}`
  );
};

export const GetPurchaseProductSearch = (roleId: any, productName: any) => {
  return Axios.get(
    `/api/v1/product_service/product/product-search-with-role-purchase/${roleId}?${"product_name"}=${productName}`
  );
};

export const GetSaleProductSearch = (
  roleId: any,
  productName: any,
  productId?: any
) => {
  return Axios.get(
    `/api/v1/product_service/product/product-search-with-role-sale/${roleId}?${"product_name"}=${productName}&product_id=${productId}`
  );
};

export const GetBankName = () => {
  return Axios.get("/api/v1/product_service/bank-list");
};

export const GetAllBanks = () => {
  return Axios.get("/api/v1/product_service/bank-list").then(
    (res) => res.data.data
  );
};

//As this is only used for the sales return

export const useAllBanks = (
  returnType: BillReturnType = BillReturnType.Sales
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{ id: number; name: string }[]>(
    "/api/v1/product_service/bank-list",
    () => GetAllBanks(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to  bank names");
      },
      enabled: Boolean(returnType === BillReturnType.Sales),
    }
  );
};

export const GetBillInvoice = (document_number: any) => {
  return Axios.get(
    `/api/v1/product_service/transaction/bill_invoice/${document_number}`
  ).then((res) => res.data.data);
};
export const useGetBillInvoice = (documentNumber = "") => {
  const { showErrorMessage } = useToast();

  return useQuery<IReceipt>(
    ["bill_invoice", documentNumber],
    () => GetBillInvoice(documentNumber),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch invoice data");
      },
      enabled: Boolean(documentNumber !== ""),
      staleTime: 0, // Data is considered stale immediately
      cacheTime: 0, // Data is never cached
    }
  );
};

export const SignIn = () => {
  return Axios.get("/api/v1/user/session");
};

export const SignOut = () => {
  return Axios.get("/api/v1/user/session/destroy");
};

export const GetSalesBankName = () => {
  return Axios.get("/api/v1/product_service/sales/bank-list");
};

export const UpdatedSalesBankNames = () => {
  return Axios.get("/api/v1/product_service/sales/bank-list").then(
    (res) => res.data.data
  );
};

//This is used in sales return

export const useSalesBankNames = (
  returType: BillReturnType = BillReturnType.Purchase
) => {
  const { showErrorMessage } = useToast();
  return useQuery<SalesBankNames[]>(
    "Sales-Bank-Names",
    () => UpdatedSalesBankNames(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch sales bank list");
      },
      enabled: Boolean(returType === BillReturnType.Purchase),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const ProductEdit = (product_price_id: any, data: any) => {
  return Axios.put(
    `/api/v1/product_service/product/role/update/${product_price_id}`,
    data
  );
};

export const GetCustomerList = (customerName: string) => {
  return Axios.get(
    `/api/v1/product_service/payslip/customer-search?${"customer_name"}=${customerName}`
  );
};

const GetVendorList = () => {
  return Axios.get("/api/v1/common_api/vendor-list").then((res) => res.data);
};

export const useVendorList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<IVendorListGroup>(
    "/api/v1/common_api/vendor-list",
    () => GetVendorList(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch vendor list");
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetVenderProductList = () => {
  return Axios.get(`/api/v1/product_service/vendor-product-list`).then(
    (res) => res.data
  );
};

export const useVenderProductList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<IVendorProductListGroup>(
    "/api/v1/product_service/vendor-product-list",
    () => GetVenderProductList(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch  product list");
      },
    }
  );
};

export const GetOpeningProductList = () => {
  return Axios.get(
    `/api/v1/product_service/opening/stock-vendor-product-list`
  ).then((res) => res.data.data);
};

export const useOpeningProductList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<IOpeningProductList[]>(
    "/api/v1/product_service/opening/stock-vendor-product-list",
    () => GetOpeningProductList(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch  product list");
      },
    }
  );
};

// api/v1/product_service/vendor-product-list
export const GetCustomerLedger = (
  user_role_id: number,
  page_num: number,
  page_size: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/payslip/user/transaction-list/${user_role_id}?page_num=${page_num + 1
    }
    &page_size=${page_size}&date_from=${date.dateFrom}&date_to=${date.dateTo}`
  ).then((res) => res.data);
};

export const useCustomerLedger = (
  user_role_id: number,
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();

  return useQuery(
    ["ledger", user_role_id, pageIndex, pageSize, date.dateTo, date.dateFrom],
    () => GetCustomerLedger(user_role_id, pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch ledger ");
      },
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(user_role_id) ||
        Boolean(date?.dateTo) ||
        Boolean(date?.dateFrom),
    }
  );
};

export const GetVendorLedger = (
  user_role_id: number,
  page_num: number,
  page_size: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/payment-voucher/vendor/transaction-list/${user_role_id}?page_num=${page_num + 1
    }
    &page_size=${page_size}&date_from=${date.dateFrom}&date_to=${date.dateTo}`
  ).then((res) => res.data);
};

export const useVendorLedger = (
  user_role_id: number,
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();

  return useQuery(
    ["ledger", user_role_id, pageIndex, pageSize, date.dateTo, date.dateFrom],
    () => GetVendorLedger(user_role_id, pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch ledger ");
      },
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(user_role_id) ||
        Boolean(date?.dateTo) ||
        Boolean(date?.dateFrom),
    }
  );
};

export interface ProfileData extends BusinessValue { }

export const GetBusinessData = () => {
  return Axios.get("/api/v1/business/show").then((res) => res.data.data);
};

export const useBusinessData = () => {
  const { showErrorMessage } = useToast();
  return useQuery<ProfileData>(["api/v1/business/show"], GetBusinessData, {
    onError: (err: any) => {
      showErrorMessage("Failed to fetch busoness profile");
    },
    refetchOnMount: true,
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const ShowCustomerList = () => {
  return Axios.get(`/api/v1/customer/list?page_num=1&page_size=1000`).then(
    (res) => res.data
  );
};

export const useAllCustomerList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<IVendorListGroup>(
    "/api/v1/customer/list",
    () => ShowCustomerList(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch vendor list");
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetPaginatedPatronList = (
  pageIndex: number,
  pageSize: number,
  name: string,
  mobile_no: string
) => {
  return Axios.get(
    `/api/v1/customer/list?page_num=${pageIndex + 1}&page_size=${pageSize}&mobile_no=${mobile_no}
  &name=${name.toLowerCase()}`
  ).then((res) => res.data);
};

export const usePatronList = (pageIndex = 0, pageSize = 10, name = "", mobile_no = "") => {
  const { showErrorMessage } = useToast();

  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: PatronListResponse[];
  }>(
    ["customer-lists", pageIndex, pageSize, name, mobile_no],
    () => GetPaginatedPatronList(pageIndex, pageSize, name, mobile_no),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch customer lists");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize) || Boolean(name),
    }
  );
};

export const ShowProductList = () => {
  return Axios.get("/api/v1/product_service/product/list");
};

export const GetProductList = (
  pageIndex: number,
  pageSize: number,
  // name: string,
  search?: Search,
  categoryId?: number
) => {
  return Axios.get(
    `/api/v1/product_service/product/list?page_num=${pageIndex + 1
    }&page_size=${pageSize}
    &product_name=${SearchHelpers(search?.product_name, "Lower")}${categoryId ? `&product_category_id=${categoryId}` : ""
    }`
  ).then((res) => res.data);
};

//Default value is given for refetch logic
export const useProductList = (
  pageIndex = 0,
  pageSize = 10,
  search: Search,
  categoryId: number | undefined
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["productLists", pageIndex, pageSize, search, categoryId],
    () => GetProductList(pageIndex, pageSize, search, categoryId),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch product lists");
      },
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(search.product_name) ||
        Boolean(categoryId),
    }
  );
};

//Pageindex 1 is done cause pageIndex props will always be 0 and api pagindex start from 1
export const GetPurchaseList = (
  pageIndex: number,
  pageSize: number,
  search: Search,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/purchase-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}&vendor_name=${SearchHelpers(
      search.vendor_name,
      "Lower"
    )}&bill_no=${SearchHelpers(search.bill_number, "Upper")}&date_from=${date.dateFrom || ""
    }&date_to=${date.dateTo || ""}`
  ).then((res) => res.data);
};

export const usePurchaseList = (
  pageIndex = 0,
  pageSize = 10,
  search: Search,
  date = {
    dateFrom: "",
    dateTo: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["purchaselists", pageIndex, pageSize, search, date],
    () => GetPurchaseList(pageIndex, pageSize, search, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch purhcase lists");
      },
      enabled:
        Boolean(search && search.bill_no) ||
        Boolean(search && search.vendor_name) ||
        date["dateFrom"] != "" && date["dateTo"] != "",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetSalesList = (
  pageIndex: number,
  pageSize: number,
  search: Search,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/sale-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}&customer_name=${SearchHelpers(
      search.customer_name,
      "Lower"
    )}&document_no=${SearchHelpers(search.document_no, "Upper")}&date_from=${date.dateFrom ? date.dateFrom : ""
    }&date_to=${date.dateTo ? date.dateTo : ""}`
  ).then((res) => res.data);
};

export const useSalesList = (
  pageIndex = 0,
  pageSize = 10,
  search: Search,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["saleslists", pageIndex, pageSize, search, date],
    () => GetSalesList(pageIndex, pageSize, search, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch sale lists");
      },
      enabled:
        Boolean(search && search.customer_name) ||
        Boolean(search && search.document_no) ||
        date["dateFrom"] != "" && date["dateTo"] != "",
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetSubManualVoucher = (documentNumber: number | string) => {
  return Axios.get(
    `api/v1/product_service/transaction/manual-voucher-invoice/${documentNumber}`
  ).then((res) => res.data);
};

export const useSubManualVoucher = (documentNumber: number | string) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["subManualVoucher", documentNumber],
    () => GetSubManualVoucher(documentNumber),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch manual voucher data");
      },
      enabled: Boolean(documentNumber),
    }
  );
};

export const GetManualVoucheList = (
  pageIndex: number,
  pageSize: number,
  search: Search,
  date: FilterDate
) => {
  return Axios.get(
    `api/v1/product_service/transaction-report/manual-voucher-list?date_from=${date.dateFrom ? date.dateFrom : ""
    }&date_to=${date.dateTo ? date.dateTo : ""}&page_num=${pageIndex + 1
    }&page_size=${pageSize}&document_no=${SearchHelpers(
      search.invoice_no,
      "Upper"
    )}&customer_name=${SearchHelpers(search.customer_name, "Lower")}${search.pan ? `&pan=${SearchHelpers(search.pan, "Lower")}` : ""
    }&mobile_no=${SearchHelpers(search.mobile_no, "Lower")}`
  ).then((res) => res.data);
};

export const useManualVoucheList = (
  pageIndex = 0,
  pageSize = 10,
  search: Search,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: ManualVoucherResponse[];
  }>(
    ["manualVoucheLists", pageIndex, pageSize, search, date],
    () => GetManualVoucheList(pageIndex, pageSize, search, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch Manual Voucher Report");
      },
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(search) ||
        Boolean(date),
    }
  );
};

export const GetStockList = (
  pageIndex: number,
  pageSize?: number,
  roleId?: number,
  search?: Search,
  quantityId?: number,
  categoryId?: number
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/stock-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}&role_id=${roleId}&product_name=${SearchHelpers(
      search?.product_name,
      "Lower"
    )}${categoryId ? `&product_category_id=${categoryId}` : ""
    }&quantity_type=${quantityId}`
  ).then((res) => res.data);
};

export const useStockList = (
  pageIndex = 0,
  pageSize = 10,
  roleId = 6,
  search: Search,
  quantityId: number | undefined,
  categoryId: number | undefined
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["stocklists", pageIndex, pageSize, search],
    () =>
      GetStockList(pageIndex, pageSize, roleId, search, quantityId, categoryId),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch stocklists lists");
      },
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(search) ||
        Boolean(roleId) ||
        Boolean(quantityId),
    }
  );
};

export const GetProductDetail = (prodId: number) => {
  return Axios.get(`/api/v1/product_service/product/show/${prodId}`);
};

export const GetProducts = (prodName: string) => {
  return Axios.get(
    `/api/v1/product_service/product/search?product_name=${prodName}`
  );
};

/// Daily Sales Report

export const GetDailySalesReport = (
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/daily-sale-report?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1}&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const useDailySalesReport = (
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["daily-sales-report", pageIndex, pageSize, date],
    () => GetDailySalesReport(pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily sales lists");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize) || Boolean(date),
    }
  );
};

//Materialized View

export const GetCbmsData = (
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/cbms-data-report?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1}&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const useCbmsData = (
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    ["cbms-report", pageIndex, pageSize, date],
    () => GetCbmsData(pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch cbms reports ");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize) || Boolean(date),
    }
  );
};

export const GetUserList = () => {
  return Axios.get("api/v1/role/business-role-list").then((res) => res.data);
};

export const useUserList = () => {
  const { showErrorMessage } = useToast();
  return useQuery(["user-list"], () => GetUserList(), {
    onError: (err: any) => {
      showErrorMessage("Failed to fetch user list");
    },
  });
};

export const GetSalesDetailReport = (
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/sales-detail-report?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1}&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const useSalesDetailReport = (
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: SalesDetailDataResponse;
  }>(
    ["sales-detail-report", pageIndex, pageSize, date],
    () => GetSalesDetailReport(pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily sales lists");
      },
      enabled: (date["dateTo"] != "" && date["dateFrom"] !== ""),
    }
  );
};

export const GetPartyLedger = (
  pageIndex: number,
  pageSize: number,
  date: FilterDate,
  id: number
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/partywise-ledger?customer_id=${id}&date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1}&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const usePartyLedger = (
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  },
  id: number | undefined
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: PartyWiseLedgerResponse[];
  }>(
    ["party-wise-ledger", pageIndex, pageSize, date, id],
    () => {
      if (id !== undefined) {
        return GetPartyLedger(pageIndex, pageSize, date, id as number);
      }
      return {
        status: {} as StatusResponse,
        pagination: {} as Pagination,
        data: [] as PartyWiseLedgerResponse[],
      };
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch party ledger data");
      },
      enabled:
        Boolean(id !== undefined) &&
        (Boolean(pageIndex) || Boolean(pageSize) || Boolean(date)),
      refetchOnMount: false,
      retry: 2,
    }
  );
};

export const GetCustomerOptions = () => {
  return Axios.get("/api/v1/common_api/customer-list").then((res) => res.data);
};

export const useCustomerOptions = () => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    data: CustomerListResponse[];
  }>(["customer-options"], () => GetCustomerOptions(), {
    onError: (err: any) => {
      showErrorMessage("Failed to fetch daily customer lists");
    },
    refetchOnMount: true,
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const GetVendorOptions = () => {
  return Axios.get("/api/v1/common_api/vendor-list").then((res) => res.data);
};

export const useVendorOptions = () => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    data: VandorListResponse[];
  }>(["customer-options"], () => GetVendorOptions(), {
    onError: (err: any) => {
      showErrorMessage("Failed to fetch daily customer lists");
    },
  });
};

export const GetStaffList = () => {
  return Axios.get("/api/v1/user/business-user-list").then((res) => res.data);
};

export const useStaffList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    data: StaffListResponse[];
  }>(["staff-list"], () => GetStaffList(), {
    onError: (err: any) => {
      showErrorMessage("Failed to fetch staff lists");
    },
  });
};

export const GetStaffDetail = (id: number) => {
  return Axios.get(`/api/v1/user/show/${id}`).then((res) => res.data.data);
};

export const useStaffDetail = (id: number | undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<StaffDetailResponse>(
    ["staff-detail", id],
    () => GetStaffDetail(id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch staffdetail");
      },
      enabled: Boolean(id !== undefined),
    }
  );
};

//DashBoard Apis

export const GetDashboardSummary = () => {
  return Axios.get("/api/v1/dashboard/summary-data").then(
    (res) => res.data.data
  );
};

export const useDashboardSummary = () => {
  const { showErrorMessage } = useToast();
  return useQuery<DashboardSummaryResponse>(
    ["api/v1/dashboard/summary-data"],
    () => GetDashboardSummary(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch dashboard summary");
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const GetCreditorList = (pageIndex: number, pageSize: number) => {
  return Axios.get(
    `/api/v1/product_service/dashboard/purchase/payable-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const useCreditorList = (pageIndex = 0, pageSize = 10) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: CreditorListResponse[];
  }>(
    ["dashboard/purchase/payable-list", pageIndex, pageSize],
    () => GetCreditorList(pageIndex, pageSize),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily creditor lists");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize),
      refetchOnWindowFocus: false,
    }
  );
};

export const GetDebtorList = (pageIndex: number, pageSize: number) => {
  return Axios.get(
    `/api/v1/product_service/dashboard/sales/receivable-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const useDebtorList = (pageIndex = 0, pageSize = 10) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: CreditorListResponse[];
  }>(
    ["/dashboard/sales/receivable-list", pageIndex, pageSize],
    () => GetDebtorList(pageIndex, pageSize),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily creditor lists");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize),
      refetchOnWindowFocus: false,
    }
  );
};

export const GetCategoryTableList = (
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/product-category/list?page_num=${pageIndex + 1
    }&page_size=${pageSize}&category_name=`
  ).then((res) => res.data);
};
export const useCategoryTableList = (pageIndex = 0, pageSize = 10) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: ChequeListResponse[];
  }>(
    ["category-lists", pageIndex, pageSize],
    //@ts-ignore
    () => GetCategoryTableList(pageIndex, pageSize),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily sales lists");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize),
    }
  );
};
/// remove date because date is not set anywhere
export const GetChequeList = (pageIndex: number, pageSize: number) => {
  return Axios.get(
    `/api/v1/product_service/dashboard/sales/cheque-issue-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}
  `
  ).then((res) => res.data);
};
export const useChequeList = (pageIndex = 0, pageSize = 10) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: ChequeListResponse[];
  }>(
    ["cheque-lists", pageIndex, pageSize],
    () => GetChequeList(pageIndex, pageSize),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily sales lists");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize),
      refetchOnWindowFocus: false,
    }
  );
};

export const GetLedgerHeadList = (
  pageIndex: number,
  pageSize: number,
  search?: Search
) => {
  return Axios.get(
    `api/v1/product_service/ledger/list?page_num=${pageIndex + 1
    }&page_size=${pageSize}&ledger_name=${SearchHelpers(
      search?.ledger_name,
      "Lower"
    )}`
  ).then((res) => res.data);
};

export const useLedgerHeadList = (
  pageIndex = 0,
  pageSize = 100,
  search: Search
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: LedgerListResponse[];
  }>(
    ["ledger-head", pageIndex, pageSize, search],
    () => GetLedgerHeadList(pageIndex, pageSize, search),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize) || Boolean(search),
    }
  );
};

//Sales Return Apis

//This api is not in use for now

export const GetReturnCustomers = (name: string | undefined) => {
  return Axios.get(
    `/api/v1/product_service/transaction/sales-return-customer-search?customer_name=${name}`
  ).then((res) => res.data.data);
};

export const useGetReturnCustomers = (name = "") => {
  const { showErrorMessage } = useToast();
  return useQuery<ReturnCustomersResponse[]>(
    ["sales-return-customers", name],
    () => GetReturnCustomers(name),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily return customer lists");
      },
      enabled: Boolean(name !== "" || name !== undefined),
    }
  );
};

//Sales Customer
export const GetBillNumber = (customer_id: number | undefined) => {
  return Axios.get(
    `/api/v1/product_service/transaction/sales-return-customer-search?customer_id=${customer_id}`
  ).then((res) => res.data.data);
};

export const useBillNumber = (id: number | undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<BillNumberResponse[]>(
    ["bill-numbers", id],
    () => GetBillNumber(id),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
      enabled: Boolean(id !== undefined),
      refetchOnWindowFocus: false,
    }
  );
};

//Purchase Clients
//This a

//Purchase Clients bills

export const GetPurchaseBillNumber = (vendor_id: number | undefined) => {
  return Axios.get(
    `/api/v1/product_service/transaction/purchase-return/vendor-search-bill?vendor_id=${vendor_id}`
  ).then((res) => res.data.data);
};

export const usePurchaseBillNumber = (id: number | undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<BillNumberResponse[]>(
    ["bill-numbers", id],
    () => GetPurchaseBillNumber(id),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
      enabled: Boolean(id !== undefined),
      refetchOnWindowFocus: false,
    }
  );
};

export const GetLedgerHead = () => {
  return Axios.get(`api/v1/product_service/ledger/ledger-head-list`).then(
    (res) => res.data.data
  );
};

export const useLedgerHead = () => {
  const { showErrorMessage } = useToast();
  return useQuery<Array<LedgerHeadResponse>>(
    ["ledger-head"],
    () => GetLedgerHead(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
    }
  );
};

export const GetSpecificLedgerHead = (id: number) => {
  return Axios.get(`/api/v1/product_service/ledger/show/${id}`).then(
    (res) => res.data.data
  );
};

export const useSpecificLedgerHead = (id: number | undefined = undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<EditLedgerData>(
    ["specific-ledger", id],
    () => GetSpecificLedgerHead(id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch ledger data");
      },
      enabled: Boolean(id !== undefined),
    }
  );
};

export const GetCategoryList = () => {
  return Axios.get(`/api/v1/product_service/product-category-list`).then(
    (res) => res.data.data
  );
};

export const useCategories = () => {
  const { showErrorMessage } = useToast();
  return useQuery<CategoryResponse[]>(
    ["categories"],
    //@ts-ignore
    () => GetCategoryList(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
    }
  );
};

export const GetBillDetails = (customer_id: number, bill_id: number) => {
  return Axios.get(
    `api/v1/product_service/transaction/sales-return-bill-detail?customer_id=${customer_id}&bill_id=${bill_id}`
  ).then((res) => res.data.data);
};

export const useBillDetail = (
  customer_id: number | undefined,
  bill_id: number | undefined,
  returnType: BillReturnType = BillReturnType.Sales
) => {
  const { showErrorMessage } = useToast();
  return useQuery<BillDetailResponse>(
    ["bill-detail", customer_id, bill_id],
    () => GetBillDetails(customer_id as number, bill_id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill details");
      },
      enabled:
        Boolean(customer_id !== undefined) &&
        Boolean(bill_id !== undefined) &&
        Boolean(returnType === BillReturnType.Sales),
      refetchOnWindowFocus: false,
    }
  );
};

export const GetPurchaseReturnDetails = (
  vendor_id: number,
  bill_id: number
) => {
  return Axios.get(
    `/api/v1/product_service/transaction/purchase-return/bill-detail?bill_id=${bill_id}`
  ).then((res) => res.data.data);
};

export const usePurchaseReturnDetail = (
  customer_id: number | undefined,
  bill_id: number | undefined,
  returnType: BillReturnType = BillReturnType.Purchase
) => {
  const { showErrorMessage } = useToast();
  return useQuery<BillDetailResponse>(
    ["bill-detail", customer_id, bill_id],
    () => GetPurchaseReturnDetails(customer_id as number, bill_id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch purchase return bill details");
      },
      enabled:
        Boolean(customer_id !== undefined) &&
        Boolean(bill_id !== undefined) &&
        Boolean(returnType === BillReturnType.Purchase),
      refetchOnWindowFocus: false,
    }
  );
};

//Sales Return Invoice
export const GetSalesReturnInvoice = (bill_number: string) => {
  return Axios.get(
    `/api/v1/product_service/transaction/sale-return-invoice/${bill_number}`
  ).then((res) => res.data.data);
};

export const useSalesReturnInvoice = (bill_number: string | undefined = "") => {
  const { showErrorMessage } = useToast();
  return useQuery<SalesReturnInvoiceResponse>(
    ["sales-return-invoice", bill_number],
    () => GetSalesReturnInvoice(bill_number as string),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill details");
      },

      enabled:
        Boolean(bill_number !== undefined) && Boolean(bill_number !== ""),
    }
  );
};

//Purchase Return Invoice

export const GetPurchaseReturnInvoice = (bill_number: string) => {
  return Axios.get(
    `/api/v1/product_service/transaction/purchase-return-invoice/${bill_number}`
  ).then((res) => res.data.data);
};

export const usePurchaseReturnInvoice = (
  bill_number: string | undefined = ""
) => {
  const { showErrorMessage } = useToast();
  return useQuery<SalesReturnInvoiceResponse>(
    ["purchase-return-invoice", bill_number],
    () => {
      if (bill_number !== "" || bill_number !== undefined) {
        return GetPurchaseReturnInvoice(bill_number as string);
      }
      return {} as SalesReturnInvoiceResponse;
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch purchase return invoice details");
      },

      enabled:
        Boolean(bill_number !== undefined) && Boolean(bill_number !== ""),
    }
  );
};

export const GetAuditTrialReport = (
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/audit-trial-report?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1}&page_size=${pageSize}
  `
  ).then((res) => res.data);
};

export const useAuditTrialReport = (
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: AuditTrialResponse[];
  }>(
    ["audit-trial-report", pageIndex, pageSize, date],
    () => GetAuditTrialReport(pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily audit trial report");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize) || Boolean(date),
    }
  );
};

export const GetUserProile = () => {
  return Axios.get("/api/v1/user/profile").then((res) => res.data.data);
};

export const useUserProfile = () => {
  const { showErrorMessage } = useToast();
  return useQuery(["user-profile"], () => GetUserProile(), {
    onError: (err: any) => {
      showErrorMessage("Failed to fetch user profile data");
    },
    retry: 2,
    refetchOnMount: true,
  });
};

export const GetDashboardChart = (monthlyData: boolean) => {
  const apiCall = monthlyData
    ? "/api/v1/product_service/dashboard/purchase-sale/chart-data?date_period=1"
    : "/api/v1/product_service/dashboard/purchase-sale/chart-data";
  return Axios.get(apiCall).then((res) => res.data.data);
};

export const useDashboardChart = (monthlyData: boolean) => {
  const { showErrorMessage } = useToast();
  return useQuery<DashBoardChartResponse>(
    ["dashboard-chart"],
    () => GetDashboardChart(monthlyData),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch user dashboard chart data");
      },
      retry: 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetPayslipData = (id: number) => {
  return Axios.get(
    `/api/v1/product_service/payslip/customer-search?customer_id=${id}`
  ).then((res) => res.data.data);
};

export const usePayslip = (id: number | null = null) => {
  const { showErrorMessage } = useToast();
  return useQuery<CustomerData>(
    ["payslip", id],
    () => GetPayslipData(id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch user payslip data");
      },
      enabled: Boolean(id !== null),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetPaymentVoucherData = (id: number) => {
  return Axios.get(
    `/api/v1/product_service/payment-voucher/vendor-search?customer_id=${id}`
  ).then((res) => res.data.data);
};

export const usePaymentVoucher = (id: number | null = null) => {
  const { showErrorMessage } = useToast();
  return useQuery<CustomerData>(
    ["PaymentVoucher", id],
    () => GetPaymentVoucherData(id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch user Payment Voucher data");
      },
      enabled: Boolean(id !== null),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetSalesReturnReport = (
  //will be using in future
  search: Search,
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/sale-return-list?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1
    }&page_size=${pageSize}&customer_name=${SearchHelpers(
      search.customer_name,
      "Lower"
    )}&document_no=${SearchHelpers(search.document_no, "Upper")}
  `
  ).then((res) => res.data);
};

export const useSalesReturnReport = (
  search: Search,
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: SalesReturnDataResponse[];
  }>(
    ["sales-return-report", pageIndex, pageSize, date, search],
    () => GetSalesReturnReport(search, pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  sales return report");
      },
      enabled:
        Boolean(search && search.customer_name) ||
        Boolean(search && search.document_no) ||
        date["dateFrom"] != "" && date["dateTo"] != ""
    }
  );
};

export const GetPurchaseReturnReport = (
  search: Search,
  pageIndex: number,
  pageSize: number,
  date: FilterDate
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/purchase-return-list?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1
    }&page_size=${pageSize}&vendor_name=${SearchHelpers(
      search.vendor_name,
      "Lower"
    )}&bill_no=${SearchHelpers(search.bill_no, "Upper")}
  `
  ).then((res) => res.data);
};

export const usePurchaseReturnReport = (
  search: Search,
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: PurchaseReturnDataResponse[];
  }>(
    ["purchase-return-report", pageIndex, pageSize, date, search],
    () => GetPurchaseReturnReport(search, pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  purchase return report");
      },
      enabled:
        date["dateTo"] != "" && date["dateFrom"] != "" ||
        Boolean(search && search.vendor_name),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
};

export const GetSettingData = () => {
  return Axios.get("/api/v1/setting/show").then((res) => res.data.data);
};

export const useSettingData = () => {
  const { showErrorMessage } = useToast();
  const [retryCount, setRetryCount] = useState(0);

  return useQuery<SettingPageValues>(["setting-page-data"], GetSettingData, {
    onError: (err: any) => {
      if (retryCount < 2) {
        setRetryCount(retryCount + 1);
      }
      if (retryCount < 1 && err.response.data.status.status_code !== 404) {
        showErrorMessage("Failed to fetch setting data");
      }
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const GetStockDetail = (
  pageIndex: number,
  pageSize: number,
  prodId: number | null | undefined,
  uptoDate: string | undefined
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/stock-detail-report?product_id=${prodId}&page_num=${pageIndex + 1
    }&page_size=${pageSize}&upto_date=${uptoDate}`
  ).then((res) => res.data);
};
export const useStockDetailReport = (
  prodId: number | undefined = undefined,
  pageIndex = 0,
  pageSize = 10,
  uptoDate = ""
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status?: StatusResponse;
    pagination?: Pagination;
    data: StockDetailResponse[];
  }>(
    ["stock-detail-report", pageIndex, pageSize, prodId, uptoDate],
    () => {
      if (prodId !== undefined) {
        return GetStockDetail(pageIndex, pageSize, prodId, uptoDate);
      }
      return {
        status: {} as StatusResponse,
        pagination: {} as Pagination,
        data: [],
      };
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  sales return report");
      },

      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(prodId !== undefined),
    }
  );
};

export const GetProductOptions = () => {
  return Axios.get("/api/v1/product_service/product-list").then(
    (res) => res.data.data
  );
};
export const useProductOptions = () => {
  const { showErrorMessage } = useToast();
  return useQuery<ProductOptionsResponse[]>(
    ["product-options"],
    () => GetProductOptions(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  product list report");
      },
    }
  );
};

export const GetBillCancelList = (
  pageIndex: number,
  pageSize: number,
  search: Search
) => {
  return Axios.get(
    `api/v1/product_service/transaction-report/bill-cancel-list?page_num=${pageIndex + 1
    }&page_size=${pageSize}&document_no=${SearchHelpers(
      search.invoice_no,
      "Upper"
    )}`
  ).then((res) => res.data);
};
export const useBillCancelList = (
  pageIndex = 0,
  pageSize = 10,
  search: Search
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: BillCancelListResponse[];
  }>(
    ["bill-cancel-report", pageIndex, pageSize, search],
    () => GetBillCancelList(pageIndex, pageSize, search),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  bill cancel report");
      },
      retry: 1,
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(search && search.invoice_no),
    }
  );
};

//Print Count
export const UpdatePrintCount = (document_number: string) => {
  return Axios.get(
    `/api/v1/product_service/transaction/update-bill-print-count/${document_number}?documnet_number=${document_number}`
  ).then((res) => res.data);
};

export const GetLedgerForOpeningAdjustment = () => {
  return Axios.get(
    `api/v1/product_service/ledger-hierarchy-list?is_opening_entry=1`
  ).then((res) => res.data.data);
};

export const useLedgerForOpeningAdjustment = () => {
  const { showErrorMessage } = useToast();
  return useQuery<Array<LedgerHeadResponse>>(
    ["ledger-head"],
    () => GetLedgerForOpeningAdjustment(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
    }
  );
};

//LedgerHeads
export const GetLedgerForManualVoucher = () => {
  return Axios.get(`api/v1/product_service/ledger-hierarchy-list`).then(
    (res) => res.data.data
  );
};

export const useLedgerForManualVoucher = () => {
  const { showErrorMessage } = useToast();
  return useQuery<Array<LedgerHeadResponse>>(
    ["api/v1/product_service/ledger-hierarchy-list"],
    () => GetLedgerForManualVoucher(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch ledeger head lists ");
      },
      retry: 2,
    }
  );
};

//Opening Adjustment list

export const GetOpeningTableList = () => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/opening-balance-list`
  ).then((res) => res.data.data);
};

export const useOpeningTableList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<BalanceSheetData>(
    ["opening-list"],
    () => GetOpeningTableList(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch opening balance");
      },
    }
  );
};
// OpeningReport

export const GetLedgerList = (date: FilterDate, id: number) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/ledger-report?ledger_id=${id}&date_from=${date.dateFrom}&date_to=${date.dateTo}`
  ).then((res) => res.data);
};

export const useLedgerList = (
  date = {
    dateTo: "",
    dateFrom: "",
  },
  id: number | undefined
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: LedgerWiseResponse[];
  }>(
    ["ledger-list", date, id],
    () => {
      if (id !== undefined) {
        // only call GetLedgerList when id is not undefined
        return GetLedgerList(date, id);
      } else {
        // return empty object when id is undefined
        return Promise.resolve({
          status: {},
          pagination: {},
          data: [],
        });
      }
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch ledger list data");
      },
      enabled: Boolean(id !== undefined) && Boolean(date),
      refetchOnMount: false,
      retry: 2,
    }
  );
};

export const ShowLedgerList = () => {
  return Axios.get(
    `/api/v1/product_service/ledger-hierarchy-list?is_opening_entry=0&is_ledger_report=1`
  ).then((res) => res.data);
};

export const useAllLedgerList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<ILedgerListResponse>(
    "/api/v1/customer/list",
    () => ShowLedgerList(),
    {
      onError: (err: any) => {
        showErrorMessage(err.message || "Failed to fetch vendor list");
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const GetJournalEntry = (id: number) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/journal-entry/${id}`
  ).then((res) => res.data.data);
};

export const useJournalEntry = (id: number | undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<IJournalEntryResponse>(
    ["journal-entry", id],
    () => {
      return GetJournalEntry(id as number);
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to Jounrnal Entry data");
      },
      enabled: Boolean(id !== undefined),
      refetchOnMount: false,
      retry: 2,
    }
  );
};

export const GetOpeningBalanceDetail = (
  id: number,
  pageIndex: number,
  pageSize: number
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/opening-balance-detail/${id}?page_num=${pageIndex + 1
    }&page_size=${pageSize}`
  ).then((res) => res.data);
};

export const useGetOpeningBalanceDetail = (
  id: number | undefined,
  pageIndex = 0,
  pageSize = 10
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: IOpeningStockDetail[] | UpdatedOpeningBalanceDetail[];
  }>(
    ["opening-balance-detail", id, pageIndex, pageSize],
    () => GetOpeningBalanceDetail(id as number, pageIndex, pageSize),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  bill cancel report");
      },
      retry: 1,
      refetchOnMount: false,
      enabled:
        Boolean(id !== undefined) && (Boolean(pageIndex) || Boolean(pageSize)),
    }
  );
};

export const GetSpecificOpeningStockDetail = (id: number, prodId: number) => {
  return Axios.get(
    `/api/v1/product_service/opening/stock-detail_show/${id}?product_id=${prodId}`
  ).then((res) => res.data.data);
};

export const useGetSpecificOpeningStockDetail = (
  id: number | undefined,
  prodId?: number
) => {
  const { showErrorMessage } = useToast();
  return useQuery<ISpecificOpeningStockDetail>(
    [
      `/product_service/opening/stock-detail_show/${id}?product_id=${prodId}`,
      id,
      prodId,
    ],
    () => {
      if (id === undefined || prodId === undefined)
        return {} as ISpecificOpeningStockDetail;
      return GetSpecificOpeningStockDetail(id as number, prodId as number);
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch  bill cancel report");
      },
      retry: 1,
      refetchOnMount: false,
    }
  );
};
//Profitand loss account list

export const GetProfitLossList = (
  date = {
    dateFrom: "",
    dateTo: "",
  }
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/profit-loss-account?date_from=${date.dateFrom}&date_to=${date.dateTo}`
  ).then((res) => res.data.data);
};

export const useProfitLossList = (
  date = {
    dateFrom: "",
    dateTo: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<ProfitLossList>(
    ["profit-loss", date],
    () => GetProfitLossList(date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch Trading Account");
      },
      enabled: Boolean(date.dateFrom),
    }
  );
};

//Balance Sheet list

export const GetBalanceSheet = (date: string | undefined) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/balance-sheet-report?date_search=${date}`
  ).then((res) => res.data.data);
};

export const useBalanceSheet = (date: string | undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<BalanceSheetData>(
    ["balance-sheet", date],
    () => GetBalanceSheet(date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch Trading Account");
      },
      enabled: Boolean(date),
    }
  );
};

export const GetTrialBalanceList = (fromDate: string, toDate: string) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/trial-balance?date_from=${fromDate}&date_to=${toDate}`
  ).then((res) => res.data);
};

export const useTrialBalanceList = (fromDate: string, toDate: string) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    data: TrialBalanceResponce[];
  }>(
    [
      `/api/v1/product_service/transaction-report/trial-balance?date_from=${fromDate}&date_to=${toDate}`,
      fromDate,
    ],
    () => GetTrialBalanceList(fromDate as string, toDate as string),
    {
      onError: () => {
        showErrorMessage("Failed to fetch daily bill numbers");
      },
    }
  );
};

//Trading account list

export const GetTradingAccountList = (date: FilterDate) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/trading-account?date_from=${date.dateFrom}&date_to=${date.dateTo}`
  ).then((res) => res.data.data);
};

export const useTradingAccountList = (
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<TradingAccountList>(
    ["trading-account", date.dateTo, date.dateFrom],
    () => GetTradingAccountList(date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch Trading Account");
      },
      enabled: Boolean(date?.dateTo) || Boolean(date?.dateFrom),
    }
  );
};

export const GetSpecificCustomer = (id: number) => {
  return Axios.get(`/api/v1/customer/show/${id}`).then((res) => res.data.data);
};

export const useGetSpecificCustomer = (id: number | undefined) => {
  const { showErrorMessage } = useToast();
  return useQuery<ISpecificCustomerResponse>(
    [`api/v1/customer/show/${id}`, id],
    () => {
      if (id === undefined) return {} as ISpecificCustomerResponse;
      return GetSpecificCustomer(id as number);
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch customer report");
      },
      retry: 1,
    }
  );
};

export const GetPatronTransactionSummary = (
  role_id: number,
  page_num: number,
  page_size: number,
  date: FilterDate,
  search: Search
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/patron-summary-list?page_num=${page_num + 1
    }
    &page_size=${page_size}&date_from=${date.dateFrom}&date_to=${date.dateTo}${role_id ? `&role_id=${role_id}` : ""
    }&transaction_more_than=${Number(search.amount)}`
  ).then((res) => res.data);
};

export const usePatronTransactionSummary = (
  search: Search,
  role_id: number,
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  const { showErrorMessage } = useToast();

  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: IPatronTransactionResponse[];
  }>(
    [
      "/product_service/transaction-report/patron-summary-list",
      role_id,
      pageIndex,
      pageSize,
      search,
    ],
    () => {
      if (role_id !== undefined && date.dateTo !== "" && date.dateFrom !== "") {
        return GetPatronTransactionSummary(
          role_id,
          pageIndex,
          pageSize,
          date,
          search
        );
      }
      return {
        status: {} as StatusResponse,
        pagination: {} as Pagination,
        data: [] as IPatronTransactionResponse[],
      };
    },

    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch transaction summary ");
      },
      enabled:
        Boolean(pageIndex) ||
        Boolean(pageSize) ||
        Boolean(role_id) ||
        Boolean(date) ||
        Boolean(search),
    }
  );
};

export const GetReminderNotice = () => {
  return Axios.get(`/api/v1/product_service/transaction-reminder/list`).then(
    (res) => res.data.data
  );
};

export const useReminderNotice = () => {
  const { showErrorMessage } = useToast();
  return useQuery<IRemaindeNoticeResponse>(
    ["reminder-notice"],
    () => {
      return GetReminderNotice();
    },
    {
      onError: (err: any) => {
        showErrorMessage("Failed to Reminder Notice data");
      },
    }
  );
};
//VAT Report

export const GetVatTableList = (monthId: string) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/monthly-vat?month_id=${monthId}`
  ).then((res) => res.data.data);
};

export const useVatTableList = (monthId: string) => {
  const { showErrorMessage } = useToast();
  return useQuery<IVatReport>(
    [`/product_service/transaction-report/monthly-vat?month_id=${monthId}`],
    () => GetVatTableList(monthId),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch VAT Report");
      },
      enabled: monthId !== "",
    }
  );
};

//LedgerHeads
export const GetManualVoucherCustomer = () => {
  return Axios.get(`api/v1/common_api/manual-voucher-customer-list`).then(
    (res) => res.data.data
  );
};

export const useManualVoucherCustomer = () => {
  const { showErrorMessage } = useToast();
  return useQuery<Array<ManualVoucherCustomerResponse>>(
    ["manual-voucher-customer"],
    () => GetManualVoucherCustomer(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch customer");
      },
      retry: 2,
    }
  );
};
//AnusuchiReport

export const GetAnusuchiList = (
  pageIndex = 0,
  pageSize = 10,
  date = {
    dateTo: "",
    dateFrom: "",
  }
) => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/anusuchi-13-report?date_from=${date.dateFrom
    }&date_to=${date.dateTo}&page_num=${pageIndex + 1}
  `
  ).then((res) => res.data);
};
// transaction_more_than halne
export const useAnusuchiList = (
  pageIndex = 0,
  pageSize = 10,
  date = { dateTo: "", dateFrom: "" }
) => {
  const { showErrorMessage } = useToast();
  return useQuery<{
    status: StatusResponse;
    pagination: Pagination;
    data: AnusuchiListResponse[];
  }>(
    ["purchase-return-report", pageIndex, pageSize, date],
    () => GetAnusuchiList(pageIndex, pageSize, date),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch daily anusuchi report");
      },
      enabled: Boolean(pageIndex) || Boolean(pageSize) || Boolean(date),
    }
  );
};
//Cash flow

export const GetCashFlowList = () => {
  return Axios.get(
    `/api/v1/product_service/transaction-report/cash-flow-report`
  ).then((res) => res.data.data);
};

export const useCashFlowList = () => {
  const { showErrorMessage } = useToast();
  return useQuery<Array<ICashFlowList>>(
    [`/api/v1/product_service/transaction-report/cash-flow-report`],
    () => GetCashFlowList(),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch cash flow report");
      },
    }
  );
};

export const GetLedgerClosingBalance = (id: number | undefined | null) => {
  return Axios.get(
    `api/v1/product_service/ledger-closing-balance?ledger_id=${id}`
  ).then((res) => res.data.data);
};

export const useLedgerClosingBalance = (id: number | undefined | null) => {
  const { showErrorMessage } = useToast();
  return useQuery<LedgerClosingBalanceResponse>(
    ["staff-detail", id],
    () => GetLedgerClosingBalance(id as number),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch LedgerClosingBalance");
      },
      enabled: Boolean(id !== undefined),
    }
  );
};

export const GetReceiptSlip = (document_no: string) => {
  return Axios.get(
    `/api/v1/product_service/payslip/receipt_invoice/${document_no}`
  ).then((res) => res.data.data);
};

export const useReceiptSlip = (document_no?: string) => {
  const { showErrorMessage } = useToast();
  return useQuery<IReceiptData>(
    [`payslip/receipt_invoice/${document_no}`, document_no],
    () => GetReceiptSlip(document_no as string),
    {
      onError: (err: any) => {
        showErrorMessage("Failed to fetch receipt data");
      },
      enabled: Boolean(document_no !== undefined),
    }
  );
};
