import { ErrorMessage, Field } from "formik";
import { useEffect } from "react";
import { fixedValue } from "../../utils/services";
import { AddableBank } from "../AddableBank/AddableBank";
import { useNewBillFormikContext } from "../BillPage/BillFormikWrapper";
import { useToast } from "../context/ToasterContext/ToasterContext";
import "./BankDetail.scss";

export interface bankDetailProps {
  id: number;
  name: string;
}

export const BankDetail = () => {
  const { getFieldProps, setFieldValue, values } = useNewBillFormikContext();

  const { showErrorMessage } = useToast();

  const isCashTransaction = localStorage.getItem("TRANSACTION_IN") === 'cash'


  const alterCashCredit = (cash: number, credit: number) => {
    setFieldValue("cash", cash);
    setFieldValue("credit", credit);
  }

  const CreditValues = (value: any) => {
    const requireTotal = values.total;
    const payCheckFlag = values.pay_by_check;
    let totalChequeAmount = 0;
    let requestedAmount = Number(value);

    if (payCheckFlag) {
      totalChequeAmount = values.bank.reduce((a, b) => {
        return a + Number(b.amount);
      }, 0);
      requestedAmount = requestedAmount + totalChequeAmount;
    }
    if (requestedAmount > values.total) {
      if (isCashTransaction) {
        alterCashCredit(requestedAmount, 0)
      } else {
        alterCashCredit(0, requireTotal)
      }
      showErrorMessage(
        "Exceed the payable amount.Please kindly check the payable amount"
      );
      return;
    }
    if (isCashTransaction) {
      alterCashCredit(fixedValue(requireTotal - requestedAmount), Number(value))
    } else {
      alterCashCredit(Number(value), requireTotal - requestedAmount)
    }
    return;
  };

  const TogglePayByCheque = () => {
    setFieldValue("pay_by_check", !values.pay_by_check);
    const temp = [
      {
        cheque_no: "",
        amount: 0,
        cheque_issue_date: "",
        bank_id: 0,
      },
    ];
    setFieldValue("bank", temp);
    if (isCashTransaction) {
      setFieldValue("cash", values.total - values.credit); //to handle the pay by cheque click
    } else {
      setFieldValue("credit", values.total - values.credit); //to handle the pay by cheque click
    }
  };

  useEffect(() => {
    if (values.pay_by_check) {
      const requireTotal = values.total;
      const totalChequeAmount = values.bank.reduce((a, b) => {
        return a + Number(b.amount);
      }, 0);
      const requestedAmount = totalChequeAmount + (isCashTransaction ? values.credit : values.cash);
      if (requestedAmount > requireTotal) {
        if (isCashTransaction) {
          alterCashCredit(values.total, 0)
        } else {
          alterCashCredit(0, values.total)
        }

        return showErrorMessage(
          "Exceed the payable amount.Please check the payable amount"
        );
      } else {
        if (isCashTransaction) {
          setFieldValue(
            "cash",
            fixedValue(values.total - totalChequeAmount - values.credit)
          );
        } else {
          setFieldValue(
            "credit",
            fixedValue(values.total - totalChequeAmount - values.cash)
          );
        }
      }
    }
  }, [values.bank]);

  return (
    <div className="bank-detail-page">
      <div className="total-card bank-details">
        <table style={{ width: "100%" }}>
          <thead>
            <tr className="header-wrap">
              <th className="bank-header cash-border content">Cash</th>
              <th className="bank-header content">Credit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cash-border side-spaces">
                <div className="cash-amt">
                  <label className="input-label">Amount</label>
                  <Field
                    type="number"
                    className="input-field"
                    pattern="[0-9]*"
                    onFocus={(event: any) => {
                      if (parseInt(event.target.value) === 0) {
                        event.target.value = "";
                      }
                    }}
                    disabled={isCashTransaction}
                    onChange={(e: any) => {
                      CreditValues(e.target.value);
                    }}
                    onWheel={(e: any) => {
                      (e.target as HTMLInputElement).blur();
                    }}
                    value={values.cash}
                  />
                  <ErrorMessage
                    name={"cash"}
                    component="div"
                    className="error"
                  />
                </div>
              </td>
              <td className="side-spaces">
                <div>
                  <label className="input-label">Amount</label>
                  <Field
                    className="input-field"
                    pattern="[0-9]*"
                    onWheel={(e: any) => {
                      (e.target as HTMLInputElement).blur();
                    }}
                    onFocus={(event: any) => {
                      if (parseInt(event.target.value) === 0) {
                        event.target.value = "";
                      }
                    }}
                    onChange={(e: any) => {
                      CreditValues(e.target.value);
                    }}
                    placeholder="Enter amount"
                    value={values.credit}
                    disabled={!isCashTransaction}
                  />
                  <ErrorMessage
                    name={"credit"}
                    component="div"
                    className="error"
                  />
                </div>
              </td>
            </tr>
            <div className="mt-4">
              <input
                className={true ? "checkbox onButton" : "checkbox offButton"}
                disabled={values.total <= 0}
                type="checkbox"
                onChange={() => {
                  TogglePayByCheque();
                }}
                checked={values.pay_by_check}
              />
              <label className="check-label">Pay By Cheque</label>
            </div>
            {values.pay_by_check && (
              <tr>
                <td
                  //@ts-ignore
                  colspan="2 "
                  className="cash-border side-spaces bank-body-warp"
                >
                  <div>
                    <AddableBank
                      name="Bank Details"
                      buttonName="Add Details"
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
