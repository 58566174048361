import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { usePagination, useSortBy, useTable } from "react-table";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import { ExcelIcon, PrintIcon } from "../../assets/images/svg/svg";
import { SearchKeyUpdates } from "../../utils/services";
import { exportToExcel, handlePrint } from "../../utils/utils";
import { usePatronRoles } from "../Api/Get/GetApi";
import { Search, SearchKeysArray } from "../Api/Get/GetApiTypes";
import { Loader } from "../Loader/Loader";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import { FilterDate } from "../SalesList/SalesListPage";
import { Select } from "../Select";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import "./CustomReactTable.scss";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export interface TableHeaderContentGroupProps {
  totalEntries?: number;
  columns?: any;
  data?: any;
  currentPageNumber?: number;
  totalPages?: number;
  loading?: boolean;
  searchTerm?: Search;
  setSearchTerm?: React.Dispatch<React.SetStateAction<Search>>;
  roleId?: number;
  setRoleId?: React.Dispatch<React.SetStateAction<number>>;
  quantityId?: number;
  setQuantityId?: React.Dispatch<React.SetStateAction<number>>;
  showSelect?: boolean;
  quantitySelect?: boolean;
  categorySelect?: boolean;
  showFooter?: boolean;
  showDate?: boolean;
  date?: FilterDate;
  setDate?: React.Dispatch<React.SetStateAction<FilterDate>>;
  showUptoDate?: boolean;
  setUptoDate?: React.Dispatch<React.SetStateAction<string | undefined>>;
  queryPageIndex?: number;
  setQueryPageIndex?: React.Dispatch<React.SetStateAction<number>>;
  queryPageSize?: number;
  setQueryPageSize?: React.Dispatch<React.SetStateAction<number>>;
  excelDownload?: boolean;
  showPagination?: boolean;
  showPageSize?: boolean;
  smallPagination?: boolean;
  showSelectOptions?: boolean;
  selectValue?: SelectOptions | undefined;
  setSelectValue?: React.Dispatch<
    React.SetStateAction<SelectOptions | undefined>
  >;
  options?: SelectOptions[];
  optionlabel?: string;
  searchArray?: SearchKeysArray;
  onDoubleClick?: (row: any) => void;
  description?: string;
}

function useInstance(instance: any) {
  const { allColumns } = instance;

  let rowSpanHeaders: any[] = [];

  allColumns.forEach((column: any, i: any) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0 },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

export const Table: React.FC<TableHeaderContentGroupProps> = ({
  columns,
  data,
  currentPageNumber,
  totalPages,
  loading,
  //@ts-nocheck
  searchTerm,
  setSearchTerm,
  roleId,
  setRoleId,
  quantityId,
  setQuantityId,
  showSelect = false,
  quantitySelect = false,
  showFooter = false,
  showDate = false,
  showUptoDate = false,
  totalEntries,
  date,
  setDate,
  setUptoDate,
  queryPageIndex,
  setQueryPageIndex,
  queryPageSize,
  setQueryPageSize,
  excelDownload = false,
  showPagination = true,
  showPageSize = true,
  smallPagination = false,
  showSelectOptions = false,
  selectValue,
  setSelectValue,
  options,
  optionlabel,
  searchArray,
  onDoubleClick,
  description,
}) => {
  const location = useLocation();
  const { businessUserInfo } = useAuth();
  const { data: patronRoles } = usePatronRoles({
    enabled: showSelect,
    isOnlyPatron: false,
  });

  const [markerGroupRef, setMarkerGroupRef] = useState<
    HTMLTableElement | HTMLDivElement | null
  >(null);

  const useControlledState = (state: any) => {
    return useMemo(
      () => ({
        ...state,
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      }),
      [state, queryPageIndex, queryPageSize]
    );
  };

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    rowSpanHeaders,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,

      defaultColumn,
      useControlledState,
      currentPageNumber,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: totalPages,
    },
    (hooks) => {
      hooks.useInstance.push(useInstance);
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    //This code is to handle to get back to last available page when pagesize increase
    if (totalPages && Number(queryPageIndex) + 1 > Number(totalPages)) {
      setQueryPageIndex?.(totalPages - 1);
    }
  }, [totalPages]);
  // Render the UI for your table

  const RoleOption = [
    {
      value: 0,
      label: "Out of Stock",
    },
    {
      value: 1,
      label: "Positive Stock",
    },
  ];
  const currentFiscalYear: any = localStorage.getItem("currentFiscalYear");
  const firstFiscalDate: any = localStorage.getItem("firstFiscalDate");
  const lastFiscalDate: any = localStorage.getItem("lastFiscalDate");

  const [inputValue, setInputValue] = useState({});
  let searchTimer: NodeJS.Timeout | null = null;

  const handleSearchTerm = useCallback(
    (searchValue: string, key: string) => {
      setInputValue((prevState) => ({ ...prevState, [key]: searchValue }));

      // Clear previous timeout if exists
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      // Set new timeout
      searchTimer = setTimeout(() => {
        setSearchTerm?.((prevState) => ({ ...prevState, [key]: searchValue }));
      }, 300);
    },
    [setSearchTerm]
  );

  return (
    <>
      <div className="customer-react-table">
        <div className="col-md-12">
          <div className="row">
            {showSelectOptions && (
              <div className="col-md-3">
                <div className="form-group">
                  <label className="d-block input-label">
                    Select {optionlabel ? optionlabel : ""}
                  </label>
                  <ReactSelect
                    isClearable={true}
                    options={options ? options : []}
                    value={selectValue}
                    styles={{
                      control: (base) => ({
                        ...base,
                        fontSize: "12px",
                      }),
                      menu: (base) => ({
                        ...base,
                        fontSize: "12px",
                      }),
                    }}
                    onChange={(e) => {
                      setSelectValue?.(e as SelectOptions);
                    }}
                  />
                </div>
              </div>
            )}
            {showSelect && (
              <div className="col-md-3">
                <label className="input-label">Select Patron Type</label>
                <Select
                  value={roleId}
                  onChange={(e) => {
                    setRoleId?.(Number(e.target.value));
                  }}
                >
                  {patronRoles?.map((opt, index) => (
                    <option key={index} value={opt.id}>
                      {opt?.name.charAt(0).toUpperCase() + opt?.name.slice(1)}
                    </option>
                  ))}
                </Select>
              </div>
            )}
            {quantitySelect && (
              <div className="col-md-3">
                <label className="input-label">Select Quantity Type</label>
                <Select
                  value={quantityId}
                  onChange={(e) => {
                    setQuantityId?.(Number(e.target.value));
                  }}
                >
                  {RoleOption.map((val, idx) => {
                    return (
                      <option key={idx} value={val.value}>
                        {val.label}
                      </option>
                    );
                  })}
                </Select>
              </div>
            )}

            {showDate && (
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mg-btm-16">
                      <label htmlFor="date" className="input-label">
                        From Date
                      </label>
                      <NepaliDatePicker
                        defaultDate={firstFiscalDate}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateFrom: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="date" className="input-label">
                        To Date
                      </label>
                      <NepaliDatePicker
                        currentDate={currentFiscalYear === "Yes"}
                        defaultDate={currentFiscalYear === "Yes" ? "" : lastFiscalDate}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateTo: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* upto data */}
            {showUptoDate && (
              <div className="col-md-3">
                <div className="form-group mg-btm-16">
                  <label htmlFor="date" className="input-label">
                    Upto Date
                  </label>
                  <NepaliDatePicker
                    currentDate={true}
                    onDateSelect={(value: any) => {
                      setUptoDate?.(value);
                    }}
                  />
                </div>
              </div>
            )}
            {searchArray && searchArray?.length > 0 && (
              <>
                {searchArray.map((val, index) => {
                  return (
                    <div className="col-md-3" key={index}>
                      <div className="form-group mg-btm-16">
                        <label className="input-label">
                          {SearchKeyUpdates(val)}
                        </label>
                        <div className="search-wrapper">
                          <div className="search-icon">
                            <img
                              alt="search"
                              src={require("../../assets/images/search.png")}
                            />
                          </div>
                          <input
                            value={
                              inputValue &&
                              inputValue[val as keyof typeof inputValue]
                            }
                            className="input-field"
                            placeholder="Search here"
                            name="user_name"
                            onChange={(e) => {
                              handleSearchTerm(e.target.value, val);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {excelDownload && (
              <div className="col-md-3 downloads">
                <div className="d-flex gap-3 float-right">
                  <button
                    className="d-flex btn btn-light my-4 "
                    onClick={() => {
                      exportToExcel(
                        "my-table",
                        columns,
                        location,
                        businessUserInfo as UserInfoResponse,
                        date as FilterDate,
                        roleId
                      );
                    }}
                  >
                    <span className="export-title"> Export</span>
                    <ExcelIcon />
                  </button>
                  <button
                    className="d-flex btn btn-light my-4 "
                    onClick={() => {
                      handlePrint(
                        columns,
                        businessUserInfo as UserInfoResponse,
                        date as FilterDate,
                        "my-table",
                        location,
                        roleId
                      );
                    }}
                  >
                    <span
                      style={{ marginRight: "10px" }}
                      className="export-title"
                    >
                      Print{" "}
                    </span>
                    <PrintIcon />
                  </button>
                </div>
              </div>
            )}

            <>
              {data && (
                <div className="col-md-12">
                  <div className="custom-react-table">
                    <div
                      className="overflowTable nested-column "
                      ref={(ref) => setMarkerGroupRef(ref as HTMLDivElement)}
                    >
                      <table {...getTableProps()} id="my-table">
                        <thead>
                          {columns.length > 1 && (
                            <>
                              {headerGroups.map((headerGroup, index) => (
                                <tr
                                  {...headerGroup.getHeaderGroupProps()}
                                  key={index}
                                >
                                  {headerGroup.headers.map((column, index) => (
                                    <th
                                      {...column.getHeaderProps()}
                                      key={index}
                                    >
                                      {column.render("Header")}
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </>
                          )}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {loading ? (
                            <Loader loaderType="Spinner" />
                          ) : (
                            <>
                              {rows.map((row, i) => {
                                prepareRow(row);

                                for (var j = 0; j < row.allCells.length; j++) {
                                  let cell = row.allCells[j];
                                  let rowSpanHeader = rowSpanHeaders.find(
                                    (x: any) => x.id === cell.column.id
                                  );
                                  if (rowSpanHeader !== undefined) {
                                    if (
                                      i > 0 &&
                                      rows[i].values.invoice_no ===
                                        rows[i - 1].values.invoice_no
                                    ) {
                                      rows[rowSpanHeader.topCellIndex].allCells[
                                        j //@ts-ignore
                                      ].rowSpan++;
                                      //@ts-ignore
                                      cell.isRowSpanned = true;
                                    } else {
                                      //@ts-ignore
                                      cell.isRowSpanned = false;
                                      rowSpanHeader.topCellValue = cell.value;
                                      rowSpanHeader.topCellIndex = i;
                                      //@ts-ignore
                                      cell.rowSpan = 1;
                                    }
                                  }
                                }
                                return null;
                              })}
                              {rows.map((row) => {
                                return (
                                  <tr
                                    className="tablerow"
                                    onDoubleClick={() => {
                                      onDoubleClick?.(row.original);
                                    }}
                                    {...row.getRowProps()}
                                  >
                                    {row.cells.map((cell) => {
                                      //@ts-ignore
                                      if (cell.isRowSpanned) return null;
                                      else
                                        return (
                                          <td
                                            // style={borderStyle}
                                            //@ts-ignore
                                            rowSpan={cell.rowSpan}
                                            {...cell.getCellProps()}
                                          >
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                    })}
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                        {showFooter && !loading && data.length > 1 && (
                          <tfoot>
                            {footerGroups.map((group) => (
                              <tr {...group.getFooterGroupProps()}>
                                {group.headers.map((column) => (
                                  <td {...column.getFooterProps()}>
                                    {column.render("Footer")}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tfoot>
                        )}
                      </table>

                      {!loading &&
                        (data.length <= 0 || columns.length <= 0) && (
                          <NoDataPage description={description} />
                        )}
                    </div>
                    {showPagination && (
                      <div className="block-gap">
                        {showPageSize && (
                          <div className="show-wrapper">
                            <div className="entries">
                              <span className="content accent-2-charcoal-50">
                                Showing
                              </span>
                              <span className="show-drop">
                                <select
                                  className="select-tag"
                                  value={queryPageSize}
                                  onChange={(e) => {
                                    setQueryPageSize?.(Number(e.target.value));
                                  }}
                                >
                                  {[
                                    10, 20, 40, 80, 100, 200, 500, 1000, 2000,
                                    5000,
                                  ].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                      {pageSize}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              <span className="content accent-2-charcoal-50">
                                entries
                              </span>
                            </div>
                            <div className="content entry-text">
                              Showing 1-{pageSize} (
                              {totalEntries ? totalEntries : "0"} entries)
                            </div>
                          </div>
                        )}
                        <div className="data-table">
                          <div className="data-buttons">
                            <button
                              className={`content light-secondary ${
                                queryPageIndex === 0
                                  ? "remove-space-rt"
                                  : "prev"
                              } ${smallPagination && "small-button"}`}
                              onClick={() => {
                                const page = Number(queryPageIndex) - 1;
                                setQueryPageIndex?.(page);
                              }}
                              disabled={!canPreviousPage}
                            >
                              Prev
                            </button>
                            {queryPageIndex === 0 ? (
                              ""
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    const page = queryPageIndex
                                      ? Number(pageIndex) - 1
                                      : 0;
                                    setQueryPageIndex?.(page);
                                    // gotoPage(page)
                                  }}
                                  className={`content ${
                                    smallPagination && "small-button"
                                  } `}
                                >
                                  {queryPageIndex}
                                </button>
                              </>
                            )}
                            <button
                              className={`button-space active ${
                                smallPagination && "small-button"
                              }`}
                            >
                              {Number(queryPageIndex) + 1}
                            </button>
                            {Number(queryPageIndex) + 1 < pageCount ? (
                              <button
                                onClick={() => {
                                  const page = Number(pageIndex) + 1;
                                  setQueryPageIndex?.(page);
                                  // gotoPage(page)
                                }}
                                className={`${
                                  smallPagination && "small-button"
                                }`}
                              >
                                {Number(queryPageIndex) + 2}
                              </button>
                            ) : (
                              ""
                            )}
                            <button
                              className={`content light-secondary ${
                                queryPageIndex === pageCount - 1
                                  ? "remove-space-rt"
                                  : "next"
                              } ${smallPagination && "small-button"}`}
                              onClick={() => {
                                const page = Number(pageIndex) + 1;
                                setQueryPageIndex?.(page);
                              }}
                              disabled={!canNextPage}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
