import { BalanceSheetOrderName } from "../../EnumValues";
import { TriangleDown, TriangleRignt } from "../../assets/images/svg/svg";
import {
  BalanceSheetSameOrder,
  parenthesisInNegativeValue,
} from "../../utils/services";
import { IBalanceSheet } from "../Api/Get/GetApiTypes";
import { Loader } from "../Loader/Loader";
import { CalculateDifference, RecursiveChildInfo } from "./BalanceSheet";

interface Props {
  date?: string;
  assetVal: number;
  liabVal: number;
  liabilitiesOrder: BalanceSheetOrderName;
  assetsOrder: BalanceSheetOrderName;
  formattedDate?: string;
  handleOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IBalanceSheet
  ) => void;
  checkedValues: number[];
  startingLiabilityId: number;
  startingAssetsId: number;
  isLoading: boolean;
  liability_data: IBalanceSheet[] | undefined;
  asset_data: IBalanceSheet[] | undefined;
}

export const BalanceSheetVartical: React.FC<Props> = ({
  date,
  assetVal,
  liabVal,
  liabilitiesOrder,
  assetsOrder,
  formattedDate,
  handleOnChange,
  checkedValues,
  startingLiabilityId,
  startingAssetsId,
  isLoading,
  liability_data,
  asset_data,
}) => {
  return (
    <>
      <table className="main-table table">
        <tbody>
          <tr>
            <td className="width-50">
              <table className="small-table table mg-top-12">
                <thead className="table-header">
                  <tr>
                    <th className="content-title width-60 ">Liabilities</th>
                    <th className="content-title text-center">Amount</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <div className="loder-wrapper">
                          <Loader loaderType="Spinner" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {BalanceSheetSameOrder(
                      liabilitiesOrder,
                      RecursiveChildInfo(
                        liability_data as IBalanceSheet[],
                        startingLiabilityId
                      )
                    )?.map((item, index) => {
                      return (
                        <>
                          {RecursiveBalanceSheetShow(
                            item.childInfo as IBalanceSheet[],
                            item,
                            index,
                            0,
                            14,
                            500,
                            "#000",
                            "normal",
                            date,
                            formattedDate,
                            checkedValues,
                            handleOnChange
                          )}
                        </>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <table className="table footer-table">
                {assetVal < liabVal ? (
                  <tr>
                    <td className="black-content">
                      Difference in opening balance
                    </td>
                    <td className="black-content text-right">
                      {parenthesisInNegativeValue(
                        CalculateDifference(assetVal, liabVal).difference
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
                <tr>
                  <td className="footer-content width-60 border-right">
                    Total
                  </td>
                  <td className="footer-content text-right">
                    {parenthesisInNegativeValue(
                      CalculateDifference(assetVal, liabVal).grandTotal
                    )}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style={{ borderTop: "1px solid #dcdcdc" }}>
            <td className="width-50">
              <table className="small-table table mg-top-12">
                <thead className="table-header">
                  <tr>
                    <th className="content-title width-60 ">Assets</th>
                    <th className="content-title text-center">Amount</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <div className="loder-wrapper">
                          <Loader loaderType="Spinner" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {BalanceSheetSameOrder(
                      assetsOrder,
                      RecursiveChildInfo(
                        asset_data as IBalanceSheet[],
                        startingAssetsId
                      )
                    )?.map((item, index) => {
                      return (
                        <>
                          {RecursiveBalanceSheetShow(
                            item.childInfo as IBalanceSheet[],
                            item,
                            index,
                            0,
                            14,
                            500,
                            "#000",
                            "normal",
                            date,
                            formattedDate,
                            checkedValues,
                            handleOnChange
                          )}
                        </>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <table className="table footer-table">
                {liabVal < assetVal ? (
                  <tr>
                    <td className="black-content  border-bottom">
                      Difference in opening balance
                    </td>
                    <td className="black-content text-right">
                      {parenthesisInNegativeValue(
                        CalculateDifference(assetVal, liabVal).difference
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
                <tr>
                  <td className="footer-content width-60 border-right">
                    Total
                  </td>
                  <td className="footer-content text-right">
                    {parenthesisInNegativeValue(
                      CalculateDifference(assetVal, liabVal).grandTotal
                    )}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const RecursiveBalanceSheetShow = (
  childInfo: IBalanceSheet[],
  item: IBalanceSheet,
  index: number,
  padding: number,
  fontSize: number,
  fontWeight: number,
  color: string,
  fontStyle: string,
  date: string | undefined,
  formattedDate: string | undefined,
  checkedValues: Array<number>,
  handleOnChange: (e: any, item: IBalanceSheet) => void
): JSX.Element | null => {
  if (childInfo?.length <= 0) {
    return null;
  }

  // Sort the childInfo array in alphabetical order by ledger_name
  const sortedChildInfo = childInfo?.sort((a, b) =>
    a.ledger_name.localeCompare(b.ledger_name)
  );

  const exists = checkedValues?.find((val) => val === item.id);
  const handleDoubleClick = (id: number) => {
    const url = `/ledger-wise-report?ledger-id=${encodeURIComponent(
      id
    )}&dateFrom=${encodeURIComponent(
      formattedDate || ""
    )}&dateTo=${encodeURIComponent(date || "")}`;
    window.open(url, "_blank");

    // alert(`check id= ${id}, check date ${date}, check fiscal date=${formattedDate}`);
  };
  return (
    <>
      <tr
        onDoubleClick={() => {
          handleDoubleClick(item.id);
        }}
      >
        <td className="border-right">
          <p
            style={{
              paddingLeft: `${padding}px`,
              fontSize: `${fontSize}px`,
              fontWeight: `${fontWeight}`,
              color: `${color}`,
              fontStyle: `${fontStyle}`,
            }}
          >
            <div className="d-flex">
              <input
                className="checkbox-none"
                type="checkbox"
                id={`custom-checkbox-${item.id}`}
                name={item.ledger_name}
                value={item.ledger_name}
                checked={Boolean(exists)}
                onChange={(e) => {
                  handleOnChange(e, item);
                }}
              />
              <label htmlFor={`custom-checkbox-${item.id}`}>
                {sortedChildInfo?.length > 0 && (
                  <>{Boolean(exists) ? <TriangleDown /> : <TriangleRignt />}</>
                )}
                {item.ledger_name}
              </label>
            </div>
          </p>
        </td>
        <td>
          <p
            className="text-right"
            style={{
              fontSize: `${fontSize}px`,
              fontWeight: `${fontWeight}`,
              color: `${color}`,
              fontStyle: `${fontStyle}`,
            }}
          >
            {parenthesisInNegativeValue(item?.total || 0)}
          </p>
        </td>
      </tr>

      {Boolean(exists) ? (
        <>
          {sortedChildInfo?.map((val, idx) => {
            return (
              <>
                {RecursiveBalanceSheetShow(
                  val?.childInfo as IBalanceSheet[],
                  val,
                  idx,
                  padding + 20,
                  fontSize === 12 ? 12 : fontSize - 2,
                  fontWeight,
                  (color = "#666666"),
                  (fontStyle = "italic"),
                  date,
                  formattedDate,
                  checkedValues,
                  handleOnChange
                )}
              </>
            );
          })}
        </>
      ) : (
        ""
      )}
    </>
  );
};
