import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BalanceSheetOrderName } from "../../EnumValues";
import { ExcelIcon, PrintIcon } from "../../assets/images/svg/svg";
import { replaceDecimal } from "../../utils/services";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { useBalanceSheet } from "../Api/Get/GetApi";
import { IBalanceSheet } from "../Api/Get/GetApiTypes";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import "./BalanceSheet.scss";
import { BalanceSheetHorizontal } from "./BalanceSheetHorizontal";
import { BalanceSheetVartical } from "./BalanceSheetVartical";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export const RecursiveChildInfo = (
  data: IBalanceSheet[],
  groupId: number
): IBalanceSheet[] => {
  const result: IBalanceSheet[] = [];
  for (const item of data) {
    if (item.group_code === groupId) {
      const children = RecursiveChildInfo(data, item.id);
      if (children.length > 0) {
        (item.childInfo as IBalanceSheet[]) = children;
      }
      result.push(item);
    }
  }

  return result;
};

export const CalculateDifference = (asset: number, liability: number) => {
  return {
    grandTotal:
      asset > liability ? replaceDecimal(asset) : replaceDecimal(liability),
    difference: replaceDecimal(Math.abs(asset - liability)),
  };
};

export const BalanceSheet = () => {
  const toDate = "0";
  const fromDate = "0";
  const location = useLocation();
  const { businessUserInfo } = useAuth();

  const [date, setDate] = useState<string>();
  const [showRow, setShowRow] = useState(false);
  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);
  const [colNumber, setColNumber] = useState(0);
  const { data, isLoading } = useBalanceSheet(date);
  const [uptoDate, setUptoDate] = useState<string>();
  const col_Span = colNumber;
  const liability_data = data?.liabilities;
  const asset_data = data?.assets;

  const startingLiabilityId = liability_data?.[0]?.id || 0;
  const startingAssetsId = asset_data?.[0]?.id || 0;
  const liabilitiesOrder = BalanceSheetOrderName.orderLiabilities;
  const assetsOrder = BalanceSheetOrderName.orderAssets;

  //functions
  const formattedDate = businessUserInfo?.fiscal_year_info?.first_fiscal_date
    .split("/")
    .join("-");

  const assetVal: number = asset_data ? asset_data?.[0]?.total : 0;
  const liabVal: number = liability_data ? liability_data?.[0]?.total : 0;

  const handleRow = () => {
    setShowRow(!showRow);
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt  + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const newLiabilityArr =
            liability_data?.map((item) => {
              return item.id;
            }) || [];

          const newAssetArr =
            asset_data?.map((item) => {
              return item.id;
            }) || [];

          const concatenatedArr = newLiabilityArr.concat(newAssetArr);

          setCheckedValues(concatenatedArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, keyDownChecked]);

  useEffect(() => {
    if (showRow) {
      setColNumber(2);
    } else {
      setColNumber(4);
    }
  }, [showRow]);

  const handleOnChange = (e: any, item: IBalanceSheet) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };

  return (
    <>
      <div className="balance-sheet">
        <div className="head-section">
          <div className="small-title">Balance Sheet</div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="date" className="input-label">
                  Upto Date
                </label>
                <NepaliDatePicker
                  currentDate={true}
                  onDateSelect={(value: any) => {
                    setDate(value);
                    setUptoDate(value);
                  }}
                />
              </div>
            </div>

            <div className="col-md-5"></div>
            <div className="col-md-3 d-flex">
              <div className="check-field">
                <input
                  type="checkbox"
                  id={`show_row`}
                  checked={!showRow ? false : true}
                  onClick={() => handleRow()}
                />
                <label className="black-content" htmlFor={`show_row`}>
                  Show vertically
                </label>
              </div>
            </div>
            <div className="col-md-2 ">
              <div className="d-flex gap-3 float-right">
                <button
                  className="d-flex btn btn-light"
                  onClick={() => {
                    if (uptoDate !== undefined) {
                      nestedTableExcel(
                        "exportTable",
                        location,
                        businessUserInfo as UserInfoResponse,
                        fromDate,
                        toDate,
                        col_Span,
                        uptoDate
                      );
                    }
                  }}
                >
                  <span className="export-title"> Export</span>
                  <ExcelIcon />
                </button>
                <button
                  className="d-flex btn btn-light my-4 "
                  onClick={() => {
                    financialReportPrint(
                      [],
                      businessUserInfo as UserInfoResponse,
                      date as string,
                      "exportTable",
                      location
                    );
                  }}
                >
                  <span
                    style={{ marginRight: "10px" }}
                    className="export-title"
                  >
                    Print{" "}
                  </span>
                  <PrintIcon />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md12-12"> </div>
          <div className="col-md-12">
            <div className="top-head">
              <h2 className="small-title">
                {businessUserInfo?.business_info.name}
              </h2>
              <p className="flex bold-content">
                {businessUserInfo?.business_info.address}
              </p>

              <div className="div-flex bold-content">
                <div className="small-text">Balance Sheet</div>

                <p className="black-text">As an {date}</p>
              </div>
            </div>

            <div className="table-section" id="exportTable">
              {!showRow ? (
                <BalanceSheetHorizontal
                  date={date}
                  assetVal={assetVal}
                  liabVal={liabVal}
                  liabilitiesOrder={liabilitiesOrder}
                  assetsOrder={assetsOrder}
                  formattedDate={formattedDate}
                  handleOnChange={handleOnChange}
                  checkedValues={checkedValues}
                  startingLiabilityId={startingLiabilityId}
                  startingAssetsId={startingAssetsId}
                  isLoading={isLoading}
                  liability_data={liability_data}
                  asset_data={asset_data}
                />
              ) : (
                <BalanceSheetVartical
                  date={date}
                  assetVal={assetVal}
                  liabVal={liabVal}
                  liabilitiesOrder={liabilitiesOrder}
                  assetsOrder={assetsOrder}
                  formattedDate={formattedDate}
                  handleOnChange={handleOnChange}
                  checkedValues={checkedValues}
                  startingLiabilityId={startingLiabilityId}
                  startingAssetsId={startingAssetsId}
                  isLoading={isLoading}
                  liability_data={liability_data}
                  asset_data={asset_data}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
