import React, { useContext, useEffect, useMemo, useState } from "react";
import "./ReciptSlip.scss";
import { AddableBank } from "../AddableBank/AddableBank";
import { toast } from "react-toastify";
import { ReciptValue, useReciptWrapper } from "./ReciptFormikWrapper";
import { replaceDecimal } from "../../utils/services";
import { ErrorMessage, Field } from "formik";
import { DocumentContext } from "../BillPage/Document-Context";
import { PopupContext } from "../PopUp/Popup-Context";
export interface bankDetailProps {
  id: number;
  name: string;
}

export interface CustomerData {
  name: string;
  pan: number | undefined;
  mobile_no: string;
  user_role_id: number | undefined;
  user_id: number | undefined;
  role_id: number | undefined;
  role: string;
  credit_total: any | undefined;
}
interface ReciptSlipProps {
  selectedUserObj?: CustomerData;
  setSelectedUserObj: React.Dispatch<
    React.SetStateAction<CustomerData | undefined>
  >;
  LedgerType: string;
  creatable: boolean;
}

export const ReciptSlip: React.FC<ReciptSlipProps> = ({
  setSelectedUserObj,
  selectedUserObj,
  LedgerType,
  creatable,
}) => {
  const [checkBox, setCheckBox] = useState(false);

  const { setFieldValue, values, handleSubmit } = useReciptWrapper();
  const { isSubmitted } = useContext(DocumentContext);
  const { openModal } = useContext(PopupContext);

  useEffect(() => {

    if ((values.payType === 'both' || values.payType === 'cheque') && Number(values.cash) + Number(values.bank[0].amount) > selectedUserObj?.credit_total) {
      toast.warn(
        `Total amount can't be greater than  ${selectedUserObj?.credit_total ?? 0
        }`
      );
    }
  }, [values.bank[0].amount, values.cash]);

  const handleCash = (value: any) => {
    console.log(value, values.bank[0].amount)
    if (values.payType === 'both' && Number(value) + Number(values.bank[0].amount) > selectedUserObj?.credit_total) {
      toast.warn(
        `Total amount can't be greater than  ${selectedUserObj?.credit_total ?? 0
        }`
      );
    } else if (value <= selectedUserObj?.credit_total) {
      setFieldValue("cash", replaceDecimal(value));
    } else {
      toast.warn(
        `Cash amount can't be greater than  ${selectedUserObj?.credit_total ?? 0
        }`
      );
    }
  };


  const handleCheck = () => {
    setCheckBox((checkBox) => !checkBox);
  };

  useMemo(() => {
    setFieldValue("total_credit", selectedUserObj?.credit_total ?? 0);
  }, [selectedUserObj?.credit_total]);

  useEffect(() => {
    setFieldValue("bank", [
      {
        cheque_no: "",
        amount: 0,
        cheque_issue_date: "",
        bank_id: "",
      },
    ]);
  }, [isSubmitted]);

  useMemo(() => {
    const totalAmount =
      Number(values.cash ?? 0) + Number(values?.bank[0]?.amount ?? 0);
    setFieldValue("total", totalAmount);
    setFieldValue("user_role_id", selectedUserObj?.user_role_id);
  }, [selectedUserObj?.user_role_id, values?.bank, values.cash]);

  const PayTypeCheck = (value: "cash" | "cheque") => {
    if (values.payType === "both") {
      if (value === "cash") {
        setFieldValue("payType", "cheque");
        return;
      }
      if (value === "cheque") {
        setFieldValue("payType", "cash");
        return;
      }
    } else if (values.payType !== "" && values.payType === value) {
      setFieldValue("payType", "");
      return;
    } else if (values.payType !== "" && values.payType !== value) {
      setFieldValue("payType", "both");
      return;
    }
    setFieldValue("payType", value);
    return;
  };
  const handleClear = () => {
    setFieldValue("cash", 0);
  };

  return (
    <>
      <div className="check-box-wrapper">
        <div className="check-box-section">
          <input
            className={checkBox ? "checkbox onButton" : "checkbox offButton"}
            type="checkbox"
            onChange={() => {
              PayTypeCheck("cash");
              handleCheck();
              setFieldValue("cash", 0);
            }}
            checked={values.payType === "cash" || values.payType === "both"}
          />

          <label className="check-label">Cash</label>
          <input
            type="checkbox"
            className="checkbox"
            onChange={() => {
              PayTypeCheck("cheque");
              setFieldValue("bank", [
                {
                  cheque_no: "",
                  amount: 0,
                  cheque_issue_date: "",
                  bank_id: "",
                },
              ]);
            }}
            checked={values.payType === "cheque" || values.payType === "both"}
          />
          <label className="check-label">Cheque</label>
        </div>
        <ErrorMessage className="error" component="div" name="payType" />
      </div>

      <div className="recipt-slip-page">
        {(values.payType === "cash" || values.payType === "both") && (
          <div className="cash-amt">
            <label className="input-label">Amount</label>
            <Field
              className="input-field"
              pattern="[0-9]*"
              onFocus={(e: any) => {
                if (parseInt(e.target.value) === 0) {
                  e.target.value = "";
                }
              }}
              value={values.cash}
              onChange={(val: any) => {
                handleCash(val.target.value);
              }}
            />
            <ErrorMessage component="div" name="cash" className="error" />
          </div>
        )}
        {(values.payType === "cheque" || values.payType === "both") && (
          <div>
            <AddableBank
              LedgerType={LedgerType}
              creatableSelect={creatable}
              name="Pay by Cheque (Optional)"
              buttonName="Add Cheque"
              values={values as ReciptValue}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
      </div>
      <div className="footer-space">
        <div className="d-end">
          <div>
            <button
              className="light-secondary mr-16"
              type="button"
              onClick={() => {
                handleClear();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="main-button"
              type="submit"
              onClick={() => {
                openModal();
                handleSubmit();
              }}
            >
              Add Receipt
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
