import { Formik, FormikConfig, useFormikContext } from "formik";
import { useContext } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useCategoryTableList
} from "../Api/Get/GetApi";
import { PostUpdateCategory } from "../Api/Post/PostApi";
import { PopupContext } from "../PopUp/Popup-Context";

interface UpdateCategory {
  id: number;
  name: string;
}

export const initialValues: UpdateCategory = {
  id: -1,
  name: "",
};

const validationSchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().required("Please enter Category name"),
});

export const useUpdateCategoryWrapper = () => {
  const formik = useFormikContext<UpdateCategory>();

  if (!formik) {
    throw new Error("useUpdateCategoryWrapper must be used within a Formik");
  }

  return formik;
};

interface UpdateCategoryWrapperProps {
  children: React.ReactNode;
}

export const UpdateCategoryWrapper: React.FC<UpdateCategoryWrapperProps> = ({
  children,
}) => {
  const { closeModal } = useContext(PopupContext);
  const { refetch: CategoryOptionRefetch } = useCategoryTableList();

  const handleSubmit: FormikConfig<UpdateCategory>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);

    const id = values.id;
    const data = {
      name: values.name,
    };

    const newRoleArr = [];
    newRoleArr.push(Number(values.name));
    PostUpdateCategory(id, data)
      .then((res) => {
        CategoryOptionRefetch();
        toast.success("Category updated successfully");
        setSubmitting(false);
        closeModal();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to update Category";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.status.status_code === 422) {
          errorMessage = err?.response?.data?.status?.status_message[0].msg;
        } else if (err.response.data.status.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
