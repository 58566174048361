import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";
import { useDebtorList } from "../Api/Get/GetApi";
import { CreditorListResponse } from "../Api/types";
import { Table } from "../CustomReactTable/CustomReactTable";

interface ViewAlProps {
  viewAll: boolean;
}
export const DebtorList: React.FC<ViewAlProps> = ({ viewAll }) => {
  const [queryPageIndex, setQueryPageIndex] = useState(0);

  const [queryPageSize, setQueryPageSize] = useState(() => (viewAll ? 10 : 5));

  //useQuery
  const { data: creditorList, isLoading } = useDebtorList(
    queryPageIndex,
    queryPageSize
  );

  const FormatTable = (
    array: CreditorListResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item: CreditorListResponse, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          name: item?.user_info?.name || "-",
          mobile_no: item?.user_info?.mobile_no || "-",
          amount: parenthesisInNegativeValue(item?.amount),
        };
      });
      return formatData;
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: "s_n",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile No.",
        accessor: "mobile_no",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
    ],
    []
  );

  return (
    <div className="dashboard-table card-height">
      {!viewAll ? (
        <>
          <Table
            loading={isLoading}
            columns={columns}
            data={
              creditorList
                ? FormatTable(creditorList.data, queryPageIndex, queryPageSize)
                : []
            }
            totalPages={1}
            totalEntries={creditorList && creditorList.pagination.total_count}
            queryPageIndex={queryPageIndex}
            setQueryPageIndex={setQueryPageIndex}
            queryPageSize={queryPageSize}
            setQueryPageSize={setQueryPageSize}
            showPagination={false}
          />
          <Link to={`/debtor-list`} className="view-btn">
            <button
              type="button"
              className="content light-secondary next small-button "
            >
              View All
            </button>
          </Link>
        </>
      ) : (
        <>
          <div className="head-section">
            <p className="small-title">Debtors List</p>
          </div>
          <Table
            excelDownload={true}
            loading={isLoading}
            columns={columns}
            data={
              creditorList
                ? FormatTable(creditorList.data, queryPageIndex, queryPageSize)
                : []
            }
            totalPages={
              creditorList &&
              totalPages(queryPageSize, creditorList.pagination.total_count)
            }
            totalEntries={creditorList && creditorList.pagination.total_count}
            queryPageIndex={queryPageIndex}
            setQueryPageIndex={setQueryPageIndex}
            queryPageSize={queryPageSize}
            setQueryPageSize={setQueryPageSize}
            showPageSize={true}
            smallPagination={true}
          />
        </>
      )}
    </div>
  );
};
