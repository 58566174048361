import { Formik, FormikConfig, useFormikContext } from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useCustomerOptions,
  useGetSpecificCustomer,
  usePatronList,
  useVendorList,
} from "../Api/Get/GetApi";
import { PostCreateCustomer } from "../Api/Post/PostApi";
import { UpdatePatronDetail } from "../Api/Put/PutApi";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { PopupContext } from "../PopUp/Popup-Context";
import { HandleError } from "../../utils/services";

export interface NewCustomerValue {
  id?: number;
  pan: number | null;
  mobile_no: number | null;
  name: string;
  role_id: number | null | number[] | string;
  additional_info: {
    address: string;
  };
  email: string;
  amount?: number;
  bill_due_notice_day: number;
  transaction_column_type?: string;
}

export const initialValues: NewCustomerValue = {
  pan: null,
  mobile_no: null,
  name: "",
  role_id: "",
  additional_info: {
    address: "",
  },
  email: "",
  amount: 0,
  bill_due_notice_day: 0,
  transaction_column_type: "dr",
};
const validationSchema = Yup.object().shape({
  pan: Yup.string()
    .length(9, "Must be of 9 digit")
    .when(["role_id"], {
      is: "4",
      then: Yup.string().required("Please enter the pan no").nullable(),
    })
    .nullable(),

  mobile_no: Yup.string()
    .length(10, "Must be 10 digit")
    .when(["role_id"], {
      is: (id: any) => id === "5" || id === "6",
      then: Yup.string().required("Please enter your number").nullable(),
    })
    .nullable(),
  //
  name: Yup.string().required("Please enter customer name"),
  role_id: Yup.string().required("Please select a role"),
  email: Yup.string().email("Must be a valid email"),
  bill_due_notice_day: Yup.number().when(["role_id"], {
    is: "4",
    then: Yup.number().positive("Please enter the bill due notice days"),
  }),
  additional_info: Yup.object().shape({
    address: Yup.string().required("Please enter  address"),
  }),
});

export const useNewCustomerWrapper = () => {
  const formik = useFormikContext<NewCustomerValue>();
  if (!formik) {
    throw new Error("useNewCustomerWrapper must be used within a Formik");
  }
  return formik;
};
interface NewCustomeWrapperProps {
  children: React.ReactNode;
  initialValueProps?: { id: number; name: string };
}

export const NewCustomeWrapper: React.FC<NewCustomeWrapperProps> = ({
  children,
  initialValueProps,
}) => {
  const { closeModal } = useContext(PopupContext);
  const { refetch } = usePatronList();
  const { refetch: customerOptionRefetch } = useCustomerOptions();
  const { refetch: vendorRefetch } = useVendorList();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const handleSubmit: FormikConfig<NewCustomerValue>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);
    const data = {
      ...values,
    };
    const newRoleArr = [];
    newRoleArr.push(Number(values.role_id));
    data.role_id = newRoleArr as number[];
    data.pan = Number(values.pan) > 0 ? Number(values.pan) : null;
    data.bill_due_notice_day = Number(values.bill_due_notice_day);
    data.amount = Number(values.amount);
    if (data.amount <= 0) delete data.transaction_column_type;
    if (initialValueProps) {
      //Update data
      UpdatePatronDetail(initialValueProps.id as number, data)
        .then((res) => {
          showSuccessMessage("Edit successfully");
          setSubmitting(false);
          closeModal();
          refetch();
          customerOptionRefetch();
          vendorRefetch();
        })
        .catch((err: any) => {
          const errorMessage = HandleError(err);
          showErrorMessage(errorMessage);
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    PostCreateCustomer(data)
      .then((res) => {
        toast.success("New patron added successfully");
        setSubmitting(false);
        closeModal();
        refetch();
        customerOptionRefetch();
        vendorRefetch();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to submit new customer";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.status.status_code === 422) {
          errorMessage = err?.response?.data?.status?.status_message[0].msg;
        } else if (err.response.data.status.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
