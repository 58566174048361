import { Formik, FormikConfig, useFormikContext } from "formik";
import { Fragment, useContext } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useCategoryTableList,
  usePatronList,
  useVendorList,
} from "../Api/Get/GetApi";
import { PostCreateCategory } from "../Api/Post/PostApi";
import { PopupContext } from "../PopUp/Popup-Context";

interface NewCategoryName {
  name: string;
}

export const initialValues: NewCategoryName = {
  name: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter Category name"),
});

export const useNewCategoryWrapper = () => {
  const formik = useFormikContext<NewCategoryName>();

  if (!formik) {
    throw new Error("useNewCategoryWrapper must be used within a Formik");
  }

  return formik;
};

interface NewCategoryWrapperProps {
  children: React.ReactNode;
}

export const NewCategoryWrapper: React.FC<NewCategoryWrapperProps> = ({
  children,
}) => {
  const { closeModal } = useContext(PopupContext);
  const { refetch } = usePatronList();
  const { refetch: CategoryOptionRefetch } = useCategoryTableList();
  const { refetch: vendorRefetch } = useVendorList();

  const handleSubmit: FormikConfig<NewCategoryName>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);
    const data = {
      ...values,
    };
    const newRoleArr = [];
    newRoleArr.push(Number(values.name));
    PostCreateCategory(data)
      .then((res) => {
        toast.success("New Category added successfully");
        setSubmitting(false);
        closeModal();
        refetch();
        CategoryOptionRefetch();
        vendorRefetch();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to submit new Category";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.status.status_code === 422) {
          errorMessage = err?.response?.data?.status?.status_message[0].msg;
        } else if (err.response.data.status.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
