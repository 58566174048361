import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  PartyLedgerOptionFormatter,
  fomratColumns,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";
import {
  useAllCustomerList,
  useJournalEntry,
  usePartyLedger,
} from "../Api/Get/GetApi";
import { CustomerListResponse, PartyWiseLedgerResponse } from "../Api/types";
import { Table } from "../CustomReactTable/CustomReactTable";
import { JournalDetail } from "../JournalDetail/JournalDetail";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { FilterDate } from "../SalesList/SalesListPage";
import { SelectCustomerBill } from "../SelectCustomerBill/SelectCustomerBill";
import "./Partyledger.scss";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";
import { SelectOptions } from "../../Pages/SalesReturn/types";

export type chipState = "Receipt" | "Ledger" | "Transaction" | "";

export const Partyledger = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [requireId, setRequireId] = useState<{
    journalId: number | undefined;
  }>({
    journalId: undefined,
  });

  const { openModal } = useContext(PopupContext);

  const [selectedCustomer, setSelectedCustomer] = useState<SelectOptions>();
  const { data } = useAllCustomerList();
  const { data: partyLedger, refetch } = usePartyLedger(
    queryPageIndex,
    queryPageSize,
    date,
    selectedCustomer?.value
  );
  const { data: journalData } = useJournalEntry(requireId.journalId);
  const column = [
    "SN",
    "Date",
    "Document Type",
    "Document Number",
    "Debit",
    "Credit",
    "Balance",
  ];

  const FormatTable = (
    array: PartyWiseLedgerResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          sn: tableIndexNumber(pageIndex, pageSize, index),
          date: item.miti,
          document_type: item.document_type,
          document_number: item.document_number,
          debit: parenthesisInNegativeValue(item.debit),
          credit: parenthesisInNegativeValue(item.credit),
          id: item.id,
          balance: parenthesisInNegativeValue(item.balance),
        };
      });
      return formatData;
    }
    return [];
  };
  const [popupTypes, setPopupType] = useState({
    journal: false,
  });
  const handleDoubleClick = (row: PartyWiseLedgerResponse) => {
    setRequireId((p) => ({ ...p, journalId: row.id }));
    setPopupType((p) => ({ ...p, journal: true }));

    openModal();
  };

  useEffect(() => {
    refetch();
  }, [date.dateFrom, date.dateTo]);

  return (
    <>
      <div className="pay-slip-page">
        <div>
          <div className="head-section">
            <div className="small-title">Party Wise Ledger</div>
          </div>
        </div>
      </div>
      <div>
        <React.Fragment>
          <Table
            data={
              partyLedger
                ? FormatTable(partyLedger.data, queryPageIndex, queryPageSize)
                : []
            }
            columns={fomratColumns(column)}
            excelDownload={true}
            totalEntries={partyLedger && partyLedger.pagination.total_count}
            totalPages={
              partyLedger &&
              totalPages(queryPageSize, partyLedger.pagination.total_count)
            }
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            setQueryPageIndex={setQueryPageIndex}
            setQueryPageSize={setQueryPageSize}
            showDate={true}
            date={date}
            setDate={setDate}
            onDoubleClick={handleDoubleClick}
            options={PartyLedgerOptionFormatter(
              data ? (data?.data as CustomerListResponse[]) : []
            )}
            optionlabel="Patron"
            selectValue={selectedCustomer}
            setSelectValue={setSelectedCustomer}
            showSelectOptions={true}
          />
        </React.Fragment>
      </div>
      {popupTypes.journal &&
        requireId.journalId !== undefined &&
        journalData && (
          <PopUp
            showCrossIcon={true}
            popupType="full"
            popupWidth={"100%"}
            showHeader={true}
            popupHeight={"100%"}
            renderComponent={<JournalDetail journalData={journalData} />}
          />
        )}
    </>
  );
};
