import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExcelIcon, PrintIcon } from "../../assets/images/svg/svg";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { useProfitLossList, useTradingAccountList } from "../Api/Get/GetApi";
import { IProfitLossList, ITradingAccountList } from "../Api/Get/GetApiTypes";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import { FilterDate } from "../SalesList/SalesListPage";
import { VerticalPLAccount } from "./VerticalPLAccount";
import "./TradingAccount.scss";
import { HorizontalPLAccount } from "./HorizontalPLAccount";
import { isCurrentFiscalYear } from "../../utils/services";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export const NewProfitLossAc = () => {
  const uptoDate = "";
  const location = useLocation();
  const { businessUserInfo } = useAuth();
  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);
  const [activeItem, setActiveItem] = useState(null);
  const [showRow, setShowRow] = useState(false);
  const [colNumber, setColNumber] = useState(0);
  const col_Span = colNumber;

  const formattedDate = businessUserInfo?.fiscal_year_info?.first_fiscal_date
    .split("/")
    .join("-");

  const lastFiscalDate: any =
    businessUserInfo?.fiscal_year_info?.last_fiscal_date.split("/").join("-");

  const currentFiscalYear = isCurrentFiscalYear(
    businessUserInfo?.fiscal_year_info?.current_fiscal_year
  );

  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });

  const { data: tading_data, isLoading } = useTradingAccountList(date);
  
  const { data } = useProfitLossList(date);

  const handleRow = () => {
    setShowRow(!showRow);
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const newDebitArr =
            tading_data?.debit_data_list?.map((item) => {
              return item.id;
            }) || [];

          const newCreditArr =
            tading_data?.credit_data_list?.map((item) => {
              return item.id;
            }) || [];
          const newLiabilityArr =
            data?.debit_profit_loss?.map((item) => {
              return item.id;
            }) || [];

          const newAssetArr =
            data?.credit_profit_loss?.map((item) => {
              return item.id;
            }) || [];

          const concatenatedArr = newDebitArr.concat(
            newCreditArr,
            newLiabilityArr,
            newAssetArr
          );

          setCheckedValues(concatenatedArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, keyDownChecked]);

  useEffect(() => {
    if (showRow) {
      setColNumber(3);
    } else {
      setColNumber(6);
    }
  }, [showRow]);

  const toggleAccordion = (id: any) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
    }
  };

  const handleOnChange = (e: any, item: IProfitLossList) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };

  const handleChange = (e: any, item: ITradingAccountList) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };

  const handleDoubleClick = (id: number) => {
    const url = `/ledger-wise-report?ledger-id=${encodeURIComponent(
      id
    )}&dateFrom=${encodeURIComponent(
      date?.dateFrom || ""
    )}&dateTo=${encodeURIComponent(date?.dateTo || "")}`;
    window.open(url, "_blank");
  };

  return (
    <div className="trading-balance-page">
      <div className="head-section">
        <h2 className="small-title">Profit Loss Account</h2>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tarding-balance-table">
            <div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group mg-btm-16">
                      <label htmlFor="date" className="input-label">
                        From Date
                      </label>
                      {/* <NepaliDatePicker
                        className="input-field"
                        placeholder="Select a date"
                        showResetDateButton={false}
                        defaultDate={formattedDate as string}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateFrom: value,
                          }));
                        }}
                      /> */}
                      <NepaliDatePicker
                        defaultDate={formattedDate as string}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateFrom: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="date" className="input-label">
                        To Date
                      </label>
                      {/* <NepaliDatePicker
                        showResetDateButton={false}
                        className="input-field"
                        // value={data?.date_info.date_to}
                        placeholder="Select a date"
                        defaultDate={currentFiscalYear ? true : lastFiscalDate}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateTo: value,
                          }));
                        }}
                      /> */}
                      <NepaliDatePicker
                        currentDate={currentFiscalYear as boolean}
                        defaultDate={currentFiscalYear ? "" : lastFiscalDate}
                        onDateSelect={(value: any) => {
                          setDate?.((p) => ({
                            ...p,
                            dateTo: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-5 ">
                    <div className="d-flex d-end check-field">
                      <div className="check-fields">
                        <input
                          type="checkbox"
                          id={`show_row`}
                          checked={!showRow ? false : true}
                          onClick={() => handleRow()}
                        />
                        <label className="black-content" htmlFor={`show_row`}>
                          Show vertically
                        </label>
                      </div>
                      <div className="d-flex gap-3 float-right ">
                        <button
                          onClick={() =>
                            nestedTableExcel(
                              "exportTable",
                              location,
                              businessUserInfo as UserInfoResponse,
                              date?.dateFrom,
                              date?.dateTo,
                              col_Span,
                              uptoDate
                            )
                          }
                          className="d-flex btn btn-light my-4 "
                        >
                          <span className="export-title"> Export </span>
                          <ExcelIcon />
                        </button>
                        <button
                          className="d-flex btn btn-light my-4 "
                          onClick={() => {
                            financialReportPrint(
                              [],
                              businessUserInfo as UserInfoResponse,
                              date as FilterDate,
                              "exportTable",
                              location
                            );
                          }}
                        >
                          <span
                            style={{ marginRight: "10px" }}
                            className="export-title"
                          >
                            Print{" "}
                          </span>
                          <PrintIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top-head">
                <h2 className="small-title">
                  {businessUserInfo?.business_info.name}
                </h2>
                <p className="flex bold-content">
                  {businessUserInfo?.business_info.address}
                </p>

                <div className="div-flex bold-content">
                  <div className="small-text">Profit Loss Account</div>

                  <p className="black-text">
                    For the period from {date?.dateFrom} to {date?.dateTo}
                  </p>
                </div>
              </div>

              <div id="exportTable">
                {!showRow ? (
                  <HorizontalPLAccount
                    checkedValues={checkedValues}
                    toggleAccordion={toggleAccordion}
                    handleOnChange={handleOnChange}
                    handleChange={handleChange}
                    handleDoubleClick={handleDoubleClick}
                    tading_data={tading_data}
                    isLoading={isLoading}
                    data={data}
                  />
                ) : (
                  <VerticalPLAccount
                    checkedValues={checkedValues}
                    toggleAccordion={toggleAccordion}
                    handleOnChange={handleOnChange}
                    handleChange={handleChange}
                    handleDoubleClick={handleDoubleClick}
                    tading_data={tading_data}
                    isLoading={isLoading}
                    data={data}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
