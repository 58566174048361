import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import { ErrorMessage, Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { capitalCase } from "../../utils/services";
import { PopupContext } from "../PopUp/Popup-Context";
import { Select } from "../Select";
import "./BusinessInfoForm.scss";
import { BusinessValue, useBusinessInfo } from "./BusinessInfoWrapper";
import { BusinessType } from "../../EnumValues";

interface BusinessInfoProps {
  businessData?: BusinessValue;
  showCancelButton?: boolean;
}

export const BusinessInfoForm: React.FC<BusinessInfoProps> = ({
  businessData,
  showCancelButton = true,
}) => {
  const { handleSubmit, getFieldProps, setFieldValue, values, errors } =
    useBusinessInfo();
  const [handleShow, setHandleShow] = useState(false);
  const { closeModal } = useContext(PopupContext);
  // const [showIRD, setShowIRD] = useState(false);
  const businessTypeList: string[] = Object.values(BusinessType);

  useEffect(() => {
    setFieldValue("tax", 13);
    // if (
    //   businessData?.ird_username !== null ||
    //   businessData?.ird_password !== null
    // ) {
    //   setShowIRD(true);
    // }
  }, []);

  return (
    <div className="business-info-form">
      <div>
        <div className="business-body-data">
          <fieldset className="business-field-set">
            <legend className="small-title">Business Information:</legend>
            <div className="row">
              <div className="col-md-6">
                <div className="data-field">
                  <label className="input-label">Name</label>
                  <div>
                    <Field
                      className="input-field"
                      name="name"
                      placeholder="Enter name"
                      onChange={(e: any) => {
                        const item = capitalCase(e.target.value);
                        setFieldValue("name", item);
                      }}
                    />
                    <ErrorMessage
                      name={"name"}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Email</label>
                <div className="data-field">
                  <Field
                    className="input-field"
                    placeholder="Enter email"
                    {...getFieldProps("email")}
                  />
                  <ErrorMessage
                    name={"email"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Address</label>
                <div className="data-field no-bottom">
                  <Field
                    className="input-field"
                    name={`address`}
                    placeholder="Enter address"
                    onChange={(e: any) => {
                      const item = capitalCase(e.target.value);
                      setFieldValue("address", item);
                    }}
                  />
                  <ErrorMessage
                    name={`address`}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Mobile number</label>
                <div className="data-field">
                  <Field
                    type="tel"
                    maxLength={10}
                    className="input-field"
                    placeholder="Enter mobile number"
                    {...getFieldProps("mobile_no")}
                  />
                  <ErrorMessage
                    name={"mobile_no"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Pan number</label>
                <div className="data-field">
                  <Field
                    type="tel"
                    maxLength={9}
                    className="input-field"
                    placeholder="Enter pan number"
                    {...getFieldProps("pan")}
                  />
                  <ErrorMessage
                    name={"pan"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Registration number</label>
                <div className="data-field">
                  <Field
                    className="input-field"
                    //   name="registration_no"
                    placeholder="Enter registration number"
                    {...getFieldProps("registration_no")}
                  />
                  <ErrorMessage
                    name={"registration_no"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Business type</label>
                <div className="data-field">
                  <Select
                    onChange={(e) => {
                      setFieldValue("business_type", e.target.value);
                    }}
                    name="business_type"
                    value={
                      businessData?.business_type
                        ? businessData?.business_type
                        : values.business_type
                    }
                  >
                    <option value="" key="">
                      --Select--
                    </option>
                    {businessTypeList?.map((opt, optindex) => (
                      <option key={optindex} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name={"business_type"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label className="input-label">Establish Date</label>
                <div>
                  <NepaliDatePicker
                    className="input-field"
                    defaultDate={
                      businessData?.establish_date
                        ? businessData?.establish_date.split("/").join("-")
                        : true
                    }
                    onDateSelect={(value: any) => {
                      setFieldValue("establish_date", value);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label className="input-label">Logo</label>
                <div className="logo-field">
                  <input
                    className="input-field"
                    name="logo"
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={(e: any) => {
                      const value = e.target.files[0];
                      setFieldValue("logo", value === undefined ? null : value);
                    }}
                  />
                  {/* <ErrorMessage
                    name={"logo"}
                    component="div"
                    className="error"
                  /> */}
                </div>
              </div>
              <div className="col-md-6">
                <label className="input-label">Footer text(Optional)</label>
                <div className="data-field no-bottom">
                  <Field
                    className="input-field"
                    placeholder="Enter footer text"
                    {...getFieldProps("footer_text")}
                  />
                  <ErrorMessage
                    name={"footer_text"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label className="input-label">Website(Optional)</label>
                <div className="data-field no-bottom">
                  <Field
                    className="input-field"
                    //   name="business_type"
                    placeholder="Enter website url"
                    {...getFieldProps("website")}
                  />
                  <ErrorMessage
                    name={"website"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <div className="check-box-area d-flex">
            <input
              type="checkbox"
              checked={values.sync_with_IRD}
              {...getFieldProps("sync_with_IRD")}
            />
            <label className="content">Sync with ird</label>
          </div>
          {values.sync_with_IRD && (
            <fieldset className="ird-field-set">
              <legend className="small-title">IRD information:</legend>
              <div className="row">
                <div className="col-md-6">
                  <label className="input-label">IRD Username</label>
                  <div className="data-field no-bottom">
                    <Field
                      className="input-field"
                      //   name="ird_username"
                      placeholder="Enter username"
                      {...getFieldProps("ird_username")}
                    />
                    {errors.ird_username && (
                      <div className="error">{errors.ird_username}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-block password-area">
                    <label className="input-label">IRD Password</label>
                    <div className="data-field no-bottom">
                      <Field
                        className="input-field"
                        placeholder="Enter password"
                        type={handleShow ? "text" : "password"}
                        {...getFieldProps("ird_password")}
                      />
                    </div>
                    <div
                      className="eye-section"
                      onClick={() => {
                        setHandleShow(!handleShow);
                      }}
                    >
                      {handleShow ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#908F9B"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#908F9B"
                          className="bi bi-eye-slash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                          <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      )}
                    </div>
                  </div>
                  {errors.ird_password && (
                    <div className="error">{errors.ird_password}</div>
                  )}
                </div>
              </div>
            </fieldset>
          )}
        </div>
        <div className="btn-right">
          <div className="d-end">
            {showCancelButton && (
              <div>
                <button
                  className="light-secondary mr-16"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}

            <div>
              <button
                className="main-button"
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
