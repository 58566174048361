import React, { useCallback, useContext, useRef } from "react"
import {
  CancelBillIcon
} from "../../assets/images/svg/svg"
import "./PasswordPopup.scss"
import { PopupContext } from "./Popup-Context"

function useFocusNext() {
  const controls = useRef([])

  const handler = (event: any) => {
    if (event.keyCode === 13) {
      //@ts-ignore
      const index = controls.current.indexOf(event.target)
      const next = controls.current[index + 1]
      const current = controls.current[index]
      //@ts-ignore
      if (current.type !== "submit") {
        //@ts-ignore
        next && next.focus()
        event.preventDefault()
      }
    }
  }
  return useCallback((element: any) => {
    //@ts-ignore
    if (element && !controls.current.includes(element)) {
      //@ts-ignore
      controls.current.push(element)
      element.addEventListener("keydown", handler)
    }
  }, [])
}

interface ConfirmationPopupProps {
  handleCancel?: any;
  reason?:string;
  setReason?:React.Dispatch<React.SetStateAction<string>>
  confirmationQuestion:string
  showReasonField?:boolean
}

export const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({showReasonField=false, handleCancel,reason,setReason ,confirmationQuestion}) => {
  const { closeModal } = useContext(PopupContext)

  return (
    <div className="password-page">
      <div className="title-box">
        <CancelBillIcon />
      </div>

      <p className="extra-small-title">
        {confirmationQuestion}
      </p>
      {showReasonField &&    <div className="col-md-12">
        <div className="field-block password-area">
          <label className="input-label">Please specify the reason</label>
          <textarea
            className="input-field text-area-field"
            rows={3}
            placeholder="Write here..."
            onChange={(e) => {
              setReason?.(e.target.value)
            }}
          />
        </div>
      </div> }
   
      <div className="btn-right">
        <div className="d-end">
          <div>
            <button onClick={closeModal} className="light-secondary mr-16">
              No
            </button>
          </div>
          <div>
            <button
              className="main-button"
              onClick={() => {
             
                handleCancel?.()
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
