import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import { ErrorMessage, Field } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import { fixedValue, formatSelectOptions } from "../../utils/services";
import { usePaymentVoucher, useVendorOptions } from "../Api/Get/GetApi";
import { VandorListResponse } from "../Api/types";
import { DocumentContext } from "../BillPage/Document-Context";
import { Ledger } from "../Ledger/Ledger";
import "../PayslipPage/PayslipPage.scss";
import { useReciptWrapper } from "../ReciptSlip/ReciptFormikWrapper";
import { ReciptSlip } from "../ReciptSlip/ReciptSlip";

export type chipState = "Receipt" | "Ledger" | "Transaction" | "";

export type ledgerPageType = "paymentLedger" | "paySlipLedger";

export const PaymentVoucher = () => {
  const [patronList, setPatronList] = useState<VandorListResponse[]>();
  const [activeState, setActiveState] = useState<chipState>("");
  const [reciptActive, setReciptActive] = useState(false);
  const [ledgerActive, setLedgerActive] = useState(false);
  const [dateNew, setDateNew] = useState("");
  const { values, setFieldValue } = useReciptWrapper();


  const {
    selectedVendorObj,
    setSelectedVendorObj,
    setSelectedAPIType,
    selectedVendor,
    setSelectedVendor,
  } = useContext(DocumentContext);

  const { data } = useVendorOptions();
  const { data: paymentVoucherData } = usePaymentVoucher(selectedVendor?.value);

  useEffect(() => {
    setFieldValue("date", dateNew);
  }, [dateNew, values.date]);

  useEffect(() => {
    setSelectedVendor(null);
  }, []);

  useEffect(() => {
    if (data?.data) {
      setPatronList(data.data);
    }
  }, [data]);

  useMemo(() => {
    activeState === "Ledger" ? (
      <>
        {setLedgerActive(true)}
        {setReciptActive(false)}
      </>
    ) : activeState === "Receipt" ? (
      <>
        {setLedgerActive(false)}
        {setReciptActive(true)}
      </>
    ) : (
      <></>
    );
  }, [activeState]);

  useEffect(() => {
    if (paymentVoucherData) {
      setSelectedVendorObj(paymentVoucherData);
    }
  }, [paymentVoucherData]);

  return (
    <div className="pay-slip-page">
      <div>
        <div className="head-section">
          <div className="small-title">Payment Voucher</div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <label className="input-label">Vendor Name</label>
            <div className="search-field-customer auto-complete-search-wrap">
              <Select
                isClearable={true}
                maxMenuHeight={150}
                value={selectedVendor}
                options={formatSelectOptions<VandorListResponse>(
                  patronList as VandorListResponse[],
                  "additional_info",
                  "address"
                )}
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                  menu: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                }}
                onChange={(e) => {
                  setSelectedVendor(e as SelectOptions);
                  setSelectedAPIType("paymentVoucherApi");
                }}
                
              />
            </div>
            <div className="bill-info">
              <div className="amount-data d-flex">
                <div className="small-content uppercase">Due Amount:</div>
                <div className="extra-small-title">
                  Rs.{" "}
                  {selectedVendor && selectedVendorObj?.credit_total > 0
                    ? fixedValue(selectedVendorObj?.credit_total)
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="date" className="input-label">
                Date:
              </label>
              <NepaliDatePicker
                className="input-field"
                defaultDate={true}
                onDateSelect={(value: any) => {
                  setDateNew(value);
                }}
              />
              <ErrorMessage name={`date`} component="div" className="error" />
            </div>
          </div>
        </div>
        {/* {selectedVendorObj?.credit_total === undefined || null
          ? "undefined"
          : "..."} */}
        <div className="d-flex justify-content-between">
          <div className="d-flex tag-wrapper gap-2">
            <button
              className={`chip-tag recipt-bar small-content ${
                reciptActive ? "active" : ""
              }`}
              disabled={
                selectedVendorObj?.credit_total === undefined || null
                  ? true
                  : false
              }
              onClick={() => {
                setActiveState("Receipt");
                setFieldValue("hasBill", "paymentVoucher");
                setFieldValue("apiType", "paymentVoucherApi");
                setFieldValue("bill_number", "");
                setFieldValue("cash", 0);
              }}
            >
              Payment
            </button>
            <button
              className={`chip-tag recipt-bar small-content ${
                ledgerActive ? "active" : ""
              }`}
              disabled={
                selectedVendorObj?.credit_total === undefined || null
                  ? true
                  : false
              }
              // disabled={
              //   selectedVendorObj && values?.user_role_id && selectedVendor
              //     ? false
              //     : true
              // }
              onClick={() => {
                setActiveState("Ledger");
                setFieldValue("hasBill", "paymentVoucher");
              }}
            >
              Ledger
            </button>
          </div>
          {activeState === "Receipt" && selectedVendor && (
            <>
              <div className="col-md-4">
                <label className="input-label">Receipt Number</label>
                <Field
                  className="input-field"
                  pattern="[0-9]*"
                  onFocus={(e: any) => {
                    if (parseInt(e.target.value) === 0) {
                      e.target.value = "";
                    }
                  }}
                  value={values?.bill_number}
                  onChange={(val: any) => {
                    setFieldValue("bill_number", val.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="bill_number"
                  className="error"
                />
              </div>
            </>
          )}
        </div>
        {activeState === "Receipt" && selectedVendor && (
          <>
            <ReciptSlip
              LedgerType={"paymentLedger"}
              creatable={false}
              selectedUserObj={selectedVendorObj}
              setSelectedUserObj={setSelectedVendorObj}
            />
          </>
        )}
        {activeState === "Ledger" && selectedVendor && (
          <>
            <Ledger
              userRole={selectedVendorObj?.user_role_id ?? 0}
              LedgerType={"paymentLedger"}
            />
          </>
        )}
      </div>
    </div>
  );
};
