import React from "react";

interface BottomSheetContextType {
  bottomSheetcloseModal: () => void;
  bottomSheetopenModal: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValues = {
  bottomSheetcloseModal: () => {},
  bottomSheetopenModal: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
};

export const BottomSheetContext =
  React.createContext<BottomSheetContextType>(initialValues);

interface BottomSheetContextProps {
  children: React.ReactNode;
}

export const BottomSheetContextProvider: React.FC<BottomSheetContextProps> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const bottomSheetopenModal = () => {
    setIsModalOpen(true);
  };

  const bottomSheetcloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <BottomSheetContext.Provider
      value={{
        bottomSheetopenModal,
        bottomSheetcloseModal,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </BottomSheetContext.Provider>
  );
};
