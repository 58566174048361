import { Form } from "formik";
import { useSettingData } from "../Api/Get/GetApi";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import "./SettingPage.scss";
import {
  SettingPageWrapper,
  useSettingPage,
} from "./SettingPageWrapper/SettingPageWrapper";
import GeneralSetting from "./GeneralSetting";
import FinancialSetting from "./FinancialSetting";
import ReminderPoolSetting from "./ReminderPoolSetting";

export const SettingPage = () => {
  const { isSubmitting } = useSettingPage();
  const { data: settingData } = useSettingData();

  return (
    <Form>
      <div className="setting-page">
        <div className="row">
          <div className="head-section mg-btm-30">
            <h2 className="small-title">Setting</h2>
          </div>
          <div className="col-md-4">
            <GeneralSetting />
          </div>
          <div className="col-md-3">
            <FinancialSetting settingData={settingData} />
          </div>

          <div className="col-md-5">
            <ReminderPoolSetting />
          </div>
          <div className="col-md-12">
            <div className="footer-btn-section">
              <div className="float-right">
                <ButtonComponent
                  isLoading={isSubmitting}
                  type="submit"
                  title="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
