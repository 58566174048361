import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { CrossIcon, EyeIcon, HideEyeIcon } from "../../assets/images/svg/svg";
import { checkNumber, formatSelectOptions } from "../../utils/services";
import { RoleListResponce, StaffDetailResponse } from "../Api/types";
import Select from "react-select";
import "./NewUser.scss";
import { useAddUserWrapper } from "./NewUserWrapper";
import { useUserList } from "../Api/Get/GetApi";

interface NewUserProps {
  initialValues?: StaffDetailResponse;
}

export const NewUser: React.FC<NewUserProps> = ({ initialValues }) => {
  const [handleShow, setHandleShow] = useState({
    password: false,
    confirmPassword: false,
  });
  const {
    getFieldProps,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    isSubmitting,
  } = useAddUserWrapper();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileURL, setFileURL] = useState("");
  const { data: rolData, isLoading } = useUserList();

  useEffect(() => {
    if (initialValues) {
      setFieldValue("pan", initialValues.pan);
      setFieldValue("mobile_no", initialValues.mobile_no);
      setFieldValue("name", initialValues.name);
      setFieldValue("additional_info", initialValues.additional_info);
      setFieldValue("email", initialValues.email);
      setFieldValue("role_id", initialValues.roles[0].role.id);
      setFieldValue("user_role_id", initialValues.roles[0].id);
    }
  }, [initialValues]);

  const selectImg = (e: any) => {
    const value = e.target.files[0];
    const url = URL.createObjectURL(e.target.files[0]);
    setFileURL(url);
    setFieldValue("photo", value === undefined ? null : value);
  };
  const reset = () => {
    // @ts-ignore
    inputRef.current.value = "";
    setFileURL("");
    setFieldValue("photo", null);
  };

  return (
    <div className="admin-page">
      <div className="row">
        <div className="form-group col-md-6">
          <label className="input-label">Full Name*</label>
          <Field
            className="input-field capatilize"
            placeholder="Enter your full name"
            {...getFieldProps("name")}
          />
          <ErrorMessage name={"name"} component="div" className="error" />
        </div>
        <div className="form-group col-md-6">
          <label className="input-label">Mobile No.*</label>

          <Field
            className={`${errors.mobile_no && touched.mobile_no
              ? "input-field error-input"
              : "input-field"
              }`}
            onWheel={(e: any) => {
              (e.target as HTMLInputElement).blur();
            }}
            onFocus={(event: any) => {
              if (parseInt(event.target.value) === 0) {
                event.target.value = "";
              }
            }}
            onKeyPress={(e: any) => {
              if (parseInt(e.target.value) === 0) {
                e.target.value = "";
              }
            }}
            maxLength={10}
            placeholder="Enter mobile number"
            value={values.mobile_no}
            autoComplete="off"
            onChange={(e: any) => {
              setFieldValue("mobile_no", checkNumber(e.target.value));
            }}
          />
          <ErrorMessage name={"mobile_no"} component="div" className="error" />
        </div>

        {!initialValues && (
          <>
            <div className="form-group col-md-6">
              <label className="input-label">Password*</label>
              <Field
                className="input-field"
                name="password"
                autoComplete="off"
                type={handleShow.password ? "text" : "password"}
                placeholder="Enter password"
              />

              <div
                className="eye-section"
                onClick={() => {
                  setHandleShow((p) => ({
                    ...p,
                    password: !handleShow.password,
                  }));
                }}
              >
                {handleShow.password ? <EyeIcon /> : <HideEyeIcon />}
              </div>

              <ErrorMessage
                name={"password"}
                component="div"
                className="error"
              />
            </div>
            <div className="form-group col-md-6">
              <label className="input-label">Confirm Password*</label>
              <Field
                className="input-field"
                name="confirm_password"
                type={handleShow.confirmPassword ? "text" : "password"}
                placeholder="Enter password"
              />
              <div
                className="eye-section"
                onClick={() => {
                  setHandleShow((p) => ({
                    ...p,
                    confirmPassword: !handleShow.confirmPassword,
                  }));
                }}
              >
                {handleShow.password ? <EyeIcon /> : <HideEyeIcon />}
              </div>
              <ErrorMessage
                name={"confirm_password"}
                component="div"
                className="error"
              />
            </div>
          </>
        )}

        <div className="form-group col-md-6">
          <div className="select-field">
            <label className="input-label">Role*</label>

            <Select
              options={
                isLoading
                  ? []
                  : formatSelectOptions<RoleListResponce>(
                    rolData?.data as RoleListResponce[],
                    "id",
                    "name"
                  )
              }
              styles={{
                control: (base) => ({
                  ...base,
                  fontSize: "12px",
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: "12px",
                }),
              }}
              placeholder="Select Role"
              onChange={(e) => {
                setFieldValue("role_id", Number(e?.value));
              }}
            />

            <ErrorMessage name={"role_id"} component="div" className="error" />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="input-label">Pan No.</label>
          <Field
            className={`${errors.pan && touched.pan
              ? "input-field error-input"
              : "input-field"
              }`}
            onWheel={(e: any) => {
              (e.target as HTMLInputElement).blur();
            }}
            onFocus={(event: any) => {
              if (parseInt(event.target.value) === 0) {
                event.target.value = "";
              }
            }}
            onKeyPress={(e: any) => {
              if (parseInt(e.target.value) === 0) {
                e.target.value = "";
              }
            }}
            maxLength={9}
            placeholder="Enter pan number"
            value={values.pan}
            onChange={(e: any) => {
              setFieldValue("pan", checkNumber(e.target.value));
            }}
          />
          <ErrorMessage name={"pan"} component="div" className="error" />
        </div>

        <div className="form-group col-md-6">
          <label className="input-label">Email</label>
          <Field
            autocomplete="off"
            className="input-field capatilize"
            type="email"
            placeholder="Enter your Email"
            {...getFieldProps("email")}
          />
          <ErrorMessage name={"email"} component="div" className="error" />
        </div>
        <div className="form-group col-md-6">
          <label className="input-label">Address</label>
          <Field
            className="input-field capatilize"
            placeholder="Enter your address"
            name="additional_info.address"
          />
          <ErrorMessage
            name={"additional_info.address"}
            component="div"
            className="error"
          />
        </div>
        <div className="form-group col-md-6">
          <label className="input-label">Profile Picture</label>

          <input
            className="input-field"
            type="file"
            id="fileInput"
            accept="image/*"
            ref={inputRef}
            onChange={(e) => {
              selectImg(e);
            }}
          />
          {fileURL ? (
            <div className="img-section">
              <div className="logo-section">
                <div
                  className="cross"
                  onClick={() => {
                    reset();
                  }}
                >
                  <CrossIcon />
                </div>

                <img src={fileURL} className="logo" />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="btn-right">
          <div className="d-end">
            <div>
              <button
                className="light-secondary mr-16"
                onClick={() => {
                  resetForm();
                }}
                type="button"
              >
                Reset
              </button>
            </div>
            <div>
              <button
                className="main-button"
                disabled={isSubmitting}
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
