import { useEffect, useState } from "react";
import { useTrialBalanceList } from "../Api/Get/GetApi";
import { FilterDate } from "../SalesList/SalesListPage";
import { useLocation } from "react-router-dom";
import {
  ExcelIcon,
  PrintIcon,
  TriangleDown,
  TriangleRignt,
} from "../../assets/images/svg/svg";
import {
  checkCreditOrDebit,
  isCurrentFiscalYear,
  parenthesisInNegativeValue,
} from "../../utils/services";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { TrialBalanceResponce } from "../Api/Get/GetApiTypes";
import { Loader } from "../Loader/Loader";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import "./TrialBalancePage.scss";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export const RecursiveChildInfo = (
  data: TrialBalanceResponce[],
  groupId: number
): TrialBalanceResponce[] => {
  const result: TrialBalanceResponce[] = [];
  for (const item of data) {
    if (item.group_code === groupId) {
      const children = RecursiveChildInfo(data, item.id);
      if (children.length > 0) {
        (item.childInfo as TrialBalanceResponce[]) = children;
      }
      result.push(item);
    }
  }

  return result;
};

export const TrialBalancePage = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });

  const location = useLocation();
  const { businessUserInfo } = useAuth();

  const { data, isLoading } = useTrialBalanceList(date?.dateFrom, date?.dateTo);
  let count = 0;
  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);

  const col_Span = 6;
  const uptoDate = "";

  const formattedDate = businessUserInfo?.fiscal_year_info?.first_fiscal_date
    .split("/")
    .join("-");

  const lastFiscalDate: any =
    businessUserInfo?.fiscal_year_info?.last_fiscal_date.split("/").join("-");

  const currentFiscalYear = isCurrentFiscalYear(
    businessUserInfo?.fiscal_year_info?.current_fiscal_year
  );

  const filteredDebit =
    data && data?.data.filter((item) => item.group_code === 0);
  const totalDebit =
    filteredDebit &&
    filteredDebit.reduce((sum, item) => sum + item.total_debit, 0);
  const filteredCredit =
    data && data?.data.filter((item) => item.group_code === 0);
  const totalCredit =
    filteredCredit &&
    filteredCredit.reduce((sum, item) => sum + item.total_credit, 0);

  const handleOnChange = (e: any, item: TrialBalanceResponce) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt  + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const newLiabilityArr =
            data?.data?.map((item) => {
              return item.id;
            }) || [];

          setCheckedValues(newLiabilityArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, keyDownChecked]);

  return (
    <div className="trial-balance-page">
      <div className="head-section">
        <h2 className="small-title">Trial Balance</h2>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mg-btm-16">
                <label htmlFor="date" className="input-label">
                  From Date
                </label>
                {/* <NepaliDatePicker
                  className="input-field"
                  defaultDate={formattedDate as string}
                  placeholder="Select a date"
                  showResetDateButton={false}
                  onDateSelect={(value: any) => {
                    setDate?.((p) => ({
                      ...p,
                      dateFrom: value,
                    }));
                  }}
                /> */}
                <NepaliDatePicker
                  defaultDate={formattedDate as string}
                  onDateSelect={(value: any) => {
                    setDate?.((p) => ({
                      ...p,
                      dateFrom: value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mg-btm-16">
                <label htmlFor="date" className="input-label">
                  To Date
                </label>
                <NepaliDatePicker
                  currentDate={currentFiscalYear as boolean}
                  defaultDate={currentFiscalYear ? "" : lastFiscalDate}
                  onDateSelect={(value: any) => {
                    setDate?.((p) => ({
                      ...p,
                      dateTo: value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4 ">
          <div className="d-flex gap-3 float-right">
            <button
              className="d-flex btn btn-light"
              onClick={() =>
                nestedTableExcel(
                  "exportTable",
                  location,
                  businessUserInfo as UserInfoResponse,
                  date?.dateFrom,
                  date?.dateTo,
                  col_Span,
                  uptoDate
                )
              }
            >
              <span className="export-title"> Export</span>
              <ExcelIcon />
            </button>
            <button
              className="d-flex btn btn-light my-4 "
              onClick={() => {
                financialReportPrint(
                  [],
                  businessUserInfo as UserInfoResponse,
                  date as FilterDate,
                  "exportTable",
                  location
                );
              }}
            >
              <span style={{ marginRight: "10px" }} className="export-title">
                Print{" "}
              </span>
              <PrintIcon />
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <div className="top-head">
            <h2 className="small-title">
              {businessUserInfo?.business_info.name}
            </h2>
            <p className="flex bold-content">
              {businessUserInfo?.business_info.address}
            </p>

            <div className="div-flex bold-content">
              <div className="small-text">Trial Balance</div>

              <p className="black-text">
                For the period from {date?.dateFrom} to {date?.dateTo}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="trial-balance-table">
            <table className="table" id="exportTable">
              <thead className="table-header">
                <tr>
                  <th className="width-35" rowSpan={2}>
                    Particular
                  </th>
                  <th className="width-15" rowSpan={2}>
                    Opening Balance
                  </th>
                  <th className="width-30" colSpan={2}>
                    Transaction
                  </th>

                  <th className="width-15" rowSpan={2}>
                    Closing Balance
                  </th>
                </tr>
                <tr>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className="loder-wrapper">
                        <Loader loaderType="Spinner" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {RecursiveChildInfo(
                    data?.data as TrialBalanceResponce[],
                    0
                  )?.map((item, index) => {
                    count = count + 1;

                    const checkOpeningNumber = item?.total_opening < 0;
                    const openingAbsoluteValue = Math.abs(item?.total_opening);
                    const formattedOpening = checkOpeningNumber
                      ? `${openingAbsoluteValue} (Cr)`
                      : `${openingAbsoluteValue} (Dr)`;

                    const checkClosingNumber = item?.total_closing < 0;
                    const closingAbsoluteValue = Math.abs(item?.total_closing);
                    const formattedClosing = checkClosingNumber
                      ? `${closingAbsoluteValue} (Cr)`
                      : `${closingAbsoluteValue} (Dr)`;

                    return (
                      <>
                        {RecursiveBalanceSheetShow(
                          item.childInfo as TrialBalanceResponce[],
                          item,
                          index,
                          0,
                          14,
                          500,
                          "#000",
                          "normal",
                          date?.dateFrom,
                          date?.dateTo,
                          formattedClosing,
                          formattedOpening,
                          checkedValues,
                          handleOnChange
                        )}
                      </>
                    );
                  })}

                  <tr>
                    <td className="footer-content">Total</td>
                    <td className="footer-content">-</td>
                    <td className="footer-content">
                      {parenthesisInNegativeValue(totalDebit || 0)}
                    </td>
                    <td className="footer-content">
                      {parenthesisInNegativeValue(totalCredit || 0)}
                    </td>
                    <td className="footer-content">-</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const RecursiveBalanceSheetShow = (
  childInfo: TrialBalanceResponce[],
  item: TrialBalanceResponce,
  index: number,
  padding: number,
  fontSize: number,
  fontWeight: number,
  color: string,
  fontStyle: string,
  fromDate: string | undefined,
  toDate: string | undefined,
  formattedClosing: string,
  formattedOpening: string,
  checkedValues: Array<number>,
  handleOnChange: (e: any, item: TrialBalanceResponce) => void
): JSX.Element | null => {
  if (childInfo?.length <= 0) {
    return null;
  }
  const exists: any = checkedValues?.find((val) => val === item.id);

  const childAmountStyle = {
    fontSize: `${fontSize}px`,
    fontWeight: `${fontWeight}`,
    color: `${color}`,
    fontStyle: `${fontStyle}`,
  };

  const childStyle = {
    paddingLeft: `${padding}px`,
    fontWeight: `${fontWeight}`,
    color: `${color}`,
    fontStyle: `${fontStyle}`,
  };

  const handleDoubleClick = (id: number) => {
    const url = `/ledger-wise-report?ledger-id=${encodeURIComponent(
      id
    )}&dateFrom=${encodeURIComponent(fromDate || "")}
   &dateTo=  ${encodeURIComponent(toDate || "")}`;
    window.open(url, "_blank");
  };

  const AmountComponent: React.FC<{ amount: number; type?: string }> = ({
    amount,
    type = null,
  }) => {
    return (
      <p className="black-content" style={childAmountStyle}>
        {type === "Dr/Cr"
          ? checkCreditOrDebit(amount)
          : parenthesisInNegativeValue(amount)}
      </p>
    );
  };

  return (
    <>
      <tr
        onDoubleClick={() => {
          handleDoubleClick(item.id);
        }}
      >
        <td className="border-right">
          <div className="black-content text-left" style={childStyle}>
            <input
              className="checkbox-none"
              type="checkbox"
              id={`custom-checkbox-${item.id}`}
              name={item.ledger_name}
              value={item.ledger_name}
              checked={Boolean(exists)}
              onChange={(e) => {
                handleOnChange(e, item);
              }}
            />

            <label
              htmlFor={`custom-checkbox-${item.id}`}
              title="Double click to view ledger report"
            >
              {childInfo?.length > 0 && (
                <>{Boolean(exists) ? <TriangleDown /> : <TriangleRignt />}</>
              )}
              {item?.ledger_name}
            </label>
          </div>
        </td>
        <td>
          <AmountComponent amount={item?.total_opening} type="Dr/Cr" />
        </td>
        <td>
          <AmountComponent amount={item?.total_debit} />
        </td>
        <td>
          <AmountComponent amount={item?.total_credit} />
        </td>
        <td>
          <AmountComponent amount={item?.total_closing} type="Dr/Cr" />
        </td>
      </tr>
      {Boolean(exists) ? (
        <>
          {childInfo?.map((val, idx) => {
            return (
              <>
                {RecursiveBalanceSheetShow(
                  val.childInfo as TrialBalanceResponce[],
                  val,
                  idx,
                  padding + 20,
                  fontSize === 12 ? 12 : fontSize - 2,
                  fontWeight,
                  (color = "#666666"),
                  (fontStyle = "italic"),
                  fromDate,
                  toDate,
                  formattedClosing,
                  formattedOpening,
                  checkedValues,
                  handleOnChange
                )}
              </>
            );
          })}
        </>
      ) : (
        ""
      )}
    </>
  );
};
