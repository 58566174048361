import { useAuth } from "../context/auth/auth";
import { isMobile } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { useProSidebar } from "react-pro-sidebar";

import "bootstrap/dist/css/bootstrap.css";
import "./Header.scss";
import {
  BarIcon,
  ProfileIcon,
  SettingIcon,
  LogOutIcon,
  OpeningStockIcon,
  WalletIcon,
  LedgerIcon,
  PartyleadgerIcon,
  NotificationIcon,
  Shortcut,
} from "../../assets/images/svg/svg";
import { Link } from "react-router-dom";
import { ReadTransaction } from "../Api/Post/PostApi";
import { toast } from "react-toastify";

export const Header = () => {
  const { signOut, businessUserInfo } = useAuth();
  const { collapseSidebar } = useProSidebar();
  const handleClick = () => {
    ReadTransaction();
  };

  return (
    <div className="main-header">
      <div className={`logo-area ${isMobile ? "d-space-between" : "nav-flex"}`}>
        <div className="top-nav">
          <button className="nav-icon-btn" onClick={() => collapseSidebar()}>
            <BarIcon />
          </button>
          <div className={`${isMobile ? "" : "img-center"}`}>
            <a href={"/"}>
              <img
                src={require("../../assets/images/logo.png")}
                alt="logo"
                className="logo"
              />
            </a>
          </div>
          <div className="dropdown-wrapper mg-lf">
            <div className="business-profile dropdown show">
              <button
                className="profile-logo dropdown-toggle menu-btn"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-img-wrap">
                  <p className="manu-name ">Opening</p>
                </div>
              </button>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <Link to="/opening" className="dropdown-item extra-small-title">
                  <span className="icon">
                    <OpeningStockIcon />
                  </span>
                  <span className="mg-left-6">Opening Stock</span>
                </Link>
                <Link
                  to="/opening-balance"
                  className="dropdown-item extra-small-title"
                >
                  <span className="icon">
                    <WalletIcon />
                  </span>
                  <span className="mg-left-6">Opening Balance</span>
                </Link>
              </div>
            </div>
            <div className="business-profile dropdown show">
              <button
                className="profile-logo dropdown-toggle menu-btn"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-img-wrap">
                  <p className="manu-name ">Ledger</p>
                </div>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <Link
                  to="/party-ledger"
                  className="dropdown-item extra-small-title"
                >
                  <span className="icon">
                    <PartyleadgerIcon />
                  </span>
                  <span className="mg-left-6">Party Wise Ledger</span>
                </Link>
                <Link to="/ledger" className="dropdown-item extra-small-title">
                  <span className="icon">
                    <LedgerIcon />
                  </span>
                  <span className="mg-left-6">Ledger</span>
                </Link>
                <Link
                  to="/ledger-wise-report"
                  className="dropdown-item extra-small-title"
                >
                  <span className="icon">
                    <LedgerIcon />
                  </span>
                  <span className="mg-left-6">Ledger Wise Report</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="business-profile dropdown show d-flex">
          {businessUserInfo?.setting_info?.is_reminder === true ? (
            <>
              {businessUserInfo?.unread_reminder_count === 0 || null ? (
                <Link to="/reminder-page">
                  <div className="no-notification">
                    <NotificationIcon />
                  </div>
                </Link>
              ) : (
                <div
                  className="icon-wrapper"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <Link to="/reminder-page">
                    <div className="bell-notification">
                      {businessUserInfo?.unread_reminder_count}
                    </div>
                    <NotificationIcon />
                  </Link>
                </div>
              )}
            </>
          ) : (
            <></>
          )}

          <button
            className="profile-logo dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="user-img-wrap">
              <div className="logo-section d-center">
                {businessUserInfo?.user_info?.photo ? (
                  <img
                    src={businessUserInfo?.user_info?.photo ?? null}
                    alt=""
                  />
                ) : (
                  <p className="extra-small-title user-name">
                    {businessUserInfo?.user_info?.name.charAt(0).toUpperCase()}
                  </p>
                )}
              </div>

              <p className="extra-small-title user-name">
                {businessUserInfo?.user_info?.name}
              </p>
            </div>
          </button>
          {/* <div className="profile-name small-title">Axios Softwork</div> */}
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <Link to="/profile" className="dropdown-item extra-small-title">
              <span className="icon">
                <ProfileIcon />
              </span>
              Profile
            </Link>
            <Link to="/settings" className="dropdown-item extra-small-title">
              <span className="icon">
                <SettingIcon />
              </span>
              Settings
            </Link>
            <div className="dropdown-item extra-small-title shortcut">
              <>
                <span className="icon">
                  <Shortcut />
                </span>
                Shortcuts
              </>
              <div className="sub-dropdown-box toggle">
                <div className="sortcut-group">
                  <p className="float-right">Expand/Collapse</p>
                  <div className="d-flex float-right">
                    <div className="key">Shift</div>+
                    <div className="key">D</div>
                  </div>
                </div>
              </div>
            </div>

            <p
              className="dropdown-item extra-small-title"
              onClick={() => {
                signOut();
              }}
            >
              <span className="icon">
                <LogOutIcon />
              </span>
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
