import { ErrorMessage } from "formik";
import { useUpdateCategoryWrapper } from "../NewCategoryModal/UpdateCategoryWrapper";
import "./CategoryTable.scss";

export interface SelectedCategoryPopup {
  categoryTableList: [CategoryDataListResponse];
}

export interface CategoryDataListResponse {
  id: number;
  name: string;
}

// : React.FC<SelectedCategoryPopup>
export const CategoryForm = () => {
  const { setFieldValue, errors, touched, handleSubmit, values } =
    useUpdateCategoryWrapper();

  return (
    <div className="category_form">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="input-label"> Category Name</label>
            <input
              className={`${
                errors.name && touched.name
                  ? "input-field error-input"
                  : "input-field capitalize"
              }`}
              placeholder="Enter Category Name"
              type="text"
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
            />
            <ErrorMessage name={"name"} component="div" className="error" />
          </div>
        </div>

        <div className="btn-section">
          <button
            className="main-button"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
