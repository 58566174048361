import { ErrorMessage } from "formik";
import React, { useContext, useState, useRef } from "react";
import { NewCustomerModal } from "../NewCustomerModal/NewCustomerModal";
import { NewCustomeWrapper } from "../NewCustomerModal/NewCustomerWrapper";
import { PopUp } from "../PopUp/PopUp";
import { PurchasePage } from "../PurchasePage/PurchasePage";
import { useNewBillFormikContext } from "./BillFormikWrapper";
import { ProductWrapper } from "../AddablePrice/ProductWrapper";
import { NewProductModal } from "../NewProductModal/NewProductModal";
import { Receipt } from "../Receipt/Receipt";
import "./BillPage.scss";
import { AddBillProps, popupType, searchValueProps } from "./BillPageComponent";
import { PopupContext, PopupContextProvider } from "../PopUp/Popup-Context";
import { DocumentContext } from "./Document-Context";
import { bankToaster } from "../../utils/services";
import { useEffect } from "react";
import { useCustomerOptions, useVendorList } from "../Api/Get/GetApi";
import {
  SelectOptions,
  UpdatedSelectOptions,
} from "../../Pages/SalesReturn/types";
import { CustomerListResponse } from "../Api/types";
import Select from "react-select";
import { Loader } from "../Loader/Loader";
import { BatchesContext } from "../context/BatchesContext/BatchesContext";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";
import { AbbreviatedInvoice } from "../Receipt/AbbreviatedInvoice";
import { useAuth } from "../context/auth/auth";
import { BusinessType, ReportType } from "../../EnumValues";
import { toast } from "react-toastify";

export interface AddBillDataProps {
  setDate?: React.Dispatch<React.SetStateAction<string | undefined>>;
  // onKeyUp: (e: any) => void;
  setSearchDescription: React.Dispatch<React.SetStateAction<AddBillProps>>;
  alterdata: (e: any) => void;
  searchData: searchValueProps[];
  roleVal: number;
  customerInfo: { name: string; mobileNo: string; panNo: string };
}

interface optionStrings {
  mobile_no: SelectOptions[];
  pan: SelectOptions[] | [];
  name: UpdatedSelectOptions[];
}



export const BillPage: React.FC<AddBillDataProps> = ({ roleVal }) => {
  const { businessUserInfo } = useAuth();
  const barCodeInputRef = useRef<HTMLInputElement>(null);
  const [scannedData, setScannedData] = useState("");
  const [popupType, setPopupType] = useState<popupType | null>(null);
  const [name, setName] = useState<UpdatedSelectOptions | null>(null);
  const [pan, setPan] = useState<SelectOptions | null>(null);
  const [mobileNo, setMobileNo] = useState<SelectOptions | null>(null);

  const [saleOptions, setSaleOptions] = useState<optionStrings>({
    mobile_no: [],
    pan: [],
    name: [],
  });

  const [purchaseOptions, setPurchaseOptions] = useState<optionStrings>({
    mobile_no: [],
    pan: [],
    name: [],
  });

  const {
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    values,
    isSubmitting,
    resetForm,
    validateForm
  } = useNewBillFormikContext();

  const { openModal } = useContext(PopupContext);
  const { resStatus, documentNumber, setDocumentNumber, isSubmitted, setSearchStrings } =
    useContext(DocumentContext);
  const { setActiveBatch } = useContext(BatchesContext);
  const { data: customerOptions } = useCustomerOptions();
  const { data: vendorLists } = useVendorList();

  const TransactionOptions = [
    { id: ReportType.Sales, value: "Sales" },
    { id: ReportType.Purchase, value: "Purchase" },
  ];

  const popUpView = {
    receipt: <Receipt showCreateBillBtn={true} reportType={values.transaction_type} />,
    abbreviated: <AbbreviatedInvoice />
  }

  const FormatOptions = (
    array: CustomerListResponse[],
    key: keyof optionStrings,
    transaction_type = ReportType.Purchase
  ) => {
    let formatData: SelectOptions[] | UpdatedSelectOptions[] = [];
    if (key === "name") {
      switch (transaction_type) {
        case ReportType.Purchase:
          formatData = array.map((val) => {
            return {
              value: val.id,
              label: val[key] ? val[key] : "-",
              extraDetail: val.additional_info
                ? val.additional_info.address ||
                val.additional_info.additionalProp1
                : "",
            };
          });
          break;
        case ReportType.Sales:
          formatData = array.map((val) => {
            return {
              value: val.id,
              label: val[key] ? val[key] : "-",
              extraDetail: val.additional_info
                ? val.additional_info.address ||
                val.additional_info.additionalProp1
                : "",
            };
          });
          break;
        default:
          break;
      }
    } else {
      formatData = array.map((val) => {
        return {
          value: val.id,
          label: val[key] ? val[key] : "-",
        };
      });
    }

    return formatData;
  };

  const formatOptionLabel = (val: UpdatedSelectOptions) => (
    <div style={{ display: "flex" }}>
      <div>
        {val.label}
        {val.extraDetail && (
          <>
            (
            {/* {values.transaction_type === ReportType.Purchase ? (
          <PanIcon styles={{ width: "20", height: "20", fill: "black" }} />
        ) : (
          <SmartPhone />
        )} */}
            {val.extraDetail})
          </>
        )}
      </div>
    </div>
  );
  const ProductValuesClearance = () => {
    const tempArr = [];
    tempArr.push({
      relation: 0,
      product_id: 0,
      discount: 0,
      amt: 0,
      sub_amt: 0,
      qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      free_qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      rate: {
        primary_price: 0,
        secondary_price: 0,
      },
    });
    setFieldValue("bill_details", tempArr);
    setSearchStrings([{ title: "" }]);
    setActiveBatch(null);
  };

  //Select all the values of select whenever one is chosen
  const HandlePatronSelect = (
    e: UpdatedSelectOptions | SelectOptions,
    transaction_type: ReportType
  ) => {
    if (e) {
      let roleId = 0;
      let findPan: SelectOptions | null | undefined = null;
      let findMobile: SelectOptions | null | undefined = null;
      let findName: UpdatedSelectOptions | null | undefined = null;

      if (transaction_type === ReportType.Sales) {
        findPan = saleOptions.pan.find(
          (val) => val.value === (e as SelectOptions).value
        );
        findMobile = saleOptions.mobile_no.find(
          (val) => val.value === (e as SelectOptions).value
        );
        findName = saleOptions.name.find(
          (val) => val.value === (e as UpdatedSelectOptions).value
        );
        const findRole =
          customerOptions &&
          customerOptions.data.find(
            (val) => val.id === (e as SelectOptions).value
          );
        //This role ID required for product search
        roleId = findRole?.role_id as number;
      } else if (transaction_type === ReportType.Purchase) {
        findPan = purchaseOptions.pan.find(
          (val) => val.value === (e as SelectOptions).value
        );
        findMobile = purchaseOptions.mobile_no.find(
          (val) => val.value === (e as SelectOptions).value
        );
        findName = purchaseOptions.name.find(
          (val) => val.value === (e as UpdatedSelectOptions).value
        );
        const findRole =
          vendorLists &&
          vendorLists.data.find((val) => val.id === (e as SelectOptions).value);
        roleId = findRole?.role_id as number;
      }
      //Roleid needed for product search api as response will be base of patron roles
      setFieldValue("role_id", roleId);
      setFieldValue("billed_business_id", e?.value);
      setPan(findPan as SelectOptions);
      setName(findName as UpdatedSelectOptions);
      setMobileNo(findMobile as SelectOptions);

      // auto focus barcode input
      barCodeInputRef.current?.focus();
    } else {
      //This works on state clear
      setName(null);
      setMobileNo(null);
      setPan(null);
      setFieldValue("role_id", 0);
      setFieldValue("billed_business_id", 0);
    }
    ProductValuesClearance();
    return;
  };

  //This Sets the option data
  useEffect(() => {
    if (customerOptions?.data) {
      setSaleOptions((p) => ({
        ...p,
        mobile_no: FormatOptions(customerOptions.data, "mobile_no"),
        pan: FormatOptions(customerOptions.data, "pan"),
        name: FormatOptions(
          customerOptions.data,
          "name",
          ReportType.Sales
        ) as UpdatedSelectOptions[],
      }));
    }
    if (vendorLists?.data) {
      setPurchaseOptions((p) => ({
        ...p,
        mobile_no: FormatOptions(vendorLists?.data, "mobile_no"),
        pan: FormatOptions(vendorLists?.data, "pan"),
        name: FormatOptions(
          vendorLists?.data,
          "name",
          ReportType.Purchase
        ) as UpdatedSelectOptions[],
      }));
    }
  }, [customerOptions, vendorLists]);

  //States the clear when transaction type is toggled
  useEffect(() => {
    setName(null);
    setPan(null);
    setMobileNo(null);
    setFieldValue("role_id", 0);
    setFieldValue("billed_business_id", 0);
    const newArray = [
      {
        cheque_no: "",
        amount: 0,
        cheque_issue_date: "",
        bank_id: 0,
      },
    ];
    setFieldValue("bank", newArray);
  }, [values.transaction_type, isSubmitted]);

  //default select the cash patron if transaction type is sales, bussiness type is retail or minimart
  const reInitializePatron = () => {
    if (values.transaction_type === ReportType.Sales && barCodeInputRef.current) {
      if (businessUserInfo?.business_info.business_type === BusinessType.RETAIL || businessUserInfo?.business_info.business_type === BusinessType.MINIMART) {
        if (saleOptions?.mobile_no?.length > 0) {
          const index = saleOptions.mobile_no.findIndex(option => option.label === "9841000000")
          if (index > -1) {
            const selectedOptionName = saleOptions.name[index]
            HandlePatronSelect(
              selectedOptionName,
              values.transaction_type as ReportType
            );
            barCodeInputRef.current?.focus()
          }
        }
      }
    }
  }

  useEffect(() => {
    reInitializePatron()
  }, [values.transaction_type, saleOptions, barCodeInputRef.current])

  const getpopUpType = () => {
    let type = "receipt"
    if (values.transaction_type === ReportType.Sales)
      if (businessUserInfo?.business_info.business_type === BusinessType.RETAIL || businessUserInfo?.business_info.business_type === BusinessType.MINIMART) {
        type = values.total > 10000 ? "receipt" : "abbreviated";
      }
    return type as typeof popupType
  }
  return (
    <div className="add-bill">
      <div>
        <div className="head-section">
          <h2 className="small-title">New Bill</h2>
          {values.transaction_type === ReportType.Purchase && (
            <>
              <div className="col-md-2 date-section transaction-date">
                <label className="input-label">Transaction Date</label>
                <NepaliDatePicker
                  currentDate={true}
                  onDateSelect={(date) => {
                    setFieldValue("transaction_date", date);
                  }}
                />
                <ErrorMessage
                  name={"transaction_date"}
                  component="div"
                  className="error"
                />
              </div>
              <div className="col-md-2 date-section">
                <label className="input-label">Issue Date</label>
                <NepaliDatePicker
                  currentDate={true}
                  onDateSelect={(date) => {
                    setFieldValue("date", date);
                  }}
                />
                <ErrorMessage name={"date"} component="div" className="error" />
              </div>
            </>
          )}
        </div>
        <div>
          <div className="bill-section">
            <div className="date-line-section">
              <label className="input-label">Bill Type</label>
              <div className="tab-btn">
                {TransactionOptions.map((val, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        resetForm();
                        setFieldValue("transaction_type", val.id);
                      }}
                      className={`manual-switch ${val.id === values.transaction_type
                        ? "active-bg"
                        : "unactive"
                        } `}
                    >
                      {val.value}
                    </div>
                  );
                })}
              </div>
            </div>
            {values.transaction_type === ReportType.Purchase && (
              <>
                <div className="col-md-2">
                  <label className="input-label">Invoice No.</label>
                  <input
                    className={`${errors.bill_number && touched.bill_number
                      ? "input-field error-input"
                      : "input-field bill-no"
                      }`}
                    placeholder="Enter bill no"
                    value={values.bill_number}
                    onChange={(e) => {
                      setFieldValue(
                        "bill_number",
                        e.target.value.toUpperCase()
                      );
                    }}
                  />
                  <ErrorMessage
                    name={"bill_number"}
                    component="div"
                    className="error"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bill-detail">
          <div className="d-space-between customer-title">
            <div className="extra-small-title c-title">Patron Info</div>
            <div>
              <button
                className="light-primary c-button"
                onClick={() => {
                  setPopupType("customer");
                  openModal();
                }}
                type="button"
              >
                <span>
                  <img
                    alt="plus"
                    src={require("../../assets/images/plus-16.png")}
                  />
                </span>
                Add New Patron
              </button>
              {popupType === "customer" && (
                <PopUp
                  title="New Patron"
                  popupWidth={800}
                  renderComponent={
                    <NewCustomeWrapper>
                      <NewCustomerModal />
                    </NewCustomeWrapper>
                  }
                />
              )}
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4">
              {/* <div className="each-select-child"> */}
              <label className="input-label">Patron Name</label>
              <Select
                isClearable={true}
                formatOptionLabel={formatOptionLabel}
                name=""
                form=""
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                  menu: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                }}
                //@ts-ignore
                value={name}
                //@ts-ignore
                options={
                  values.transaction_type === ReportType.Purchase
                    ? purchaseOptions.name
                    : saleOptions.name
                }
                onChange={(e) => {
                  HandlePatronSelect(
                    e as UpdatedSelectOptions,
                    values.transaction_type as ReportType
                  );
                }}
              />
              <ErrorMessage
                name={"billed_business_id"}
                className="error"
                component={"div"}
              />
              {/* </div> */}
            </div>
            <div className="col-md-4">
              <label className="input-label">Patron Pan</label>
              <Select
                name=""
                form=""
                value={pan}
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                  menu: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                }}
                options={
                  values.transaction_type === ReportType.Purchase
                    ? purchaseOptions.pan
                    : saleOptions.pan
                }
                isClearable
                onChange={(e) => {
                  HandlePatronSelect(
                    e as SelectOptions,
                    values.transaction_type as ReportType
                  );
                }}
              />
            </div>

            <div className="col-md-4">
              {/* <div className="each-select-child"> */}
              <label className="input-label">Patron Mobile</label>
              <Select
                name=""
                form=""
                value={mobileNo}
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                  menu: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                }}
                options={
                  values.transaction_type === ReportType.Purchase
                    ? purchaseOptions.mobile_no
                    : saleOptions.mobile_no
                }
                isClearable
                onChange={(e) => {
                  HandlePatronSelect(
                    e as SelectOptions,
                    values.transaction_type as ReportType
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="toggle-section">
          <div className="d-space-between add-product">
            <div className="extra-small-title form-title">Product Info</div>
            {values.transaction_type === ReportType.Sales && (
              <input
                autoFocus
                ref={barCodeInputRef}
                type="text"
                className="input-field col-md-2"
                placeholder="Product Bar Code"
                value={scannedData}
                onChange={(e) => {
                  setScannedData(e.target.value);
                }}
              />
            )}

            {values.transaction_type === ReportType.Purchase && (
              <div>
                <button
                  className="light-primary c-button"
                  onClick={() => {
                    setPopupType("product");
                    openModal();
                  }}
                  type="button"
                >
                  <img
                    alt="plus"
                    src={require("../../assets/images/plus-16.png")}
                  />
                  Add New Product
                </button>
              </div>
            )}
          </div>
          <PopupContextProvider>
            <PurchasePage
              roleID={roleVal}
              scannedData={scannedData}
              setScannedData={setScannedData}
            />
          </PopupContextProvider>
        </div>
      </div>
      <div className="footer-space">
        <div className="d-end">
          <div>
            <button
              className="light-secondary mr-16"
              type="button"
              onClick={() => {
                resetForm();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="main-button"
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit();
                validateForm().then((errors) => {
                  if (Object.keys(errors).length > 0) {
                    toast.error("Please fill all the required fields");
                    return;
                  } else {
                    const popupType = getpopUpType();
                    setPopupType(popupType);
                    bankToaster(values, setFieldValue);
                  }
                });
              }}
            >
              {isSubmitting ? (
                <div>
                  <Loader loaderType="Spinner" universalCenter={false} />
                </div>
              ) : (
                <>{values.transaction_type === ReportType.Sales ? "Create Bill" : "Save"}</>
              )}
            </button>
          </div>
        </div>
      </div>

      {popupType === "product" && (
        <PopUp
          title="New Product"
          popupWidth={800}
          popupHeight={"90vh"}
          renderComponent={
            <ProductWrapper mode="new">
              <NewProductModal mode="new" />
            </ProductWrapper>
          }
        />
      )}
      <PopUp
        popupType="full"
        popupWidth={"100%"}
        showHeader={false}
        closeOnClickOutside={false}
        popupHeight={"100%"}
        renderComponent={popUpView[popupType as 'receipt' | 'abbreviated']}
        onAfterClose={() => {
          setPopupType(null)
          setDocumentNumber("")
          resetForm();
          reInitializePatron()

        }}
      />
    </div>
  );
};
