import  { Suspense, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/main.scss"
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { LoginPage } from "./components/LoginPage/LoginPage"
import "react-toastify/dist/ReactToastify.css"
import { useAuth } from "./components/context/auth/auth"
import { Header } from "./components/Header/Header"
import { NavBar } from "./components/NavBar/NavBar"
import { Loader } from "./components/Loader/Loader"
import { PrivateRoute } from "./components/ProtectedRoute/ProtectedRoute"
import { ROLE } from "./EnumValues"
import { PopUp } from "./components/PopUp/PopUp"
import { BusinessInfoWrapper } from "./components/BusinessInfoForm/BusinessInfoWrapper"
import { BusinessInfoForm } from "./components/BusinessInfoForm/BusinessInfoForm"
import { routes } from "./routes"
import { PageNotFound } from "./components/ErrorPage/PageNotFound"

// const rolewiseCompoonent = (
//   role: string | undefined,
//   path: string,
//   component: JSX.Element
// ) => {
//   const routeConfig = RouteConfig()
//   if (
//     //@ts-ignore
//     routeConfig[path] !== undefined &&
//     role !== undefined &&
//     //@ts-ignore
//     routeConfig[path][role] !== undefined
//   ) {
//     //@ts-ignore
//     const reqValue = routeConfig[path][role]
//     return reqValue
//   }
//   return component
// }

function App() {
  const { businessPopUp } = useAuth()
  const { signIn } = useAuth()
  const location = useLocation()

  useEffect(() => {
    signIn()
  }, [])

  const { isLoading } = useAuth()

  return (
    <div className="App">
      {/* Set up account */}
      {businessPopUp && (
        <PopUp
          closeOnClickOutside={false}
          showCrossIcon={false}
          popupType="full"
          title="Set Business Profile to continue"
          renderComponent={
            <BusinessInfoWrapper>
              <BusinessInfoForm showCancelButton={false} />
            </BusinessInfoWrapper>
          }
        />
      )}
      <>
        {isLoading ? (
          <Loader loaderType="ImageSpinner" />
        ) : (
          <Suspense fallback={<Loader loaderType="ImageSpinner" />}>
            {location.pathname !== "/login" && <Header />}
            <div className="mid-section">
              {location.pathname !== "/login" && <NavBar />}
              <div className="card-80">
                <Routes>
                  <Route
                    path="*"
                    element={
                      <PrivateRoute roles={[ROLE.Admin, ROLE.Staff] as ROLE[]}>
                        <PageNotFound />
                      </PrivateRoute>
                    }
                  />

                  <Route path="/login" element={<LoginPage />} />
                  {routes.map(({ path, component, role }, index) => {
                    //This works when we have to load different component in single route role wise
                    // if (path === "/" && isAuthenticated) {
                    //   component =
                    //     businessUserInfo &&
                    //     rolewiseCompoonent(
                    //       businessUserInfo.user_info.role[0].name,
                    //       path,
                    //       component
                    //     ).component
                    //   role =
                    //     businessUserInfo &&
                    //     rolewiseCompoonent(
                    //       businessUserInfo.user_info.role[0].name,
                    //       path,
                    //       component
                    //     ).roles
                    // }
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={
                          <PrivateRoute roles={role as ROLE[]}>
                            {component}
                          </PrivateRoute>
                        }
                      />
                    )
                  })}
                </Routes>
              </div>
            </div>
          </Suspense>
        )}
      </>
    </div>
  )
}

export default App
