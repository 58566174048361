import { useCallback, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { GetCustomerSearch } from "../Api/Get/GetApi";
import { BottomSheetContextProvider } from "../BottomSheet/BottomSheetContext";
import { useNewBillFormikContext } from "./BillFormikWrapper";
import { BillMobileView } from "./BillMobileView";
import { BillPage } from "./BillPage";
import { DocumentContext } from "./Document-Context";
import { ReportType } from "../../EnumValues";

export interface AddBillProps {
  name: string;
  val: string;
}

export interface searchValueProps {
  id: number;
  mobile_no: string;
  pan: string;
  role_name: string;
  role_id: number;
  name: string;
  user_role_id: number;
  roles: {
    id: number;
    name: string;
  }[];
}

export type popupType = "customer" | "product" | "receipt" | "abbreviated";

export const BillPageComponent = () => {
  const [roleVal, setRoleVal] = useState(0);
  const [searchData, setSearchData] = useState<Array<searchValueProps>>([]);
  const [date, setDate] = useState<string>();
  const [searchDescription, setSearchDescription] = useState<AddBillProps>();
  const [debouncedSearch, setDebouncedSearch] = useState<AddBillProps>(
    searchDescription as AddBillProps
  );
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    mobileNo: "",
    panNo: "",
  });

  const { setFieldValue, values } = useNewBillFormikContext();
  const { setSearchStrings } = useContext(DocumentContext);

  const CustomerSearch = useCallback(
    (searchDescription: AddBillProps, transaction_type: ReportType) => {
      let value = searchDescription?.val.split("-")[0];
      GetCustomerSearch(searchDescription?.name, value, transaction_type === ReportType.Purchase ? 1 : 2)
        .then((res) => {
          setSearchData(res.data.data);
        })
        .catch((err: any) => {
          let errorMessage = "Failed to edit profile";
          if (err.response.data.status.status_message) {
            errorMessage = err.response.data.status.status_message;
          }
          toast.error(errorMessage.toString());
        });
    },
    []
  );

  const clearResults = () => {
    setSearchData([]);
  };

  const alterdata = (e: any) => {
    if (e.target.name === "user_name") {
      setCustomerInfo({
        name: e.target.value.split("-")[0],
        mobileNo: e.target.value.split("-")[1] || null,
        panNo: e.target.value.split("-")[2] || null,
      });
    } else if (e.target.name === "pan") {
      setCustomerInfo({
        name: e.target.value.split("-")[1] || null,
        panNo: e.target.value.split("-")[0] || e.target.value,
        mobileNo: e.target.value.split("-")[2] || null,
      });
    } else {
      setCustomerInfo({
        name: e.target.value.split("-")[1] || null,
        mobileNo: e.target.value.split("-")[0] || e.target.value,
        panNo: e.target.value.split("-")[2] || null,
      });
    }

    setFieldValue("billed_business_id", parseInt(e.target.value.split("-")[3]));
    const temp = parseInt(e.target.value.split("-")[3]);
    const searchObj = searchData.find((i) => i.user_role_id === temp);

    if (
      e.target.value.split("-")[0] === "" ||
      e.target.value.split("-")[1] === "" ||
      e.target.value.split("-")[2] === ""
    ) {
      setFieldValue("role_id", "");
      setFieldValue("billed_business_id", "");
    } else {
      setFieldValue("role_id", searchObj?.role_id);
    }
    setRoleVal(searchObj?.role_id || 0);
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearchDescription(debouncedSearch), 400);
    return () => clearTimeout(timer);
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchDescription && searchDescription?.val != "") {
      CustomerSearch(
        searchDescription as AddBillProps,
        values.transaction_type as ReportType
      );
    } else {
      clearResults();
    }
  }, [searchDescription]);

  useEffect(() => {
    setFieldValue("billed_business_id", 0);
    setFieldValue("bill_number", "");
    setSearchStrings([{ title: "" }]);
  }, [date, values.transaction_type]);

  return (
    <div className="bill-function">
      {isMobile ? (
        <>
          <BottomSheetContextProvider>
            <BillMobileView
              setDate={setDate}
              setSearchDescription={setDebouncedSearch}
              alterdata={alterdata}
              searchData={searchData}
              roleVal={roleVal}
              customerInfo={customerInfo}
            />
          </BottomSheetContextProvider>
          setDate={setDate}
        </>
      ) : (
        <>
          <BillPage
            setSearchDescription={setDebouncedSearch}
            alterdata={alterdata}
            searchData={searchData}
            roleVal={roleVal}
            customerInfo={customerInfo}
          />
        </>
      )}
    </div>
  );
};
