import { NepDatePicker } from "nepali-date-picker-axiossoftwork";

interface NepDatePickerProps {
  domain?: string;
  onDayIdSelect?: (dayId: string) => void;
  onDateSelect?: (date: string) => void;
  currentDate?: boolean;
  defaultDate?: string;
  reset?: boolean;
  defaultColor?: boolean;
  activeColor?: string;
}

const NepaliDatePicker = ({ ...rest }: NepDatePickerProps) => {
  return <NepDatePicker defaultColor={true} activeColor="#1db195" {...rest} />;
};
export default NepaliDatePicker;
