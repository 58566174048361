import { ErrorMessage, Field } from "formik";
import { checkNumber } from "../../utils/services";
import { useState } from "react";
import { useSettingPage } from "./SettingPageWrapper/SettingPageWrapper";

const ReminderPoolSetting = () => {
  const { values } = useSettingPage();
  const [value, setValue] = useState("");
  const handleKeyPress = (e: any) => {
    const input = e.key;
    if (!checkNumber(input)) {
      e.preventDefault();
    } else {
      const newValue = value + input;
      setValue(newValue);
    }
  };

  return (
    <div className="col-md-12 ">
      <fieldset>
        <legend>Reminder Setting </legend>
        <div className="col-md-12">
          <div className="mb-4">
            <Field
              name="is_reminder"
              id="s2d"
              type="checkbox"
              className="switch"
              checked={values.is_reminder}
            />
            <label className="content">Reminder</label>
          </div>
          <div className=" mb-2">
            <label className="black-content">Bill Due Notice Day</label>{" "}
            <Field
              name="bill_due_notice_day"
              className="input-section"
              type="number"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
            <ErrorMessage
              name={"bill_due_notice_day"}
              component="div"
              className="error"
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="mt-4">
        <legend>Pool Setting</legend>
        <div className="d-flex gap-2">
          <div className="form-group">
            <label className="input-label w-auto">Pool A</label>
            <Field
              name="asset_pool_A"
              type="number"
              className="input-section"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label className="input-label w-auto">Pool B</label>
            <Field
              name="asset_pool_B"
              type="number"
              className="input-section"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label className="input-label w-auto">Pool C</label>
            <Field
              name="asset_pool_C"
              type="number"
              className="input-section"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label className="input-label w-auto">Pool D</label>
            <Field
              name="asset_pool_D"
              type="number"
              className="input-section"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label className="input-label w-auto">Pool E</label>
            <Field
              name="asset_pool_E"
              type="number"
              className="input-section"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default ReminderPoolSetting;
