import { Formik, FormikConfig, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { HandleError } from "../../utils/services";
import { PostCreateProduct, PostEditProduct } from "../Api/Post/PostApi";
import { PopupContext } from "../PopUp/Popup-Context";
import { GetProductDetail, useProductList } from "../Api/Get/GetApi";
import { ProductDetailResponse } from "../ProductDetails/ProductDetails";

export interface PriceValueProps {
  role_id: number;
  primary_price: number;
  secondary_price: number;
}

export interface NewProductValue {
  name: string;
  primary_unit: string;
  secondary_unit: string;
  relation: string;
  is_damage_reclaimable: boolean;
  tax: number;
  is_price_fixed: boolean;
  batch: number | string;
  price: Array<PriceValueProps>;
  expiry_date: string;
  product_category_id: string;
  product_bar_code: string;
}

export const initialValues: NewProductValue = {
  name: "",
  primary_unit: "",
  secondary_unit: "",
  relation: "",
  expiry_date: "",
  is_damage_reclaimable: false,
  tax: 0,
  is_price_fixed: true,
  batch: 1,
  price: [
    {
      role_id: 4,
      primary_price: 0,
      secondary_price: 0,
    },
  ],
  product_category_id: "",
  product_bar_code: ""
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide product name"),
  primary_unit: Yup.string().required("Please select primary unit"),
  secondary_unit: Yup.string().required("Please select secondary unit"),
  relation: Yup.number().required("Please select relation"),
  // .matches(/^[A-Za-z/, "Not a valid number"),
  batch: Yup.number().required("Please select batch"),
  // expiry_date: Yup.string().required("Please select expiry date"),
  role_id: Yup.array().of(Yup.number().required("Please select a role")),
  is_price_fixed: Yup.boolean(),
  price: Yup.array().when("is_price_fixed", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        role_id: Yup.number().required("Please select role"),
        primary_price: Yup.number().required("Please set primary price"),
        secondary_price: Yup.number().required("Please set secondary price"),
      })
    ),
  }),
  product_category_id: Yup.string().required("Please select category"),
});

//Function to statically add the product value role wise as needed to api
const PriceAddition = (data: PriceValueProps[]) => {
  if (data.length === 3) {
    return data;
  }
  const idArray: number[] = [];
  const tempArray = [...data];
  data.map((val) => {
    return idArray.push(val.role_id as number);
  });
  if (data.length === 2) {
    if (idArray.includes(4) && idArray.includes(5)) {
      tempArray.push({ role_id: 6, secondary_price: 0, primary_price: 0 });
    } else if (idArray.includes(4) && idArray.includes(6)) {
      tempArray.push({ role_id: 5, secondary_price: 0, primary_price: 0 });
    } else {
      tempArray.push({ role_id: 4, secondary_price: 0, primary_price: 0 });
    }
  } else {
    if (idArray.includes(4)) {
      tempArray.push(
        { role_id: 6, secondary_price: 0, primary_price: 0 },
        { role_id: 5, secondary_price: 0, primary_price: 0 }
      );
    } else if (idArray.includes(5)) {
      tempArray.push(
        { role_id: 6, secondary_price: 0, primary_price: 0 },
        { role_id: 4, secondary_price: 0, primary_price: 0 }
      );
    } else {
      tempArray.push(
        { role_id: 4, secondary_price: 0, primary_price: 0 },
        { role_id: 5, secondary_price: 0, primary_price: 0 }
      );
    }
  }
  return tempArray;
};

export const useProductWrapper = () => {
  const formik = useFormikContext<NewProductValue>();
  if (!formik) {
    throw new Error("useNewCustomerWrapper must be used within a Formik");
  }
  return formik;
};
interface ProductWrapperProps {
  children: React.ReactNode;
  prodId?: number | string;
  mode: "new" | "edit";
  productRefetch?: any;
}

export const ProductWrapper: React.FC<ProductWrapperProps> = ({
  children,
  prodId,
  mode,
  productRefetch,
}) => {
  const { closeModal } = useContext(PopupContext);
  const [prodDetails, setProdDetails] = useState<ProductDetailResponse>();
  const [initialValueFromProps, setInitialValueFromProps] =
    useState<NewProductValue>();

  const handleSubmit: FormikConfig<NewProductValue>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);
    const newData = { ...values };
    newData.price = PriceAddition(values.price);
    const editData = {
      ...values,
      batch: {
        name: values.batch,
        id: prodDetails?.batch_info?.[0]?.batch_id,
      },
      price: values?.price?.map((item) => {
        return {
          ...item,
          product_price_id: prodDetails?.batch_info?.[0]?.product_price.find(
            (itm) => itm.role_id === item.role_id
          )?.id,
        };
      }),
    };
    const data = mode === "edit" ? editData : newData;

    const apiCall =
      mode === "edit"
        ? PostEditProduct(Number(prodId) || 0, data)
        : PostCreateProduct(data);

    apiCall
      .then(() => {
        toast.success(
          mode === "new"
            ? "New product added successfully"
            : "Product has been updated successfully"
        );
        closeModal();
        productRefetch();
        setSubmitting(false);
      })
      .catch((err: any) => {
        const response = err.response.data.status;
        const errorMessage = HandleError(err);

        toast.error(errorMessage);
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const ProductDetail = (prodId: number) => {
    GetProductDetail(prodId)
      .then((res) => {
        setProdDetails(res.data.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (prodId) {
      ProductDetail(Number(prodId));
    }
  }, [prodId]);

  useEffect(() => {
    if (prodDetails) {
      const newTemp = {
        name: prodDetails?.name,
        primary_unit: prodDetails?.primary_unit,
        secondary_unit: prodDetails?.secondary_unit,
        relation: prodDetails?.relation.toString(),
        is_damage_reclaimable: prodDetails?.is_damage_reclaimable,
        tax: Number(prodDetails?.tax),
        is_price_fixed: prodDetails?.is_price_fixed,
        batch: prodDetails?.batch_info?.[0]?.batch_name,
        price: prodDetails?.batch_info?.[0]?.product_price.map((item) => {
          return {
            role_id: item.role_id,
            primary_price: item.primary_price,
            secondary_price: Number(item.secondary_price.toFixed(3)),
          };
        }),
        expiry_date: "",
        product_category_id: "",
        product_bar_code: prodDetails.product_bar_code
      };
      setInitialValueFromProps(newTemp);
    }
  }, [prodDetails, prodId]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValueFromProps || initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
