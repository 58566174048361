import { Formik, FormikConfig, useFormikContext } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BankDataProps } from "../BillPage/BillFormikWrapper";
import {
  PostPaymentVoucherCreate,
  PostPayslipCreate,
} from "../Api/Post/PostApi";
import { useContext } from "react";
import { DocumentContext } from "../BillPage/Document-Context";
import { useCustomerOptions, usePaymentVoucher, usePayslip, useVendorOptions } from "../Api/Get/GetApi";

export interface ReciptValue {
  user_role_id: number | null;
  cash: number;
  bill_number?: string;
  date: string;
  bank: BankDataProps[];
  total: number;
  total_credit: number;
  payType?: "cash" | "cheque" | "both" | "";
  hasBill: "paymentVoucher" | "paySlip";
  apiType: "paymentVoucherApi" | "paySlipApi" ;
}

export const initialValues: ReciptValue = {
  payType: "cash",
  user_role_id: null,
  cash: 0,
  bill_number: "",
  date: "",
  bank: [
    {
      cheque_no: "",
      amount: "",
      cheque_issue_date: "",
      bank_id: "",
    },
  ],
  total: 0,
  total_credit: 0,
  hasBill: "paySlip",
  apiType: "paySlipApi",
};

const validationSchema = Yup.object().shape({
  cash: Yup.number().when("payType", {
    is: (payType: "both" | "cash") => payType === "cash" || payType === "both",
    then: Yup.number()
      .positive("Must be greater than zero")
      .required("Required"),
  }),
  bill_number: Yup.string().when("hasBill", {
    is: (value: string) => value === undefined || value === "paymentVoucher",
    then: Yup.string().required("Receipt number is required"),
  }),
  payType: Yup.string().required("Check at least one "),
  date: Yup.string().required("Please select the date"),
  bank: Yup.array().when(["payType"], {
    is: (payType: "both" | "cheque") =>
      payType === "cheque" || payType === "both",
    then: Yup.array().of(
      Yup.object().shape({
        cheque_no: Yup.string().required("Required"),
        amount: Yup.number()
          .positive("Must be greater than zero")
          .required("Required"),
        cheque_issue_date: Yup.string().required("Required"),
        bank_id: Yup.string().required("Required"),
      })
    ),
  }),
});

export const useReciptWrapper = () => {
  const formik = useFormikContext<ReciptValue>();
  if (!formik) {
    throw new Error("useNewCustomerWrapper must be used within a Formik");
  }
  return formik;
};
interface ProductWrapperProps {
  children: React.ReactNode;
}
const initialPaySlipValue = {
  name: "",
  pan: undefined,
  mobile_no: "",
  user_role_id: undefined,
  user_id: undefined,
  role_id: undefined,
  role: "",
  credit_total: undefined,
};

export const ReciptFormikWrapper: React.FC<ProductWrapperProps> = ({
  children,
}) => {
  const {
    setSelectedUserObj,
    setPayslipSearchString,
    setSelectedVendor,
    setSelectedAPIType,
    setSelectedPatron,
    setIsSubmitted,
    selectedPatron,
    selectedAPIType,
    selectedVendor,
    isSubmitted,
    setReceiptNumber,
    setShowReceipt,
  } = useContext(DocumentContext);

  const selectedId = selectedAPIType === "paymentVoucherApi"? selectedVendor?.value : selectedPatron?.value
  const apiOptionsRefetch =selectedAPIType === "paymentVoucherApi"? useVendorOptions: useCustomerOptions;
  const { refetch } = apiOptionsRefetch();
  const apiRefetch =selectedAPIType === "paymentVoucherApi"? usePaymentVoucher: usePayslip;
  const { refetch: refetchPayment } = apiRefetch(selectedId);

  const handleSubmit: FormikConfig<ReciptValue>["onSubmit"] = (
    values,
    { setSubmitting, resetForm, setStatus }
  ) => {
    setSubmitting(true);
    const data = { ...values };
    if (values.payType === "cash") {
      data.bank = [];
    }
    if (values.payType === "cheque") {
      data.cash = 0;
    }
    delete data.payType;
    //PostPaymentVoucherCreate

    let postApiCall: any;

    if (selectedAPIType === "paySlipApi") {
      postApiCall = PostPayslipCreate;
    } else {
      postApiCall = PostPaymentVoucherCreate;
    }
    postApiCall(data)
      .then((res: any) => {
        toast.success("Receipt created successfully");
        setSubmitting(false);
        setStatus(true);
        refetch();
        refetchPayment();
        //The line below is just for state clearing after the succesfully receipt creation
        setSelectedUserObj(initialPaySlipValue);
        setReceiptNumber(res.data.data.document_no);
        setShowReceipt(true);
        setPayslipSearchString("");
        setSelectedPatron(null);
       
        setSelectedVendor(null);
        setSelectedAPIType(null)
        resetForm();
        setIsSubmitted(!isSubmitted);
      })
      .catch((err: any) => {
        let errorMessage = "Failed to create recipt";

        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.status.status_code === 422) {
          errorMessage =
            err.response.data.status.status_message[0].msg.split("|")[0];
        } else if (err.response.data.status.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        toast.error(errorMessage);
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
