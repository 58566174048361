export enum ROLE {
  Admin = 2,
  Staff = 3,
}

export enum PatronRoles {
  Vendor = 4,
  WholeSaler = 5,
  Retailer = 6,
}

export enum BillReturnType {
  Sales = "Sales-Return",
  Purchase = "Purchase-Return",
}

export enum ReportType {
  Sales = "Sales",
  Purchase = "Purchase",
}

export enum BalanceSheetOrderName {
  orderLiabilities = "orderLiabilities",
  orderAssets = "orderAssets",
}

export enum Liabilities {
  CurrentLiabilities = "Current Liabilities",
  WaiIii = "Wai Iii",
  Equity = "Equity",
}

export enum Assets {
  CurrentAssets = "Current Assets",
  NonCurrentAssets = "Non-Current Assets",
  FixedAssets = "Fixed Assets",
  Cashcash = "Cashcash",
}

export type BalancesheetOrder = {
  [BalanceSheetOrderName.orderLiabilities]: Liabilities[];
  [BalanceSheetOrderName.orderAssets]: Assets[];
};

enum OptionLabel {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

export type Option = {
  value: OptionLabel;
  label: OptionLabel;
};

export const poolOptions: Option[] = [
  { value: OptionLabel.A, label: OptionLabel.A },
  { value: OptionLabel.B, label: OptionLabel.B },
  { value: OptionLabel.C, label: OptionLabel.C },
  { value: OptionLabel.D, label: OptionLabel.D },
];

export enum HasDetailResponse {
  OPENING_DETAIL = "OPENING-DETAIL",
  OPENING_STOCK_DETAIL = "OPENING-STOCK-DETAIL",
  JOURNAL = "JOURNAL",
}

export enum ProfileTabs {
  Company_Details = "company_details",
  Profile_Details = "profile_details",
}

export enum AbbreviatedBillType {
  A4 = "A4",
  THERMAL = "Thermal",
}

export enum BusinessType {
  MANUFACTURE = "Manufacture",
  RETAIL = "Retail",
  JEWELLERY = "Jewellery",
  ONLINE = "Online",
  WHOLESALER = "Wholesaler",
  MINIMART = "Minimart",
}
