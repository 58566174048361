import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { UpdateBillDetailProps } from "../../../Pages/SalesReturn/SalesReturn"
import { SelectOptions } from "../../../Pages/SalesReturn/types"
import { AmountInfoValues, BillDetailResponse } from "../../Api/types"
import { BatchInfoResponse } from "../../ProductDetails/ProductDetails"

interface ReturnContextType {
  customer: SelectOptions | null
  setCustomer: React.Dispatch<React.SetStateAction<SelectOptions | null>>
  bill: SelectOptions | null
  setBill: React.Dispatch<React.SetStateAction<SelectOptions | null>>
  productValues: BillDetailResponse | undefined
  setProductValues: React.Dispatch<
    React.SetStateAction<BillDetailResponse | undefined>
  >
  productInfo: UpdateBillDetailProps[] | undefined
  setProductInfo: React.Dispatch<
    React.SetStateAction<UpdateBillDetailProps[] | undefined>
  >
  amountInfos: AmountInfoValues | undefined
  setAmountInfos: React.Dispatch<React.SetStateAction<AmountInfoValues>>
  selectAll: boolean
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValues = {
  customer: "",
  setCustomer: () => {},
  bill: {},
  setBill: () => {},
  setProductInfo: () => {},
  amountInfos: {},
  setAmountInfos: () => {},
  productInfo: [],
  productValues: [],
  setProductValues: () => {},
  selectAll: false,
  setSelectAll: () => {},
}
interface BatchesProps {
  children: any
}

export const ReturnContext = React.createContext<ReturnContextType>(
  //@ts-ignore
  initialValues
)

export type ProdBatches = {
  [key: string]: {
    relation: number
    batch_info: BatchInfoResponse[]
  }
}

export type SelectedBatch = {
  [key: number]: BatchInfoResponse
}

export const ReturnContextProvider: React.FC<BatchesProps> = ({ children }) => {
  const location = useLocation()
  const [customer, setCustomer] = useState<SelectOptions | null>(null)
  const [bill, setBill] = useState<SelectOptions | null>(null)
  //This state holds the response from the api for data comparison needed in logics
  const [productValues, setProductValues] = useState<BillDetailResponse>()
  //This state holds the updating datas that will be send to the api
  const [productInfo, setProductInfo] = useState<UpdateBillDetailProps[]>()
  const [amountInfos, setAmountInfos] = useState<AmountInfoValues>({
    non_taxable_amount: 0,
    taxable_amount: 0,
    total_amount: 0,
    discount: 0,
    sub_total: 0,
    tax: 0,
    credit: 0,
    cash: 0,
  })
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    setCustomer(null)
    setBill(null)
    setProductInfo(undefined)
    setAmountInfos({
      non_taxable_amount: 0,
      taxable_amount: 0,
      total_amount: 0,
      discount: 0,
      sub_total: 0,
      tax: 0,
      credit: 0,
      cash: 0,
    })
    setSelectAll(false)
  }, [location.pathname])

  return (
    <ReturnContext.Provider
      value={{
        productInfo,
        productValues,
        amountInfos,
        customer,
        bill,
        setAmountInfos,
        setBill,
        setCustomer,
        setProductInfo,
        setProductValues,
        selectAll,
        setSelectAll,
      }}
    >
      {children}
    </ReturnContext.Provider>
  )
}
