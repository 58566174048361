import {
  BagIcon,
  CalenderIcon,
  CallIcon,
  EmailIcon,
  FileIcon,
  GreenProfileIcon,
  HashIcon,
  LocationIcon,
  PanIcon,
  ScreenIcon
} from "../../assets/images/svg/svg";
import { useBusinessData } from "../Api/Get/GetApi";
import { ProfileHeader } from "../BusinessProfileHeader/ProfileHeader";
import { Loader } from "../Loader/Loader";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import "./BusinessProfilePage.scss";


interface InfoSectionProps {
  Icon: JSX.Element;
  label: string;
  text: string;
}
const InfoSection = ({ Icon, label, text }: InfoSectionProps) => {
  return <div className="col-md-6">
    <div className="field-group d-flex align-items-center">
      <div className="icon-section d-flex justify-content-center align-items-center mb-2 col-1">
        {Icon}
      </div>
      <div className="col-10">
        <div className="desc-wrap d-flex flex-column align-items-start col-10">
          <label className="input-label">{label}</label>
          <p className="name content text-start">{text}</p>
        </div>
      </div>
    </div>
  </div>


}
export const BusinessProfilePage = () => {
  const { data, isLoading } = useBusinessData();

  const fieldItems: InfoSectionProps[] = [
    { Icon: <GreenProfileIcon />, label: 'Name:', text: data?.name ?? "" },
    { Icon: <EmailIcon />, label: 'Email:', text: data?.email ?? "" },
    { Icon: <LocationIcon />, label: 'Address:', text: data?.address ?? '' },
    { Icon: <PanIcon />, label: 'Pan number:', text: String(data?.pan ?? '') },
    { Icon: <CallIcon />, label: 'Mobile number:', text: String(data?.mobile_no ?? "") },
    { Icon: <HashIcon />, label: 'Registration number:', text: String(data?.registration_no ?? '') },
    { Icon: <BagIcon />, label: 'Business Type:', text: data?.business_type ?? "" },
    {
      Icon: <CalenderIcon />,
      label: 'Establish date:',
      text: data?.establish_date?.split('/').join(' - ') as string
    },
    { Icon: <ScreenIcon />, label: 'Website:', text: data?.website ?? "-" },
    { Icon: <FileIcon />, label: 'Footer text:', text: data?.footer_text ?? "-" },
  ];
  return (
    <>
      {isLoading ? (
        <>
          <Loader loaderType="Spinner" />
        </>
      ) : (
        <div className="profile-page">
          <div>
            <ProfileHeader businessData={data} showPersonalDetails={false} />
          </div>
          <div className="profile-body">
            {data ? (
              <div className="row">
                {fieldItems.map((item, index) => (
                  <InfoSection
                    key={index}
                    Icon={item.Icon}
                    label={item.label}
                    text={item.text}
                  />
                ))}

              </div>
            ) : (
              <NoDataPage />
            )}
          </div>
        </div>
      )}
    </>
  );
};
