import { englishToNepaliNumber, nepaliAmountFormat } from "nepali-number";
import {
  BillProductDetail,
  CustomerListResponse,
} from "../components/Api/types";
import { BillDetailProps } from "../components/BillPage/BillFormikWrapper";
import { SideBarComponentsKeys } from "../components/NavBar/NavBar";
import { InputContent } from "../components/ProductPage/ProductPage";
import { BalanceSheetOrderName } from "../EnumValues";
import { SelectOptions } from "../Pages/SalesReturn/types";
import { IBalanceSheet } from "../components/Api/Get/GetApiTypes";

const language = localStorage.getItem("language") || "en";

export const replaceDecimal = (value: any) => {
  if (!value) {
    return 0;
  }
  const splitVals = value.toString().split(".");
  if (splitVals[1] !== undefined) {
    if (splitVals[1].length > 2) {
      return parseFloat(value).toFixed(3);
    }
  }
  return value;
};

export const AcceptDecimal = (event: any) => {
  // Get the current input value
  const value = event;

  // Use a regular expression to check if the input value matches the pattern
  const pattern = /^\d+(\.\d{1,3})?$/;
  if (!pattern.test(value)) return "";
  return Number(value);
};

export const capitalCase = (str: string, setItemName?: any) => {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    if (i === 0 || str[i - 1] === " ") {
      result += str[i].toUpperCase();
    } else {
      result += str[i].toLowerCase();
    }
  }
  // setItemName?.(result);
  return result;
};
export const onlyNumbers = (str: string) => {
  return /^[0-9]+$/.test(str);
};

export const checkNumber = (
  str: any,
  checkType: "numberOnly" | "decimalToo" = "decimalToo"
) => {
  if (str === "") {
    return "";
  }

  switch (checkType) {
    case "numberOnly":
      for (let i = 0; i < str.length; i++) {
        if (!onlyNumbers(str[i])) {
          return str.replace(str[i], "");
        }
      }
      break;
    case "decimalToo":
      for (let i = 0; i < str.length; i++) {
        if (!onlyNumbers(str[i]) && str[i] !== ".") {
          return str.replace(str[i], "");
        }
      }
      break;
    default:
      return;
  }
  return replaceDecimal(str);
};

// additional_info: Yup.object().shape({}),

export const bankToaster = (value: any, setState: any) => {
  if (value?.bank.length > 0) {
    const err: any = [];
    value.bank.forEach((element: any) => {
      if (
        (element.bank_id === "" || element.bank_id === 0) &&
        element.cheque_issue_date === "" &&
        (element.amount === 0 || element.amount === "") &&
        element.cheque_no === ""
      ) {
        setState("bank", err);
        return err;
      }
    });
  }
};

export const handleNoData = (
  data: number | string | boolean | null,
  ifNoValueString?: string
) => {
  return data ? data : ifNoValueString ? ifNoValueString : "-";
};

export const tableIndexNumber = (
  pageIndex: number,
  pageSize: number,
  index: number
) => {
  return pageIndex + 1 === 1 ? index + 1 : pageIndex * pageSize + index + 1;
};

export const fixedValue = (value = 0, toFix = 3) => {
  let check = 0;
  if (!isNaN(value)) {
    check = Number.isInteger(value) ? value : Number(value.toFixed(toFix));
  }
  return Number(check);
};

export const HandleError = (err: any) => {
  let errormessage = "Internal Server Error";
  let statusCode = err?.response?.data?.status?.status_code;
  let statusMessage = err?.response?.data?.status?.status_message;
  if (statusCode && statusMessage) {
    switch (statusCode) {
      case 422:
        if (Array.isArray(statusMessage)) {
          let newVal = statusMessage.map((val) => {
            return (
              val.loc[1] +
              "_" +
              `${val.loc[3] ? val.loc[3] : ""}` +
              ":" +
              val.msg
            );
          });
          errormessage = newVal.join(",");
          break;
        }
        errormessage = statusMessage as string;
        break;

      default:
        errormessage = (statusMessage as string)
          ? (statusMessage as string)
          : "Something went wrong";
    }
  }

  return errormessage;
};

export const totalPages = (pageSize: number, totalData: number) => {
  return Math.ceil(totalData / pageSize);
};

export const RoleWiseComponents = (
  allSideBar: SideBarComponentsKeys[],
  requiredSideBar: string[]
) => {
  if (requiredSideBar) {
    const components = allSideBar.filter((val) => {
      return requiredSideBar.includes(val.name);
    });
    return components;
  }
  return [];
};

export const sameOrderArray = (
  arr1: string[],
  arr2: SideBarComponentsKeys[]
) => {
  const samearray = arr2.sort(
    (a, b) => arr1.indexOf(a.name) - arr1.indexOf(b.name)
  );
  return samearray;
};

export const SortData = (arr1: string[], arr2: any[]) => {
  const samearray = arr2.sort(
    (a, b) =>
      arr1.indexOf(a.toLowerCase().split(" ").join("_")) - arr1.indexOf(b)
  );
  return samearray;
};

export const sliceDate = (date: string) => {
  return date ? date.slice(0, 10) : "";
};

export const fomratColumns = (headers: string[]) => {
  const formattedHeaders = headers.map((val) => {
    return {
      Header: val,
      accessor: val.toLowerCase().split(" ").join("_"),
    };
  });
  return formattedHeaders;
};

export const BillGeneratedDay = (day: any) => {
  let message = "";
  switch (day) {
    case "0":
      message = "Today";
      break;
    case "1":
      message = "Yesterday";
      break;
    default:
      message = day + " days ago";
  }
  return message;
};

export const formatSelectOptions = <T>(
  data: T[],
  key?: keyof T,
  nestedKey?: any
): SelectOptions[] => {
  let formattedOptions: SelectOptions[] = [];
  if (data) {
    if (key) {
      formattedOptions = data.map((val: any) => {
        return {
          value: val.id,
          label:
            val.name +
            `${
              nestedKey
                ? val[key] && val[key][nestedKey]
                  ? `(${val[key][nestedKey]})`
                  : ""
                : val[key]
                ? `(${val[key]})`
                : ""
            }`,
        };
      });
    } else {
      formattedOptions = data.map((val: any) => {
        return { value: val.id, label: val.name };
      });
    }
  }

  return formattedOptions;
};

export const UpdatedFormatOptions = <T>(
  data: T[],
  valueKey: keyof T,
  labelKey: keyof T
): SelectOptions[] => {
  if (data.length < 1) return [];
  const formattedData = data.map((val) => {
    return {
      //@ts-ignore
      value: valueKey ? val[valueKey] : val.id ? val.id : null,
      //@ts-ignore
      label: labelKey ? val[labelKey] : val.name ? val.name : "",
    };
  });
  return formattedData;
};

export const UpdatedformatSelectOptions = <T>(
  data: T[],
  valueKey: keyof T | string = "id",
  labelKey: keyof T | string = "name"
): SelectOptions[] => {
  let formattedOptions: SelectOptions[] = [];

  formattedOptions = data?.map((val) => {
    return {
      //@ts-ignore
      value: val[valueKey],
      //@ts-ignore
      label: val[labelKey],
    };
  });
  return formattedOptions;
};

export const CalculateDiscountPercent = (total: any, discount: any) => {
  const discountPercent = (Number(discount) / Number(total)) * 100;
  return discountPercent;
};

export const BillDataFormat = (product_details: BillProductDetail[]) => {
  const formattedData = product_details.map((val) => {
    return {
      id: val.id,
      product_bar_code: val.product_bar_code,
      product_id: val.product_price_id,
      qty: {
        primary_unit: val.primary_product_unit,
        secondary_unit: val.secondary_product_unit,
      },
      free_qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      rate: {
        primary_price: val.primary_price,
        secondary_price: val.secondary_price,
      },
      tax: Number(val?.tax),
      sub_amt: val.net_amount,
      discount: val.discount,
      amt: val.total,
      selected: false,
      relation: val.relation,
    };
  });
  return formattedData;
};

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function ObjArrValueExist<T>(arr: T[]): boolean {
  let flag = false;
  arr.forEach((val) => {
    //@ts-ignore
    if (val.amount !== 0) {
      flag = true;
    }
  });
  return flag;
}

export const ChartMap = (array: number[]) => {
  if (array) {
    const mappedData = array.map((val) => val);
    return mappedData;
  }

  return [];
};

export const updateSecondaryQuantity = (arr: BillDetailProps[]) => {
  const temp = [...arr];
  const newArr = temp.map((val) => {
    if (val.qty.primary_unit > 0 && val.qty.secondary_unit <= 0) {
      val.rate.secondary_price = fixedValue(
        val.rate.primary_price / Number(val.relation)
      ) as number;
    }
    return val;
  });
  return newArr;
};

export const FormatDate = (date: string) => {
  if (date === "" || undefined) return "";
  const formatSplitdate = date.slice(0, 10).split("");
  const replaceSlash = formatSplitdate.map((val) => {
    if (val === "/") {
      val = "-";
    }
    return val;
  });
  return replaceSlash;
};

export const ReturnHeaders = (
  columns: { Header: string; accessor: string }[]
) => {
  if (columns) {
    const updates = columns.map((val) => {
      return val.Header;
    });
    return updates;
  }
  return [];
};

export const HeaderUpdate = (str: string, splitChar = "_") => {
  const newVal = str.split(splitChar);
  const capitalFirst = newVal.map((val) => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  });
  return capitalFirst.join(" ");
};

export const CheckUnwantedColumnExist = (
  columns: { Header: string; accessor: string }[],
  unwantedColumn: string
) => {
  const onlyHeader = columns.map((val) => {
    return val.Header.toLowerCase();
  });
  return onlyHeader.includes(unwantedColumn.toLowerCase());
};

export const CheckRoute = (route: string) => {
  if (route) {
    return (
      route.includes("sales") ||
      route.includes("purchase") ||
      route.includes("audit") ||
      route.includes("materialized") ||
      route.includes("trial") ||
      route.includes("profit") ||
      route.includes("trading") ||
      route.includes("indirect") ||
      route.includes("balance") ||
      route.includes("cash")
    );
  }
  return false;
};

export interface NestedColumnOptions {
  Header: string;
  accessor: string;
  columns?: NestedColumnOptions[];
}

export const nestedColumnCounts = (columns: NestedColumnOptions[]) => {
  let highCount = 0;
  columns.forEach((val) => {
    let tempCount = 0;
    if (val.columns) {
      tempCount = tempCount + 1;
      val.columns.map((itm) => {
        if (itm.columns) {
          tempCount = tempCount + 1;
        }
      });
    }
    if (tempCount > highCount) {
      highCount = tempCount;
    }
  });
  return highCount;
};

export const onlyDecimalNumber = (e: string) => {
  if (!/^[0-9]*\.?[0-9]*$/.test(e)) {
    // Replace non-numeric characters with empty string
    const filteredValue = e.replace(/[^0-9.]/g, "");
    return filteredValue;
  }
  return e;
};

export const SearchKeyUpdates = (val: string) => {
  let value = val.split("_").join(" ");
  return capitalCase(value);
};

export const SearchHelpersNumber = (val: string) => {
  return val !== "" ? Number(val) : null;
};

export const SearchHelpers = (
  val: string | undefined | "",
  casingStyle: "Lower" | "Upper"
) => {
  if (!val || val === undefined) {
    return "";
  }
  if (casingStyle === "Lower") {
    return val.toLocaleLowerCase();
  }
  if (casingStyle === "Upper") {
    return val.toUpperCase();
  }
};

export const PartyLedgerOptionFormatter = (data: CustomerListResponse[]) => {
  if (data.length < 1) return [];
  const fomratOptions = data.map((val) => {
    return {
      label: `${val.name} ${
        val.additional_info && `(${val.additional_info.address})`
      }`,
      value: val.roles?.[0].user_role_id,
    };
  });
  return fomratOptions;
};

export function FilterArray<T, K>(
  filteringArray: T[],
  checkArray: K[],
  filteringKey: keyof T,
  checkKey: keyof K
): T[] {
  if (!filteringArray || filteringArray.length <= 0) return [];
  if (!checkArray || checkArray.length <= 0) return filteringArray;
  const filteredArray = filteringArray.filter(
    (obj) =>
      !checkArray
        .map((innerObj) => innerObj[checkKey])
        //@ts-ignore
        .includes(obj[filteringKey])
  );
  return filteredArray;
}

export function ProductFilter<T>(
  filteringArray: InputContent[],
  checkArray: T[],
  checkKey: keyof T
): InputContent[] {
  if (!filteringArray || filteringArray.length <= 0) return [];
  if (!checkArray || checkArray.length <= 0) return filteringArray;
  // const filteredArray = filteringArray.filter(
  //   (obj) =>
  //     !checkArray
  //       .map((innerObj) => innerObj[checkKey])
  //       //@ts-ignore
  //       .includes(obj?.batch_info[0]?.product_price[0]?.id)
  // );
  return filteringArray;
}

type Person = Record<string, string | number>;

export const ObjectToArrayOfObject = (obj: Person) => {
  const objectArray = Object.entries(obj).map(([key, val]) => {
    return {
      label: capitalCase(key.split("_").join(" ")),
      value: fixedValue(val as number),
    };
  });
  return objectArray;
};

// export function addCommasToAmount(amount: any) {
//   const formattedAmount = amount
//     .toString()
//     .replace(/(\d)(?=(\d{2})+(\d)(?!\d))/g, "$1,");
//   return formattedAmount;
// }

export const parenthesisInNegativeValue = (num: number) => {
  if (!num) {
    num = 0;
  }
  let requiredAnswer = englishToNepaliAmountConverter(num.toString(), 2, "en");
  if (num < 0) {
    requiredAnswer = `(${englishToNepaliAmountConverter(
      Math.abs(num).toString(),
      2,
      "en"
    )})`;
  }
  return requiredAnswer;
};

export const checkCreditOrDebit = (num: number) => {
  let requiredAnswer = "";
  const currencyFormat = englishToNepaliAmountConverter(
    Math.abs(num).toString(),
    2,
    "en"
  );
  if (num < 0) {
    requiredAnswer = `${currencyFormat}(Cr)`;
  } else {
    requiredAnswer = `${currencyFormat}(Dr)`;
  }
  return requiredAnswer;
};

export const englishToNepaliAmountConverter = (
  strNum: string | number,
  decimalPrecision: number = 2,
  lang: string = language
): string => {
  return nepaliAmountFormat(strNum, decimalPrecision, lang);
};

export const englishToNepaliConverter = (strNum: string): string => {
  return language === "ne" ? englishToNepaliNumber(strNum) : strNum;
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const BalanceSheetSameOrder = (
  arr1: BalanceSheetOrderName,
  arr2: Array<IBalanceSheet>
) => {
  const sortedArray = [...arr2].sort((a, b) => {
    const indexA = arr1.indexOf(a.ledger_name);
    const indexB = arr1.indexOf(b.ledger_name);

    if (indexA === -1 && indexB === -1) {
      return 0; // Preserve the original order for items not in arr1
    }

    if (indexA === -1) {
      return 1; // a is not in arr1, so it comes after b
    }

    if (indexB === -1) {
      return -1; // b is not in arr1, so it comes after a
    }

    return indexA - indexB; // Sort based on the order in arr1
  });

  return sortedArray;
};

export const checkAmounttType = (num: number | undefined) => {
  if (num === undefined) {
    return "Opening balance is undefined.";
  }
  let requiredAnswer = String(num);
  if (num < 0) {
    requiredAnswer = `(${Math.abs(num)}) payable`;
  } else {
    requiredAnswer = ` ${Math.abs(num)} receivable `;
  }
  return requiredAnswer;
};

export const isCurrentFiscalYear = (currentFiscalYear: string | undefined) => {
  return currentFiscalYear && currentFiscalYear.toLowerCase() === "yes";
};
