import { ErrorMessage, Field } from "formik";
import "./BusinessInfoForm.scss";
import { usePersonalDetailsInfo } from "./PersonalInfoWrapper";

export const PersonalForm = () => {
  const { handleSubmit, setFieldValue } = usePersonalDetailsInfo();
  return (
    <div className="business-info-form">
      <div className="business-body-data">
        <fieldset className="business-field-set">
          <legend className="small-title">Personal Information:</legend>
          <div className="row">
            <div className="col-md-6">
              <div className="data-field">
                <label className="input-label">Name</label>
                <div>
                  <Field
                    className="input-field"
                    placeholder="Enter name"
                    name="name"
                    onChange={(e: any) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name={"name"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <label className="input-label">Email</label>
              <div className="data-field no-bottom">
                <Field
                  className="input-field"
                  placeholder="Enter email"
                  name="email"
                  onChange={(e: any) => {
                    setFieldValue("email", e.target.value);
                  }}
                />
                <ErrorMessage
                  name={"email"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="input-label">Address</label>
              <div className="data-field no-bottom">
                <Field
                  className="input-field"
                  placeholder="Enter address"
                  name="additional_info.address"
                  onChange={(e: any) => {
                    setFieldValue("additional_info.address", e.target.value);
                  }}
                />
                <ErrorMessage
                  name={"additional_info.address"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="input-label">Mobile number</label>
              <div className="data-field">
                <Field
                  type="number"
                  className="input-field"
                  placeholder="Enter mobile number"
                  onWheel={(e: any) => {
                    (e.target as HTMLInputElement).blur();
                  }}
                  onFocus={(event: any) => {
                    if (parseInt(event.target.value) === 0) {
                      event.target.value = "";
                    }
                  }}
                  name="mobile_no"
                  onChange={(e: any) => {
                    setFieldValue("mobile_no", e.target.value);
                  }}
                />
                <ErrorMessage
                  name={"mobile_no"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="input-label">PAN</label>
              <div className="data-field">
                <Field
                  type="number"
                  className="input-field"
                  placeholder="Enter pan number"
                  onWheel={(e: any) => {
                    (e.target as HTMLInputElement).blur();
                  }}
                  onFocus={(event: any) => {
                    if (parseInt(event.target.value) === 0) {
                      event.target.value = "";
                    }
                  }}
                  name="pan"
                  onChange={(e: any) => {
                    setFieldValue("pan", e.target.value);
                  }}
                />
                <ErrorMessage name={"pan"} component="div" className="error" />
              </div>
            </div>
            <div className="col-md-6">
              <label className="input-label">Profile Picture</label>
              <div className="logo-field">
                <input
                  className="input-field"
                  name="photo"
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  onChange={(e: any) => {
                    const value = e.target.files[0];
                    setFieldValue("photo", value === undefined ? null : value);
                  }}
                />
              </div>
            </div>
          </div>
          <button
            className="main-button m-auto"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </button>
        </fieldset>
      </div>
    </div>
  );
};
