import "./Receipt.scss";
import { useContext, useEffect, useState } from "react";
import { UpdatePrintCount, useGetBillInvoice } from "../Api/Get/GetApi";
import { PopupContext } from "../PopUp/Popup-Context";
import { DocumentContext } from "../BillPage/Document-Context";
import moment from "moment";
import { ToWords } from "to-words";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { BillGenerate } from "../BillGenerate/BillGenrate";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import {
  englishToNepaliConverter,
  englishToNepaliAmountConverter,
  fixedValue,
} from "../../utils/services";
import { ReportType } from "../../EnumValues";
import { convertToNepaliWords } from "../../utils/num-to-nepaliwords";
interface ReciptPageProps {
  title: string;
}
export interface ChequeReceipt {
  cheque_no: string;
  created_at: string;
  cheque_withdraw: boolean;
  user_role_id: number;
  id: number;
  updated_at: string | null;
  issue_date: string;
  amount: number;
  transaction_detail_id: number;
  bank_name: string;
}
export interface billDetailsResponse {
  free_primary_unit: number;
  free_secondary_unit: number;
  secondary_unit_measure: string;
  primary_price: number;
  primary_unit: number;
  primary_unit_measure: string;
  secondary_price: number;
  secondary_unit: number;
  sub_total: number;
  discount: number;
  tax: number;
  total: number;
  product: string;
}

export interface IReceipt {
  bill_user_info: {
    pan: number;
    name: string;
    customer_note: string;
    mobile_no: number;
    bill_print_date: string;
    bill_miti: string;
    bill_miti_ad: string;
    is_total_discount: boolean | null;
    document_no: string;
    bill_no: string | null;
    bill_print_count: number;
  };

  customer_user_info: {
    pan: number;
    name: string;
    mobile_no: number;
    document_no: string;
    date: string;
    bill_no: string;
    additional_info: {
      address: string;
      city: string;
      additionalProp1: string;
    };
  };
  bill_detail: billDetailsResponse[];
  amount_detail: {
    bank: number;
    cash: number;
    credit: number;
    discount_paid: number;
    discount_received: number;
    purchase: number;
    sub_total: number;
    taxable_amount: number;
    tax_paid: number;
    tax_received: number;
    total_amount: number;
  };
  payment_type: {
    bank: number;
    cash: number;
    credit: number;
  };
  cheque_detail: Array<ChequeReceipt>;
  business_info: {
    name: string;
    pan: number;
    mobile_no: string;
    logo: string;
    id: 4;
    address: string;
  };
}
interface ReciptProps {
  showCreateBillBtn?: boolean;
  reportType?: ReportType;
}

export const Receipt: React.FC<ReciptProps> = ({
  showCreateBillBtn = false,
  reportType = ReportType.Sales,
}) => {
  const [billProductNumber, setBillProductNumber] = useState("18");
  const [loading, setLoading] = useState(false);
  const { closeModal } = useContext(PopupContext);
  const { documentNumber, setResStatus, langData, language } =
    useContext(DocumentContext);
  const toWords = new ToWords({
    converterOptions: { currency: true, ignoreDecimal: false },
  });
  const componentRef = useRef(null);
  const { data, isLoading } = useGetBillInvoice(documentNumber);
  const { showErrorMessage } = useToast();
  const printTitle = ["Tax Invoice", "Invoice"];
  const createBillTitle = ["Invoice"];
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setLoading(true),
    onAfterPrint: () => setLoading(false),
  });

  const handlePrintCount = (document_number: string) => {
    setLoading(true);
    UpdatePrintCount(document_number)
      .then((res) => {
        handlePrint();
        setResStatus?.(false);
      })
      .catch((err) => {
        showErrorMessage("Something Went wrong while printing");
        setLoading(false);
      });
  };

  const userInfo = data && data?.bill_user_info;
  const billDetails = data && data?.bill_detail;
  const amountDetails = data && data?.amount_detail;
  const bankDetails = data && data?.cheque_detail;
  const businessInfo = data && data?.business_info;
  const customerInfo = data && data?.customer_user_info;

  const showFreeData = (billArray: billDetailsResponse[]) => {
    let flag = false;
    if (billArray) {
      billArray.forEach((val) => {
        if (val.free_primary_unit || val.free_secondary_unit) {
          flag = true;
        }
      });
    }

    return flag;
  };

  const isNepali = language === "ne";

  const ReciptPage: React.FC<ReciptPageProps> = ({ title }) => {
    return (
      <div className={`receipt-section ${isNepali && "nepali-text"}`}>
        <div className="p-receipt">
          <div className="d-space-between">
            <div className="logo-detail">
              {reportType === ReportType.Sales && (
                <>
                  <div className="d-flex logo-section">
                    {businessInfo?.logo ? (
                      <div className="logo">
                        <img src={businessInfo?.logo} alt="logo" />
                      </div>
                    ) : (
                      <div className="large-title">
                        {businessInfo?.name.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div>
                    <h2 className="small-title">{businessInfo?.name}</h2>
                    <p className="content">{businessInfo?.address}</p>

                    <div className="div-flex">
                      {businessInfo?.mobile_no && (
                        <div className="bold-content">
                          {langData.customerDetails.phone}:{" "}
                          <span className="content">
                            {/* +977- */}
                            {englishToNepaliConverter(
                              String(`+977-${businessInfo?.mobile_no}`)
                            )}
                          </span>
                        </div>
                      )}
                      {businessInfo?.pan && (
                        <div className="bold-content pan-box">
                          {/* <div className="vertical-line"></div> */}
                          {langData.customerDetails.panNo}:
                          <span className="content">
                            {englishToNepaliConverter(
                              String(businessInfo?.pan)
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="receipt-detail-block d-end">
              {Number(userInfo?.bill_print_count) > 0 &&
              showCreateBillBtn === false &&
              createBillTitle &&
              reportType === ReportType.Sales ? (
                <>
                  <div className="content copy-text">
                    Copy of Original ({Number(userInfo?.bill_print_count)})
                  </div>
                </>
              ) : (
                ""
              )}
              {reportType === ReportType.Sales && (
                <h2 className="large-title">{title} </h2>
              )}
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th className="bold-content" scope="row">
                      {langData.billDetails.invoiceNo}:
                    </th>
                    <td className="content">
                      {reportType === ReportType.Purchase
                        ? userInfo?.bill_no
                        : userInfo?.document_no}
                    </td>
                  </tr>
                  {userInfo?.bill_miti && (
                    <tr>
                      <th className="bold-content" scope="row">
                        {langData.billDetails.billMiti}:
                      </th>

                      <td>
                        <span className="content">
                          {englishToNepaliConverter(
                            String(userInfo?.bill_miti.split("/").join(" - "))
                          )}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={
              reportType === ReportType.Sales
                ? "d-space-between header-wrapper"
                : "d-space-between"
            }
          >
            <div className="receipt-header">
              <div className="receipt-header-block">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th className="bold-content" scope="row">
                        {langData.customerDetails.customerName}:
                      </th>
                      <td className="content">{customerInfo?.name}</td>
                    </tr>
                    {customerInfo?.additional_info?.address ||
                    customerInfo?.additional_info?.additionalProp1 ? (
                      <tr>
                        <th className="bold-content" scope="row">
                          {langData.customerDetails.address}:
                        </th>
                        <td className="content">
                          {customerInfo?.additional_info?.address ||
                            customerInfo?.additional_info?.additionalProp1}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {customerInfo?.mobile_no && (
                      <tr>
                        <th className="bold-content" scope="row">
                          {langData.customerDetails.phone}:
                        </th>

                        <td className="content">
                          {englishToNepaliConverter(
                            String(`+977-${customerInfo?.mobile_no}`)
                          )}
                        </td>
                      </tr>
                    )}
                    {customerInfo?.pan && (
                      <tr>
                        <th className="bold-content" scope="row">
                          {langData.customerDetails.panNo}:
                        </th>
                        <td className="content">
                          {englishToNepaliConverter(String(customerInfo?.pan))}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="last-block">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th className="bold-content" scope="row">
                      {langData.billDetails.transactionDate}:
                    </th>
                    <td className="content">
                      {englishToNepaliConverter(
                        String(userInfo?.bill_miti_ad.split("/").join(" - "))
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="bold-content" scope="row">
                      {langData.billDetails.printDateTime}:
                    </th>
                    <td className="content">
                      {englishToNepaliConverter(
                        String(new Date().toLocaleString() + "")
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="bold-content" scope="row">
                      {langData.billDetails.IssuedBy}:{" "}
                    </th>
                    <td className="content">{userInfo?.name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="full-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th rowSpan={2} className="header-align-center sn-head">
                    {langData.productHeads.SN}
                  </th>
                  <th rowSpan={2} className="header-align-center name-sec">
                    {langData.productHeads.hsCode}
                  </th>
                  <th rowSpan={2} className="header-align-center name-sec">
                    {langData.productHeads.productName}
                  </th>

                  <th colSpan={2} className="center-align">
                    {langData.productHeads.quantity}
                  </th>

                  {showFreeData(billDetails as billDetailsResponse[]) && (
                    <th colSpan={2} className="center-align">
                      Free
                    </th>
                  )}

                  <th colSpan={2} className="center-align">
                    {langData.productHeads.rate}
                  </th>

                  <th rowSpan={2} className="end-align header-align-center">
                    {langData.productHeads.discount}
                  </th>
                  <th rowSpan={2} className="end-align header-align-center">
                    {langData.productHeads.tax}
                  </th>
                  <th rowSpan={2} className="end-align header-align-center">
                    {langData.productHeads.amount}
                  </th>
                  {/* <th rowSpan={2} className="end-align header-align-center">
                    {langData.productHeads.netAmount}
                  </th> */}
                </tr>
                <tr className="center-align">
                  <th>{langData.productHeads.pri}</th>
                  <th>{langData.productHeads.sec}</th>
                  {showFreeData(billDetails as billDetailsResponse[]) && (
                    <>
                      <th>Pri</th>
                      <th>SEC</th>
                    </>
                  )}

                  <th>{langData.productHeads.pri}</th>
                  <th>{langData.productHeads.sec}</th>
                </tr>
              </thead>
              <tbody>
                {billDetails &&
                  Array(Number(billProductNumber))
                    .fill(undefined)
                    ?.map((_, index) => {
                      const item = billDetails[index];
                      return (
                        <tr
                          style={{ visibility: item ? "visible" : "hidden" }}
                          key={index}
                        >
                          <td className="padd-5"> {item ? index + 1 : "1"}</td>
                          <td></td>
                          <td className="padd-5 name-sec">{item?.product}</td>
                          <td className="padd-5">
                            <p>
                              {item?.primary_unit > 0
                                ? englishToNepaliConverter(
                                    String(item?.primary_unit) +
                                      " " +
                                      item?.primary_unit_measure
                                  )
                                : "-"}
                            </p>
                          </td>
                          <td className="padd-5">
                            <p>
                              {item?.secondary_unit > 0
                                ? englishToNepaliConverter(
                                    String(item?.secondary_unit)
                                  ) +
                                  " " +
                                  item?.secondary_unit_measure
                                : "-"}
                            </p>
                          </td>
                          {showFreeData(
                            billDetails as billDetailsResponse[]
                          ) && (
                            <>
                              <td className="padd-5">
                                <p>
                                  {englishToNepaliConverter(
                                    String(item?.free_primary_unit)
                                  )}
                                </p>
                              </td>
                              <td className="padd-5">
                                <p>
                                  {englishToNepaliConverter(
                                    String(item?.free_secondary_unit)
                                  )}
                                </p>
                              </td>
                            </>
                          )}

                          <td className="padd-5">
                            <p>
                              {item?.primary_unit > 0
                                ? englishToNepaliAmountConverter(
                                    String(item?.primary_price)
                                  )
                                : "-"}
                            </p>
                          </td>
                          <td className="padd-5">
                            <p>
                              {item?.secondary_unit > 0
                                ? englishToNepaliAmountConverter(
                                    String(item?.secondary_price)
                                  )
                                : "-"}
                            </p>
                          </td>

                          <td className="padd-5 end-col">
                            {!userInfo?.is_total_discount
                              ? englishToNepaliAmountConverter(
                                  String(item?.discount)
                                )
                              : englishToNepaliAmountConverter(0)}
                          </td>
                          <td className="padd-5 end-col">
                            {englishToNepaliAmountConverter(
                              String(item?.tax > 0 ? "13" : "0")
                            )}
                            %
                          </td>
                          <td className="padd-5 end-col">
                            <p>
                              {englishToNepaliAmountConverter(
                                String(item?.sub_total)
                              )}
                            </p>
                          </td>
                          {/* <td className="padd-5 end-col">
                            <p>
                              {englishToNepaliAmountConverter(
                                String(item?.total)
                              )}
                            </p>
                          </td> */}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="footer-div">
            <div className="receipt-info">
              <div className="receipt-info-section">
                <div className="receipt-info-block">
                  <h4 className="block-title">
                    {langData.transactionDetails.inWords}:
                  </h4>
                  <p className="small-content capitalize">
                    {isNepali
                      ? convertToNepaliWords(
                          fixedValue(amountDetails?.total_amount, 2) || 0
                        )
                      : toWords.convert(
                          fixedValue(amountDetails?.total_amount, 2) || 0
                        )}
                  </p>
                </div>
              </div>
              <div className="receipt-info-section ">
                <div className="receipt-info-block">
                  <h4 className="block-title capitalize">
                    {langData.footer.note}:
                  </h4>
                  <p className="small-content">
                    {userInfo?.customer_note
                      ? userInfo?.customer_note
                      : `Thank You`}
                  </p>
                </div>
              </div>
              <div className="receipt-info-block">
                <h4 className="block-title">
                  {langData.signatures.authorized} :
                </h4>
                <p className="small-content customers-sign">
                  ----------------------------
                </p>
              </div>
            </div>

            <div className="receipt-calculation">
              <div className="receipt-calculation-block ">
                <p className="small-content">
                  {langData.transactionDetails.subTotal}
                </p>
                <p className="small-content text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {englishToNepaliAmountConverter(
                    String(amountDetails?.sub_total)
                  )}
                </p>
              </div>
              <div className="receipt-calculation-block">
                <p className="small-content">
                  {langData.transactionDetails.discount}
                </p>
                <p className="small-content text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {amountDetails && amountDetails?.discount_paid > 0
                    ? englishToNepaliAmountConverter(
                        String(amountDetails?.discount_paid)
                      )
                    : englishToNepaliAmountConverter(
                        String(amountDetails?.discount_received)
                      )}
                </p>
              </div>
              <div className="receipt-calculation-block">
                <p className="bold-content">
                  {langData.transactionDetails.taxableAmt}
                </p>
                <p className="small-content text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {amountDetails &&
                    englishToNepaliAmountConverter(
                      String(amountDetails?.taxable_amount)
                    )}
                </p>
              </div>
              <div className="receipt-calculation-block">
                <p className="small-content">
                  {langData.transactionDetails.vat} @{" "}
                  {langData.transactionDetails.vatPer}%{" "}
                </p>
                <p className="small-content text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {amountDetails && amountDetails?.tax_paid > 0
                    ? englishToNepaliAmountConverter(
                        String(amountDetails?.tax_paid)
                      )
                    : englishToNepaliAmountConverter(
                        String(amountDetails?.tax_received)
                      )}
                </p>
              </div>
              <div className="receipt-calculation-block">
                <p className="bold-content">
                  {langData.transactionDetails.total} (
                  {langData.transactionDetails.npr})
                </p>
                <p className="content text-right">
                  {langData.transactionDetails.rupees}.{" "}
                  {englishToNepaliAmountConverter(
                    String(amountDetails?.total_amount)
                  )}
                </p>
              </div>
              <div className="receipt-info-block width-21">
                <h4 className="block-title text-right">
                  {langData.signatures.customer}:
                </h4>
                <p className="small-content customers-sign text-right">
                  ----------------------------
                </p>
              </div>
            </div>
          </div>

          {(bankDetails?.length || 0) > 0 && (
            <div className="receipt-info payment-information">
              <div className="receipt-info-block ">
                <h4 className="block-title">Payment Information</h4>
                {bankDetails?.map((bank) => {
                  return (
                    <>
                      <p className="small-content">
                        Bank Name: {bank.bank_name}
                      </p>
                      <p className="small-content">
                        Cheque No.: {bank.cheque_no}
                      </p>
                      <p className="small-content">
                        Cheque Issued Date:{" "}
                        {moment(bank?.issue_date).format("YYYY - MM - DD")}
                      </p>
                      <p className="small-content">Amount: Rs. {bank.amount}</p>
                    </>
                  );
                })}
              </div>
            </div>
          )}
          <div className="receipt-info">
            <div className="receipt-info-block receipt-footer">
              <p className="small-content">
                {langData.footer.computerGenerated}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading || !data ? (
        <BillGenerate />
      ) : (
        <>
          <div className="receipt">
            <div className="receipt-top">
              <div className="d-space-between">
                <>
                  <h4 className="title">Receipt</h4>
                  <input
                    className="form-control font-field"
                    onChange={(e) => {
                      if (e.target.value.length <= 0) {
                        setBillProductNumber("20");
                      } else {
                        setBillProductNumber(e.target.value);
                      }
                    }}
                  />

                  <div className="cancel-icon">
                    <img
                      src={require("../../assets/images/cross.png")}
                      alt="cancel"
                      onClick={() => {
                        setResStatus?.(false);
                        closeModal();
                      }}
                    />
                  </div>
                </>
              </div>
            </div>
            <ReciptPage title="Invoice" />
            {showCreateBillBtn || Number(userInfo?.bill_print_count) <= 0 ? (
              <div style={{ display: "none" }}>
                <div className="print-page" ref={componentRef}>
                  {printTitle.map((itm, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <ReciptPage title={itm} />
                        <div className="page-break" />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div style={{ display: "none" }}>
                <div className="print-page" ref={componentRef}>
                  {createBillTitle.map((itm, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <ReciptPage title={itm} />
                        <div className="page-break" />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="btn-right">
            <div className="d-end">
              <div>
                <button
                  className="light-secondary mr-16"
                  onClick={() => {
                    closeModal();
                    setResStatus?.(false);
                  }}
                >
                  Back
                </button>
              </div>

              <div>
                <ButtonComponent
                  isLoading={loading}
                  title="Print"
                  onClick={() => {
                    handlePrintCount(documentNumber);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
