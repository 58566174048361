import { ErrorMessage, Field, Form, Formik, FormikConfig } from "formik"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PostLoginPage } from "../Api/Post/PostApi"
import { useAuth } from "../context/auth/auth"
import "./LoginPage.scss"
import * as Yup from "yup"
import { Loader } from "../Loader/Loader"
import { DontShowEye, ShowEye } from "../../assets/images/svg/svg"
import { checkNumber } from "../../utils/services";

function useFocusNext() {
  const controls = useRef([])

  const handler = (event: any) => {
    if (event.keyCode === 13) {
      //@ts-ignore
      const index = controls.current.indexOf(event.target)
      const next = controls.current[index + 1]
      const current = controls.current[index]
      //@ts-ignore
      if (current.type !== "submit") {
        //@ts-ignore
        next && next.focus()
        event.preventDefault()
      }
    }
  }

  return useCallback((element: any) => {
    //@ts-ignore
    if (element && !controls.current.includes(element)) {
      //@ts-ignore
      controls.current.push(element)
      element.addEventListener("keydown", handler)
    }
  }, [])
}

const initialValues = {
  mobile_no: "",
  password: "",
}

const validationSchema = Yup.object({
  mobile_no: Yup.string()
    .length(10, "Must be of 10 digit")
    .required("Required"),
  password: Yup.string().required("Password Required"),
})

export const LoginPage = () => {
  const [handleShow, setHandleShow] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const focusNextRef = useFocusNext()
  const { signIn, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  const handleSubmit: FormikConfig<{
    mobile_no: string
    password: string
  }>["onSubmit"] = (values) => {
    setIsSubmitting(true)
    const postData = { mobile_no: values.mobile_no, password: values.password }
    PostLoginPage(postData)
      .then((res) => {
        setIsSubmitting(false)
        navigate("/")
        signIn()
      })
      .catch((err: any) => {
        let errorMessage = "Failed to Login !!"
        if (err?.response?.data?.status?.status_message) {
          errorMessage = err?.response?.data?.status?.status_message
        }
        toast.error(errorMessage)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [])

  return (
    <div className="login-page">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {
          ({ values, setFieldValue }) => (
            <Form>
              <div className="custom-card">
                <div className="card-wrapper">
                  <div className="login-title">
                    <div className="large-title">Welcome Back!</div>
                    <div className="content">Sign in to continue </div>
                  </div>
                  <div className="login-body">
                    <div className="field-block">
                      <label className="input-label">Mobile Number</label>
                      <Field
                        type="text"
                        className="input-field"
                        placeholder="Enter your mobile number"
                        name="mobile_no"
                        ref={focusNextRef}
                        maxLength={10}
                        value={values.mobile_no}
                        onChange={(e: any) => {
                          setFieldValue("mobile_no", checkNumber(e.target.value));
                        }}
                      />
                      <ErrorMessage
                        name="mobile_no"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="field-block password-area">
                      <label className="input-label">Password</label>
                      <Field
                        className="input-field"
                        name="password"
                        autoComplete="off"
                        type={handleShow ? "text" : "password"}
                        placeholder="Enter password"
                        ref={focusNextRef}
                      />

                      <div
                        className="eye-section"
                        onClick={() => {
                          setHandleShow(!handleShow)
                        }}
                      >
                        {handleShow ? <ShowEye /> : <DontShowEye />}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />

                    <div className="d-end content password-field ">
                      Forgot Password?
                      <span className="tooltiptext content">Please contact the administrative for assistance with changing your password.</span>
                      <div className="triangle-down"></div>
                    </div>
                  </div>
                  <div className="d-vertical-center">
                    <div className="log-btn">
                      <button
                        className="main-button"
                        type="submit"
                        ref={focusNextRef}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div>
                            <Loader loaderType="Spinner" universalCenter={false} />
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                    {/* <div className="log-note extra-small-title">
                  Don’t have an account?{" "}
                  <span className="log-name">Sign Up</span>
                </div> */}
                  </div>
                </div>
              </div>
              <div className="image-wrapper">
                <div className="bg-img">
                  <img
                    src={require("../../assets/images/bg.png")}
                    alt="background"
                  />
                </div>
              </div>
            </Form>
          )}
      </Formik>
    </div>
  )
}
