import { useEffect, useState } from "react";
import {
  HandleError,
  checkNumber,
  fixedValue,
  replaceDecimal,
} from "../../utils/services";
import {
  useOpeningProductList
} from "../Api/Get/GetApi";
import { PostProductService } from "../Api/Post/PostApi";
import { IOpeningProductList } from "../Api/types";
import { useToast } from "../context/ToasterContext/ToasterContext";
import "./Openning.scss";

export interface ISupplierList {
  name: string;
  primary_product: number;
  secondary_product: number;
  primary_price: number;
  secondary_price: number;
  total: number;
  product_id: number | string;
  isChange: boolean;
  id: number;
  relation: number;
}

export const Openning = () => {
  const [supplier, setSupplier] = useState<Array<ISupplierList>>();
  const [grandTotal, setGrandTotal] = useState<number>();
  const { showErrorMessage, showSuccessMessage } = useToast();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { data: openingProdut, isLoading, refetch } = useOpeningProductList();

  const RelationWiseUpdateValue = (
    index: number,
    value: any,
    newArray: ISupplierList[],
    key: "secondary_product" | "primary_price"
  ) => {
    const relation = Number(newArray[index].relation);
    switch (key) {
      case "secondary_product":
        const primVal = Math.floor(Number(value) / relation);
        const secondaryVal = Number(value) % relation;
        const preExistedPrimVal = supplier?.[index].primary_product;
        newArray[index].primary_product = Number(preExistedPrimVal) + primVal;
        newArray[index].secondary_product = replaceDecimal(secondaryVal);
        break;
      case "primary_price":
        const secPrice = value / relation;
        newArray[index].secondary_price = replaceDecimal(secPrice);
        newArray[index].primary_price = replaceDecimal(value);
        break;
      default:
        return;
    }
  };

  const handleDataUpdate = (
    value: any,
    index: number,
    key: keyof ISupplierList
  ) => {
    const cloned = [...(supplier as ISupplierList[])];
    value = checkNumber(value, "decimalToo");
    if (
      key === "secondary_product" ||
      (key === "primary_product" &&
        supplier?.[index] &&
        supplier?.[index].relation !== 1)
    ) {
      value = checkNumber(value, "numberOnly");
    }

    if (key === "secondary_product" || key === "primary_price") {
      RelationWiseUpdateValue(index, value, cloned, key);
    } else {
      //@ts-ignore
      cloned[index][key] = replaceDecimal(value);
    }

    cloned[index].total = fixedValue(
      cloned[index].primary_price * cloned[index].primary_product +
        cloned[index].secondary_price * cloned[index].secondary_product
    );
    setSupplier(cloned);
  };

  useEffect(() => {
    setSupplier(
      openingProdut?.map((item: IOpeningProductList) => {
        return {
          name: item.name,
          primary_product: 0,
          secondary_product: 0,
          primary_price: item?.product_price?.primary_price,
          secondary_price: item?.product_price?.secondary_price,
          total: 0,
          product_id: item?.product_price?.product_id,
          id: item?.product_price?.id,
          isChange: false,
          relation: item.relation,
        };
      })
    );
  }, [isSubmitted, openingProdut]);

  useEffect(() => {
    const grandTotal = supplier?.reduce((accumulator, object) => {
      return accumulator + object.total;
    }, 0);
    setGrandTotal(fixedValue(grandTotal));
  }, [supplier]);

  const removeProduct = (product_id: any) => {
    const temp = supplier?.filter((f) => f.product_id !== product_id);
    setSupplier(temp);
    showSuccessMessage("Product removed successfully !");
  };

  const testIsChange = (value: string, index: number, item: ISupplierList) => {
    if (value !== item?.primary_price.toString()) {
      const findProduct = supplier?.find(
        (f) => f.product_id === item.product_id
      );
      const tempProduct = { ...findProduct, isChange: true };
      //@ts-ignore
      const temp = [...supplier];
      temp.splice(index, 1, tempProduct);
      setSupplier(temp);
    }
  };

  const onSubmit = () => {
    const validProduct = supplier?.filter(
      (f) => f.primary_product > 0 || f.secondary_product > 0
    );
    var productData = validProduct?.map((item) => {
      return {
        product_id: item?.id,
        qty: {
          primary_unit: item?.primary_product,
          secondary_unit: item?.secondary_product,
        },
        rate: {
          primary_price: item?.primary_price,
          secondary_price: item?.secondary_price,
        },
        is_change: item?.isChange,
        amt: item?.total,
      };
    });

    var postData = {
      product_details: productData,
      total: grandTotal,
    };
    PostProductService(postData)
      .then((res) => {
        showSuccessMessage(res.data.status.status_message);
        refetch();
        setIsSubmitted(!isSubmitted);
      })
      .catch((err: any) => {
        const errorMessage = HandleError(err);
        showErrorMessage(errorMessage);
      });
  };

  return (
    <div className="opennig-page">
      <div className="head-section">
        <h2 className="small-title">Opening Stock</h2>
      </div>

      <div className="parent-search">
        <div className="search-feild ">
          <div className="search-wrapper">
            <div className="search-icon">
              <img
                alt="search"
                src={require("../../assets/images/search.png")}
              />
            </div>
            <input
              className="input-field"
              placeholder="Search here"
              name="user_name"
              onChange={(e) => {
                setTimeout(() => {
                  //@ts-ignore
                  window.find(e.target.value);
                }, 1000);
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="select-field">
        <div className="col-md-3">
          <Select
            label="Select Vendor"
            onChange={(e) => {
              setVendorId(e.target.value);
            }}
          >
            {vendorList?.data?.map?.((item: any, index: number) => {
              return (
                <option key={index} value={item.id}>
                  {item?.name}
                  {item.additional_info && item.additional_info.address
                    ? `(${item.additional_info.address})`
                    : ""}
                </option>
              );
            })}
          </Select>
        </div>
      </div> */}

      <table className="table table-hover">
        <thead className="tablehead">
          <tr>
            <th className="content"> Product name</th>
            <th className="content"> Primary Product</th>
            <th className="content"> Secondary Product</th>
            <th className="content"> Primary Price</th>
            <th className="content"> Secondary Price</th>
            <th className="content"> Total</th>
            <th className="content"></th>
          </tr>
        </thead>
        <tbody className="table-body-content">
          {!isLoading && (
            <>
              {supplier?.map((item: ISupplierList, index: number) => {
                return (
                  <tr key={index} className="product-list">
                    <td className="tablerow" role="row">
                      <p className="small-content">{item?.name}</p>
                    </td>
                    <td className="tablerow" role="row">
                      <input
                        name="primary_product"
                        type="text"
                        className="input-field "
                        onWheel={(e) => {
                          (e.target as HTMLInputElement).blur();
                        }}
                        onChange={(e) => {
                          handleDataUpdate(
                            e.target.value,
                            index,
                            "primary_product"
                          );
                        }}
                        onFocus={(e: any) => {
                          if (parseInt(e.target.value) === 0) {
                            e.target.value = "";
                          }
                        }}
                        value={supplier[index].primary_product}
                        placeholder="Supplier"
                      />
                    </td>

                    <td>
                      <div>
                        <input
                          name="secondary_product"
                          type="text"
                          className="input-field "
                          onWheel={(e) => {
                            (e.target as HTMLInputElement).blur();
                          }}
                          disabled={
                            supplier[index] &&
                            Number(supplier[index].relation) === 1
                          }
                          onChange={(e) =>
                            handleDataUpdate(
                              Number(e.target.value),
                              index,
                              "secondary_product"
                            )
                          }
                          onFocus={(e: any) => {
                            if (parseInt(e.target.value) === 0) {
                              e.target.value = "";
                            }
                          }}
                          value={item?.secondary_product}
                          placeholder="Product Type Batch"
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          name="primary_price"
                          type="text"
                          className="input-field "
                          onWheel={(e) => {
                            (e.target as HTMLInputElement).blur();
                          }}
                          onChange={(e) => {
                            handleDataUpdate(
                              e.target.value,
                              index,
                              "primary_price"
                            );
                            testIsChange(e.target.value, index, item);
                          }}
                          value={
                            supplier[index] && supplier[index].primary_price
                          }
                          defaultValue={item?.primary_price}
                          placeholder="primary_price"
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          name="secondary_price"
                          type="text"
                          className="input-field "
                          onWheel={(e) => {
                            (e.target as HTMLInputElement).blur();
                          }}
                          disabled={
                            supplier[index] &&
                            Number(supplier[index].relation) === 1
                          }
                          onChange={(e) => {
                            handleDataUpdate(
                              e.target.value,
                              index,
                              "secondary_price"
                            );
                            testIsChange(e.target.value, index, item);
                          }}
                          value={
                            supplier[index] && supplier[index].secondary_price
                          }
                          defaultValue={item?.secondary_price}
                          placeholder="secondary_price"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="total-block">
                        <input
                          name="total"
                          type="number"
                          className="input-field "
                          onWheel={(e) => {
                            (e.target as HTMLInputElement).blur();
                          }}
                          value={item?.total}
                          disabled={true}
                          placeholder="0"
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        className="delete-icon"
                        onClick={() => {
                          removeProduct(item?.product_id);
                        }}
                      >
                        <img
                          src={require("../../assets/images/trash.png")}
                          alt="icon"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      <div className="grand-total">
        <p className="extra-small-title">Total</p>
        <input className="form-control" value={grandTotal} disabled />
      </div>
      <button
        className="main-button"
        onClick={() => {
          onSubmit();
        }}
      >
        Submit
      </button>
    </div>
  );
};
