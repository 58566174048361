import { useEffect, useState } from "react";
import { useProfitLossList } from "../Api/Get/GetApi";
import { useLocation } from "react-router-dom";
import { ExcelIcon, PrintIcon } from "../../assets/images/svg/svg";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { IProfitLossList } from "../Api/Get/GetApiTypes";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import "./ProfitLossAC.scss";
import { ProfitLossHorizontal } from "./ProfitLossHorizontal";
import { ProfitLossVartical } from "./ProfitLossVartical";
import { FilterDate } from "../SalesList/SalesListPage";
import { isCurrentFiscalYear } from "../../utils/services";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";

export interface TableHeaderContentGroupProps {
  columns?: any;
  loading?: boolean;
  roleId?: number;
}

export const ProfitLossAC = () => {
  const uptoDate = "0";
  const location = useLocation();
  const { businessUserInfo } = useAuth();
  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const [keyDownChecked, setKeyDownChecked] = useState<boolean>(true);
  const [activeItem, setActiveItem] = useState(null);
  const [showRow, setShowRow] = useState(false);
  const [colNumber, setColNumber] = useState(0);
  const col_Span = colNumber;

  const formattedDate = businessUserInfo?.fiscal_year_info?.first_fiscal_date
    .split("/")
    .join("-");

  const lastFiscalDate: any =
    businessUserInfo?.fiscal_year_info?.last_fiscal_date.split("/").join("-");

  const currentFiscalYear = isCurrentFiscalYear(
    businessUserInfo?.fiscal_year_info?.current_fiscal_year
  );

  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });

  const { data } = useProfitLossList(date);
  const handleRow = () => {
    setShowRow(!showRow);
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.shiftKey && event.key === "D") {
        // Alt + F1 is pressed
        // Perform the checkbox checking/unchecking logic here
        // alert('Alt + F1 pressed!');
        if (keyDownChecked) {
          const newLiabilityArr =
            data?.debit_profit_loss?.map((item) => {
              return item.id;
            }) || [];

          const newAssetArr =
            data?.credit_profit_loss?.map((item) => {
              return item.id;
            }) || [];

          const concatenatedArr = newLiabilityArr.concat(newAssetArr);

          setCheckedValues(concatenatedArr);
        } else {
          setCheckedValues([]);
        }
        setKeyDownChecked((prevKeyDownChecked) => !prevKeyDownChecked);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, keyDownChecked]);

  const toggleAccordion = (id: any) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
    }
  };

  useEffect(() => {
    if (showRow) {
      setColNumber(3);
    } else {
      setColNumber(6);
    }
  }, [showRow]);

  const handleOnChange = (e: any, item: IProfitLossList) => {
    if (e.target.checked) {
      setCheckedValues((prev) => {
        return [...prev, item.id];
      });
    } else {
      const cloned = [...checkedValues];
      const filtered = cloned.filter((oldVal) => {
        return oldVal !== item.id;
      });
      setCheckedValues(filtered);
    }
  };
  return (
    <div className="profit-loss-page">
      <div className="head-section">
        <h2 className="small-title">Indirect Profit And Loss Account</h2>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tarding-balance-table">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group mg-btm-16">
                  <label htmlFor="date" className="input-label">
                    From Date
                  </label>
                  <NepaliDatePicker
                    defaultDate={formattedDate as string}
                    onDateSelect={(value: any) => {
                      setDate?.((p) => ({
                        ...p,
                        dateFrom: value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="date" className="input-label">
                    To Date
                  </label>
                  <NepaliDatePicker
                     currentDate={currentFiscalYear as boolean}
                     defaultDate={currentFiscalYear ? "" : lastFiscalDate}
                    onDateSelect={(value: any) => {
                      setDate?.((p) => ({
                        ...p,
                        dateTo: value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-3 d-flex">
                <div className="check-field">
                  <input
                    type="checkbox"
                    id={`show_row`}
                    checked={!showRow ? false : true}
                    onClick={() => handleRow()}
                  />
                  <label className="black-content" htmlFor={`show_row`}>
                    Show vertically
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex gap-3 float-right">
                  <button
                    className="d-flex btn btn-light "
                    onClick={() =>
                      nestedTableExcel(
                        "exportTable",
                        location,
                        businessUserInfo as UserInfoResponse,
                        date?.dateFrom,
                        date?.dateTo,
                        col_Span,
                        uptoDate
                      )
                    }
                  >
                    <span className="export-title"> Export</span>
                    <ExcelIcon />
                  </button>
                  <button
                    className="d-flex btn btn-light my-4 "
                    onClick={() => {
                      financialReportPrint(
                        [],
                        businessUserInfo as UserInfoResponse,
                        date as FilterDate,
                        "exportTable",
                        location
                      );
                    }}
                  >
                    <span
                      style={{ marginRight: "10px" }}
                      className="export-title"
                    >
                      Print{" "}
                    </span>
                    <PrintIcon />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="top-head">
                <h2 className="small-title">
                  {businessUserInfo?.business_info.name}
                </h2>
                <p className="flex bold-content">
                  {businessUserInfo?.business_info.address}
                </p>

                <div className="div-flex bold-content">
                  <div className="small-text">
                    Indirect Profit And Loss Account
                  </div>

                  <p className="black-text">
                    For the period from {date?.dateFrom} to {date?.dateTo}
                  </p>
                </div>
              </div>
              <div id="exportTable">
                {!showRow ? (
                  <ProfitLossHorizontal
                    fromDate={date?.dateFrom}
                    toDate={date?.dateTo}
                    checkedValues={checkedValues}
                    toggleAccordion={toggleAccordion}
                    handleOnChange={handleOnChange}
                  />
                ) : (
                  <ProfitLossVartical
                    fromDate={date?.dateFrom}
                    toDate={date?.dateTo}
                    checkedValues={checkedValues}
                    toggleAccordion={toggleAccordion}
                    handleOnChange={handleOnChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
