import { ErrorMessage, Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { fixedValue, replaceDecimal } from "../../utils/services";
import { useNewBillFormikContext } from "../BillPage/BillFormikWrapper";
import { BankDetail } from "../BankDetail/BankDetail";
import { PopupContextProvider } from "../PopUp/Popup-Context";
import "./PurchasePage.scss";
import {
  ProductFormikPage,
  UpdateAmount,
} from "../ProductPage/ProductFormikPage";
// import { useAuth } from "../context/auth/auth";
import {
  BatchesContext,
  SelectedBatch,
} from "../context/BatchesContext/BatchesContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BatchInfoResponse } from "../ProductDetails/ProductDetails";
import { ReportType } from "../../EnumValues";
// import { UserInfoResponse } from "../context/auth/types";

export interface roleProps {
  roleID: number;
  scannedData: string;
  setScannedData: React.Dispatch<React.SetStateAction<string>>;
}

export const UpdateSelectedProducts = (
  index: number,
  selectedBatches: SelectedBatch,
  setSelectedBatches: React.Dispatch<React.SetStateAction<SelectedBatch>>,
  batchInfos: BatchInfoResponse
) => {
  const cloned = [...selectedBatches];
  //Update the key selectedBatches
  cloned[index] = batchInfos;
  setSelectedBatches(cloned);
};

export const PurchasePage: React.FC<roleProps> = ({
  roleID,
  scannedData,
  setScannedData,
}) => {
  //useState
  // const [addTax, setAddTax] = useState(false);

  //useContext
  const { getFieldProps, setFieldValue, errors, touched, values } =
    useNewBillFormikContext();
  const isCashTransaction = localStorage.getItem("TRANSACTION_IN") === 'cash'

  // const { businessUserInfo } = useAuth();

  const {
    activeBatch,
    activeProductBatches,
    selectedBatches,
    setSelectedBatches,
  } = useContext(BatchesContext);

  //useQuery

  const HandleSelectBatch = (
    getActiveIndex: number,
    batchInfos: BatchInfoResponse,
    updatedBillDetails?: any
  ) => {
    //Update the key selectedBatches
    UpdateSelectedProducts(
      getActiveIndex,
      selectedBatches,
      setSelectedBatches,
      batchInfos
    );
    let newArr = [...values.bill_details];
    if (updatedBillDetails) {
      newArr = [...updatedBillDetails];
    }
    //This needs to be update when we work with batch
    newArr[getActiveIndex].rate.primary_price = batchInfos?.product_price
      ? (batchInfos?.product_price[0].primary_price as number)
      : 0;
    newArr[getActiveIndex].rate.secondary_price = batchInfos?.product_price
      ? (batchInfos?.product_price[0].secondary_price as number)
      : 0;
    const relation = batchInfos.relation;
    newArr[getActiveIndex].relation = relation;
    newArr[getActiveIndex].id = batchInfos?.product_id as number;
    newArr[getActiveIndex].product_bar_code =
      batchInfos?.product_bar_code as string;
    newArr[getActiveIndex].product_id = batchInfos?.product_price[0]
      .id as number;
    newArr[getActiveIndex].discount = 0;
    newArr[getActiveIndex].amt = UpdateAmount(getActiveIndex, newArr).amount;
    newArr[getActiveIndex].sub_amt = UpdateAmount(
      getActiveIndex,
      newArr
    ).netAmount;
    setFieldValue("bill_details", newArr);
  };

  // useEffect(() => {
  //   if (businessUserInfo) {
  //     setAddTax(
  //       (businessUserInfo as UserInfoResponse).business_info.sync_with_IRD
  //     );
  //   }
  // }, [businessUserInfo]);

  useEffect(() => {
    if (!values.bill_details[0].product_id) return;

    // calculate total amount before discount and tax
    const subtotal = values?.bill_details.reduce(
      (previousValue, currentValue) => previousValue + currentValue.amt,
      0
    );

    let taxableAmount = values?.bill_details
      .filter((item) => item.tax > 0)
      .reduce((totalTax, item) => {
        const amountAfterDiscount = item?.amt - item?.discount;
        return totalTax + amountAfterDiscount;
      }, 0);

    let nonTaxableAmount = values?.bill_details
      .filter((item) => item.tax === 0)
      .reduce((total, item) => total + item.sub_amt, 0);

    const discountPercentage = values.discount / values.sub_total;

    if (sumofAllDiscount() <= 0) {
      taxableAmount = taxableAmount - discountPercentage * taxableAmount;
      nonTaxableAmount =
        nonTaxableAmount - discountPercentage * nonTaxableAmount;
    }

    const taxAmount = (taxableAmount * 13) / 100;

    const totalAmount = taxableAmount + nonTaxableAmount + taxAmount;

    setFieldValue("non_taxable", fixedValue(nonTaxableAmount));
    setFieldValue("taxable", fixedValue(taxableAmount));

    setFieldValue("tax", fixedValue(taxAmount));

    setFieldValue("sub_total", fixedValue(subtotal));
    setFieldValue("total", fixedValue(totalAmount));
    if (isCashTransaction) {
      setFieldValue("cash", fixedValue(totalAmount));
    } else {
      setFieldValue("credit", fixedValue(totalAmount));

    }
  }, [
    // addTax,
    setFieldValue,
    values?.bill_details,
    values.discount,
    values?.sub_total,
  ]);

  const sumofAllDiscount = () => {
    const cloned = [...values.bill_details];
    const allDiscount = cloned.reduce((accumulator, object) => {
      return accumulator + object.discount;
    }, 0);
    return allDiscount;
  };

  const handleDiscountChange = (value: any) => {
    const allDiscount = sumofAllDiscount();
    if (Number(value) < allDiscount) {
      setFieldValue("discount", allDiscount);
      return;
    }
    setFieldValue("discount", replaceDecimal(value === "" ? 0 : value));
  };
  return (
    <>
      <div className="batch-box-parent">
        {activeBatch != null && activeProductBatches[activeBatch] &&
          activeProductBatches[activeBatch].batch_info.map((val, index) => {
            return (
              <div
                key={val.batch_id}
                className="d-flex batch-box"
                onClick={() => {
                  HandleSelectBatch(activeBatch, val);
                }}
              >
                <div>
                  <div
                    className={`check-box ${selectedBatches[activeBatch]
                      ? val.batch_id ===
                        selectedBatches[activeBatch].batch_id
                        ? "active-box"
                        : "bg-none"
                      : "bg-none"
                      }`}
                  >
                    {" "}
                  </div>
                </div>
                <div>
                  <p>
                    Batch no:<span className="value">{val.batch_name}</span>
                  </p>
                  <p>
                    Price:
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="price-tooltip">
                          Primary Price: Rs.{val.product_price ? val.product_price[0].primary_price : 0}{" "}
                          ● Secondary Price: Rs.
                          {val.product_price ? val.product_price[0].secondary_price : 0}
                        </Tooltip>
                      }
                    >
                      <span className="value">
                        Rs.{" "}
                        {val.product_price
                          ? val.product_price[0].primary_price
                          : 0}
                      </span>
                    </OverlayTrigger>
                  </p>
                  <p>
                    In stock:
                    <span className="value">
                      {
                        //@ts-ignore
                        val.remaining_stock ? val.remaining_stock : 0
                      }
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="purchase-page">
        <div className="table-body">
          <table>
            <thead className="table-header">
              <tr>
                <th className="table-content small-content product-width side-border symbol-wrapper">
                  <div className="d-flex">
                    <div className="symbol-number">S.N</div>
                    <div className="side-border"></div>
                    <div className="table-head prodcut-name">Product Name</div>
                  </div>
                </th>

                <th className="table-content small-content equal-width field-wrapper side-border quntity_th">
                  <p className="small-content d-center main-field">Quantity</p>
                  <div className="d-center">
                    <p className="table-content small-content pri-field d-center">
                      PRI
                    </p>
                    <p className="table-content small-content sec-field d-center">
                      SEC
                    </p>
                  </div>
                </th>

                <th className="table-content small-content equal-width field-wrapper side-border">
                  <p className="small-content d-center main-field">Rate</p>
                  <div className="d-center">
                    <p className="table-content small-content pri-field d-center">
                      PRI
                    </p>
                    <p className="table-content small-content sec-field d-center">
                      SEC
                    </p>
                  </div>
                </th>
                <th className="table-content small-content  no-space amount_th">
                  Amount
                </th>
                <th className="table-content small-content discount_th">
                  Discount
                </th>
                <th className="table-content small-content  vat-field">Tax</th>
                <th className="table-content small-content  no-space amount_th">
                  Net Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <PopupContextProvider>
                <ProductFormikPage
                  roleID={roleID}
                  HandleSelectBatch={HandleSelectBatch}
                  scannedData={scannedData}
                  setScannedData={setScannedData}
                />
                {/* <ProductPageComponent roleID={roleID} /> */}
              </PopupContextProvider>
            </tbody>
          </table>
        </div>
        <div className="d-space-between notes-section">
          <div className="note-wrapper">
            <div className="text-area-section">
              <label className="input-label">Narration</label>
              <div>
                <textarea
                  className={`${errors.description && touched.description
                    ? "input-field error-input"
                    : "input-field text-area-field"
                    }`}
                  rows={3}
                  placeholder="Enter here"
                  {...getFieldProps("description")}
                />
                <ErrorMessage
                  name={"description"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
            {values.transaction_type === ReportType.Sales && (
              <div className="text-area-section">
                <label className="input-label">Bill Terms</label>
                <div>
                  <textarea
                    className={`${errors.bill_terms && touched.bill_terms
                      ? "input-field error-input"
                      : "input-field text-area-field"
                      }`}
                    rows={3}
                    placeholder="Enter here"
                    {...getFieldProps("bill_terms")}
                  />
                  <ErrorMessage
                    name={"bill_terms"}
                    component="div"
                    className="error"
                  />
                  {values.transaction_type === ReportType.Sales && (
                    <div className="note-text">
                      *Will not be displayed on the Invoice
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="text-area-section">
              <label className="input-label">Notes</label>
              <div>
                <textarea
                  className={`${errors.customer_notes && touched.customer_notes
                    ? "input-field error-input"
                    : "input-field text-area-field"
                    }`}
                  rows={3}
                  placeholder="Enter here"
                  {...getFieldProps("customer_notes")}
                />
                <ErrorMessage
                  name={"customer_notes"}
                  component="div"
                  className="error"
                />
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="total-card">
              <table className="total-table">
                <thead className="table-header">
                  <tr>
                    <th className="content">Sub Total</th>
                    <th className="content">Discount</th>
                    <th className="content">Non-Taxable Amount</th>
                    <th className="content">Taxable Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="content">
                      <Field
                        className={`${errors.sub_total && touched.sub_total
                          ? "input-field error-input"
                          : "input-field"
                          }`}
                        type={"number"}
                        value={values.sub_total}
                        name="sub_total"
                        onKeyPress={(event: any) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        disabled
                      />
                      <ErrorMessage
                        name={"sub_total"}
                        component="div"
                        className="error"
                      />
                    </td>
                    <td className="content">
                      <Field
                        className={`${errors.discount && touched.discount
                          ? "input-field error-input"
                          : "input-field"
                          }`}
                        placeholder="Enter discount amount"
                        pattern="[0-9]*"
                        onKeyPress={(e: any) => {
                          if (/[A-Za-z]/.test(e.key)) {
                            e.preventDefault();
                          }
                          if (parseInt(e.target.value) === 0) {
                            e.target.value = "";
                          }
                        }}
                        disabled={sumofAllDiscount() > 0}
                        value={values.discount}
                        onChange={(e: any) => {
                          handleDiscountChange(e.target.value);
                        }}
                        onFocus={(e: any) => {
                          if (parseInt(e.target.value) === 0) {
                            e.target.value = "";
                          }
                        }}
                      />
                      <ErrorMessage
                        name={"discount"}
                        component="div"
                        className="error"
                      />
                    </td>

                    <td className="content">
                      <input
                        placeholder="Non Taxable Amount"
                        className="input-field"
                        disabled
                        type={"number"}
                        value={values.non_taxable}
                      />
                    </td>

                    <td className="content">
                      <input
                        className="input-field"
                        disabled
                        type={"number"}
                        value={fixedValue(values.taxable)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-space-between line-segment">
                <div>
                  <div className="check-box-area d-flex">
                    <label className="content">Tax (13% VAT)</label>
                  </div>
                </div>
                <div className="input-space">
                  <Field
                    className={`${errors.tax && touched.tax
                      ? "input-field error-input"
                      : "input-field"
                      }`}
                    disabled
                    type={"number"}
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter tax amount"
                    name="tax"
                    value={values.tax}
                  />
                  <ErrorMessage
                    name={"tax"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="d-space-between line-segment total-bottom">
                <div className="content">Total</div>
                <div className="input-space">
                  <Field
                    className={`${errors.total && touched.total
                      ? "input-field error-input"
                      : "input-field"
                      }`}
                    type={"number"}
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.total}
                    name="total"
                    disabled
                  />
                  <ErrorMessage
                    name={"total"}
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <BankDetail />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
