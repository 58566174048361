import { Form, Formik, FormikConfig, useFormikContext } from "formik";
import NepaliDate from "nepali-date-converter";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { deepClone, HandleError } from "../../utils/services";
import { CreateManualVoucher } from "../Api/Post/PostApi";
import { DocumentContext } from "../BillPage/Document-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { PopupContext } from "../PopUp/Popup-Context";
import { PopUp } from "../PopUp/PopUp";
import { ManualInvoice } from "../Receipt/ManualInvoicePage";

export interface ManualVoucherValue {
  ledger_id: string | any;
  description: string;
  debit: number | null;
  credit: number | null;
  closing_balance: number;
}
export interface ManualVoucherInitial {
  ledger_info: ManualVoucherValue[];
  date: string;
  main_description: string;
  customer_id: number | null;
  credit_total?: null | number;
  debit_total?: null | number;
  bill_number?: string;
  link_report_type?: null | string;
  customer_notes: string
}
export const initialValues: ManualVoucherInitial = {
  ledger_info: [
    { ledger_id: "", description: "", debit: 0, credit: 0, closing_balance: 0 },
  ],
  date: new NepaliDate().format("YYYY-MM-DD"),
  main_description: "",
  customer_id: null,
  credit_total: null,
  debit_total: null,
  bill_number: "",
  link_report_type: null,
  customer_notes: ""
};

const validationSchema = Yup.object().shape({
  date: Yup.string().required("Please Select Date"),
  main_description: Yup.string().required("Please add naration"),
  ledger_info: Yup.array().of(
    Yup.object().shape({
      ledger_id: Yup.string().required("Please select a ledger"),
      debit: Yup.string()
        .matches(/^\d+(.\d{1,3})?$/, "Invalid input")
        .test("debit-or-credit", "Invalid Debit Amount", function (value) {
          const { credit } = this.parent;
          return Number(value) > 0 || credit > 0;
        }),
      credit: Yup.string()
        .matches(/^\d+(.\d{1,3})?$/, "Invalid input")
        .test("debit-or-credit", "Invalid Credit Amount", function (value) {
          const { debit } = this.parent;
          return Number(value) > 0 || debit > 0;
        }),
    })
  ),
  customer_id: Yup.number().when(
    ["link_report_type", "ledger_label", "ledger_exist"],
    {
      is: (val: string, ledgerLabel: string, ledgerExist: boolean) =>
        val ||
        ledgerLabel === "Account Payable (Current Liabilities)" ||
        ledgerLabel === "Account Receivable (Current Assets)" ||
        ledgerExist === true,
      then: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .required("Please Select Customer"),
      otherwise: Yup.number().nullable(),
    }
  ),

  bill_number: Yup.string().when("link_report_type", {
    is: (val: string) => val && val === "PUR",
    then: Yup.string().required("Please Enter Bill Number"),
    otherwise: Yup.string(),
  }),
});

export const useManualVoucherWrapper = () => {
  const formik = useFormikContext<ManualVoucherInitial>();
  if (!formik) {
    throw new Error("ManualVoucherWrapper must be used within a Formik");
  }
  return formik;
};
interface ManualVoucherWrapperProps {
  children: React.ReactNode;
}

export const ManualVoucherWrapper: React.FC<ManualVoucherWrapperProps> = ({
  children,
}) => {
  const { showErrorMessage, showSuccessMessage } = useToast();
  const { setIsSubmitted, isSubmitted } = useContext(DocumentContext);
  const { setIsModalOpen, isModalOpen } = useContext(PopupContext);
  const [printTitle, setPrintTitle] = useState(["Manual Voucher"]);
  const [documentNumber, setDocumentNumber] = useState<string>();
  const [showPopUP, setShowPopUp] = useState(false);

  const handleSubmit: FormikConfig<ManualVoucherInitial>["onSubmit"] = (
    values,
    { setSubmitting, resetForm }
  ) => {
    if (values.credit_total !== values.debit_total) {
      setSubmitting(false);
      return showErrorMessage("Credit and Debit total must be equal");
    }

    if (values.link_report_type === "SAL") {
      setPrintTitle(["Tax Invoice", "Invoice"]);
      setShowPopUp(true);
    } else {
      setPrintTitle(["Manual Voucher"]);
      setShowPopUp(false);
    }

    setSubmitting(true);
    const data = deepClone(values);
    delete data.credit_total;
    delete data.debit_total;

    CreateManualVoucher(data)
      .then((res) => {
        showSuccessMessage("Manual Voucher Created Successfully");
        setIsSubmitted(!isSubmitted);
        setDocumentNumber(res.data.data.document_no);
        setIsModalOpen(true);
        resetForm();
      })
      .catch((err: any) => {
        const ErrorMessage = HandleError(err);
        showErrorMessage(ErrorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <>
          {children}
          {isModalOpen && showPopUP && (
            <PopUp
              closeOnClickOutside={false}
              showCrossIcon={true}
              popupType="full"
              popupWidth={"100%"}
              showHeader={true}
              popupHeight={"100%"}
              renderComponent={
                <ManualInvoice
                  documentNumber={documentNumber || ""}
                  titles={printTitle}
                />
              }
            />
          )}
        </>
      </Form>
    </Formik>
  );
};
