const nepaliWords : { [key: number]: string } = {
    0: 'सुन्ना',
    1: 'एक',
    2: 'दुई',
    3: 'तीन',
    4: 'चार',
    5: 'पाँच',
    6: 'छ',
    7: 'सात',
    8: 'आठ',
    9: 'नौ',
    10: 'दस',
    11: 'एघार',
    12: 'बाह्र',
    13: 'तेह्र',
    14: 'चौध',
    15: 'पन्ध्र',
    16: 'सोह्र',
    17: 'सत्र',
    18: 'अठार',
    19: 'उन्नाइस',
    20: 'बीस',
    21: 'एक्काइस',
    22: 'बाइस',
    23: 'तेइस',
    24: 'चौबिस',
    25: 'पच्चिस',
    26: 'छब्बिस',
    27: 'सत्ताइस',
    28: 'अट्ठाइस',
    29: 'उनन्तिस',
    30: 'तिस',
    31: 'एकतिस',
    32: 'बत्तिस',
    33: 'तेत्तिस',
    34: 'चौतिस',
    35: 'पैँतिस',
    36: 'छत्तिस',
    37: 'सैँतिस',
    38: 'अठतिस',
    39: 'उनन्चालिस',
    40: 'चालिस',
    41: 'एकचालिस',
    42: 'बयालिस',
    43: 'त्रिचालिस',
    44: 'चवालिस',
    45: 'पैँतालिस',
    46: 'छयालिस',
    47: 'सतचालिस',
    48: 'अठचालिस',
    49: 'उनन्नब्बे',
    50: 'पचास',
    51: 'एकाउन्न',
    52: 'बाउन्न',
    53: 'त्रिपन्न',
    54: 'चवन्न',
    55: 'पचपन्न',
    56: 'छपन्न',
    57: 'सन्ताउन्न',
    58: 'अन्ठाउन्न',
    59: 'उनसट्ठी',
    60: 'साठी',
    61: 'एकसट्ठी',
    62: 'बयासट्ठी',
    63: 'त्रिसट्ठी',
    64: 'चौसट्ठी',
    65: 'पैँसट्ठी',
    66: 'छयासट्ठी',
    67: 'सन्तासट्ठी',
    68: 'अन्ठासट्ठी',
    69: 'उनन्सत्तरी',
    70: 'सत्तरी',
    71: 'एकहत्तर',
    72: 'बहत्तर',
    73: 'त्रिहत्तर',
    74: 'चौहत्तर',
    75: 'पचहत्तर',
    76: 'छयहत्तर',
    77: 'सतहत्तर',
    78: 'अठहत्तर',
    79: 'उनासी',
    80: 'असी',
    81: 'एकासी',
    82: 'बयासी',
    83: 'त्रियासी',
    84: 'चौरासी',
    85: 'पचासी',
    86: 'छयासी',
    87: 'सतासी',
    88: 'अठासी',
    89: 'उनान्नब्बे',
    90: 'नब्बे',
    91: 'एकान्नब्बे',
    92: 'बयान्नब्बे',
    93: 'त्रियान्नब्बे',
    94: 'चौरान्नब्बे',
    95: 'पन्चान्नब्बे',
    96: 'छयान्नब्बे',
    97: 'सन्तान्नब्बे',
    98: 'अन्ठान्नब्बे',
    99: 'उनान्सय',
    100: 'सय',
    1000: 'हजार',
    10000: 'दस हजार',
    100000: 'लाख',
    1000000: 'दस लाख',
    10000000: 'करोड',
    100000000: 'दस करोड'
  };
  
export const convertToNepaliWords = (number: number): string => {
    if (number < 0 || number > 100000000) {
        return "Invalid Range";
    }

    let result = "";

    const crore = Math.floor(number / 10000000);
    number %= 10000000;
    const lakh = Math.floor(number / 100000);
    number %= 100000;
    const thousand = Math.floor(number / 1000);
    number %= 1000;
    const hundred = Math.floor(number / 100);
    number %= 100;
    const paisa = Math.floor(number);
    const paise = Math.round((number - paisa) * 100);
    
    if (crore > 0) {
        result += nepaliWords[crore] + " करोड ";
    }
    if (lakh > 0) {
        result += nepaliWords[lakh] + " लाख ";
    }
    if (thousand > 0) {
        result += nepaliWords[thousand] + " हजार ";
    }
    if (hundred > 0) {
        result += nepaliWords[hundred] + " सय ";
    }
    if (paisa > 0) {
        result += nepaliWords[paisa] + " रुपैयाँ ";
    }
    if (paise > 0) {
        result += "," + nepaliWords[paise] + " पैसा";
    }

    return result.trim();
};