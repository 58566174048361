import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  fomratColumns,
  handleNoData,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
  UpdatedFormatOptions,
} from "../../utils/services";
import {
  useAllLedgerList,
  useGetOpeningBalanceDetail,
  useJournalEntry,
  useLedgerList,
} from "../Api/Get/GetApi";
import {
  IOpeningStockDetail,
  LedgerHeadResponse,
  UpdatedOpeningBalanceDetail,
} from "../Api/Get/GetApiTypes";
import { LedgerTableReponse, LedgerWiseResponse } from "../Api/types";
import { Table } from "../CustomReactTable/CustomReactTable";
import { JournalDetail } from "../JournalDetail/JournalDetail";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { FilterDate } from "../SalesList/SalesListPage";
import { SelectCustomerBill } from "../SelectCustomerBill/SelectCustomerBill";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import { HasDetailResponse } from "../../EnumValues";
import NepaliDatePicker from "../NepaliDatePicker/NepaliDatePicker";
export type chipState = "Receipt" | "Ledger" | "Transaction" | "";

export const LedgerReport = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });

  const [openingQueryPageIndex, setOpeningQueryPageIndex] = useState(0);
  const [openingQueryPageSize, setOpeningQueryPageSize] = useState(10);
  const [hasDetail, setHasDetail] = useState<HasDetailResponse>();

  const [requireId, setRequireId] = useState<{
    journalId: number | undefined;
    detailId: number | undefined;
  }>({
    journalId: undefined,
    detailId: undefined,
  });

  const [selectedCustomer, setSelectedCustomer] = useState<
    SelectOptions | undefined
  >(undefined);
  const { data } = useAllLedgerList();
  const {
    data: ledgerList,
    isLoading: ledgerLoading,
    refetch,
  } = useLedgerList(date, selectedCustomer?.value);
  const { data: openingBalanceDetail, isLoading: openingLoading } =
    useGetOpeningBalanceDetail(
      requireId.detailId,
      openingQueryPageIndex,
      openingQueryPageSize
    );
  const { data: journalData } = useJournalEntry(requireId.journalId);

  const { openModal } = useContext(PopupContext);

  const [popupTypes, setPopupType] = useState({
    journal: false,
    detail: false,
  });

  const urlParams = new URLSearchParams(window.location.search);
    const ledgerId = urlParams.get('ledger-id');
    const queryDateTo = urlParams.get('dateTo');
    const queryDateFrom = urlParams.get('dateFrom');

  useEffect(() => {
    if(queryDateFrom != null && queryDateFrom !== null && ledgerId !== null&& data )
    {
      const findLedger=UpdatedFormatOptions<LedgerHeadResponse>(
        (data?.data as LedgerHeadResponse[]) || [],
        "id",
        "ledger_name"
      ).find((ledger)=>ledger.value===Number(ledgerId))
      setSelectedCustomer(findLedger as SelectOptions) 
      setDate((p)=>({...p,
        dateTo:queryDateTo as string,
        dateFrom:queryDateFrom as string
        }))      
    }
  }, [data]);

  const column = [
    "SN",
    "Date",
    "Document Type",
    "Document Number",
    "Debit",
    "Credit",
    "Balance",
  ];

  const FormatTable = (array: LedgerWiseResponse[]) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          sn: index + 1,
          date: item.nepali_date,
          document_type: item.document_type,
          document_number:
            item.document_type === "PUR" ||
            item.document_type === "DN_PR" ||
            item.document_type === "PMT"
              ? item.bill_number
              : item.document_number,
          debit: parenthesisInNegativeValue(item.debit),
          credit: parenthesisInNegativeValue(item.credit),
          balance:
          parenthesisInNegativeValue(item.balance),
          id: item.id,
          has_detail: item.has_detail,
        };
      });
      return formatData;
    }
    return [];
  };

  //COlumns to enable the rowspan
  const openingStockDetailColumns = [
    {
      Header: "SN",
      accessor: "sn",
      enableRowSpan: true,
    },
    {
      Header: "Date",
      accessor: "date",
      enableRowSpan: true,
    },
    {
      Header: "Document Number",
      accessor: "invoice_no",
      enableRowSpan: true,
    },

    {
      Header: "Document Type",
      accessor: "document_type",
      enableRowSpan: true,
    },

    {
      Header: "Product Name",
      accessor: "product_name",
    },
    {
      Header: "Product Category",
      accessor: "product_category",
    },
    {
      Header: "Primary Unit",
      accessor: "primary_unit",
    },
    {
      Header: "Secondary Unit",
      accessor: "secondary_unit",
    },
    {
      Header: "Relation",
      accessor: "relation",
    },

    {
      Header: "Primary Price",
      accessor: "primary_price",
    },
    {
      Header: "Secondary Price",
      accessor: "secondary_price",
    },
    {
      Header: "Remaining Stock(In Secondary Unit)",
      accessor: "remaining_stock",
    },
  ];

  const openingBalanceDetailColumns = [
    "SN",
    "Date",
    "Document Number",
    "Document Type",
    "Name",
    "Pan",
    "Mobile",
    "Debit",
    "Credit",
    "Balance",
  ];

  const OpeningStockDetailFormatTable = (
    array: IOpeningStockDetail[] | UpdatedOpeningBalanceDetail[],
    hasDetailType: HasDetailResponse,
    pageIndex: number,
    pageSize: number
  ) => {
    if (!array || array.length <= 0) return [];
    let formatData: any[] = [];
    switch (hasDetailType) {
      case HasDetailResponse.OPENING_STOCK_DETAIL:
        (array as IOpeningStockDetail[]).forEach((val, index) => {
          val.product_stock_info.map((item, idx) => {
            let temp = {
              sn: tableIndexNumber(pageIndex, pageSize, index),
              date: val.miti,
              invoice_no: val.document_number,
              document_type: val.document_type,
              product_name: item.product_name,
              product_category: handleNoData(item.product_category),
              primary_unit: item.primary_unit,
              secondary_unit: item.secondary_unit,
              primary_price: item.primary_price,
              secondary_price: item.secondary_price,
              remaining_stock: item.qty,
              relation: item.relation,
            };
            formatData.push(temp);
          });
        });
        break;
      case HasDetailResponse.OPENING_DETAIL:
        formatData = (array as UpdatedOpeningBalanceDetail[]).map(
          (val, index) => {
            return {
              sn: tableIndexNumber(pageIndex, pageSize, index),
              date: val.miti,
              document_number: val.document_number,
              document_type: val.document_type,
              debit: val.debit,
              credit: val.credit,
              balance: val.balance,
              name: val.customer_info.name,
              pan: val.customer_info.pan,
              mobile: val.customer_info.mobile_no,
            };
          }
        );
        break;
      default:
        formatData = [];
        break;
    }
    return formatData;
  };

  const handleDoubleClick = (row: LedgerTableReponse) => {
    if (row.has_detail === "" || !row.has_detail) return;
    switch (row.has_detail) {
      case HasDetailResponse.JOURNAL:
        setRequireId((p) => ({ ...p, journalId: row.id, detailId: undefined }));
        setPopupType((p) => ({ ...p, journal: true, detail: false }));
        setHasDetail(HasDetailResponse.JOURNAL);
        break;
      case HasDetailResponse.OPENING_STOCK_DETAIL:
        setRequireId((p) => ({
          ...p,
          journalId: undefined,
          detailId: selectedCustomer?.value,
        }));
        setPopupType((p) => ({ ...p, journal: false, detail: true }));
        setHasDetail(HasDetailResponse.OPENING_STOCK_DETAIL);
        break;
      case HasDetailResponse.OPENING_DETAIL:
        setRequireId((p) => ({
          ...p,
          journalId: undefined,
          detailId: selectedCustomer?.value,
        }));
        setPopupType((p) => ({ ...p, journal: false, detail: true }));
        setHasDetail(HasDetailResponse.OPENING_DETAIL);
        break;
      default:
        return;
    }
    openModal();
  };

  useEffect(() => {
    refetch();
  }, [date.dateFrom, date.dateTo]);

  

  return (
    <>
      {popupTypes.journal &&
        requireId.journalId !== undefined &&
        journalData && (
          <PopUp
            showCrossIcon={true}
            popupType="full"
            popupWidth={"100%"}
            showHeader={true}
            popupHeight={"100%"}
            renderComponent={<JournalDetail journalData={journalData} />}
          />
        )}
      {popupTypes.detail &&
        requireId.detailId !== undefined &&
        hasDetail !== undefined && (
          <PopUp
            showCrossIcon={true}
            popupType="full"
            popupWidth={"100%"}
            showHeader={true}
            popupHeight={"100%"}
            title={
              hasDetail === HasDetailResponse.OPENING_DETAIL
                ? "Opening Detail"
                : "Opening Stock Detail"
            }
            renderComponent={
              <div style={{ padding: "10px" }}>
                <Table
                  loading={openingLoading}
                  data={
                    ledgerList
                      ? OpeningStockDetailFormatTable(
                          openingBalanceDetail?.data as
                            | IOpeningStockDetail[]
                            | UpdatedOpeningBalanceDetail[],
                          hasDetail as HasDetailResponse,
                          openingQueryPageIndex,
                          openingQueryPageSize
                        )
                      : []
                  }
                  columns={
                    hasDetail === HasDetailResponse.OPENING_STOCK_DETAIL
                      ? openingStockDetailColumns
                      : fomratColumns(openingBalanceDetailColumns)
                  }
                  totalEntries={
                    openingBalanceDetail &&
                    openingBalanceDetail.pagination &&
                    openingBalanceDetail.pagination.total_count
                  }
                  totalPages={
                    openingBalanceDetail &&
                    totalPages(
                      openingQueryPageSize,
                      openingBalanceDetail.pagination.total_count
                    )
                  }
                  queryPageIndex={openingQueryPageIndex}
                  queryPageSize={openingQueryPageSize}
                  setQueryPageIndex={setOpeningQueryPageIndex}
                  setQueryPageSize={setOpeningQueryPageSize}
                />
              </div>
            }
          />
        )}
      <div className="pay-slip-page">
        <div>
          <div className="head-section">
            <div className="small-title">Ledger Report</div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label className="input-label">Ledger Name</label>
              <div className="search-field-customer auto-complete-search-wrap mg-btn-15">
                <Select
                  isClearable={true}
                  value={selectedCustomer || undefined}
                  //@ts-ignore
                  options={UpdatedFormatOptions<LedgerHeadResponse>(
                    (data?.data as LedgerHeadResponse[]) || [],
                    "id",
                    "ledger_name"
                  )}
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                  }}
                  onChange={(e: any) => {
                    setSelectedCustomer(e);
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="date" className="input-label">
                  From Date
                </label>
                <NepaliDatePicker
                  currentDate={true}
                  defaultDate={queryDateFrom || ""}
                  onDateSelect={(value: any) => {
                    setDate?.((p) => ({
                      ...p,
                      dateFrom: value     
                    }));
                  }}
                />

              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="date" className="input-label">
                  To Date
                </label>
                <NepaliDatePicker
                  currentDate={true}
                  defaultDate={queryDateTo || ""}
                  onDateSelect={(value: any) => {
                    setDate?.((p) => ({ ...p, dateTo: value }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {!selectedCustomer ? (
          <div className="select-party">
            <SelectCustomerBill
              selectParty={true}
              title={{ title1: "ledger name", title2: "ledger wise" }}
            />
          </div>
        ) : (
          <React.Fragment>
            <Table
              loading={ledgerLoading}
              data={ledgerList ? FormatTable(ledgerList.data) : []}
              columns={fomratColumns(column)}
              showFooter={false}
              showDate={false}
              date={date}
              setDate={setDate}
              onDoubleClick={handleDoubleClick}
              showPagination={false}
            />
          </React.Fragment>
        )}
      </div>
    </>
  );
};
