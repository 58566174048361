import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  tableIndexNumber,
  totalPages,
  parenthesisInNegativeValue,
} from "../../utils/services";
import { useChequeList } from "../Api/Get/GetApi";
import { ChequeListResponse } from "../Api/types";
import { Table } from "../CustomReactTable/CustomReactTable";

interface ViewAlProps {
  viewAll: boolean;
}

export const ChequeList: React.FC<ViewAlProps> = ({ viewAll }) => {
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(() => (viewAll ? 10 : 5));

  /// remove date because date is not set anywhere
  //useQuery
  const { data: chequeList, isLoading } = useChequeList(
    queryPageIndex,
    queryPageSize
  );
  const FormatTable = (
    array: ChequeListResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          date: item.miti,
          cheque_no: item.cheque_no,
          name: item.user_info.name ? item.user_info.name : "-",
          mobile_no: item.user_info.mobile_no ? item.user_info.mobile_no : "-",
          amount: parenthesisInNegativeValue(item.amount),
        };
      });
      return formatData;
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: "s_n",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      { Header: "Cheque No", accessor: "cheque_no" },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile No.",
        accessor: "mobile_no",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
    ],
    []
  );

  return (
    <div className="dashboard-table card-height">
      {!viewAll ? (
        <>
          <Table
            loading={isLoading}
            columns={columns}
            data={
              chequeList
                ? FormatTable(chequeList.data, queryPageIndex, queryPageSize)
                : []
            }
            totalPages={1}
            totalEntries={chequeList && chequeList.pagination.total_count}
            queryPageIndex={queryPageIndex}
            setQueryPageIndex={setQueryPageIndex}
            queryPageSize={queryPageSize}
            setQueryPageSize={setQueryPageSize}
            showPagination={false}
          />

          <Link to={`/cheque-list`} className="view-btn">
            <button
              type="button"
              className="content light-secondary next small-button "
            >
              View All
            </button>
          </Link>
        </>
      ) : (
        <>
          <div className="head-section">
            <p className="small-title">Cheque List</p>
          </div>
          <Table
            loading={isLoading}
            columns={columns}
            data={
              chequeList
                ? FormatTable(chequeList.data, queryPageIndex, queryPageSize)
                : []
            }
            totalPages={
              chequeList &&
              totalPages(queryPageSize, chequeList.pagination.total_count)
            }
            totalEntries={chequeList && chequeList.pagination.total_count}
            queryPageIndex={queryPageIndex}
            setQueryPageIndex={setQueryPageIndex}
            queryPageSize={queryPageSize}
            setQueryPageSize={setQueryPageSize}
            showPageSize={false}
            smallPagination={true}
          />
        </>
      )}
    </div>
  );
};
