import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import { checkNumber, formatSelectOptions } from "../../utils/services";
import { GetBankName, GetSalesBankName } from "../Api/Get/GetApi";
import { NewBillFormValues } from "../BillPage/BillFormikWrapper";
import { ReciptValue } from "../ReciptSlip/ReciptFormikWrapper";
import "./AddableBank.scss";
import { ReportType } from "../../EnumValues";

export interface BankDataProps {
  cheque_no: string;
  amount: string | number;
  cheque_issue_date: string;
  bank_id: number | string;
}

export interface bankDetailProps {
  id: number;
  name: string;
}

export interface AddableBankProps {
  name?: string;
  buttonName?: string;
  handleAddDetail?: (e: any, index: number) => void;
  handleToaster?: (e: any) => void;
  bankAmount?: NewBillFormValues | ReciptValue;
  salesBankId?: (e: any, index: number, name: string) => void;
  creatableSelect?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  bankVal?: BankDataProps[];
  setBankVal?: React.Dispatch<React.SetStateAction<BankDataProps[]>>;
  values?: NewBillFormValues | ReciptValue;
  LedgerType?: string;
}

export const AddableBank: React.FC<AddableBankProps> = ({
  name,
  creatableSelect,
  values,
  setFieldValue,
  LedgerType = "payslipLedger",
}) => {
  const [bankData, setBankData] = useState<Array<SelectOptions>>();
  const [salesBank, setSalesBank] = useState<any>([{ bank_name: "" }]);

  const [selectedBank, setSelectedBank] = useState<SelectOptions[] | null[]>([
    null,
  ]);

  useEffect(() => {
    GetBankName().then((res) => {
      const updatedData = formatSelectOptions<SelectOptions>(res.data.data);
      setBankData(updatedData);
    });
  }, []);

  const apiCall =
    LedgerType === "payslipLedger" ? GetSalesBankName : GetBankName;

  useEffect(() => {
    apiCall().then((res) => {
      setSalesBank(res.data.data);
    });
  }, []);

  const options = salesBank.map((itm: any) => {
    return {
      value: itm?.bank_name,
      label: itm?.bank_name,
    };
  });

  const UpdateValues = (
    index: number,
    value: any,
    key: keyof BankDataProps
  ) => {
    const cloned = [...(values?.bank as BankDataProps[])];
    cloned[index][key] = value;
    setFieldValue("bank", cloned);
  };

  return (
    <div className="addable-bank-page">
      <div>
        <div className="bank-detail-body-wrapper">
          <div className="d-space-between pay-title">
            <div className="content optional-content">{name}</div>
          </div>

          {values?.bank.map((bank, index) => {
            return (
              <div className="bank-detail-body" key={index}>
                {values.bank.length > 1 &&
                  (index === 0 ? (
                    ""
                  ) : (
                    <div className="remove-block">
                      <button className="remove-btn">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className="remove"
                            d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="#B11D1D"
                          />
                          <path
                            className="remove"
                            d="M5.80715 5.8074C5.86521 5.74919 5.93418 5.70301 6.01011 5.67151C6.08604 5.64 6.16744 5.62378 6.24965 5.62378C6.33186 5.62378 6.41326 5.64 6.48919 5.67151C6.56513 5.70301 6.6341 5.74919 6.69215 5.8074L9.99965 9.11615L13.3072 5.8074C13.3653 5.74929 13.4342 5.70319 13.5102 5.67174C13.5861 5.64029 13.6675 5.62411 13.7497 5.62411C13.8318 5.62411 13.9132 5.64029 13.9891 5.67174C14.0651 5.70319 14.134 5.74929 14.1922 5.8074C14.2503 5.86551 14.2964 5.93449 14.3278 6.01042C14.3593 6.08634 14.3754 6.16772 14.3754 6.2499C14.3754 6.33208 14.3593 6.41345 14.3278 6.48938C14.2964 6.5653 14.2503 6.63429 14.1922 6.6924L10.8834 9.9999L14.1922 13.3074C14.2503 13.3655 14.2964 13.4345 14.3278 13.5104C14.3593 13.5863 14.3754 13.6677 14.3754 13.7499C14.3754 13.8321 14.3593 13.9135 14.3278 13.9894C14.2964 14.0653 14.2503 14.1343 14.1922 14.1924C14.134 14.2505 14.0651 14.2966 13.9891 14.3281C13.9132 14.3595 13.8318 14.3757 13.7497 14.3757C13.6675 14.3757 13.5861 14.3595 13.5102 14.3281C13.4342 14.2966 13.3653 14.2505 13.3072 14.1924L9.99965 10.8836L6.69215 14.1924C6.63404 14.2505 6.56506 14.2966 6.48913 14.3281C6.41321 14.3595 6.33183 14.3757 6.24965 14.3757C6.16747 14.3757 6.0861 14.3595 6.01017 14.3281C5.93425 14.2966 5.86526 14.2505 5.80715 14.1924C5.74904 14.1343 5.70295 14.0653 5.6715 13.9894C5.64005 13.9135 5.62386 13.8321 5.62386 13.7499C5.62386 13.6677 5.64005 13.5863 5.6715 13.5104C5.70295 13.4345 5.74904 13.3655 5.80715 13.3074L9.1159 9.9999L5.80715 6.6924C5.74895 6.63434 5.70277 6.56537 5.67126 6.48944C5.63975 6.41351 5.62354 6.33211 5.62354 6.2499C5.62354 6.16769 5.63975 6.08629 5.67126 6.01035C5.70277 5.93442 5.74895 5.86545 5.80715 5.8074Z"
                            fill="#B11D1D"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Bank Name</label>
                      {creatableSelect ||
                        ((values as NewBillFormValues).transaction_type &&
                          (values as NewBillFormValues).transaction_type == ReportType.Sales) ? (
                        <>
                          <Creatable
                            isClearable={true}
                            value={selectedBank[index]}
                            options={options}
                            name="bank_id"
                            styles={{
                              control: (base) => ({
                                ...base,
                                fontSize: "12px",
                              }),
                              menu: (base) => ({
                                ...base,
                                fontSize: "12px",
                              }),
                            }}
                            onChange={(e: any) => {
                              const newArr = [...selectedBank];
                              newArr[index] = e;
                              const notNull = e !== null;
                              setSelectedBank(newArr as SelectOptions[]);
                              if (typeof e?.value === "string") {
                                UpdateValues(
                                  index,
                                  notNull ? e.label : "",
                                  "bank_id"
                                );
                              } else {
                                UpdateValues(
                                  index,
                                  notNull ? e.value : "",
                                  "bank_id"
                                );
                              }
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <ReactSelect
                            isClearable={true}
                            value={selectedBank[index]}
                            styles={{
                              control: (base) => ({
                                ...base,
                                fontSize: "12px",
                              }),
                              menu: (base) => ({
                                ...base,
                                fontSize: "12px",
                              }),
                            }}
                            options={bankData}
                            name="bank_id"
                            onChange={(e: any) => {
                              const newArr = [...selectedBank];
                              newArr[index] = e;
                              setSelectedBank(newArr as SelectOptions[]);
                              UpdateValues(
                                index,
                                e == null ? 0 : e.value,
                                "bank_id"
                              );
                            }}
                          />
                        </>
                      )}
                      <ErrorMessage
                        component="div"
                        name={`bank.${index}.bank_id`}
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Cheque No.</label>
                      <input
                        className="input-field"
                        placeholder="Enter cheque no."
                        onChange={(e) => {
                          UpdateValues(index, e.target.value, "cheque_no");
                        }}
                        name="cheque_no"
                        value={values.bank[index].cheque_no}
                      />
                      <ErrorMessage
                        name={`bank.${index}.cheque_no`}
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Cheque Issue Date</label>
                      <div>
                        <NepaliDatePicker
                          showResetDateButton={true}
                          className="input-field"
                          defaultDate={""}
                          placeholder="Select Date"
                          onDateSelect={(value: any) => {
                            UpdateValues(
                              index,
                              value ? value : "",
                              "cheque_issue_date"
                            );
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name={`bank.${index}.cheque_issue_date`}
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Amount</label>
                      <Field
                        className="input-field"
                        pattern="[0-9]*"
                        type="number"
                        onWheel={(e: any) => {
                          (e.target as HTMLInputElement).blur();
                        }}
                        onFocus={(event: any) => {
                          if (parseInt(event.target.value) === 0) {
                            event.target.value = "";
                          }
                        }}
                        placeholder="Enter amount"
                        onChange={(e: any) => {
                          let value = checkNumber(e.target.value, "decimalToo");
                          UpdateValues(index, value, "amount");
                        }}
                        name="amount"
                        value={values?.bank[index]?.amount}
                      />
                      <ErrorMessage
                        name={`bank.${index}.amount`}
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
