import { Form, Formik, FormikConfig, useFormikContext } from "formik"
import { Fragment, useContext, useEffect } from "react"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { HandleError } from "../../utils/services"
import { useStaffList } from "../Api/Get/GetApi"
import { CreateUser } from "../Api/Post/PostApi"
import { UpdateUser } from "../Api/Put/PutApi"
import { NewUserValues, StaffDetailResponse } from "../Api/types"
import { useToast } from "../context/ToasterContext/ToasterContext"
import { PopupContext } from "../PopUp/Popup-Context"
import { UpdatedStaff } from "../Api/Put/PutApitypes"

export const initialValues: NewUserValues | UpdatedStaff = {
  pan: "",
  mobile_no: "",
  name: "",
  additional_info: {
    address: "",
  },
  role_id: "",
  password: "",
  confirm_password: "",
  photo: null,
  user_role_id: undefined,
}
const validationSchema = Yup.object().shape({
  pan: Yup.string().length(9, "Must be of 9 digit").nullable(),
  mobile_no: Yup.string()
    .length(10, "Must be 10 digit")
    .required("Please enter mobile no"),
  name: Yup.string().required("Please enter your name"),
  email: Yup.string().email("Must be a valid email"),
  additional_info: Yup.object().shape({
    address: Yup.string(),
  }),
  role_id: Yup.string().required("Please select the role"),
  password: Yup.string().required("Please enter the password"),
  confirm_password: Yup.string()
    .required("Please Enter Password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})

const editValidationSchema = Yup.object().shape({
  pan: Yup.string().length(9, "Must be of 9 digit"),
  mobile_no: Yup.string()
    .length(10, "Must be 10 digit")
    .required("Please enter the mobile no"),
  name: Yup.string().required("Please enter your name"),
  email: Yup.string().email("Must be a valid email"),
  additional_info: Yup.object().shape({
    address: Yup.string(),
  }),
  role_id: Yup.string().required("Please select the role"),
})
export const useAddUserWrapper = () => {
  const formik = useFormikContext<NewUserValues>()
  if (!formik) {
    throw new Error("useNewUserWrapper must be used within a Formik")
  }
  return formik
}

export enum SubmitType {
  Edit = 1,
  Add = 2,
}

interface AddCustomeWrapperProps {
  children: React.ReactNode
  submitType?: SubmitType
  userId?: number
}

export const AddUserWrapper: React.FC<AddCustomeWrapperProps> = ({
  children,
  submitType = SubmitType.Add,
  userId,
}) => {
  const { closeModal } = useContext(PopupContext)
  const { showErrorMessage, showSuccessMessage } = useToast()
  const { refetch } = useStaffList()
  const handleSubmit: FormikConfig<NewUserValues>["onSubmit"] = (
    values,

    { setSubmitting }
  ) => {
    setSubmitting(true)

    const data = {
      ...values,
    }
    // data.role_ids = newRoleArr as number[]
    data.pan = values.pan ? Number(values.pan) : null
    data.role_id = Number(data.role_id)
    //@ts-ignore
    data.additional_info = JSON.stringify(data.additional_info)
    switch (submitType) {
      case SubmitType.Add:
        delete data.user_role_id
        CreateUser(data)
          .then((res) => {
            showSuccessMessage("New User added successfully")
            setSubmitting(false)
            refetch()
            closeModal()
          })
          .catch((err: any) => {
            const ErrorMessage = HandleError(err)
            showErrorMessage(ErrorMessage)
          })
          .finally(() => {
            setSubmitting(false)
          })
        return
      case SubmitType.Edit:
        delete data.confirm_password
        delete data.password
        data.role_id = Number(data.role_id)
        //@ts-ignore
        UpdateUser(userId as number, data as UpdatedStaff)
          .then((res) => {
            showSuccessMessage("Staff detail edited")
            setSubmitting(false)
            refetch()
            closeModal()
          })
          .catch((err: any) => {
            const ErrorMessage = HandleError(err)
            showErrorMessage(ErrorMessage)
          })
          .finally(() => {
            setSubmitting(false)
          })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={
        submitType === SubmitType.Edit ? editValidationSchema : validationSchema
      }
    >
      <Form> {children}</Form>
    </Formik>
  )
}
