import { useState } from "react"
import { tableIndexNumber, totalPages } from "../../utils/services"
import { useDailySalesReport } from "../Api/Get/GetApi"
import { Table } from "../CustomReactTable/CustomReactTable"
import { FilterDate } from "../SalesList/SalesListPage"
import "./DailySalesReport.scss"

export const DailySalesReport = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" })
  const [queryPageIndex, setQueryPageIndex] = useState(0)
  const [queryPageSize, setQueryPageSize] = useState(100)
  const { data: dailyDSR, isLoading } = useDailySalesReport(
    queryPageIndex,
    queryPageSize,
    date
  )

  const FormatColumnData = (
    headerData: string[],
    tableData: any[],
    pageIndex: number,
    pageSize: number
  ) => {
    const columns = headerData.map((val) => {
      return {
        Header: val,
        accessor: val,
      }
    })

    const requireData = tableData.map((val: any, index: number) => {
      const tempObj: any = {}
      columns.map((col, id) => {
        tempObj[col.accessor] = val.data.split(",")[id]
      })

      return {
        ...tempObj,
        name:
          index === tableData.length - 1
            ? val.particulars.slice(3, 8) //Dynamically showing total
            : val.user_info
            ? val.user_info.name
            : "",
        sn:
          index !== tableData.length - 1 &&
          tableIndexNumber(pageIndex, pageSize, index),
      }
    })
    columns.unshift({ Header: "Patron Name", accessor: "name" })
    columns.unshift({ Header: "S.N", accessor: "sn" })
    return {
      columns: columns,
      data: requireData,
    }
  }

  return (
    <>
      <div className="dailyReportPage">
        <div className="row">
          <div className="head-section">
            <div className="col-md-6">
              <div className="small-title">Daily Sales Report</div>
            </div>
          </div>
        </div>
        <Table
          loading={isLoading}
          showPageSize={false}
          columns={
            dailyDSR &&
            dailyDSR.data.data_header.data !== null &&
            dailyDSR &&
            dailyDSR.data.data_list.data !== null
              ? FormatColumnData(
                  dailyDSR.data.data_header.data.split(","),
                  dailyDSR.data.data_list,
                  queryPageIndex,
                  queryPageSize
                ).columns
              : []
          }
          data={
            dailyDSR &&
            dailyDSR.data.data_list.data !== null &&
            dailyDSR.data.data_header.data !== null
              ? FormatColumnData(
                  dailyDSR.data.data_header.data.split(","),
                  dailyDSR.data.data_list,
                  queryPageIndex,
                  queryPageSize
                ).data
              : []
          }
          totalPages={
            dailyDSR &&
            totalPages(queryPageSize, dailyDSR.pagination.total_count)
          }
          showFooter={false}
          showDate={true}
          setDate={setDate}
          date={date}
          totalEntries={2}
          queryPageIndex={queryPageIndex}
          setQueryPageIndex={setQueryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageSize={setQueryPageSize}
        />
      </div>
    </>
  )
}
