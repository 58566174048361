import React, { useContext, useEffect, useMemo, useState } from "react";
import { RevertIcon } from "../../assets/images/svg/svg";
import {
  BillGeneratedDay,
  HandleError,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";
import { useCustomerLedger, useVendorLedger } from "../Api/Get/GetApi";
import { PostRevertPayment, PostRevertPayslip } from "../Api/Post/PostApi";
import { Table } from "../CustomReactTable/CustomReactTable";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { FilterDate } from "../SalesList/SalesListPage";
import { useToast } from "../context/ToasterContext/ToasterContext";
import "./Ledger.scss";
import { LedgerResponce } from "../Api/types";
import { ConfirmationPopup } from "../PopUp/ConfirmationPopup";
import { PasswordPopup } from "../PopUp/PasswordPopup";

interface LedgerProps {
  userRole: number;
  LedgerType: string;
}

export const Ledger: React.FC<LedgerProps> = ({ userRole, LedgerType }) => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const { openModal, closeModal } = useContext(PopupContext);
  const [cancelPopup, setCancelPopUp] = useState(false)
  const [selectedId, setSelectedId] = useState("");
  const [passwordPopup, setPasswordPopup] = useState(false)
  const [reason, setReason] = useState("");
  const { showErrorMessage, showSuccessMessage } = useToast();
  const apiCall =
    LedgerType === "payslipLedger" ? useCustomerLedger : useVendorLedger;

  const {
    data: ledger,
    refetch,
    isLoading,
  } = apiCall(userRole, queryPageIndex, queryPageSize, date);

  const handleRevert = (value: {
    id: string;
    is_reverted: string;
    document_type: string;
  }) => {
    const revertText = LedgerType === "payslipLedger" ? "RCP" : "PMT";
    if (value?.is_reverted === "No" && value.document_type === revertText) {
      openModal();
      setCancelPopUp(false);
      setPasswordPopup(true);
      setSelectedId(value.id);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N.",
        accessor: "sn",
      },
      {
        Header: "Date",
        accessor: "nepali_date",
      },
      {
        Header: "Bill No.",
        accessor: "document_number",
      },
      {
        Header: "Debit",
        accessor: "debit",
      },
      {
        Header: "Credit",
        accessor: "credit",
      },
      {
        Header: "Balance",
        accessor: "balance",
      },
      {
        Header: "Bill Generated",
        accessor: "bill_generated_date",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({
          value,
        }: {
          value: { id: string; is_reverted: string; document_type: string };
        }) => {
          return (
            <div className="action-section">
              {value.document_type === "SAL" ||
              value.document_type === "PUR" ? (
                ""
              ) : (
                <div
                  className={
                    value.is_reverted === "No"
                      ? "line-primary gap-2"
                      : "line-default gap-2"
                  }
                  onClick={() => {
                    handleRevert(value);
                  }}
                >
                  <span className="mg-right-10">
                    <RevertIcon />
                  </span>
                  Revert
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const FormatTable = (
    array: LedgerResponce[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item: LedgerResponce, index) => {
        return {
          sn: tableIndexNumber(pageIndex, pageSize, index),
          nepali_date: item.nepali_date,
          document_number:
            LedgerType === "payslipLedger"
              ? item.document_number
              : item.bill_number,
          debit: item.debit,
          credit: item.credit,
          balance: item.balance,
          bill_generated_date: BillGeneratedDay(
            String(item.bill_generated_date)
          ),
          action: {
            id: item.document_number,
            is_reverted: item.is_reverted,
            document_type: item.document_type,
          },
        };
      });
      return formatData;
    }
    return [];
  };

  const billCancelation = (reason: string, selectedId: string) => {
    if (!reason) {
      showSuccessMessage("Please specify reason");
      closeModal();

      return;
    }
    const data = {
      reason_to_revert: reason,
    };
    const PostApi =
      LedgerType === "payslipLedger" ? PostRevertPayslip : PostRevertPayment;
    PostApi(selectedId, data)
      .then((res) => {
        showSuccessMessage("Ledger canceled successfully");
        refetch();
        closeModal();
      })
      .catch((err) => {
        let erroMessage = "";
        if (err.response.data) {
          erroMessage = HandleError(err);
        }
        showErrorMessage(erroMessage || "Something went wrong");
      });
  };

  useEffect(() => {
    refetch();
  }, [date.dateFrom, date.dateTo]);

  return (
    <div className="ledger-page">
      <Table
        loading={isLoading}
        columns={columns}
        data={
          ledger ? FormatTable(ledger.data, queryPageIndex, queryPageSize) : []
        }
        totalPages={
          ledger && totalPages(queryPageSize, ledger.pagination.total_count)
        }
        showFooter={true}
        showDate={true}
        setDate={setDate}
        date={date}
        totalEntries={ledger && ledger.pagination.total_count}
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
      />
      {passwordPopup && (
        <PopUp
          title="Sign-In Required"
          showCrossIcon={true}
          renderComponent={
            <PasswordPopup
              setPasswordPopup={setPasswordPopup}
              setCancelPopUp={setCancelPopUp}
            />
          }
        />
      )}
      {cancelPopup && (
        <>
          <PopUp
            title="Confirmation"
            renderComponent={
              <ConfirmationPopup
                handleCancel={() => {
                  billCancelation(reason, selectedId as string);
                  setCancelPopUp(false);
                }}
                showReasonField={true}
                reason={reason}
                setReason={setReason}
                confirmationQuestion={`Are you sure you want to cancel ${
                  selectedId || ""
                }?`}
              />
            }
          />
        </>
      )}
    </div>
  );
};
