import { useContext, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { EditIcon, PlusIcon, ThreeDotsIcon } from "../../assets/images/svg/svg";
import { totalPages } from "../../utils/services";
import { useLedgerHeadList } from "../Api/Get/GetApi";
import { LedgerListResponse } from "../Api/types";
import { Table } from "../CustomReactTable/CustomReactTable";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { LedgerForm } from "./LedgerForm";
import { Search } from "../Api/Get/GetApiTypes";

interface PopUpTypes {
  editPopUp: boolean;
  addPopUp: boolean;
}
export type EditPopupType = "semi_edit" | "form_page" | "null";

export const LedgerList = () => {
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(100);
  const { openModal } = useContext(PopupContext);
  const [ledgerPopupType, setLedgerPopupType] = useState<PopUpTypes>({
    editPopUp: false,
    addPopUp: false,
  });
  const [ledgerItem, setLedgerItem] = useState<LedgerListResponse>();
  const [searchTerm, setSearchTerm] = useState<Search>({ ledger_name: "" });

  //useQuery
  const {
    data: ledgerList,
    refetch,
    isLoading,
  } = useLedgerHeadList(queryPageIndex, queryPageSize, searchTerm as Search);


  const HandleEdit = (value: any) => {
    setLedgerPopupType((p) => ({
      ...p,
      editPopUp: true,
      addPopUp: false,
    }));
    setLedgerItem(value);
    openModal();
  };

  const FormatTable = (
    array: LedgerListResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          s_n: index + 1,
          parent_ledger_name:
            item?.parent_ledger_name === null ? "-" : item?.parent_ledger_name,
          sub_group: item.has_sub_group,
          ledger_name: item.ledger_name,
          action: item, //Edit not used for now may use in future
        };
      });
      return formatData;
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: "s_n",
      },
      { Header: "Ledger Name", accessor: "ledger_name" },

      {
        Header: "Ledger Head",
        accessor: "parent_ledger_name",
      },
      //Edit not used for now may use in future
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }: { value: any }) => {
          return (
            <div className="action-section">
              <Dropdown>
                <Dropdown.Toggle className="dropdown-icon" id="dropdown-basic">
                  <ThreeDotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      if (value?.is_fixed || value?.is_fixed === null) {
                        setLedgerPopupType((p) => ({
                          ...p,
                          editPopUp: true,
                          addPopUp: false,
                        }));
                        HandleEdit(value);
                        openModal();
                      } else {
                        setLedgerPopupType((p) => ({
                          ...p,
                          editPopUp: true,
                          addPopUp: false,
                        }));
                        HandleEdit(value);
                      }
                    }}
                  >
                    <div>
                      <span className="icon">
                        <EditIcon />
                      </span>
                      Edit
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="dashboard-table">
      <div className="head-section">
        <div className="small-title">Ledger table</div>
        <button
          className="main-button c-button"
          onClick={() => {
            setLedgerPopupType((p) => ({
              ...p,
              addPopUp: true,
              editPopUp: false,
            }));
            openModal();
          }}
          type="button"
        >
          <span className="plus-icon">
            <PlusIcon />
          </span>
          Add New Ledger
        </button>
      </div>
      {ledgerPopupType.editPopUp && (
        <PopUp
          closeOnClickOutside={true}
          showCrossIcon={true}
          popupWidth={500}
          title="Edit Form"
          renderComponent={
            <LedgerForm
              ledgerId={ledgerItem?.id}
              mode="edit"
              refetch={refetch}
            />
          }
        />
      )}

      {ledgerPopupType.addPopUp && (
        <PopUp
          closeOnClickOutside={true}
          showCrossIcon={true}
          popupWidth={500}
          title="Ledger Form"
          renderComponent={<LedgerForm refetch={refetch} mode="create" />}
        />
      )}

      <Table
        loading={isLoading}
        columns={columns}
        data={
          ledgerList
            ? FormatTable(ledgerList?.data, queryPageIndex, queryPageSize)
            : []
        }
        totalPages={
          ledgerList &&
          totalPages(queryPageSize, ledgerList?.pagination.total_count)
        }
        totalEntries={ledgerList && ledgerList?.pagination.total_count}
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
        showPageSize={false}
        smallPagination={true}
        searchArray={["ledger_name"]}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  );
};
