import { useContext, useEffect, useState } from "react";
import {
  useGetSpecificOpeningStockDetail,
  useStockDetailReport,
} from "../Api/Get/GetApi";
import { Loader } from "../Loader/Loader";
import "./OpeningStockDetailEdit.scss";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { checkNumber, replaceDecimal } from "../../utils/services";
import { IPostUpdateOpeningDetail } from "../Api/Post/PostApiTypes";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { UpdateSpecificOpeningStockDetail } from "../Api/Put/PutApi";
import { PopupContext } from "../PopUp/Popup-Context";
interface OpeningStockDetailEditProps {
  id?: number;
  productId?: number;
}

interface IUpdateData {
  primary_unit: number;
  secondary_unit: number;
  primary_price: number;
  secondary_price: number;
  amt: number;
  relation: number;
  product_id: number;
  stock_detail_id: number;
}

interface ILookUpPrice {
  primaryPrice: number;
  secondaryPrice: number;
}

export const OpeningStockDetailEdit: React.FC<OpeningStockDetailEditProps> = ({
  id,
  productId,
}) => {
  const [editedDetail, setEditedDetail] = useState<IUpdateData>();
  const [priceLookUp, setPriceLookUp] = useState<ILookUpPrice>();
  const { data, isLoading } = useGetSpecificOpeningStockDetail(id, productId);
  const { refetch } = useStockDetailReport(productId);
  const { showErrorMessage, showSuccessMessage } = useToast();
  const { closeModal } = useContext(PopupContext);

  const CalculateAmount = (
    data: IUpdateData,
    key: keyof IUpdateData,
    value: any
  ) => {
    let total = 0;
    switch (key) {
      case "primary_price":
        total =
          Number(value) * Number(data.primary_unit) +
          Number(data.secondary_price) * Number(data.secondary_unit);
        break;
      case "primary_unit":
        total =
          Number(data.primary_price) * Number(value) +
          Number(data.secondary_price) * Number(data.secondary_unit);
        break;
      case "secondary_price":
        total =
          Number(data.primary_price) * Number(data.primary_unit) +
          Number(value) * Number(data.secondary_unit);
        break;
      case "secondary_unit":
        total =
          Number(data.primary_price) * Number(data.primary_unit) +
          Number(data.secondary_price) * Number(value);
        break;
    }
    return replaceDecimal(total);
  };

  const UpdateDetail = (value: any, key: keyof IUpdateData) => {
    //@ts-ignore
    setEditedDetail((p) => ({
      ...p,
      [key]: value as number,
      amt: CalculateAmount(editedDetail as IUpdateData, key, value),
    }));
  };

  const CheckIsPriceChange = (
    editedData: IUpdateData,
    lookUpData: ILookUpPrice
  ) => {
    return (
      Boolean(editedData.primary_price !== lookUpData.primaryPrice) ||
      Boolean(editedData.secondary_price !== lookUpData.secondaryPrice)
    );
  };

  const PostUpdatedDetail = (data: IUpdateData, lookUpData: ILookUpPrice) => {
    const postData: IPostUpdateOpeningDetail = {
      product_id: data.product_id,
      qty: {
        primary_unit: data.primary_unit,
        secondary_unit: data.secondary_unit,
      },
      rate: {
        primary_price: data.primary_price,
        secondary_price: data.secondary_price,
      },
      is_change: CheckIsPriceChange(data, lookUpData),
      amt: data.amt,
    };
    UpdateSpecificOpeningStockDetail(postData, data.stock_detail_id)
      .then((res) => {
        showSuccessMessage("Opening detail updated successfully");
        refetch();
        closeModal();
      })
      .catch((err) => {
        showErrorMessage("Something went wrong");
      });
  };

  useEffect(() => {
    if (data) {
      setEditedDetail((p) => ({
        ...p,
        primary_unit: data.primary_qty,
        secondary_unit: data.secondary_qty,
        primary_price: data.primary_price,
        secondary_price: data.secondary_price,
        amt: data.total_amount,
        relation: data.relation,
        product_id: data.product_id,
        stock_detail_id: data.stock_detail_id,
      }));
      setPriceLookUp((p) => ({
        ...p,
        primaryPrice: data.primary_price,
        secondaryPrice: data.secondary_price,
      }));
    }
  }, [data]);

  if (isLoading) {
    return <Loader loaderType="Spinner" />;
  }

  return (
    <>
      <div className="opening-edit-page">
        <div className="parent">
          <div className="child">
            <label className="input-label">Primary Product</label>
            <div className="data-field">
              <input
                className="input-field"
                value={editedDetail?.primary_unit}
                onChange={(e) => {
                  let value;
                  if (editedDetail?.relation === 1) {
                    value = checkNumber(e.target.value, "decimalToo");
                  } else {
                    value = checkNumber(e.target.value, "numberOnly");
                  }
                  UpdateDetail(value, "primary_unit");
                }}
              />
            </div>
          </div>
          <div className="child">
            <label className="input-label">Secondary Product</label>
            <div className="data-field">
              <input
                className="input-field"
                value={editedDetail?.secondary_unit}
                disabled={editedDetail?.relation === 1}
                onChange={(e) => {
                  let value = checkNumber(e.target.value, "numberOnly");
                  UpdateDetail(value, "secondary_unit");
                }}
              />
            </div>
          </div>
        </div>
        <div className="parent">
          <div className="child">
            <label className="input-label">Primary Price</label>
            <div className="data-field">
              <input
                className="input-field"
                value={editedDetail?.primary_price}
                onChange={(e) => {
                  let value = checkNumber(e.target.value, "decimalToo");
                  UpdateDetail(value, "primary_price");
                }}
              />
            </div>
          </div>
          <div className="child">
            <label className="input-label">Secondary Price</label>
            <div className="data-field">
              <input
                className="input-field"
                value={editedDetail?.secondary_price}
                disabled={editedDetail?.relation === 1}
                onChange={(e) => {
                  let value = checkNumber(e.target.value, "decimalToo");
                  UpdateDetail(value, "secondary_unit");
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <label className="input-label">Amount</label>
          <div className="data-field">
            <input className="input-field" value={editedDetail?.amt} disabled />
          </div>
        </div>
        <div className="button-content">
          <ButtonComponent
            title="Save"
            onClick={() => {
              PostUpdatedDetail(
                editedDetail as IUpdateData,
                priceLookUp as ILookUpPrice
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
