import { LegacyRef, ReactInstance, useContext, useRef } from "react";
import { PlusIcon, ServerError } from "../../assets/images/svg/svg";
import { DocumentContext } from "../BillPage/Document-Context";
import "./ReceiptPage.scss";
import { useReceiptSlip } from "../Api/Get/GetApi";
import { Loader } from "../Loader/Loader";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { useReactToPrint } from "react-to-print";
import { PopupContext } from "../PopUp/Popup-Context";
import { ToWords } from "to-words";
export const ReceiptPage = () => {
  const { receiptNumber } = useContext(DocumentContext);
  const { data, isLoading, isError } = useReceiptSlip(receiptNumber);
  const componentRef = useRef(null);
  const { closeModal } = useContext(PopupContext);
  const toWords = new ToWords({ converterOptions: { ignoreDecimal: true } });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (isLoading) {
    return <Loader loaderType="Spinner" />;
  }
  if (!isLoading && isError) {
    return <ServerError />;
  }

  return (
    <div
      className="receipt-page"
      ref={componentRef as LegacyRef<HTMLDivElement> | undefined}
    >
      <div>
        <div className="head-section">
          <div className="d-flex logo-section">
            <div className="logo">
              <img
                src={
                  data?.business_info.logo
                    ? data.business_info.logo
                    : require("../../assets/images/seller.png")
                }
                alt="icon"
              />{" "}
            </div>
            <div className="logo-detail">
              <h2 className="small-title">{data?.business_info.name}</h2>
              <p className="business-address">{data?.business_info.address}</p>

              <div className="div-flex">
                <div className="content">
                  Phone:
                  <span className="bold-content">
                    {data?.business_info.mobile_no}
                  </span>
                </div>
                <div className="content pan-box">
                  {/* <div className="vertical-line"></div> */}
                  PAN No.:
                  <span className="bold-content">
                    {data?.business_info.pan}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="c-button">RECEIPT</p>
            <p className="content">
              Receipt No:
              <span className="bold-content">
                {data?.bill_user_info.document_no}
              </span>
            </p>
            <p className="content">
              Date:
              <span className="bold-content">
                {" "}
                {data?.bill_user_info.bill_miti}
              </span>
            </p>
          </div>
        </div>
        <div className="comment-section">
          <div className="div-flex">
            <p className="content">Received with thanks from:</p>
            <div className="text-section">{data?.customer_user_info.name}</div>
          </div>
          <div className="div-flex">
            <p className="content">Amount (In words):</p>
            <div className="text-section">
              {toWords.convert(data?.amount_detail.total as number)}
            </div>
          </div>
          <div className="div-flex">
            <p className="content">Amount (In figures):</p>
            <div className="text-section">
              Rs. {data?.amount_detail.total}/-
            </div>
          </div>
          <div className="footer-section">
            <div className="div-flex width-50">
              <p className="content black-font">Receiver</p>
              <div className="sign-section black-dot"></div>
            </div>
            <div className="div-flex width-50 justify-end">
              <p className="content black-font">Cashier</p>
              <div className="sign-section black-dot"></div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="button-content">
        <button className="light-secondary" onClick={closeModal}>
          Cancel
        </button>
        <ButtonComponent onClick={handlePrint} title="Print" />
      </div>
    </div>
  );
};
