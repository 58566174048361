import { useNavigate } from "react-router-dom";
import { PageErrorIcon } from "../../assets/images/svg/svg";
import "./ErrorPage.scss";

export const PageNotFound  = () => {
  const navigate = useNavigate()
  const handelBack=()=>{
    navigate(-1)
  }
  function refreshPage() {
    //@ts-ignore
    window.location.reload(false);
  }
  return (
    <div className="error-page">
      <div className="icon-section"><PageErrorIcon /></div>
      <div className="mid-page">
        <div className="text-section">
          <h2 className="small-title error-title">Oops!</h2>
          <p className="sub-text">
            We can’t seem to find the page you’re looking for
          </p>
          <p className="error">Error Code : 404</p>
        </div>
        <div className="btn-mid">
          <button className="light-secondary mr-16" onClick={handelBack}>Back</button>
          <button className="light-primary" onClick={refreshPage}>Try Again</button>
        </div>
      </div>
    </div>
  );
};
