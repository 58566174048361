import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  HandleError,
  UpdatedformatSelectOptions,
  checkNumber,
  deepClone,
} from "../../utils/services";

import { ExclamationIcon } from "../../assets/images/svg/svg";
import { useLedgerHead, useSpecificLedgerHead } from "../Api/Get/GetApi";
import { LedgerHeadResponse } from "../Api/Get/GetApiTypes";
import { CreateLedger, PostUpdateLedger } from "../Api/Post/PostApi";
import { CreateLedgerData } from "../Api/Post/PostApiTypes";
import { ServerErrorPage } from "../ErrorPage/ServerErrorPage";
import { Loader } from "../Loader/Loader";
import { PopupContext } from "../PopUp/Popup-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import "./LedgerForm.scss";
import "./LedgerTable.scss";
import { poolOptions } from "../../EnumValues";

export interface INewLedgerData {
  id: number;
  name: string;
}

interface LedgerProps {
  ledgerId?: string | number;
  mode: "create" | "edit";
  refetch: () => void;
}

export const LedgerForm: React.FC<LedgerProps> = ({
  ledgerId,
  mode,
  refetch,
}) => {
  const { data: ledgerHeadList, isLoading } = useLedgerHead();

  const {
    data: specificLedger,
    isLoading: specificLoading,
    isError,
  }: {
    data: any;
    isLoading: boolean;
    isError: boolean;
  } = useSpecificLedgerHead(ledgerId as number);

  const [ledgerData, setLedgerData] = useState<CreateLedgerData>({
    ledger_name: "",
    group_code: 0,
    transaction_column_type: "dr",
    opening_amount: 0,
    has_sub_group: false,
    is_fixed: false,
    asset_pool_type: "",
    is_goods_ledger: null,
    is_opening_balance_editable: "Yes",
  });
  const { showSuccessMessage, showErrorMessage } = useToast();
  const { closeModal } = useContext(PopupContext);

  const AddNewLedger = (data: CreateLedgerData) => {
    if (!data.group_code) {
      return showErrorMessage("Please Select Ledger Head");
    }
    if (!data.ledger_name) {
      return showErrorMessage("Please add ledger name");
    }
    const tempData = deepClone(data);
    //@ts-ignore
    delete tempData.is_fixed;
    const postApi =
      mode === "create"
        ? CreateLedger(tempData)
        : PostUpdateLedger(ledgerId as number, tempData);
    postApi
      .then((res) => {
        showSuccessMessage("Successfully added the ledger");
        refetch?.();
        closeModal();
      })
      .catch((err) => {
        const errMsg = HandleError(err);
        showErrorMessage(errMsg);
      })
      .finally(() => {});
  };

  const TransactionOptions = [
    { id: 1, label: "Debit", value: "dr" },
    { id: 2, label: "Credit", value: "cr" },
  ];

  const [selectedGroupCode, setSelectedGroupCode] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (specificLedger) {
      const debit = specificLedger?.debit || 0;
      const credit = specificLedger?.credit || 0;

      const opening_balance = debit > credit ? debit : credit;
      const transactionVal = debit > credit ? "dr" : "cr";

      setLedgerData((p) => ({
        ...p,
        ledger_name: specificLedger.ledger_name as string,
        group_code: specificLedger.group_code,
        transaction_column_type: transactionVal,
        opening_amount: opening_balance,
        is_goods_ledger: specificLedger?.is_goods_ledger,
        asset_pool_type: specificLedger.asset_pool_type,
        has_sub_group: specificLedger.has_sub_group,
        is_fixed: specificLedger.is_fixed,
        is_opening_balance_editable: specificLedger.is_opening_balance_editable,
      }));
      setSelectedGroupCode(specificLedger.group_code);
    }
  }, [specificLedger]);

  const optionsArr = useMemo(() => {
    const val = UpdatedformatSelectOptions<LedgerHeadResponse>(
      ledgerHeadList as LedgerHeadResponse[],
      "id",
      "ledger_name"
    );

    return val;
  }, [ledgerHeadList]);

  const selectedGroup = useMemo(() => {
    const newObj = ledgerHeadList?.find(
      (item) => item.id === selectedGroupCode
    );
    return newObj;
  }, [ledgerHeadList, selectedGroupCode]);

  if (specificLoading) {
    return <Loader loaderType="Spinner" />;
  }

  if (isError) {
    return <ServerErrorPage />;
  }
  return (
    <>
      <div className="ledger_table">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="input-label">Ledger Head</label>
              <Select
                maxMenuHeight={100}
                isClearable={true}
                placeholder="Enter Ledger Head"
                onChange={(value) => {
                  const newValue = value ? value.value : null;
                  setSelectedGroupCode(
                    newValue !== undefined ? newValue : null
                  );

                  setLedgerData((p) => ({
                    ...p,
                    group_code: value?.value as number,
                  }));
                }}
                isDisabled={ledgerData.is_fixed}
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                  menu: (base) => ({
                    ...base,
                    fontSize: "12px",
                  }),
                  option: (provided: any, state: any) => ({
                    ...provided,
                    fontWeight: state.isSelected ? "bold" : "normal",
                    backgroundColor: state.isSelected ? "#1db195" : "#ffffff",
                    fontSize: 12,
                    "&:hover": {
                      backgroundColor: "#f3f3f3",
                      color: "#1db195",
                    },
                  }),
                }}
                options={isLoading ? [] : optionsArr}
                value={optionsArr?.find(
                  (option) => option.value === selectedGroupCode
                )}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="input-label">Ledger Name</label>
              <input
                value={ledgerData.ledger_name}
                onChange={(e) => {
                  setLedgerData((p) => ({
                    ...p,
                    ledger_name: e.target.value,
                  }));
                }}
                disabled={ledgerData.is_fixed}
                className="input-field"
                placeholder="Enter Ledger Name"
                type="text"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group d-flex">
              <div className="check-box-area d-flex">
                <input
                  type="checkbox"
                  disabled={ledgerData.is_fixed}
                  onChange={(e) => {
                    setLedgerData((p) => ({
                      ...p,
                      has_sub_group: !ledgerData.has_sub_group,
                    }));
                  }}
                  checked={ledgerData.has_sub_group}
                />
              </div>
              <label className="input-label"> Has Sub Group</label>
            </div>
          </div>
          <form action="addition_info" className="mg-top-20">
            <fieldset>
              <legend>Additional Info</legend>

              <div className="row">
                <div className="col-md-12">
                  <div className="radio-section">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radioOption1"
                        checked={
                          ledgerData?.is_goods_ledger === true ? true : false
                        }
                        onChange={(e) => {
                          setLedgerData((p) => ({
                            ...p,
                            is_goods_ledger: true,
                          }));
                        }}
                      />

                      <label className="input-label" htmlFor="radioOption1">
                        Goods
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radioOption2"
                        checked={
                          ledgerData?.is_goods_ledger === false ? true : false
                        }
                        onChange={(e) => {
                          setLedgerData((p) => ({
                            ...p,
                            is_goods_ledger: false,
                          }));
                        }}
                      />
                      <label className="input-label" htmlFor="radioOption2">
                        Service
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radioOption3"
                        checked={
                          ledgerData?.is_goods_ledger == null ? true : false
                        }
                        onChange={(e) => {
                          setLedgerData((p) => ({
                            ...p,
                            is_goods_ledger: null,
                          }));
                        }}
                      />
                      <label className="input-label" htmlFor="radioOption3">
                        other
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="input-label">Opening Balance</label>
                    {!ledgerData?.has_sub_group && (
                      <div className="data-field">
                        <input
                          type="text"
                          disabled={
                            ledgerData.is_opening_balance_editable.toLowerCase() !== "yes"
                          }
                          value={ledgerData.opening_amount || 0}
                          className="input-field"
                          placeholder="Enter amount"
                          onChange={(e) => {
                            setLedgerData((p) => ({
                              ...p,
                              opening_amount:
                                checkNumber(e.target.value, "decimalToo") || 0,
                            }));
                          }}
                          onWheel={(e: any) => {
                            (e.target as HTMLInputElement).blur();
                          }}
                          onFocus={(e: any) => {
                            if (parseInt(e.target.value) === 0) {
                              e.target.value = "";
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {selectedGroup?.is_pool_show &&
                selectedGroup?.is_pool_show.toLowerCase() === "yes" ? (
                  <div className="col-md-6">
                    <label className="input-label">Pool</label>
                    <div className="d-flex gap-2">
                      <div className="width-90">
                        <Select
                          maxMenuHeight={85}
                          styles={{
                            control: (base) => ({
                              ...base,
                              fontSize: "12px",
                            }),
                            menu: (base) => ({
                              ...base,
                              fontSize: "12px",
                            }),
                          }}
                          options={poolOptions}
                          value={poolOptions.find(
                            (item) => item.value === ledgerData?.asset_pool_type
                          )}
                          onChange={(selectedOption) => {
                            const value = selectedOption
                              ? selectedOption.value
                              : "";
                            setLedgerData((prevState) => ({
                              ...prevState,
                              asset_pool_type: value,
                            }));
                          }}
                        />
                      </div>
                      <div className="tooltip-div">
                        <ExclamationIcon />
                        <div className="tooltip-trangle"></div>
                        <div className="tooltip-box">
                          <table>
                            <thead>
                              <th className="nowrap content">
                                Name of the Pool
                              </th>
                              <th className="content">
                                Assets falling under the Pool
                              </th>
                            </thead>

                            <tbody>
                              <tr>
                                <td className="nowrap black-content">Pool A</td>
                                <td className="black-content">
                                  Building, stucture and similar works of
                                  permanent nature.
                                </td>
                              </tr>
                              <tr>
                                <td className="nowrap black-content">Pool B</td>
                                <td className="black-content">
                                  Furnitur ficture, office Equipment data
                                  handaling equipment, Computers
                                </td>
                              </tr>
                              <tr>
                                <td className="nowrap black-content">
                                  Pool C{" "}
                                </td>
                                <td className="black-content">
                                  Automobiles, bus and minibus
                                </td>
                              </tr>
                              <tr>
                                <td className="nowrap black-content">
                                  Pool D{" "}
                                </td>
                                <td className="black-content">
                                  Eartdmoving Equipments, Plant & Development
                                  Cost and Pollution Control Cost, Expenditure
                                  incurred while prospecting,
                                  exploration/extraction and development of
                                  natural resources, any other tangible assets
                                  not falling under Pool A to C above
                                </td>
                              </tr>
                              <tr>
                                <td className="nowrap black-content">Pool E</td>
                                <td className="black-content">
                                  intangible Assets
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </fieldset>
          </form>

          {Number(ledgerData?.opening_amount) > 0 &&
            ledgerData.is_opening_balance_editable !== "No" && (
              <div className="col-md-6">
                <div className="tab-btn float-right">
                  {TransactionOptions.map((val, index) => {
                    return (
                      <div
                        key={index}
                        onClick={(e: any) => {
                          setLedgerData((p) => ({
                            ...p,
                            transaction_column_type: val.value,
                          }));
                        }}
                        className={`manual-switch ${
                          val.value === ledgerData.transaction_column_type
                            ? "active-bg"
                            : "unactive"
                        } `}
                      >
                        {val.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
        <div className="footer-btn-section">
          <button
            className="main-button"
            disabled={isLoading}
            onClick={() => {
              AddNewLedger(ledgerData);
            }}
          >
            {isLoading ? (
              <div className="">
                <Loader loaderType="Spinner" universalCenter={false} />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
