import { Axios, MultiPartAxios } from "../../lib/Axios";
import { OpeningAdjustmentInitial } from "../../OpeningAdjustment/OpeningWrapper";
import {
  ManualVoucherInitial,
} from "../../ManaualVoucher/ManualVoucherWrapper";
import { SettingPageValues } from "../../SettingPage/SettingPageWrapper/SettingPageWrapper";
import { NewUserValues } from "../types";
import { CreateLedgerData } from "./PostApiTypes";

export const PostCreateCustomer = async (data: any) => {
  return Axios.post("/api/v1/customer/create", data);
};

export const PostCreateCategory = async (data: any) => {
  return Axios.post("/api/v1/product_service/product-category/create", data);
};

export const PostUpdateCategory = async (id: number, data: any) => {
  return Axios.post(
    `/api/v1/product_service/product-category/update/${id}`,
    data
  );
};

export const PostUpdateLedger = async (id: number, data: any) => {
  return Axios.post(`/api/v1/product_service/ledger/update/${id}`, data);
};

export const PostCreateProduct = async (data: any) => {
  return Axios.post("/api/v1/product_service/product/create", data);
};
export const PostEditProduct = async (id: number, data: any) => {
  return Axios.put(`api/v1/product_service/product/update/${id}`, data);
};


export const PostPurchaseData = async (data: any) => {
  return Axios.post(
    "/api/v1/product_service/transaction/purchase-create",
    data
  );
};

export const PostSalesData = async (data: any) => {
  return Axios.post("/api/v1/product_service/transaction/sale-create", data);
};

export const PostBillData = async (data: any) => {
  return Axios.post("/api/v1/product_service/transaction/create", data);
};
export const PostLoginPage = (data: any) => {
  return Axios.post("api/v1/user/login", data);
};

export const PostPayslipCreate = (data: any) => {
  return Axios.post("/api/v1/product_service/payslip/create", data);
};

export const CreateBusiness = (data: any) => {
  return MultiPartAxios.post("/api/v1/business/create", data);
};
export const PostProductService = (data: any) => {
  return Axios.post("/api/v1/product_service/opening/stock", data);
};

export const CreateUser = (data: NewUserValues) => {
  return MultiPartAxios.post("/api/v1/user/create", data);
};

export const PurchaseBillCancel = (
  document_number: string,
  data: { reason_to_cancel: string }
) => {
  return Axios.post(
    `/api/v1/product_service/transaction/purchase-bill-cancel/${document_number}`,
    data
  );
};

export const SaleBillCancel = (
  document_number: string,
  data: { reason_to_cancel: string; customer_id: number }
) => {
  return Axios.post(
    `/api/v1/product_service/transaction/sale-bill-cancel/${document_number}`,
    data
  );
};

export const ManualVoucherReportCancel = (
  document_number: string,
  data: { reason_to_cancel: string; customer_id: number }
) => {
  return Axios.post(
    `api/v1/product_service/transaction/manual-voucher-bill-cancel/${document_number}`,
    data
  );
};

export const PostSalesReturn = (data: any) => {
  return Axios.post(
    `/api/v1/product_service/transaction/sale-return-create`,
    data
  );
};

export const ReadTransaction = () => {
  return Axios.put(
    "/api/v1/product_service/transaction-reminder/update-unread"
  );
};

export const PostPurchaseReturn = (data: any) => {
  return Axios.post(
    "/api/v1/product_service/transaction/purchase-return-create",
    data
  );
};

export const UpdatePassowrd = (data: any, id: number, isStaff: boolean) => {
  return Axios.post(
    `/api/v1/user/change-password/${id}?is_change_staff=${isStaff}`,
    data
  );
};

export const UpdateSetting = (data: SettingPageValues) => {
  return Axios.post("api/v1/setting/create", data);
};

export const OpeningAdjustmentVoucher = (data: OpeningAdjustmentInitial) => {
  return Axios.post(
    "/api/v1/product_service/transaction/opening-balance-create",
    data
  );
};
export const CreateManualVoucher = (data: ManualVoucherInitial) => {
  return Axios.post(
    "/api/v1/product_service/transaction/manual-voucher-create",
    data
  );
};

export const CreateLedger = (data: CreateLedgerData) => {
  return Axios.post("/api/v1/product_service/ledger/create", data);
};

export const PostPaymentVoucherCreate = (data: any) => {
  return Axios.post("/api/v1/product_service/payment-voucher/create", data);
};

export const PostRevertPayslip = (
  id: string,
  data: { reason_to_revert: string }
) => {
  return Axios.post(
    `api/v1/product_service/payslip/receipt-revert/${id}`,
    data
  );
};

export const PostRevertPayment = (
  id: string,
  data: { reason_to_revert: string }
) => {
  return Axios.post(
    `api/v1/product_service/payment-voucher/revert/${id}`,
    data
  );
};

export const PostAutoDepriciation = (data: any) => {
  return Axios.post("/api/v1/product_service/add-depreciation", data);
};
