import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps } from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { toast } from "react-toastify";
import { ReportType } from "../../EnumValues";
import { ProductFilter, checkNumber, fixedValue } from "../../utils/services";
import {
  GetPurchaseProductSearch,
  GetSaleProductSearch,
} from "../Api/Get/GetApi";
import {
  BillDetailProps,
  useNewBillFormikContext,
} from "../BillPage/BillFormikWrapper";
import { DocumentContext } from "../BillPage/Document-Context";
import { BatchesContext } from "../context/BatchesContext/BatchesContext";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";
import { BatchInfoResponse } from "../ProductDetails/ProductDetails";
import { UpdateSelectedProducts } from "../PurchasePage/PurchasePage";
import { ReverseFunction } from "../ReverseFunction/ReverseFunction";
import { SettingPageContext } from "../SettingPage/Context/SettingPageContext";
import { UpdatePrice } from "../UpdatePrice/UpdatePrice";
import { InputContent } from "./ProductPage";
import "./ProductPage.scss";

const productCustomBarCodeRegex =
  /^(?:\d{12}|\d{13}|PO\/\d{4}\/\d{4}[A-Za-z]\d+)$/;

export type BillDetailsKey = "qty" | "free_qty" | "rate" | "discount" | "tax";
export type InnerKeys =
  | "primary_unit"
  | "secondary_unit"
  | "primary_price"
  | "secondary_price";

interface ProductFormikPageProps {
  roleID: number | string;
  HandleSelectBatch: (
    getActiveIndex: number,
    batchInfos: BatchInfoResponse,
    recentBillDetails?: any
  ) => void;
  scannedData: string;
  setScannedData: React.Dispatch<React.SetStateAction<string>>;
}

const generator = (products: InputContent[]) => {
  return products.map((product, index) => ({
    id: product?.id,
    name: product.name,
  }));
};

export const UpdateAmount = (index: number, newArr: BillDetailProps[]) => {
  let primaryUnit = newArr[index].qty.primary_unit;
  let secondaryUnit = newArr[index].qty.secondary_unit;
  let primaryPrice = newArr[index].rate.primary_price;
  let secondaryPrice = newArr[index].rate.secondary_price;
  let discount = Number(newArr[index].discount);
  let totalAmount = primaryUnit * primaryPrice + secondaryUnit * secondaryPrice;
  const discountedAmount = totalAmount - discount;
  const tax = Number(newArr[index].tax);
  const taxAmount = tax > 0 ? (tax / 100) * discountedAmount : 0;
  const tempNetTotal = discountedAmount + taxAmount;

  return {
    amount: fixedValue(totalAmount),
    netAmount: fixedValue(tempNetTotal),
  };
};

export interface ProductBatches {
  id: number;
  name: string;
}

export interface StockData {
  id: number | "";
  stock: number | "";
  relation: number | null;
}

export const ProductFormikPage: React.FC<ProductFormikPageProps> = ({
  roleID,
  HandleSelectBatch,
  scannedData,
  setScannedData,
}) => {
  const { values, setFieldValue, errors } = useNewBillFormikContext();
  const [searchData, setSearchData] = useState<Array<InputContent>>([]);
  const [editPriceModal, setEditPriceModal] = useState(false);
  const [updateIndex, setUpdateIndex] = useState<number>();
  const [updateBatchValue, setUpdateBatchValue] = useState({
    id: 0,
    relation: 0,
  });
  const { openModal } = useContext(PopupContext);
  const { searchStrings, setSearchStrings } = useContext(DocumentContext);
  const {
    activeProductBatches,
    setActiveProductBatches,
    setActiveBatch,
    activeBatch,
    selectedBatches,
    setSelectedBatches,
  } = useContext(BatchesContext);
  const { settingValues } = useContext(SettingPageContext);

  const { showErrorMessage } = useToast();

  const AddData = {
    id: 0,
    product_bar_code: "",
    relation: 0,
    product_id: 0,
    amt: 0,
    discount: 0,
    sub_amt: 0,
    qty: {
      primary_unit: 0,
      secondary_unit: 0,
    },
    free_qty: {
      primary_unit: 0,
      secondary_unit: 0,
    },
    rate: {
      primary_price: 0,
      secondary_price: 0,
    },
    tax: 0,
  };

  const GetSearchedData = (
    roleId: number,
    productName: string | null,
    transaction_type: ReportType,
    productId?: string | number,
    callback?: (productList: InputContent[]) => void
  ) => {
    //This is just to handle the onclear request
    // if (!productName) {
    //   return
    // }
    if (values.role_id == 0 || !values.role_id) {
      return;
    }
    switch (transaction_type) {
      case ReportType.Purchase:
        GetPurchaseProductSearch(roleId, productName)
          .then((res: any) => {
            let filteredData = ProductFilter<BillDetailProps>(
              res.data.data as InputContent[],
              values.bill_details,
              "product_id"
            );
            setSearchData(filteredData);
          })
          .catch((err: any) => {
            let errorMessage = "Failed to show product";
            if (err?.response?.data?.status?.status_message) {
              errorMessage = err.response.data.status.status_message;
            }
            toast.error(errorMessage.toString());
          });
        break;
      case ReportType.Sales:
        GetSaleProductSearch(roleId, productName || "None", productId)
          .then((res: any) => {
            let filteredData = ProductFilter<BillDetailProps>(
              res.data.data as InputContent[],
              values.bill_details,
              "product_id"
            );
            setSearchData(filteredData);
            filteredData.length && callback && callback(filteredData);
          })
          .catch((err: any) => {
            let errorMessage = "Failed to show product";
            if (err.response.data.status.status_message) {
              errorMessage = err.response.data.status.status_message;
            }
            toast.error(errorMessage.toString());
          });
        break;

      default:
        setSearchData([]);
        return;
    }
  };

  const HandleProductSelect = (
    value: { id: number; name: string },
    index: number
  ) => {
    if (searchData) {
      const searchProduct = searchData.find((val) => val.id === value.id);
      const newArr = [...values.bill_details];
      //This needs to be update when we work with batch
      newArr[index].rate.primary_price = searchProduct?.batch_info[0]
        .product_price[0].primary_price as number;
      newArr[index].rate.secondary_price = searchProduct?.batch_info[0]
        .product_price[0].secondary_price as number;

      newArr[index].product_id = searchProduct?.batch_info[0].product_price[0]
        .id as number;
      newArr[index].relation = searchProduct?.relation;

      setFieldValue("bill_details", newArr);
      UpdateSelectedProducts(
        index,
        selectedBatches,
        setSelectedBatches,
        //@ts-ignore
        searchProduct?.batch_info[0]
      );
    }
  };

  //Function to update the amount dynamically
  const UpdateFormikValues = (
    value: any,
    index: number,
    firstkey: BillDetailsKey,
    secondKey?: InnerKeys
  ) => {
    const newArray = [...values.bill_details];
    //Discount feature addition
    if (firstkey !== "discount" && firstkey !== "tax") {
      if (secondKey === "secondary_unit") {
        RelationWiseUpdateValue(index, value, newArray);
      } else {
        //check if the relation of pri and sec is 1
        if (
          selectedBatches[index]?.relation === 1 ||
          (firstkey === "rate" &&
            values.transaction_type === ReportType.Sales)
        ) {
          value = checkNumber(value, "decimalToo");
        } else {
          value = checkNumber(value, "numberOnly");
        }
        //@ts-ignore
        newArray[index][firstkey][secondKey] = value; //this updates the state value
      }

      if (firstkey !== "free_qty") {
        //As stock value only works in sales transaction// this may be needed if stock values need to be compared in future
        if (
          firstkey === "qty" &&
          values.transaction_type === ReportType.Sales &&
          (settingValues?.is_stock_validate !== false || !settingValues)
        ) {
          compareTheStockValue(index);
        }
        newArray[index].amt = UpdateAmount(index, newArray).amount;
        newArray[index].sub_amt = UpdateAmount(index, newArray).netAmount;
      }
    } else {
      if (firstkey === "tax") {
        newArray[index].tax = Number(value);
        newArray[index].amt = UpdateAmount(index, newArray).amount;
        newArray[index].sub_amt = UpdateAmount(index, newArray).netAmount;
      } else if (firstkey === "discount") {
        value = checkNumber(value, "decimalToo");
        newArray[index].discount = value;

        if (Number(value) > newArray[index].amt) {
          newArray[index].discount = 0;
          showErrorMessage("Discount cant be greater than amount");
        }

        newArray[index].amt = UpdateAmount(index, newArray).amount;
        newArray[index].sub_amt = UpdateAmount(index, newArray).netAmount;

        const allDiscount = newArray.reduce((accumulator, object) => {
          //@ts-ignore
          return accumulator + parseFloat(object.discount);
        }, 0);

        setFieldValue("discount", fixedValue(allDiscount));
      }
    }

    setFieldValue("bill_details", newArray);
  };

  const RelationWiseUpdateValue = (
    index: number,
    value: number,
    newArray: BillDetailProps[]
  ) => {
    const relation = Number(newArray[index].relation);

    const primVal = Math.floor(Number(value) / relation);
    const secondaryVal = Number(value) % relation;
    const preExistedPrimVal = values.bill_details[index].qty.primary_unit;
    newArray[index].qty.primary_unit = Number(preExistedPrimVal) + primVal;
    newArray[index].qty.secondary_unit = secondaryVal;
  };

  const clearSelectedBatch = (index: number) => {
    const clonedSelectedBatches = [...selectedBatches];
    if (clonedSelectedBatches[index]) {
      //deleted index batch
      clonedSelectedBatches.splice(index, 1);
      setSelectedBatches(clonedSelectedBatches);
    }
  };

  const handleClear = (index: number) => {
    const newArr = [...values.bill_details];
    newArr.splice(index, 1, AddData);
    setFieldValue("bill_details", newArr);
    const newString = [...searchStrings];
    newString.splice(index, 1, { title: "" });
    setSearchStrings(newString);
    //Stock state is only used in sales
    if (values.transaction_type === ReportType.Sales) {
      clearSelectedBatch(index);
    }
    setActiveBatch(null);
  };

  //Calculates the total input value and throw error if input value exceed the remaining stock
  const compareTheStockValue = (index: number) => {
    if (activeBatch === null || !selectedBatches[index]) {
      toast.error(`Please select the batch`);

      return;
    }
    //@ts-ignore
    const remainingStock = Number(selectedBatches[index].remaining_stock);
    const formikState = [...values.bill_details];
    const totalInput =
      Number(formikState[index].qty.primary_unit) *
      Number(activeProductBatches[activeBatch].relation) +
      Number(formikState[index].qty.secondary_unit);
    if (totalInput > remainingStock) {
      toast.error(`Exceed the stock value:${remainingStock} unit`);
      formikState[index].qty.primary_unit = 0;
      formikState[index].qty.secondary_unit = 0;

      formikState[index].amt = UpdateAmount(index, formikState).amount;
      formikState[index].sub_amt = UpdateAmount(index, formikState).netAmount;
      return false;
    }
    return true;
  };

  const OpenPriceModal = (primaryPrice: number, index: number) => {
    if (values.bill_details[index].product_id !== 0) {
      setUpdateIndex(index);
      setEditPriceModal(true);
      const reqId =
        selectedBatches[index] && selectedBatches[index].product_price[0].id;
      const relation =
        selectedBatches[index] && selectedBatches[index].relation;
      setUpdateBatchValue((p) => ({
        ...p,
        id: reqId as number,
        relation: relation as number,
      }));
      openModal();
      return;
    }
    return;
  };

  const formatResult = (item: any) => {
    return (
      <>
        <span className="formatted-result">{item.name}</span>
      </>
    );
  };

  const HandleBatches = (value: string, index: number) => {
    const clonedBatches = [...activeProductBatches];

    setActiveBatch(index);

    const requiredBatch = searchData.find((val) => val.name === value);

    const additionKeys = {
      relation: requiredBatch?.relation as number,
      batch_info: requiredBatch?.batch_info as BatchInfoResponse[],
    };

    clonedBatches[index] = additionKeys;
    setActiveProductBatches(clonedBatches);

    if (requiredBatch?.batch_info.length === 1) {
      HandleSelectBatch(
        index,
        requiredBatch.batch_info[0] as BatchInfoResponse
      );
    }
  };

  const handleRemoveProduct = (
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    arrayHelpers.remove(index);
    const newSearch = [...searchStrings];
    newSearch.splice(index, 1);
    setSearchStrings(newSearch);
    const clonedBatches = [...activeProductBatches];
    clonedBatches.splice(index, 1);

    setActiveProductBatches(clonedBatches);

    if (values.transaction_type === ReportType.Sales) {
      clearSelectedBatch(index);
      setActiveBatch(null);
    }
  };

  const ResetValues = () => {
    const tempArr = [];
    tempArr.push({
      relation: 0,
      product_id: 0,
      discount: 0,
      amt: 0,
      sub_amt: 0,
      qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      free_qty: {
        primary_unit: 0,
        secondary_unit: 0,
      },
      rate: {
        primary_price: 0,
        secondary_price: 0,
      },
    });
    setFieldValue("bill_details", tempArr);
    setSearchStrings([{ title: "" }]);
    setActiveBatch(null);
    setFieldValue("credit", 0);
    setFieldValue("cash", 0);
  };

  const findActiveProd = (activeProds: ProductBatches, index: number) => {
    const objKeys = Object.keys(activeProds);
    let requireProdName = "";
    objKeys.forEach((val) => {
      let splitVal = val.split("#");
      if (Number(splitVal[1]) === index) {
        requireProdName = val;
      }
    });
    return requireProdName;
  };

  const handleProductSelect = (
    value: any,
    index: number,
    updatedSearchString?: any
  ) => {
    // setProdBatchId(value.id);
    let stateArr = [...searchStrings];
    if (updatedSearchString) {
      stateArr = updatedSearchString;
    }
    stateArr[index].title = value.name;
    setSearchStrings?.(stateArr);

    if (values.transaction_type === ReportType.Purchase) {
      HandleProductSelect(value, index);
    } else if (values.transaction_type === ReportType.Sales) {
      HandleBatches(value.name, index);
    }

    let tax = 0;
    if (value.tax) {
      tax = value.tax;
    } else {
      tax = Number(searchData?.find((f) => f?.id === value.id)?.tax) || 0;
    }

    setFieldValue(`bill_details.${index}.tax`, tax);
    setFieldValue("cash", 0);
  };

  useEffect(() => {
    //Handle the change in transaction type
    ResetValues();
  }, [values.transaction_type]);

  const handleOnChangePrimaryQuantiy = (value: number, index: number) => {
    UpdateFormikValues(value, index, "qty", "primary_unit");
    UpdateFormikValues(values.bill_details[index].tax, index, "tax");
    setFieldValue("cash", 0);
  };

  const handleOnChangeSecondaryQuantiy = (value: number, index: number) => {
    UpdateFormikValues(value, index, "qty", "secondary_unit");
    UpdateFormikValues(values.bill_details[index].tax, index, "tax");
  };

  // ###### Barcode scanned section ###### //

  const handleBarCodeScan = () => {
    if (!scannedData) return;

    let checkProductIsExist = true;
    let productId = "";
    let batchId = "";

    if (!productCustomBarCodeRegex.test(scannedData)) return;
    if (scannedData.includes("/")) {
      productId = scannedData.split("/")[1].replace(/^0+/, "");
      batchId = scannedData.split("/")[2].replace(/^0+/, "");
    } else {
      productId = scannedData;
    }

    // find product index from barcode if exist
    let selectedProductIndex = values.bill_details.findIndex((product) => {
      return (
        product.id === Number(productId) ||
        product.product_bar_code === productId
      );
    });

    //check if product exist or not and update selected product index
    if (selectedProductIndex === -1) {
      checkProductIsExist = false;
      selectedProductIndex = values.bill_details.length - 1;
    }
    // handle product search based on product id
    GetSearchedData(
      values.role_id as number,
      null,
      values.transaction_type as ReportType,
      productId,
      (productData) => {
        const { id, name, relation, batch_info, tax, product_bar_code } =
          productData[0];

        let recentBillDetails: BillDetailProps[] = [...values.bill_details];
        let updatedSearchString = [...searchStrings];

        // handle add new bill details if product doesn't exist
        if (values.bill_details[0].product_id && !checkProductIsExist) {
          const newAddedData = {
            ...AddData,
            id: id as number,
            product_bar_code: product_bar_code as string,
            relation: relation,
          };

          recentBillDetails = [...recentBillDetails, newAddedData];
          setFieldValue("bill_details", recentBillDetails);

          updatedSearchString = [...updatedSearchString, { title: "" }];
          selectedProductIndex = recentBillDetails.length - 1;
        }

        let updateBillDetailTax = recentBillDetails[selectedProductIndex];
        updateBillDetailTax.tax = parseFloat(tax);

        handleProductSelect(
          productData[0],
          selectedProductIndex,
          updatedSearchString
        );

        const clonedBatches = [...activeProductBatches];
        setActiveBatch(selectedProductIndex);

        const additionKeys = {
          relation: relation as number,
          batch_info: batch_info as BatchInfoResponse[],
        };

        clonedBatches[selectedProductIndex] = additionKeys;
        setActiveProductBatches(clonedBatches);

        // handle batch select of searched product
        let selectedBatchInfo: BatchInfoResponse;

        if (batchId) {
          selectedBatchInfo = batch_info.find(
            (batch) => batch.batch_id === parseInt(batchId)
          ) as BatchInfoResponse;

          if (!selectedBatchInfo) {
            setScannedData("");
            toast.error("Invalid Bar Code");
            return;
          }
        } else {
          selectedBatchInfo = batch_info[0] as BatchInfoResponse;
        }

        HandleSelectBatch(
          selectedProductIndex,
          selectedBatchInfo,
          recentBillDetails
        );
      }
    );
  };

  // trigger bar code scanning events
  useEffect(() => {
    handleBarCodeScan();
  }, [scannedData]);

  //trigger quantity change via barcode
  useEffect(() => {
    if (!scannedData || searchData.length === 0 || activeBatch === null) return;

    let productId = scannedData.includes("/")
      ? scannedData.split("/")[1].replace(/^0+/, "")
      : scannedData;

    const lastSection = scannedData.split("/").pop();
    const lastSectionArray: Array<any> = lastSection?.split("") || [];
    const indexOfLetter = lastSectionArray?.findIndex((each) => isNaN(each));

    const lastDigit = lastSection?.slice(5);
    const secondLastLetter = lastSection?.[indexOfLetter]?.toUpperCase();

    const selectedProductIndex = values.bill_details.findIndex(
      ({ id, product_bar_code }) =>
        id === Number(productId) || product_bar_code === productId
    );
    
    const quantityType = secondLastLetter === "P" ? "Primary" : "Secondary";

    if (scannedData.includes("/")) {
      if (lastDigit) {
        const quantity = parseFloat(lastDigit);
        const handleChange =
          quantityType === "Primary"
            ? handleOnChangePrimaryQuantiy
            : handleOnChangeSecondaryQuantiy;
        handleChange(quantity, selectedProductIndex);
      }
    } else {
      const { relation } = searchData[0];
      const unitType = relation === 1 ? "primary_unit" : "secondary_unit";

      const quantity =
        parseFloat(
          String(values.bill_details[selectedProductIndex].qty[unitType])
        ) + 1;
      const handleChange =
        unitType === "primary_unit"
          ? handleOnChangePrimaryQuantiy
          : handleOnChangeSecondaryQuantiy;
      handleChange(quantity, selectedProductIndex);
    }

    setScannedData("");
  }, [selectedBatches]);

  // ##### Barcode scanned section end ######//
  return (
    <>
      <FieldArray
        name="bill_details"
        render={(arrayHelpers) => (
          <>
            {values.bill_details &&
              values.bill_details.map((val, index) => {
                return (
                  <Fragment key={index}>
                    <tr className="sub-title" key={index}>
                      <td className="small-input product-width side-border symbol-wrapper">
                        <div className="d-flex amount-block">
                          <div className="symbol-number"> {index + 1}</div>
                          <div className="side-border"></div>
                          <div className="auto-complete-search-wrap  prodcut-name">
                            <ReactSearchAutocomplete
                              placeholder="Select product name"
                              formatResult={formatResult}
                              styling={{
                                fontSize: "12px",
                              }}
                              items={generator(searchData as InputContent[])}
                              fuseOptions={{ minMatchCharLength: 2 }}
                              onFocus={(e) => {
                                if (e.target.value.length === 0) return;
                                GetSearchedData(
                                  values.role_id as number,
                                  e.target.value,
                                  values.transaction_type as ReportType
                                );
                                if (e.target.value !== "") {
                                  setActiveBatch(index);
                                } else {
                                  setActiveBatch(null);
                                }
                              }}
                              onSearch={(value: any) => {
                                if (value.length === 0) return;
                                GetSearchedData(
                                  values.role_id as number,
                                  value,
                                  values.transaction_type as ReportType
                                );
                              }}
                              onSelect={(value: any) => {
                                if (value.length === 0) return;
                                handleProductSelect(value, index);
                              }}
                              onClear={() => {
                                handleClear(index);
                                setFieldValue("cash", 0);
                              }}
                              inputDebounce={200}
                              inputSearchString={searchStrings?.[index]?.title}
                            />
                            <ErrorMessage
                              name={`bill_details.${index}.product_id`}
                              className="error"
                              component={"div"}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex multi-input">
                          <div className="table-input">
                            <Field
                              className="input-field first-inp"
                              type="text"
                              name={`bill_details.${index}.qty.primary_unit`}
                              placeholder="Enter quality"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              initialvalues={0}
                              onChange={(e: any) => {
                                handleOnChangePrimaryQuantiy(
                                  e.target.value,
                                  index
                                );
                              }}
                              onFocus={(e: any) => {
                                if (parseInt(e.target.value) === 0) {
                                  e.target.value = "";
                                }

                                if (
                                  values.transaction_type ===
                                  ReportType.Sales
                                ) {

                                  setActiveBatch(index);
                                }
                              }}
                            />
                            <ErrorMessage
                              className="error table-error"
                              component="div"
                              name={`bill_details.${index}.qty.primary_unit`}
                            />
                          </div>

                          <div className="table-input right-input">
                            <Field
                              className="input-field"
                              onKeyPress={(event: any) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              type="text"
                              initialvalues={0}
                              name={`bill_details.${index}.qty.secondary_unit`}
                              placeholder="Enter quality"
                              disabled={
                                selectedBatches[index] &&
                                  Number(selectedBatches[index].relation) === 1
                                  ? true
                                  : false
                              }
                              pattern="[0-9]*"
                              onChange={(e: any) => {
                                handleOnChangeSecondaryQuantiy(
                                  e.target.value,
                                  index
                                );
                              }}
                              onFocus={(e: any) => {
                                if (parseInt(e.target.value) === 0) {
                                  e.target.value = "";
                                }
                                if (
                                  values.transaction_type ===
                                  ReportType.Sales
                                ) {
                                  const ActiveProd = findActiveProd(
                                    //@ts-ignore
                                    activeProductBatches,
                                    index
                                  );
                                  setActiveBatch(index);
                                }
                              }}
                            />
                          </div>
                          <ErrorMessage
                            className="error table-error"
                            component="div"
                            name={`bill_details.${index}.qty.secondary_unit`}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="d-flex multi-input">
                          <div className="table-input">
                            <Field
                              className="input-field first-inp"
                              value={
                                values.bill_details[index].rate.primary_price
                              }
                              placeholder="Enter rate"
                              pattern="[0-9]*"
                              type="text"
                              // disabled
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              onChange={(e: any) => {
                                // if (values.transaction_type === 2) {
                                UpdateFormikValues(
                                  e.target.value,
                                  index,
                                  "rate",
                                  "primary_price"
                                );
                                setFieldValue("cash", 0);
                                // }
                              }}
                              onDoubleClick={(e: any) => {
                                OpenPriceModal(
                                  values.bill_details[index].rate.primary_price,
                                  index
                                );
                              }}
                              onFocus={(e: any) => {
                                if (parseInt(e.target.value) === 0) {
                                  e.target.value = "";
                                }
                                if (
                                  values.transaction_type ===
                                  ReportType.Sales
                                ) {
                                  const ActiveProd = findActiveProd(
                                    //@ts-ignore
                                    activeProductBatches,
                                    index
                                  );
                                  setActiveBatch(index);
                                }
                              }}
                            />
                            <ErrorMessage
                              className="error table-error"
                              component="div"
                              name={`bill_details.${index}.rate.primary_price`}
                            />
                          </div>

                          <div className="table-input right-input">
                            <Field
                              className="input-field"
                              placeholder="Enter rate"
                              type="text"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              disabled={
                                selectedBatches[index] &&
                                Number(selectedBatches[index].relation) == 1
                              }
                              value={
                                selectedBatches[index] &&
                                  Number(selectedBatches[index].relation) === 1
                                  ? 0
                                  : values?.bill_details?.[index]?.rate
                                    .secondary_price
                              }
                              onChange={(e: any) => {
                                // if (values.transaction_type === 2) {
                                UpdateFormikValues(
                                  e.target.value,
                                  index,
                                  "rate",
                                  "secondary_price"
                                );
                                // }
                              }}
                              onDoubleClick={(e: any) => {
                                OpenPriceModal(
                                  values.bill_details[index].rate
                                    .secondary_price,
                                  index
                                );
                              }}
                              onFocus={(e: any) => {
                                if (parseInt(e.target.value) === 0) {
                                  e.target.value = "";
                                }
                                if (
                                  values.transaction_type ===
                                  ReportType.Sales
                                ) {
                                  const ActiveProd = findActiveProd(
                                    //@ts-ignore
                                    activeProductBatches,
                                    index
                                  );
                                  setActiveBatch(index);
                                }
                              }}
                            />
                            <ErrorMessage
                              className="error table-error"
                              component="div"
                              name={`bill_details.${index}.rate.secondary_price`}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="amt-input">
                        <div className="multi-input">
                          <div className="">
                            <ReverseFunction
                              disabled={true}
                              //   selectedProductObj={{}}
                              onChange={(e) => {
                                // handleInput(e, index)
                              }}
                              name="amt"
                              //   setReverseTotalPiece={setReverseTotalPiece}
                              //   setReverseTotalBox={setReverseTotalBox}
                              index={index}
                              placeholder="Enter amount"
                            //   value={inputs[index]?.amt}
                            />
                            <ErrorMessage
                              name={`bill_details.${index}.amt`}
                              className="error"
                              component={"div"}
                            />
                          </div>
                        </div>
                      </td>

                      <td className="discout-input amt-input">
                        <Field
                          className="input-field"
                          type="text"
                          placeholder="Enter discount"
                          initialvalues={0}
                          value={values?.bill_details?.[index]?.discount}
                          onChange={(e: any) => {
                            UpdateFormikValues(
                              e.target.value,
                              index,
                              "discount"
                            );
                            setFieldValue("cash", 0);
                          }}
                          onFocus={(e: any) => {
                            if (parseInt(e.target.value) === 0) {
                              e.target.value = "";
                            }
                            if (
                              values.transaction_type === ReportType.Sales
                            ) {
                              const ActiveProd = findActiveProd(
                                //@ts-ignore
                                activeProductBatches,
                                index
                              );
                              setActiveBatch(index);
                            }
                          }}
                        />
                        {
                          //@ts-ignore
                          errors.bill_details &&
                          errors.bill_details[index] &&
                          //@ts-ignore
                          errors.bill_details[index].discount && (
                            <div className="error table-error">
                              {
                                //@ts-ignore
                                errors.bill_details[index].discount
                              }
                            </div>
                          )
                        }
                        {/* <ErrorMessage
                            className="error table-error"
                            component="div"
                            name={`bill_details.${index}.discount`}
                          /> */}
                      </td>
                      <td>
                        <Field
                          className="input-field vat-field"
                          as="select"
                          value={values.bill_details[index].tax}
                          onChange={(e: any) => {
                            UpdateFormikValues(
                              Number(e.target.value),
                              index,
                              "tax"
                            );
                            setFieldValue("cash", 0);
                          }}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="13">13%</option>
                          <option value="0">No</option>
                        </Field>
                      </td>
                      <td className="amt-input">
                        <input
                          className="input-field"
                          disabled={true}
                          value={values?.bill_details?.[index]?.sub_amt}
                        />
                      </td>

                      {values.bill_details.length > 1 && (
                        <div className="remove-block">
                          <button
                            onClick={() => {
                              handleRemoveProduct(arrayHelpers, index);
                            }}
                            className="remove-btn"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="remove"
                                d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                                fill="#B11D1D"
                              />
                              <path
                                className="remove"
                                d="M5.80715 5.8074C5.86521 5.74919 5.93418 5.70301 6.01011 5.67151C6.08604 5.64 6.16744 5.62378 6.24965 5.62378C6.33186 5.62378 6.41326 5.64 6.48919 5.67151C6.56513 5.70301 6.6341 5.74919 6.69215 5.8074L9.99965 9.11615L13.3072 5.8074C13.3653 5.74929 13.4342 5.70319 13.5102 5.67174C13.5861 5.64029 13.6675 5.62411 13.7497 5.62411C13.8318 5.62411 13.9132 5.64029 13.9891 5.67174C14.0651 5.70319 14.134 5.74929 14.1922 5.8074C14.2503 5.86551 14.2964 5.93449 14.3278 6.01042C14.3593 6.08634 14.3754 6.16772 14.3754 6.2499C14.3754 6.33208 14.3593 6.41345 14.3278 6.48938C14.2964 6.5653 14.2503 6.63429 14.1922 6.6924L10.8834 9.9999L14.1922 13.3074C14.2503 13.3655 14.2964 13.4345 14.3278 13.5104C14.3593 13.5863 14.3754 13.6677 14.3754 13.7499C14.3754 13.8321 14.3593 13.9135 14.3278 13.9894C14.2964 14.0653 14.2503 14.1343 14.1922 14.1924C14.134 14.2505 14.0651 14.2966 13.9891 14.3281C13.9132 14.3595 13.8318 14.3757 13.7497 14.3757C13.6675 14.3757 13.5861 14.3595 13.5102 14.3281C13.4342 14.2966 13.3653 14.2505 13.3072 14.1924L9.99965 10.8836L6.69215 14.1924C6.63404 14.2505 6.56506 14.2966 6.48913 14.3281C6.41321 14.3595 6.33183 14.3757 6.24965 14.3757C6.16747 14.3757 6.0861 14.3595 6.01017 14.3281C5.93425 14.2966 5.86526 14.2505 5.80715 14.1924C5.74904 14.1343 5.70295 14.0653 5.6715 13.9894C5.64005 13.9135 5.62386 13.8321 5.62386 13.7499C5.62386 13.6677 5.64005 13.5863 5.6715 13.5104C5.70295 13.4345 5.74904 13.3655 5.80715 13.3074L9.1159 9.9999L5.80715 6.6924C5.74895 6.63434 5.70277 6.56537 5.67126 6.48944C5.63975 6.41351 5.62354 6.33211 5.62354 6.2499C5.62354 6.16769 5.63975 6.08629 5.67126 6.01035C5.70277 5.93442 5.74895 5.86545 5.80715 5.8074Z"
                                fill="#B11D1D"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
            <tr>
              <td>
                <div className="add-button">
                  <button
                    className="light-primary content"
                    onClick={() => {
                      arrayHelpers.push(AddData);
                      setSearchStrings((p) => [...p, { title: "" }]);
                    }}
                  >
                    <span>
                      <img
                        alt="plus"
                        src={require("../../assets/images/plus-16.png")}
                      />
                    </span>
                    Add
                  </button>
                </div>
              </td>
            </tr>
          </>
        )}
      />
      {editPriceModal && (
        <>
          {" "}
          <PopUp
            popupWidth={680}
            title="Price Edit Modal"
            renderComponent={
              <UpdatePrice
                index={updateIndex as number}
                values={values}
                productId={updateBatchValue.id}
                relation={updateBatchValue.relation}
                priPrice={
                  values?.bill_details?.[updateIndex as number]?.rate
                    .primary_price
                }
                secPrice={
                  values?.bill_details?.[updateIndex as number]?.rate
                    .secondary_price
                }
              />
            }
          />
        </>
      )}
    </>
  );
};
