import { NepaliDatePicker } from "datepicker-nepali-reactjs";
import React, { useEffect, useState } from "react";
import {
  checkNumber,
  deepClone,
  formatSelectOptions,
  UpdatedFormatOptions,
} from "../../utils/services";
import { GetBankName, useAllBanks, useSalesBankNames } from "../Api/Get/GetApi";
import "./AddableBank.scss";
import { BankValues } from "../ProductBill/ProductBill";
import { SelectOptions } from "../../Pages/SalesReturn/types";
import Creatable from "react-select/creatable";
import { BillReturnType } from "../../EnumValues";
import { SalesBankNames } from "../Api/Get/GetApiTypes";
import ReactSelect from "react-select";

interface BankDetailsPageProps {
  bankDetail: BankValues[];
  setBankDetail: React.Dispatch<React.SetStateAction<BankValues[]>>;
  returnType?: BillReturnType;
}

export const BankDetailsPage: React.FC<BankDetailsPageProps> = ({
  bankDetail,
  setBankDetail,
  returnType = BillReturnType.Sales,
}) => {
  const [banks, setBanks] = useState<SelectOptions[]>();
  const [selectedBank, setSelectedBank] = useState<SelectOptions[] | null[]>([
    null,
  ]);
  const { data: bankNames } = useAllBanks(returnType);

  const { data: salesBankName } = useSalesBankNames(returnType);

  const UpdateBankValues = (
    index: number,
    key: keyof BankValues,
    value: number | string
  ) => {
    const cloned = deepClone(bankDetail);
    //@ts-ignore
    cloned[index][key] = value;
    setBankDetail(cloned);
  };

  useEffect(() => {
    if (returnType === BillReturnType.Purchase && salesBankName) {
      const options = UpdatedFormatOptions<SalesBankNames>(
        salesBankName,
        "bank_name",
        "bank_name"
      );
      setBanks(options);
    }
  }, [salesBankName]);

  useEffect(() => {
    if (returnType === BillReturnType.Sales && bankNames) {
      const options = formatSelectOptions(bankNames);

      setBanks(options);
    }
  }, [bankNames]);

  const [bankData, setBankData] = useState<Array<SelectOptions>>();

  useEffect(() => {
    GetBankName().then((res) => {
      const updatedData = formatSelectOptions<SelectOptions>(res.data.data);
      setBankData(updatedData);
    });
  }, []);

  return (
    <>
      <div className="head-section bank-details-head">
        <p className="content">Bank Details</p>
        {/* <button type="button" className="line-primary c-button">
          <span>
            <PlusIcon />
          </span>
          Add Details
        </button> */}
      </div>
      {bankDetail.map((val, index) => {
        return (
          <div key={index} className="bank-detail-page">
            <div className="row">
              <div className="col-md-6">
                <label className="input-label">Select Bank</label>
                {returnType === BillReturnType.Purchase ? (
                  <Creatable
                    isClearable={true}
                    value={selectedBank[index]}
                    //@ts-ignore
                    options={banks as SelectOptions[]}
                    name="bank_id"
                    onChange={(e: any) => {
                      const newArr = [...selectedBank];
                      newArr[index] = e;
                      const notNull = e !== null;
                      setSelectedBank(newArr as SelectOptions[]);
                      if (typeof e?.value === "string") {
                        UpdateBankValues(
                          index,
                          "bank_id",
                          notNull ? e.label : ""
                        );
                      } else {
                        UpdateBankValues(
                          index,
                          "bank_id",
                          notNull ? e.value : ""
                        );
                      }
                    }}
                  />
                ) : (
                  <ReactSelect
                    isClearable={true}
                    styles={{
                      control: (base) => ({
                        ...base,
                        fontSize: "12px",
                      }),
                      menu: (base) => ({
                        ...base,
                        fontSize: "12px",
                      }),
                    }}
                    value={selectedBank[index]}
                    options={bankData}
                    name="bank_id"
                    onChange={(e: any) => {
                      const newArr = [...selectedBank];
                      newArr[index] = e;
                      setSelectedBank(newArr as SelectOptions[]);
                      UpdateBankValues(
                        index,
                        "bank_id",
                        e == null ? 0 : e.value,
                      );
                    }}
                  />
                  // <Select
                  //   className="input-field"
                  //   value={bankDetail[index].bank_id}
                  //   onChange={(e) => {
                  //     UpdateBankValues(index, "bank_id", e.target.value)
                  //   }}
                  // >
                  //   <option value="">Select bank</option>
                  //   {banks?.map((val) => {
                  //     return (
                  //       <>
                  //         <option key={val.value} value={val.value}>
                  //           {val.label}
                  //         </option>
                  //       </>
                  //     )
                  //   })}
                  // </Select>
                )}
              </div>
              <div className="col-md-6">
                <label className="input-label">Cheque No.</label>
                <input
                  className="input-field"
                  placeholder="Enter cheque no"
                  onChange={(e) => {
                    UpdateBankValues(index, "cheque_no", e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 date-section">
                <label className="input-label">Cheque Issue Date</label>

                <NepaliDatePicker
                  className="input-field"
                  placeholder="Select Date"
                  showResetDateButton={true}
                  // value={new Date(values.date)}
                  onDateSelect={(value: any) => {
                    UpdateBankValues(index, "cheque_issue_date", value);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="input-label">Amount</label>
                <input
                  className="input-field"
                  placeholder="Enter Amount"
                  type="text"
                  onChange={(e) => {
                    let value = checkNumber(e.target.value, "decimalToo");
                    UpdateBankValues(index, "amount", value);
                  }}
                  value={bankDetail[index].amount}
                  onWheel={(e: any) => {
                    (e.target as HTMLInputElement).blur();
                  }}
                  onFocus={(event: any) => {
                    if (parseInt(event.target.value) === 0) {
                      event.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
