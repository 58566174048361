import React from "react";

interface PopupContextType {
  closeModal: () => void;
  openModal: () => void;
  isModalOpen: boolean;
  isModalOpens: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpens: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValues = {
  closeModal: () => {},
  openModal: () => {},
  isModalOpen: false,
  isModalOpens: false,
  setIsModalOpen: () => {},
  setIsModalOpens: () => {},
};
interface PopupProps {
  children: any;
}

export const PopupContext =
  React.createContext<PopupContextType>(initialValues);

export const PopupContextProvider: React.FC<PopupProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpens, setIsModalOpens] = React.useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    //Scrolling problem
    document.body.classList.remove("ReactModal__Body--open");
    setIsModalOpen(false);
  };
  
  return (
    <PopupContext.Provider
      value={{
        openModal,
        closeModal,
        isModalOpen,
        isModalOpens,
        setIsModalOpen,
        setIsModalOpens,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
