import { useState } from "react";
import { useVatTableList } from "../Api/Get/GetApi";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import { Select } from "../Select";
import "./VatReport.scss";
import {
  checkAmounttType,
  parenthesisInNegativeValue,
} from "../../utils/services";
import { useAuth } from "../context/auth/auth";
import { UserInfoResponse } from "../context/auth/types";
import { ExcelIcon, PrintIcon } from "../../assets/images/svg/svg";
import { financialReportPrint, nestedTableExcel } from "../../utils/utils";
import { useLocation } from "react-router-dom";

export const VatReport = () => {
  const [monthId, setMonthId] = useState("");
  const [monthLabel, setMonthLabel] = useState("");
  const { data } = useVatTableList(monthId);

  const location = useLocation();
  const { businessUserInfo } = useAuth();

  const MonthOptions = [
    {
      value: "1",
      label: "Baisakh",
    },
    {
      value: "2",
      label: "Jestha",
    },
    {
      value: "3",
      label: "Ashad",
    },
    {
      value: "4",
      label: "Shrawan",
    },
    {
      value: "5",
      label: "Bhadra",
    },
    {
      value: "6",
      label: "Ashoj",
    },
    {
      value: "7",
      label: "Kartik",
    },
    {
      value: "8",
      label: "Mangsir",
    },
    {
      value: "9",
      label: "Poush",
    },
    {
      value: "10",
      label: "Magh",
    },
    {
      value: "11",
      label: "Falgun",
    },
    {
      value: "12",
      label: "Chaitra",
    },
  ];

  const OpeningBalance = data?.vat_opening_balance;
  const ClosingBalance = data?.vat_closing_balance;

  const toDate = "0";
  const fromDate = "0";
  const col_Span = 10;
  const uptoDate = monthLabel;

  return (
    <div className="vat-report-page">
      <div className="head-section">
        <div className="small-title">Vat Report</div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date" className="input-label">
                Month
              </label>
              <Select
                onChange={(e) => {
                  // select lable
                  const selectedOption =
                    e.target.options[e.target.selectedIndex];
                  const label = selectedOption.innerText;
                  setMonthId(e.target.value);
                  setMonthLabel(label);
                }}
              >
                <option value="">Select Month</option>
                {MonthOptions.map((val, idx) => {
                  return (
                    <option key={idx} value={val.value}>
                      {val.label}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-2">
              <div className="d-flex gap-3">
                <button
                  className="vat-print btn btn-light"
                  onClick={() => {
                    if (uptoDate !== undefined) {
                      nestedTableExcel(
                        "exportTable",
                        location,
                        businessUserInfo as UserInfoResponse,
                        fromDate,
                        toDate,
                        col_Span,
                        uptoDate
                      );
                    }
                  }}
                >
                  <span className="export-title"> Export</span>
                  <ExcelIcon />
                </button>
                <button
                  className="d-flex btn btn-light my-4 "
                  onClick={() => {
                    financialReportPrint(
                      [],
                      businessUserInfo as UserInfoResponse,
                      "",
                      "exportTable",
                      location
                    );
                  }}
                >
                  <span
                    style={{ marginRight: "10px" }}
                    className="export-title"
                  >
                    Print{" "}
                  </span>
                  <PrintIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        {!monthId ? (
          <NoDataPage />
        ) : (
          <>
            {data && data?.purchase_vat_report?.length > 0 ? (
              <div className="table-section mg-top-40" id="exportTable">
                <table className="main-table table">
                  <thead>
                    <tr>
                      <th className="content width-50">
                        Opening Balance:
                        <span className="black-color content">
                          {checkAmounttType(OpeningBalance)}
                        </span>
                      </th>
                      <th className="content width-50">
                        Closing Balance:
                        <span className="black-color content">
                          {checkAmounttType(ClosingBalance)}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="padd-0 width-50">
                        <table className="small-table table">
                          <thead className="table-header">
                            <tr>
                              <th className="content-main-title">
                                Vat Receivable
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td className="padd-0">
                                <table className="table">
                                  <thead className="table-header">
                                    <tr>
                                      <th className="content width-10 ">S.N</th>
                                      <th className="content width-20 ">
                                        Date
                                      </th>
                                      <th className="content width-30 ">
                                        Bill
                                      </th>
                                      <th className="content width-20 ">Vat</th>
                                      <th className="content amt-col width-20 ">
                                        Balance
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.purchase_vat_report.map(
                                      (item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="small-content">
                                              {index + 1}
                                            </td>
                                            <td className="small-content">
                                              {item?.nepali_date}
                                            </td>
                                            <td className="small-content bill-section">
                                              {item?.bill_number}
                                            </td>
                                            <td className="small-content">
                                              {parenthesisInNegativeValue(
                                                item?.vat
                                              )}
                                            </td>

                                            <td className="small-content">
                                              {parenthesisInNegativeValue(
                                                item?.balance
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="padd-0 width-50">
                        <table className="small-table table">
                          <thead className="table-header">
                            <tr>
                              <th className="content-main-title width-50">
                                Vat Payable
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td className="padd-0">
                                <table>
                                  <thead className="">
                                    <tr>
                                      <th className="content width-10 ">S.N</th>
                                      <th className="content width-20 ">
                                        Date
                                      </th>
                                      <th className="content width-30 ">
                                        Bill
                                      </th>
                                      <th className="content width-20 ">Vat</th>
                                      <th className="content amt-col width-20">
                                        Balance
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.sales_vat_report.map(
                                      (item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="small-content">
                                              {index + 1}
                                            </td>
                                            <td className="small-content">
                                              {item?.nepali_date}
                                            </td>
                                            <td className="small-content">
                                              {item?.document_number}
                                            </td>
                                            <td className="small-content">
                                              {parenthesisInNegativeValue(
                                                item?.vat
                                              )}
                                            </td>

                                            <td className="small-content">
                                              {parenthesisInNegativeValue(
                                                item?.balance
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <NoDataPage />
            )}
          </>
        )}
      </div>
    </div>
  );
};
