import { OverDueCalender } from "../../assets/images/svg/svg";
import { useReminderNotice } from "../Api/Get/GetApi";
import { Loader } from "../Loader/Loader";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import "./ReminderPage.scss";

export const ReminderPage = () => {
  const description = 'Looks like there are no entries here yet.'
  const { data, isLoading } = useReminderNotice();
  return (
    <div className="reminder-page">
      <div className="row">
        <div className="head-section">
          <div className="col-md-12">
            <div className="small-title">Reminder</div>
          </div>
        </div>
        {isLoading ? (
          <>
            <Loader loaderType="Spinner" />
          </>
        ) : (
          <>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">Who do I owe</div>
                {data?.PUR ? (
                  data?.PUR?.map((item, index) => {
                    return (
                      <div className="card-row">
                        <div className="card-title-section">
                          <div className="card-title">
                            {item?.user_info.name}
                          </div>
                          <div className="high-light">
                            <div className="calender">
                              <OverDueCalender />
                            </div>
                            <div className="content red">
                              {item?.due_day_count} days overdue
                            </div>
                          </div>
                        </div>
                        <div className="card-body" key={index}>
                          <div className="content green">
                            Rs.{item?.unresolved_balance}
                          </div>
                          <div className="content green">
                            {item?.bill_number}
                          </div>
                          <div className="content">due on {item?.due_date}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoDataPage description={description}/>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">Who owe us</div>

                {data?.SAL ? (
                  data?.SAL?.map((item, index) => {
                    return (
                      <div className="card-row">
                        <div className="card-title-section">
                          <div className="card-title">
                            {item?.user_info.name}
                          </div>
                          <div className="high-light">
                            <div className="calender">
                              <OverDueCalender />
                            </div>
                            <div className="content red">
                              {item?.due_day_count} days overdue
                            </div>
                          </div>
                        </div>
                        <div className="card-body" key={index}>
                          <div className="content green">
                            Rs.{item?.unresolved_balance}
                          </div>
                          <div className="content green">
                            {item?.document_number}
                          </div>
                          <div className="content">due on {item?.due_date}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoDataPage description={description}/>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
