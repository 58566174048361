import { Field } from "formik";
import React, { useEffect } from "react";
import { ProductPrice } from "../ProductPage/ProductPage";
import { useNewBillFormikContext } from "../BillPage/BillFormikWrapper";
import { UpdatePrice } from "../UpdatePrice/UpdatePrice";

interface ReverseFunProps {
  selectedProductObj?: ProductPrice;
  labelName?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  name?: string;
  value?: any;
  disabled?: boolean;
  index: any;
  setReverseTotalBox?: React.Dispatch<React.SetStateAction<number>>;
  setReverseTotalPiece?: React.Dispatch<React.SetStateAction<number>>;
}

export const ReverseFunction: React.FC<ReverseFunProps> = ({
  selectedProductObj,
  labelName,
  placeholder,
  onChange,
  name,
  value,
  disabled,
  index,
  setReverseTotalBox,
  setReverseTotalPiece,
}) => {
  const { values, setFieldValue } = useNewBillFormikContext();

  const CheckIfZero = (value: number | string) => {
    if (value === 0 || value === "") {
      return 1;
    }
    return Number(value);
  };

  const UpdateAmount = (index: number) => {
    const newArr = [...values.bill_details];
    let primaryUnit = CheckIfZero(newArr[index].qty.primary_unit);
    let secondaryUnit = CheckIfZero(newArr[index].qty.secondary_unit);
    let primaryPrice = CheckIfZero(newArr[index].rate.primary_price);
    let secondaryPrice = CheckIfZero(newArr[index].rate.secondary_price);
    let totalAmount =
      primaryUnit * primaryPrice + secondaryUnit * secondaryPrice;
    newArr[index].amt = totalAmount;
    setFieldValue("bill_details", newArr);
  };

  const amountChanged = () => {
    let amountForReverse = values?.bill_details?.[index]?.amt;
    let primaryPrice =
      (amountForReverse ?? 0) / (selectedProductObj?.primary_price ?? 0);
    let totalBox = primaryPrice.toString().split(".")[0];

    let secUnit = (selectedProductObj?.primary_price ?? 0) * Number(totalBox);
    let secPriceCal = (amountForReverse ?? 0) - secUnit;
    let totalPiece = secPriceCal / (selectedProductObj?.secondary_price ?? 0);
    setReverseTotalBox?.(parseFloat(totalBox));
    setReverseTotalPiece?.(totalPiece);
  };

  const ReverseOnAmountChange = (index: number, value: number) => {
    const tempArray = [...values.bill_details];
    tempArray[index].amt = value;
    let primaryPrice = tempArray[index].rate.primary_price;
    let secondaryPrice = tempArray[index].rate.secondary_price;
    let primaryUnit = value / primaryPrice;
    let primaryRemainder = value % primaryPrice;
    tempArray[index].qty.primary_unit = Math.floor(primaryUnit);
    if (primaryRemainder === 0) {
      tempArray[index].qty.secondary_unit = 0;
    } else {
      let secondaryUnit = primaryRemainder / secondaryPrice;
      tempArray[index].qty.secondary_unit = Math.floor(secondaryUnit);
    }
    setFieldValue("bill_details", tempArray);
  };

  // useEffect(() => {
  //   amountChanged()
  //   UpdateAmount(index)
  // }, [values?.bill_details?.[index].qty.primary_unit])

  return (
    <div className="reverseFunction">
      <div>
        {labelName && <label className="input-label">{labelName}</label>}
        <Field
          className="input-field"
          disabled={values.bill_details[index].product_id === 0 || disabled}
          pattern="[0-9]*"
          placeholder={placeholder}
          onFocus={(e: any) => {
            if (parseInt(e.target.value) === 0) {
              e.target.value = "";
            }
          }}
          value={values.bill_details[index].amt}
          onKeyPress={(event: any) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e: any) => {
            ReverseOnAmountChange(index, Number(e.target.value));
          }}
        />
      </div>
    </div>
  );
};
