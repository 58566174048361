import React, { useCallback, useContext, useRef, useState } from "react";
import { DontShowIcon, ShowIcon } from "../../assets/images/svg/svg";
import { PostLoginPage } from "../Api/Post/PostApi";
import { PopupContext } from "./Popup-Context";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { useAuth } from "../context/auth/auth";
import "./PasswordPopup.scss";

function useFocusNext() {
  const controls = useRef([]);

  const handler = (event: any) => {
    if (event.keyCode === 13) {
      //@ts-ignore
      const index = controls.current.indexOf(event.target);
      const next = controls.current[index + 1];
      const current = controls.current[index];
      //@ts-ignore
      if (current.type !== "submit") {
        //@ts-ignore
        next && next.focus();
        event.preventDefault();
      }
    }
  };
  return useCallback((element: any) => {
    //@ts-ignore
    if (element && !controls.current.includes(element)) {
      //@ts-ignore
      controls.current.push(element);
      element.addEventListener("keydown", handler);
    }
  }, []);
}
export interface RequiredParams {
  mobile_no: string;
  password: string;
}

interface PasswordPopupProps {
  setPasswordPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setPrintModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PasswordPopup: React.FC<PasswordPopupProps> = ({
  setPasswordPopup,
  setCancelPopUp,
  setPrintModal,
}) => {
  const [handleShow, setHandleShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const focusNextRef = useFocusNext();
  const { businessUserInfo } = useAuth();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const [passwordVal, setPasswordVal] = useState("");
  const { closeModal, openModal } = useContext(PopupContext);

  const handleSubmit = (password: string, mobile: string) => {
    setIsSubmitting(true);
    const postData = { mobile_no: mobile, password: password };
    PostLoginPage(postData)
      .then((res) => {
        showSuccessMessage("Verification successful");
        setIsSubmitting(false);
        setPasswordPopup?.(false);
        setCancelPopUp(true);
        setPrintModal?.(false);
        openModal();
      })
      .catch((err: any) => {
        let errorMessage = "Failed to Login !!";
        if (err?.response?.data?.status?.status_message) {
          errorMessage = err?.response?.data?.status?.status_message;
        }
        showErrorMessage(errorMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="password-page">
      <div className="title-box">
        <p className="block-title">
          <span className="gray">
            To edit password, enter the password for{" "}
          </span>
          {businessUserInfo && businessUserInfo.user_info.mobile_no}
        </p>
      </div>
      <div className="col-md-12">
        <div className="field-block password-area">
          <label className="input-label">Password</label>
          <input
            autoComplete="off"
            role="presentation"
            className="input-field"
            name="password"
            type={handleShow ? "text" : "password"}
            placeholder="Enter password"
            onChange={(e: any) => {
              setPasswordVal(e.target.value);
            }}
            ref={focusNextRef}
          />
          <div
            className="eye-section"
            onClick={() => {
              setHandleShow(!handleShow);
            }}
          >
            {handleShow ? <ShowIcon /> : <DontShowIcon />}
          </div>
        </div>
      </div>
      <div className="btn-right">
        <div className="d-end">
          <div>
            <button
              className="light-secondary mr-16"
              type="button"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="main-button"
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit(
                  passwordVal,
                  businessUserInfo?.user_info?.mobile_no as string
                );
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
