import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CrossIcon,
  EditIcon,
  PlusIcon,
  ThreeDotsIcon,
} from "../../assets/images/svg/svg";
import { NewUser } from "../NewUser/NewUserModal";
import { PopUp } from "../PopUp/PopUp";
import { PopupContext } from "../PopUp/Popup-Context";

import { handleNoData } from "../../utils/services";
import { useStaffList, useStaffDetail } from "../Api/Get/GetApi";
import { Table } from "../CustomReactTable/CustomReactTable";
import { AddUserWrapper, SubmitType } from "../NewUser/NewUserWrapper";
import {
  StaffDetailResponse,
  StaffListResponse,
} from "../Api/types";
import "./StaffManagementTable.scss";
import { Dropdown } from "react-bootstrap";
import { DeleteStaff } from "../Api/Delete/DeleteApi";
import { ConfirmPw } from "../ConfrimPw/ConfirmPw";
import { domain } from "../config/url";
import { useToast } from "../context/ToasterContext/ToasterContext";
import "./StaffManagementTable.scss";
import { PasswordPopup } from "../PopUp/PasswordPopup";
import { ConfirmationPopup } from "../PopUp/ConfirmationPopup";

export interface PopUpFlags {
  addStaff: boolean;
  editStaff: boolean;
  deleteStaff: boolean;
  editPassword: boolean;
}

const updatePopUpFlag = (
  showPopup: PopUpFlags,
  setShowPopup: React.Dispatch<React.SetStateAction<PopUpFlags>>,
  updateKey?: keyof PopUpFlags
) => {
  const prevState = { ...showPopup };
  if (updateKey) {
    const nextState: PopUpFlags = {} as PopUpFlags;
    Object.keys(prevState).forEach((key) => {
      if (key !== updateKey) {
        nextState[key as keyof PopUpFlags] = false;
      }
    });
    nextState[updateKey] = true;
    setShowPopup(nextState);
    return;
  }
  const nextState: PopUpFlags = {} as PopUpFlags;
  Object.keys(prevState).forEach((key) => {
    nextState[key as keyof PopUpFlags] = false;
  });
  setShowPopup(nextState);
};

export const StaffManagmentTable = () => {
  //useState Hooks
  const [queryPageIndex, setQueryPageIndex] = useState(1);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [showPopup, setShowPopup] = useState({
    addStaff: false,
    editStaff: false,
    deleteStaff: false,
    editPassword: false,
  });
  const [staffId, setStaffId] = useState<number>();
  const [detailStaffId, setDetailStaffId] = useState<number>();
  const [responseDetail, setResponseDetail] = useState<StaffDetailResponse>();
  const [activeStaff, setActiveStaff] = useState("");
  const [showConfirmPwPopup, setShowConfirmPwPopup] = useState(false);
  //Context Values
  const { openModal, closeModal } = useContext(PopupContext);
  const { showSuccessMessage, showErrorMessage } = useToast();

  //useQueries
  const { data: staffs, isLoading, refetch: staffListRefetch } = useStaffList();
  const { data: staffDetail } =
    useStaffDetail(detailStaffId);

  useEffect(() => {
    if (staffDetail) {
      setResponseDetail(staffDetail);
    }
  }, [staffDetail]);

  const HandleDelete = (value: any) => {
    setStaffId(value.id);
    updatePopUpFlag(showPopup, setShowPopup, "deleteStaff");
    openModal();
    setActiveStaff(value.name);
  };
  const HandleEditPass = (value: any) => {
    setDetailStaffId(value.id);
    setActiveStaff(value.name);
    updatePopUpFlag(showPopup, setShowPopup, "editPassword");
    // set verification popup false to show Change Password
    setShowConfirmPwPopup(false);
    openModal();
  };
  const HandleEdit = (value: any) => {
    setDetailStaffId(value.id);
    updatePopUpFlag(showPopup, setShowPopup, "editStaff");
    openModal();
  };

  const FormatTable = (array: StaffListResponse[]) => {
    if (array && array.length !== 0) {
      const formatData = array?.map(
        (item: StaffListResponse, index: number) => {
          return {
            sn: index + 1,
            name: {
              name: item.name,
              photo: item.photo,
            },
            email: handleNoData(item.email),
            mobile_no: handleNoData(item.mobile_no),
            address: item.additional_info
              ? item.additional_info.address
                ? handleNoData(item.additional_info.address)
                : "-"
              : "-",
            pan: handleNoData(item.pan),
            roles: item?.roles?.[0]?.role_name,
            is_delete: item.is_delete,
            action: {
              id: item.id,
              name: item.name,
              is_delete: item.is_delete,
            },
          };
        }
      );
      return formatData;
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: "SN.",
        accessor: "sn",
      },

      {
        Header: "Full Name",
        accessor: "name",
        Cell: ({ value }: { value: { name: string; photo: string } }) => {
          return (
            <div className="d-flex gap-2">
              <div className="logo-section d-center">
                {value.photo ? (
                  <img src={`${domain.concat(value.photo)}`} alt="" />
                ) : (
                  <p className="extra-small-title user-name">
                    {value?.name?.charAt(0).toUpperCase()}
                  </p>
                )}
              </div>
              <p>{value.name}</p>
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile no",
        accessor: "mobile_no",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Pan No",
        accessor: "pan",
      },
      {
        Header: "Roles",
        accessor: "roles",
      },
      {
        Header: "Active",
        accessor: "is_delete",
        Cell: (item: any) => {
          return <div>{item.value && item.value ? "False" : "True"}</div>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({
          value,
        }: {
          value: { id: number; name: string; is_delete: boolean };
        }) => {
          return (
            <div className="action-section">
              <Dropdown>
                <Dropdown.Toggle
                  className="dropdown-icon"
                  id="dropdown-basic"
                  disabled={value.is_delete === true ? true : false}
                >
                  <ThreeDotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      HandleEdit(value);
                    }}
                  >
                    <div className="dropdown-text">
                      <span className="dropdown-eye">
                        <EditIcon />
                      </span>
                      Edit
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      HandleEditPass(value);
                    }}
                  >
                    <div className="dropdown-text">
                      <span className="dropdown-eye">
                        <EditIcon />
                      </span>
                      Edit Password
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      HandleDelete(value);
                    }}
                  >
                    <div className="dropdown-text">
                      <span className="">
                        <CrossIcon />
                      </span>
                      Delete
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const HandleDeleteUser = (id: number) => {
    DeleteStaff(id)
      .then((res) => {
        showSuccessMessage(res.data.status.status_message);
        staffListRefetch();
        closeModal();
      })
      .catch((err) => {
        showErrorMessage("Something went wrong deleting user");
      });
    return;
  };

  useEffect(() => {
    if (staffs?.data) {
      setQueryPageSize(staffs.data.length);
    }
  }, [staffs]);

  return (
    <div className="managment-table-page">
      <div className="head-section">
        <h2 className="small-title">Staff Management</h2>
        <button
          className="light-primary c-button"
          onClick={() => {
            updatePopUpFlag(showPopup, setShowPopup, "addStaff");
            openModal();
          }}
          type="button"
        >
          <span className="plus-icon">
            <PlusIcon />
          </span>
          Add New Staff
        </button>
        {/* Add staff popup */}
        {showPopup.addStaff && (
          <PopUp
            title="New Staff"
            popupWidth={800}
            closeOnClickOutside={false}
            renderComponent={
              <AddUserWrapper>
                <NewUser />
              </AddUserWrapper>
            }
          />
        )}
        {/* edit password on click*/}
        {/* edit staff password popup */}
        {showPopup.editPassword && (
          <>
            <PopUp
              popupWidth={400}
              closeOnClickOutside={false}
              renderComponent={
                <PasswordPopup setCancelPopUp={setShowConfirmPwPopup} />
              }
            />
          </>
        )}
        {/* after verification password */}
        {/* Verification PopUp for new password */}
        {showConfirmPwPopup && (
          <PopUp
            popupWidth={400}
            closeOnClickOutside={false}
            renderComponent={
              <ConfirmPw
                isStaff={true}
                activeStaff={activeStaff}
                id={detailStaffId}
              />
            }
          />
        )}

        {/* edit staff popup */}
        {showPopup.editStaff && (
          <PopUp
            title="Edit Staff"
            popupWidth={800}
            closeOnClickOutside={false}
            renderComponent={
              <AddUserWrapper
                submitType={SubmitType.Edit}
                userId={detailStaffId}
              >
                <NewUser
                  initialValues={responseDetail as StaffDetailResponse}
                />
              </AddUserWrapper>
            }
          />
        )}
        {/* delete staff */}
        {showPopup.deleteStaff && (
          <PopUp
            title={`Confirmation`}
            renderComponent={
              <ConfirmationPopup
                handleCancel={() => {
                  HandleDeleteUser(staffId as number);
                }}
                confirmationQuestion={`Are you sure you want to delete ${
                  activeStaff.toUpperCase() || ""
                }?`}
              />
            }
          />
        )}
      </div>

      <Table
        loading={isLoading}
        columns={columns}
        data={staffs?.data ? FormatTable(staffs.data) : []}
        totalPages={1}
        showSelect={false}
        totalEntries={staffs && staffs.data.length}
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
        showPagination={false}
        showPageSize={false}
      />
    </div>
  );
};
