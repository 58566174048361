import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SignIn, SignOut } from "../../Api/Get/GetApi";
import { PopupContext } from "../../PopUp/Popup-Context";
import { SettingPageContext } from "../../SettingPage/Context/SettingPageContext";
import { UserInfoResponse } from "./types";
interface IAuthContext {
  isAuthenticated: boolean;
  signIn: () => void;
  signOut: () => void;
  signUp: (email: string, password: string) => Promise<Boolean>;
  isLoading: boolean;
  setBusinessUserInfo: React.Dispatch<
    React.SetStateAction<UserInfoResponse | undefined>
  >;
  businessUserInfo: UserInfoResponse | undefined;
  businessPopUp: boolean;
  setBusinessPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  signIn: async () => {},
  signOut: () => {},
  signUp: async (email: string, password: string) => false,
  isLoading: false,
  setBusinessUserInfo: () => {},
  businessUserInfo: {} as UserInfoResponse,
  businessPopUp: false,
  setBusinessPopUp: () => false || true,
});
interface AuthContextProps {
  children: any;
}

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [businessUserInfo, setBusinessUserInfo] = useState<UserInfoResponse>();
  const [businessPopUp, setBusinessPopUp] = useState(false);
  const Navigate = useNavigate();
  const { openModal } = useContext(PopupContext);
  const { setSettingValues } = useContext(SettingPageContext);

  const signIn = async () => {
    const signedInStatus: any = {};
    try {
      setIsLoading(true);
      const response = await SignIn();
      if (response.status === 200) {
        if (Object.keys(response.data.data.business_info).length < 1) {
          setBusinessPopUp(true);
          openModal();
        }
        setBusinessUserInfo(response.data.data);
        setSettingValues(response.data.data.setting_info);
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem(
          "language",
          response.data.data.setting_info.language
        );
        localStorage.setItem(
          "currentFiscalYear",
          response.data.data.fiscal_year_info.current_fiscal_year
        );
        localStorage.setItem(
          "firstFiscalDate",
          response.data.data.fiscal_year_info.first_fiscal_date.replace(
            /\//g,
            "-"
          )
        );
        localStorage.setItem(
          "lastFiscalDate",
          response.data.data.fiscal_year_info.last_fiscal_date.replace(
            /\//g,
            "-"
          )
        );
        signedInStatus["isAuthenticated"] = true;
        setIsLoading(false);

        return signedInStatus;
      }
    } catch (error: any) {
      setIsAuthenticated(false);
      localStorage.setItem("isAuthenticated", "false");
      setIsLoading(false);
      localStorage.clear();
      return signedInStatus;
    }
  };

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const signOut = async () => {
    try {
      await SignOut();
      setIsAuthenticated(false);
      localStorage.clear();
      Navigate("/login");
    } catch (error: any) {
      let message = "Failed to Sign out";
      if (typeof error.message === "string") {
        message = error.message;
      }
      if (typeof error?.response?.data?.message === "string") {
        message = error.response.data.message;
      }
      showErrorMessage(message);
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      //   await SignUp(email, password);
      toast.success("Successfully Signed up");
      Navigate("/");
      return true;
    } catch (error: any) {
      let errorMessage = "Failed to Sign up";
      if (typeof error.message === "string") {
        errorMessage = error.message;
      }
      if (typeof error?.response?.data?.message === "string") {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
        isLoading,
        signUp,
        businessUserInfo,
        setBusinessUserInfo,
        businessPopUp,
        setBusinessPopUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};
function showErrorMessage(message: string) {
  throw new Error("Function not implemented.");
}
