import React, { useState } from "react"

import { SettingPageValues } from "../SettingPageWrapper/SettingPageWrapper"

interface SettingPageContextType {
  settingValues: SettingPageValues | undefined
  setSettingValues: React.Dispatch<
    React.SetStateAction<SettingPageValues | undefined>
  >
}

const initialValues = {
  selectAll: {},
  setSelectAll: () => {},
}
interface SettingPageContextProps {
  children: any
}

export const SettingPageContext = React.createContext<SettingPageContextType>(
  //@ts-ignore
  initialValues
)

export const SettingPageContextProvider: React.FC<SettingPageContextProps> = ({
  children,
}) => {
  const [settingValues, setSettingValues] = useState<SettingPageValues>()
  return (
    <SettingPageContext.Provider
      value={{
        settingValues,
        setSettingValues,
      }}
    >
      {children}
    </SettingPageContext.Provider>
  )
}
