import React from "react";
import { BillFormikWrapper } from "./components/BillPage/BillFormikWrapper";
import { BillPageComponent } from "./components/BillPage/BillPageComponent";
import { DocumentContextProvider } from "./components/BillPage/Document-Context";
import { BatchesContextProvider } from "./components/context/BatchesContext/BatchesContext";
import { DailySalesReport } from "./components/DailySalesReport/DailySalesReport";
import { Openning } from "./components/Openning/Openning";
import { IRDMaterializedView } from "./components/IRDMaterializedView/IRDMaterializedView";
import { Partyledger } from "./components/Partileaser/Partyledger";
import { PayslipPage } from "./components/PayslipPage/PayslipPage";
import { PopupContextProvider } from "./components/PopUp/Popup-Context";
import { ReciptFormikWrapper } from "./components/ReciptSlip/ReciptFormikWrapper";
import { Report } from "./components/Report/Report";
import { StaffManagmentTable } from "./components/StaffManagementTable/StaffManagementTable";
import { ROLE } from "./EnumValues";
import { SalesDetailReport } from "./components/SalesDetailReport/SalesDetailReport";
import { NoDataPage } from "./components/NoDataPage/NoDataPage";
import { PageNotFound } from "./components/ErrorPage/PageNotFound";
import { ServerErrorPage } from "./components/ErrorPage/ServerErrorPage";
import { ProductBill } from "./components/ProductBill/ProductBill";
import { SelectCustomerBill } from "./components/SelectCustomerBill/SelectCustomerBill";
import { ReturnContextProvider } from "./components/context/ReturnContext/ReturnContext";

import { BankDetail } from "./components/BankDetail/BankDetail";
import { ConfirmPw } from "./components/ConfrimPw/ConfirmPw";
import { ReceiptPage } from "./components/ReceiptPage/ReceiptPage";
import { CreditNotePage } from "./components/Receipt/CreditNotePage";
import { SettingPage } from "./components/SettingPage/SettingPage";
import { PersonalForm } from "./components/BusinessInfoForm/PersonalForm";
import { AuditTrialReport } from "./Pages/AuditTrialReport/AuditTrialReport";
import { AccessDeniedPage } from "./components/AccessDeniedPage/AccessDeniedPage";
import { LedgerList } from "./components/LedgerTable/LedgerList";
import { CategoryList } from "./components/CategoryTable/CategoryList";
import { SalesReturnReport } from "./Pages/SalesReturnReport/SalesReturnReport";
import { SettingPageWrapper } from "./components/SettingPage/SettingPageWrapper/SettingPageWrapper";
import { StockDetailReport } from "./Pages/StockDetailReport/StockDetailReport";
import { BillCancelReport } from "./Pages/BillCancelReport/BillCancelReport";
import { PrintTable } from "./components/PrintTable/PrintTable";
import { ManaualVoucher } from "./components/ManaualVoucher/ManaualVoucher";
import { ManualVoucherWrapper } from "./components/ManaualVoucher/ManualVoucherWrapper";
import { ManualVoucherListPage } from "./components/ManualVoucherReport/ManualVoucherReport";
import { OpeningAdjustment } from "./components/OpeningAdjustment/OpeningAdjustment";
import { OpeningAdjustmentWrapper } from "./components/OpeningAdjustment/OpeningWrapper";
import { OpeningAdjustmentReport } from "./components/OpeningAdReport/OpeningAdjustmentReport";
import { PaymentVoucher } from "./components/PaymentVoucher/PaymentVoucher";
import { LedgerReport } from "./components/LedgerReport/LedgerReport";
import { VatReport } from "./components/VatReport/VatReport";
import { ReminderPage } from "./components/ReminderPage/ReminderPage";
import { TradingAccount } from "./components/TradingAccount/TradingAccount";
import { AnusuchiReport } from "./components/AnusuchiReport/AnusuchiReport";
import { CashFlow } from "./components/CashFlow/CashFlow";
import { ProfitLossAC } from "./components/ProfitLossAC/ProfitLossAC";
import { BalanceSheet } from "./components/BalanceSheet/BalanceSheet";
import { NewProfitLossAc } from "./components/NewProfitLossAC/NewProfitLossAC";
import { ProfileTab } from "./components/ProfileHeader/ProfileTab";
import { TrialBalancePage } from "./components/TrialBalancePage/TrialBalancePage";
import { ChequeList } from "./components/ChequeList/ChequeList";
import { CreditorList } from "./components/CreditorList/CreditorList";
import { DebtorList } from "./components/DebtorList/DebtorList";

const DashBoard = React.lazy(() =>
  import("./components/DashBoard/DashBoard").then(({ DashBoard }) => ({
    default: DashBoard,
  }))
);

const CustomerListPage = React.lazy(() =>
  import("./components/CustomerListPage/CustomerListPage").then(
    ({ CustomerListPage }) => ({
      default: CustomerListPage,
    })
  )
);
const ProductListPage = React.lazy(() =>
  import("./components/ProductList/ProductList").then(
    ({ ProductListPage }) => ({
      default: ProductListPage,
    })
  )
);

const SalesListPage = React.lazy(() =>
  import("./components/SalesList/SalesListPage").then(({ SalesListPage }) => ({
    default: SalesListPage,
  }))
);

const PurchaseListPage = React.lazy(() =>
  import("./components/PurchaseList/PurchaseListPage").then(
    ({ PurchaseListPage }) => ({
      default: PurchaseListPage,
    })
  )
);

const StockListPage = React.lazy(() =>
  import("./components/StockList/StockListPage").then(({ StockListPage }) => ({
    default: StockListPage,
  }))
);

const SalesReturn = React.lazy(() =>
  import("./Pages/SalesReturn/SalesReturn").then(({ SalesReturn }) => ({
    default: SalesReturn,
  }))
);

const PurchaseReturn = React.lazy(() =>
  import("./Pages/PurchaseReturn/PurchaseReturn").then(
    ({ PurchaseReturn }) => ({
      default: PurchaseReturn,
    })
  )
);

const PurchaseReturnReport = React.lazy(() =>
  import("./Pages/PurchaseReturnReport/PurchaseReturnReport").then(
    ({ PurchaseReturnReport }) => ({
      default: PurchaseReturnReport,
    })
  )
);

const PatronTransactionSummary = React.lazy(() =>
  import("./Pages/PatronTransactionSummary/PatronTransactionSummary").then(
    ({ PatronTransactionSummary }) => ({
      default: PatronTransactionSummary,
    })
  )
);
interface RoutesChild {
  path: string;
  component: JSX.Element;
  role: ROLE[];
}

export const routes: RoutesChild[] = [
  {
    path: "/",
    component: <DashBoard />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/bill",
    component: (
      <BatchesContextProvider>
        <DocumentContextProvider>
          <PopupContextProvider>
            <BillFormikWrapper>
              <BillPageComponent />
            </BillFormikWrapper>
          </PopupContextProvider>
        </DocumentContextProvider>
      </BatchesContextProvider>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },

  {
    path: "/payslip",
    component: (
      <ReciptFormikWrapper>
        <PopupContextProvider>
          <PayslipPage />
        </PopupContextProvider>
      </ReciptFormikWrapper>
    ),
    role: [ROLE.Staff, ROLE.Admin],
  },
  {
    path: "/products",
    component: (
      <PopupContextProvider>
        <ProductListPage />
      </PopupContextProvider>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/patron-list",
    component: (
      <PopupContextProvider>
        <CustomerListPage />
      </PopupContextProvider>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/sales-report",
    component: <SalesListPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/manual-voucher-report",
    component: (
      <PopupContextProvider>
        <ManualVoucherListPage />
      </PopupContextProvider>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/purchase-report",
    component: <PurchaseListPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/print",
    component: <PrintTable />,
    role: [ROLE.Admin, ROLE.Staff],
  },

  {
    path: "/stock-report",
    component: <StockListPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/party-ledger",
    component: (
      <ReciptFormikWrapper>
        <PopupContextProvider>
          <Partyledger />
        </PopupContextProvider>
      </ReciptFormikWrapper>
    ),
    role: [ROLE.Admin],
  },
  {
    path: "/report",
    component: <Report />,
    role: [ROLE.Admin, ROLE.Staff],
  },

  {
    path: "/profile",
    component: (
      <ReciptFormikWrapper>
        <PopupContextProvider>
          <ProfileTab />
        </PopupContextProvider>
      </ReciptFormikWrapper>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/daily-sales-report",
    component: <DailySalesReport />,
    role: [ROLE.Admin],
  },
  {
    path: "/staff-management",
    component: <StaffManagmentTable />,
    role: [ROLE.Admin],
  },
  {
    path: "/opening",
    component: <Openning />,
    role: [ROLE.Admin],
  },
  {
    path: "/category",
    component: <CategoryList />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/page_error",
    component: <PageNotFound />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/server_error",
    component: <ServerErrorPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/page_error",
    component: <PageNotFound />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/bank_details",
    component: <BankDetail />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/receipt_page",
    component: <ReceiptPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/credit_note",
    component: <CreditNotePage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/settings",
    component: (
      <SettingPageWrapper>
        <SettingPage />
      </SettingPageWrapper>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/pw",
    component: <ConfirmPw />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/product_bill",
    component: <ProductBill />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/select_customer",
    component: <SelectCustomerBill />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/server_error",
    component: <ServerErrorPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/no-data",
    component: <NoDataPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/personal-form",
    component: <PersonalForm />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/access-denied",
    component: <AccessDeniedPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/materialized-view",
    component: <IRDMaterializedView />,
    role: [ROLE.Admin],
  },
  {
    path: "/sales-detail-report",
    component: <SalesDetailReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/ledger",
    component: <LedgerList />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/manual-voucher",
    component: (
      <ManualVoucherWrapper>
        <ManaualVoucher />
      </ManualVoucherWrapper>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/sales-return",
    component: (
      <ReturnContextProvider>
        <DocumentContextProvider>
          <SalesReturn />
        </DocumentContextProvider>
      </ReturnContextProvider>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/audit-trial-report",
    component: <AuditTrialReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/sales-return-report",
    component: <SalesReturnReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/stock-detail-report",
    component: <StockDetailReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/bill-cancel-report",
    component: <BillCancelReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/purchase-return",
    component: (
      <ReturnContextProvider>
        <DocumentContextProvider>
          <PurchaseReturn />
        </DocumentContextProvider>
      </ReturnContextProvider>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/opening-balance",
    component: (
      <OpeningAdjustmentWrapper>
        <OpeningAdjustment />
      </OpeningAdjustmentWrapper>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/purchase-return-report",
    component: <PurchaseReturnReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/opening-balance-report",
    component: <OpeningAdjustmentReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/payment-voucher",
    component: (
      <ReciptFormikWrapper>
        <PopupContextProvider>
          <PaymentVoucher />
        </PopupContextProvider>
      </ReciptFormikWrapper>
    ),
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/ledger-wise-report",
    component: <LedgerReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/transaction-summary",
    component: <PatronTransactionSummary />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/vat-report",
    component: <VatReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/trial-balance",
    component: <TrialBalancePage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/reminder-page",
    component: <ReminderPage />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/anusuchi-report",
    component: <AnusuchiReport />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/cash-flow",
    component: <CashFlow />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/indirect-profit-loss-account",
    component: <ProfitLossAC />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/trading-account",
    component: <TradingAccount />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/balance-sheet",
    component: <BalanceSheet />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/profit-loss-account",
    component: <NewProfitLossAc />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/cheque-list",
    component: <ChequeList viewAll={true} />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/creditor-list",
    component: <CreditorList viewAll={true} />,
    role: [ROLE.Admin, ROLE.Staff],
  },
  {
    path: "/debtor-list",
    component: <DebtorList viewAll={true} />,
    role: [ROLE.Admin, ROLE.Staff],
  },
];
