import { useProfitLossList } from "../Api/Get/GetApi";

import { TriangleDown, TriangleRignt } from "../../assets/images/svg/svg";
import { isJson, parenthesisInNegativeValue } from "../../utils/services";
import { IProfitLossList, IProfitLossSubList } from "../Api/Get/GetApiTypes";
import { Loader } from "../Loader/Loader";
import "./ProfitLossAC.scss";

export interface TableHeaderContentGroupProps {
  columns?: any;
  loading?: boolean;
  roleId?: number;
}
interface Props {
  toDate: string;
  fromDate: string;
  checkedValues: number[];
  toggleAccordion: (id: any) => void;
  handleOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IProfitLossList
  ) => void;
}
export const ProfitLossVartical: React.FC<Props> = ({
  toDate,
  fromDate,
  checkedValues,
  toggleAccordion,
  handleOnChange,
}) => {
  const { data, isLoading } = useProfitLossList({
    dateTo: toDate,
    dateFrom: fromDate,
  });

  return (
    <table className="main-table">
      <thead>
        <th className="content-main-title width-50 text-left">Expenses</th>
      </thead>
      {isLoading ? (
        <tbody>
          <tr>
            <td colSpan={2}>
              <div className="loder-wrapper">
                <Loader loaderType="Spinner" />
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td className="border-bottom-0">
              <table className="mini-table">
                <thead>
                  <th className="width-60 text-left">Particular</th>
                  <th className="width-40 text-left" colSpan={2}>
                    Amount
                  </th>
                </thead>
                <tbody>
                  {data?.debit_profit_loss?.map(
                    (item: IProfitLossList, index) => {
                      let creditProfitJsonData = isJson(item.child_ledger);
                      const exists = checkedValues.find(
                        (val) => val === item.id
                      );
                      return (
                        <>
                          {item.amount === 0 || item.ledger_name === "Total" ? (
                            ""
                          ) : (
                            <tr>
                              <td className="black-content width-60">
                                <div className="d-flex">
                                  {item?.child_ledger?.length > 0 ? (
                                    <>
                                      <input
                                        className="checkbox-none"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name={item.ledger_name}
                                        value={item.ledger_name}
                                        checked={Boolean(exists)}
                                        onChange={(e) =>
                                          handleOnChange(e, item)
                                        }
                                      />
                                      <div
                                        onClick={() => {
                                          toggleAccordion(item.id);
                                        }}
                                      ></div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <label htmlFor={`custom-checkbox-${index}`}>
                                    {item?.child_ledger?.length > 0 && (
                                      <>
                                        {Boolean(exists) ? (
                                          <TriangleDown />
                                        ) : (
                                          <TriangleRignt />
                                        )}
                                      </>
                                    )}
                                    {item.ledger_name}
                                  </label>
                                </div>
                              </td>
                              <td className="width-20"></td>

                              <td className="black-content width-20">
                                {parenthesisInNegativeValue(item.amount)}
                              </td>
                            </tr>
                          )}
                          {creditProfitJsonData ? (
                            <>
                              {JSON.parse(item.child_ledger)?.map(
                                (
                                  jsonItem: IProfitLossSubList,
                                  jsonIndex: number
                                ) => {
                                  return (
                                    <>
                                      {jsonItem.amount === 0 ? (
                                        ""
                                      ) : (
                                        <tr
                                          key={jsonIndex}
                                          className={
                                            Boolean(exists)
                                              ? "show"
                                              : "dontShow"
                                          }
                                        >
                                          <td className="content">
                                            {jsonItem.ledger_name}
                                          </td>
                                          <td className="content ">
                                            {parenthesisInNegativeValue(
                                              jsonItem.amount
                                            )}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                }
                              ) ?? ""}
                            </>
                          ) : (
                            " "
                          )}
                        </>
                      );
                    }
                  )}
                </tbody>
              </table>
              <table className="footer-table">
                {data?.credit_profit_loss?.map((item) => {
                  return (
                    <>
                      {item.ledger_name === "Total" ? (
                        <tr>
                          {item.amount === 0 ? (
                            ""
                          ) : (
                            <>
                              <td
                                className={
                                  item.ledger_name === "Total"
                                    ? "width-80 total"
                                    : "width-80"
                                }
                                colSpan={2}
                              >
                                {item.ledger_name}
                              </td>
                              <td
                                className={
                                  item.ledger_name === "Total"
                                    ? "width-20 total"
                                    : "width-20"
                                }
                              >
                                {parenthesisInNegativeValue(item.amount)}
                              </td>
                            </>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </table>
            </td>
            {/* <td className="vertical-bottom border-top-0">
              
            </td> */}
          </tr>
          <tr>
            <th className="content-main-title width-100 text-left" colSpan={2}>
              Income
            </th>
          </tr>
          <tr>
            <td className="border-bottom-0">
              <table className="mini-table">
                <thead>
                  <th className="width-60 text-left">Particular</th>
                  <th className="width-40 text-left" colSpan={2}>
                    Amount
                  </th>
                </thead>
                <tbody>
                  {data?.credit_profit_loss?.map(
                    (item: IProfitLossList, index) => {
                      let creditProfitJsonData = isJson(item.child_ledger);
                      const exists = checkedValues.find(
                        (val) => val === item.id
                      );
                      return (
                        <>
                          {item.amount === 0 || item.ledger_name === "Total" ? (
                            ""
                          ) : (
                            <tr>
                              <td className="black-content width-60">
                                <div className="d-flex">
                                  {item?.child_ledger?.length > 0 ? (
                                    <>
                                      <input
                                        className="checkbox-none"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name={item.ledger_name}
                                        value={item.ledger_name}
                                        checked={Boolean(exists)}
                                        onChange={(e) =>
                                          handleOnChange(e, item)
                                        }
                                      />
                                      <div
                                        onClick={() => {
                                          toggleAccordion(item.id);
                                        }}
                                      ></div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <label htmlFor={`custom-checkbox-${index}`}>
                                    {item?.child_ledger?.length > 0 && (
                                      <>
                                        {Boolean(exists) ? (
                                          <TriangleDown />
                                        ) : (
                                          <TriangleRignt />
                                        )}
                                      </>
                                    )}
                                    {item.ledger_name}
                                  </label>
                                </div>
                              </td>
                              <td className="width-20"></td>

                              <td className="black-content width-20">
                                {parenthesisInNegativeValue(item.amount)}
                              </td>
                            </tr>
                          )}
                          {creditProfitJsonData ? (
                            <>
                              {JSON.parse(item.child_ledger)?.map(
                                (
                                  jsonItem: IProfitLossSubList,
                                  jsonIndex: number
                                ) => {
                                  return (
                                    <>
                                      {jsonItem.amount === 0 ? (
                                        ""
                                      ) : (
                                        <tr
                                          key={jsonIndex}
                                          className={
                                            Boolean(exists)
                                              ? "show"
                                              : "dontShow"
                                          }
                                        >
                                          <td className="content">
                                            {jsonItem.ledger_name}
                                          </td>
                                          <td className="content ">
                                            {parenthesisInNegativeValue(
                                              jsonItem.amount
                                            )}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                }
                              ) ?? ""}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }
                  )}
                </tbody>
              </table>
              <table className="footer-table">
                {data?.debit_profit_loss?.map((item) => {
                  return (
                    <>
                      {item.ledger_name === "Total" ? (
                        <tr>
                          {item.amount === 0 ? (
                            ""
                          ) : (
                            <>
                              <td
                                className={
                                  item.ledger_name === "Total"
                                    ? "width-80 total"
                                    : "width-80"
                                }
                                colSpan={2}
                              >
                                {item.ledger_name}
                              </td>
                              <td
                                className={
                                  item.ledger_name === "Total"
                                    ? "width-20 total"
                                    : "width-20"
                                }
                              >
                                {parenthesisInNegativeValue(item.amount)}
                              </td>
                            </>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </table>
            </td>
            {/* <td className="vertical-bottom border-top-0">
              
            </td> */}
          </tr>
        </tbody>
      )}
    </table>
  );
};
