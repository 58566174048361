import NepaliDate from "nepali-date-converter";
import React, { useContext, useEffect, useState } from "react";
import { UpdateBillDetailProps } from "../../Pages/SalesReturn/SalesReturn";
import { ChequeReceiptResponse } from "../../Pages/SalesReturn/types";
import {
  BillDataFormat,
  CalculateDiscountPercent,
  deepClone,
  HandleError,
  ObjArrValueExist,
} from "../../utils/services";
import { useBillDetail, usePurchaseReturnDetail } from "../Api/Get/GetApi";
import { PostPurchaseReturn, PostSalesReturn } from "../Api/Post/PostApi";

import { ReturnContext } from "../context/ReturnContext/ReturnContext";
import { useToast } from "../context/ToasterContext/ToasterContext";
import { Loader } from "../Loader/Loader";
import { UpdateAmount } from "../ProductPage/ProductFormikPage";
import "./ProductBill.scss";
import { fixedValue } from "../../utils/services";
import { BankDetailsPage } from "../AddableBank/BankDetailsPage";
import { DocumentContext } from "../BillPage/Document-Context";
import { PopupContext } from "../PopUp/Popup-Context";
import {
  AmountDetailResponse,
  BillProductDetail,
  PaymentTypeResponse,
} from "../Api/types";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import { ServerError } from "../../assets/images/svg/svg";
import { BillReturnType } from "../../EnumValues";

export interface BankValues {
  cheque_no: string;
  amount: number;
  cheque_issue_date: string;
  bank_id: number | undefined;
}

interface ProductBillProps {
  returnType?: BillReturnType;
}

export const ProductBill: React.FC<ProductBillProps> = ({ returnType }) => {
  const [returnReason, setReturnReason] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [description, setDescription] = useState("");
  const [bankDetail, setBankDetail] = useState<BankValues[]>([
    {
      cheque_no: "",
      amount: 0,
      cheque_issue_date: "",
      bank_id: undefined,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [debitNoteNo, setDebitNoteNo] = useState("");

  //useCOntext
  const { showErrorMessage, showSuccessMessage } = useToast();
  const {
    customer,
    setCustomer,
    bill,
    setBill,
    productInfo,
    setProductInfo,
    productValues,
    setProductValues,
    amountInfos,
    setAmountInfos,
    selectAll,
    setSelectAll,
  } = useContext(ReturnContext);

  const { setDocumentNumber, setShowCreditNote } = useContext(DocumentContext);
  const { openModal } = useContext(PopupContext);

  //useQuery hooks
  const {
    data: billDetails,
    isLoading: billDetailLoad,
    refetch,
    isError,
  } = useBillDetail(customer?.value, bill?.value, returnType);

  const {
    data: purchaseDetail,
    isLoading: purchaseLoading,
    refetch: purchaseRefetch,
    isError: purchaseError,
  } = usePurchaseReturnDetail(customer?.value, bill?.value, returnType);

  useEffect(() => {
    if (returnType === BillReturnType.Sales) {
      if (billDetails) {
        //As sooon as data is obtained from api select state is added to each product object from check box logic
        //Data format as response is different than  payload needed so data is formatted and set in the state
        const formattedData = BillDataFormat(billDetails.product_detail);
        setProductInfo(formattedData);
        //Holidng the respoonse data for comaprisons
        setProductValues((p) => ({
          ...p,
          amount_detail:
            billDetails.amount_detail || ({} as AmountDetailResponse),
          product_detail:
            billDetails.product_detail || ([] as BillProductDetail[]),
          cheque_detail:
            billDetails.cheque_detail || ([] as ChequeReceiptResponse[]),
          payment_type: billDetails.payment_type || ({} as PaymentTypeResponse),
        }));
      }
    }
  }, [billDetails]);
  
  useEffect(() => {
    if (returnType === BillReturnType.Purchase) {
      if (purchaseDetail) {
        //As sooon as data is obtained from api select state is added to each product object from check box logic
        //Data format as response is different than  payload needed so data is formatted and set in the state
        const formattedData = BillDataFormat(purchaseDetail.product_detail);
        setProductInfo(formattedData);
        //Holidng the respoonse data for comaprisons
        setProductValues((p) => ({
          ...p,
          amount_detail:
            purchaseDetail.amount_detail || ({} as AmountDetailResponse),
          product_detail:
            purchaseDetail.product_detail || ([] as BillProductDetail[]),
          cheque_detail:
            purchaseDetail.cheque_detail || ([] as ChequeReceiptResponse[]),
          payment_type:
            purchaseDetail.payment_type || ({} as PaymentTypeResponse),
        }));
      }
    }
  }, [purchaseDetail]);

  //Update the  total ,total discount and credits
  const HandleAmountInfoUpdate = () => {
    //
    const cloned = [...(productInfo as UpdateBillDetailProps[])];
    //Filter the selected value for updateing the amount infos
    const filteredSelectedValue = cloned.filter((val) => val.selected === true);

    const allTotal = filteredSelectedValue.reduce((ac, ob) => {
      //Calculate total selected amount
      return ac + ob.amt;
    }, 0);

    //Calculate total selected discount
    const allDiscount = filteredSelectedValue.reduce((ac, ob) => {
      return ac + ob.discount;
    }, 0);

    const nonTaxableAmount = filteredSelectedValue.filter((item) => item.tax === 0)
    .reduce((total, item) => total + item.sub_amt, 0);

    //Taxable amount is allTotal-Discount
    const taxableAmount = fixedValue(allTotal - allDiscount - nonTaxableAmount);

    //Vat value is 13% of taxableAmount standarda vat calculation
    const vat = fixedValue(0.13 * taxableAmount);

    //Checks ths condition for sales return or purchase return as same component is used
    //Sales return--->return tax_received and pruchase return--->get tax_paid back
    const taxPaidOrRecived =
      productValues &&
      productValues.amount_detail &&
      returnType === BillReturnType.Sales
        ? Number(productValues?.amount_detail.tax_received)
        : Number(productValues?.amount_detail.tax_paid);

    //Total amount is taxable amount
    const totalAmount = allTotal - allDiscount + vat;

    //Product value state hold the information from bill response
    const remainingCredit = productValues?.amount_detail
      ? Number(productValues?.amount_detail.credit)
      : 0;

    //Checks the state value of credit
    const creditReminder =
      totalAmount > remainingCredit ? totalAmount - remainingCredit : 0;

    const credit =
      remainingCredit <= totalAmount ? remainingCredit : totalAmount;

    const cashAmount = creditReminder > 0 ? creditReminder : 0;
    //Check if bank amount is greater than total amount
    if (cashAmount < chequeAmount()) {
      showErrorMessage(`Exceed the total amount ${cashAmount} `);
    }

    setAmountInfos((p) => ({
      ...p,
      total_amount: fixedValue(totalAmount),
      discount: fixedValue(allDiscount),
      sub_total: fixedValue(allTotal),
      tax: taxPaidOrRecived > 0 ? fixedValue(vat) : 0,
      credit: fixedValue(credit),
      cash:
        cashAmount > 0 && Number(chequeAmount()) <= cashAmount
          ? fixedValue(cashAmount - Number(chequeAmount()))
          : fixedValue(cashAmount),
      taxable_amount: fixedValue(taxableAmount),
      non_taxable_amount: fixedValue(nonTaxableAmount),
    }));
  };

  //Updateing the prdoucts values
  const UpdateProductInfos = (
    value: any,
    key: "primary_unit" | "secondary_unit",
    index: number
  ) => {
    const cloned = [...(productInfo as UpdateBillDetailProps[])];
    // const primaryUnit =
    //   productValues?.product_detail[index].primary_product_unit;
    // const secondaryUnit =
    //   productValues?.product_detail[index].secondary_product_unit;
    ValidateQuantity(index, value, cloned, key);
    const requiredTotal = productValues?.product_detail[index].total;
    const discount = productValues?.product_detail[index].discount;
    const discountPercent = CalculateDiscountPercent(requiredTotal, discount);
    const discountValue =
      (discountPercent / 100) *
        cloned[index].rate.primary_price *
        cloned[index].qty.primary_unit +
      (discountPercent / 100) *
        cloned[index].rate.secondary_price *
        cloned[index].qty.secondary_unit;
    cloned[index].discount = fixedValue(discountValue);
    cloned[index].amt = UpdateAmount(index, cloned).amount;
    cloned[index].sub_amt = UpdateAmount(index, cloned).netAmount;
    HandleAmountInfoUpdate();
    setProductInfo(cloned);
  };

  const ValidateQuantity = (
    index: number,
    value: number,
    newArray: UpdateBillDetailProps[],
    key: "primary_unit" | "secondary_unit"
  ) => {
    const cloned = [...(productValues?.product_detail as BillProductDetail[])];
    const relation = cloned[index].relation;
    const validTotal =
      cloned[index].primary_product_unit * relation +
      cloned[index].secondary_product_unit;

    switch (key) {
      case "secondary_unit":
        const primVal = Math.floor(Number(value) / relation);
        const secondaryVal = Number(value) % relation;
        const preExistedPrimVal = productInfo?.[index].qty.primary_unit;
        const requestedTotal =
          (Number(preExistedPrimVal) + primVal) * relation + secondaryVal;
        if (requestedTotal > validTotal) {
          newArray[index].qty.primary_unit = cloned[index].primary_product_unit;
          newArray[index].qty.secondary_unit =
            cloned[index].secondary_product_unit;
        } else {
          newArray[index].qty.primary_unit =
            Number(preExistedPrimVal) + primVal;
          newArray[index].qty.secondary_unit = secondaryVal;
        }
        break;
      case "primary_unit":
        const total =
          Number(value) * relation + newArray[index].qty.secondary_unit;
        if (total > validTotal) {
          newArray[index].qty.primary_unit = cloned[index].primary_product_unit;
          newArray[index].qty.secondary_unit =
            cloned[index].secondary_product_unit;
          showErrorMessage(`Exceed the ${returnType} quantity`);
        } else {
          newArray[index].qty.primary_unit = value;
        }
        break;
      default:
        return;
    }
  };

  const handleSelectAll = () => {
    const cloned = [...(productInfo as UpdateBillDetailProps[])];
    cloned.forEach((val) => {
      val.selected = !selectAll;
    });
    setSelectAll(!selectAll);
    setProductInfo(cloned);
  };

  //Function to remove the selected key that we added in states
  const ReturnFormattedBill = (productInfo: UpdateBillDetailProps[]) => {
    const selectedData = productInfo.filter((val) => val.selected === true);
    return selectedData;
  };

  //Api call function
  const handleSalesReturn = () => {
    setIsLoading(true);
    const requiredBillDetails = ReturnFormattedBill(
      productInfo as UpdateBillDetailProps[]
    );
    //Validation
    if (requiredBillDetails.length < 1) {
      showErrorMessage("Please select any products");
      setIsLoading(false);

      return;
    }
    if (!returnReason) {
      showErrorMessage("Please specify reason for return");
      setIsLoading(false);
      return;
    }
    if (
      Number(productValues?.amount_detail.credit) < Number(amountInfos?.credit)
    ) {
      showErrorMessage(
        `Exceed the sales credit value ${Number(
          productValues?.amount_detail.credit
        )}`
      );
      setIsLoading(false);
      return;
    }

    const clonedDetails = deepClone(requiredBillDetails);

    //Remove the select key as it  doesnot have to be send to the api
    clonedDetails.forEach((val) => {
      delete val["selected"];
    });
    const data = {
      date: new NepaliDate().format("YYYY-MM-DD"),
      bill_details: clonedDetails,
      sub_total: amountInfos?.sub_total,
      discount: amountInfos?.discount,
      tax: amountInfos?.tax,
      non_taxable: amountInfos?.non_taxable_amount,
      taxable: amountInfos?.taxable_amount,
      cash: amountInfos?.cash,
      credit: amountInfos?.credit,
      bank: ObjArrValueExist(bankDetail) ? bankDetail : [],
      total: amountInfos?.total_amount,
      customer_notes: customerNotes,
      reason_to_cancel: returnReason,
      billed_business_id: customer?.value,
      transaction_main_id: bill?.value,
      description: description,
    };

    switch (returnType) {
      case BillReturnType.Sales:
        PostSalesReturn(data)
          .then((res) => {
            showSuccessMessage("Successfully Returned");
            setDocumentNumber(res.data.data.document_no);
            setShowCreditNote(true);
            openModal();
            setTimeout(() => {
              setBill(null);
              setCustomer(null);
            }, 1000);
            setIsLoading(false);
            refetch();
          })
          .catch((err) => {
            const ErrorMessage = HandleError(err);
            showErrorMessage(ErrorMessage);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case BillReturnType.Purchase:
        if (!debitNoteNo) {
          showErrorMessage("Please enter debit note no");
          setIsLoading(false);
          return;
        }
        //@ts-ignore
        data["bill_number"] = debitNoteNo;
        PostPurchaseReturn(data)
          .then((res) => {
            showSuccessMessage("Successfully Returned");
            setDocumentNumber(res.data.data.document_no);
            setShowCreditNote(true);
            openModal();
            setTimeout(() => {
              setBill(null);
              setCustomer(null);
            }, 1000);
            setIsLoading(false);
            purchaseRefetch();
          })
          .catch((err) => {
            const ErrorMessage = HandleError(err);
            showErrorMessage(ErrorMessage);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }

    return;
  };

  const chequeAmount = () => {
    const amount = bankDetail.reduce((a, c) => {
      return a + c.amount;
    }, 0);
    return amount;
  };

  useEffect(() => {
    if (productInfo) {
      HandleAmountInfoUpdate();
    }
  }, [bankDetail]);

  const DisableSecondaryUnit = (relation: number, selected: boolean) => {
    let flag = true;
    if (selected && relation !== 1) {
      flag = false;
    }
    return flag;
  };

  if (returnType === BillReturnType.Sales && billDetailLoad) {
    return (
      <>
        <Loader loaderType="Spinner" />
      </>
    );
  }
  if (returnType === BillReturnType.Purchase && purchaseLoading) {
    return (
      <>
        <Loader loaderType="Spinner" />
      </>
    );
  }

  if (
    returnType === BillReturnType.Purchase &&
    !purchaseLoading &&
    purchaseError
  ) {
    return (
      <NoDataPage
        title="Server Error"
        description="Something went wrong with server"
        icon={<ServerError />}
      />
    );
  }

  if (returnType === BillReturnType.Sales && !billDetailLoad && isError) {
    return (
      <NoDataPage
        title="Server Error"
        description="Something went wrong with server"
        icon={<ServerError />}
      />
    );
  }
  return (
    <>
      {productInfo && productInfo?.length > 0 ? (
        <>
          {returnType === BillReturnType.Purchase && (
            <div className="debit-note">
              <div className="col-md-2">
                <label className="input-label">Debit Note No.</label>
                <input
                  className="input-field"
                  onChange={(e) => {
                    setDebitNoteNo(e.target.value);
                  }}
                />
              </div>
            </div>
          )}

          <div className="product-bill">
            <div className="product-selected">
              <p className="product-title">Products</p>
              <p className="selected-title ">
                {productInfo &&
                  ReturnFormattedBill(productInfo as UpdateBillDetailProps[])
                    .length}{" "}
                Products selected
              </p>
            </div>
            <div className="table-body">
              <table>
                <thead className="table-header">
                  <tr>
                    <th className="table-content head-center">
                      <div className="symbol-number">
                        <div className="check-box-area d-flex">
                          <input
                            type="checkbox"
                            onChange={() => {
                              handleSelectAll();
                              HandleAmountInfoUpdate();
                            }}
                            checked={selectAll}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="table-content small-content product-width side-border symbol-wrapper">
                      <div>Product Name</div>
                    </th>
                    <th className="table-content small-content equal-width field-wrapper side-border qty-th">
                      <p className="small-content d-center main-field">
                        Quantity
                      </p>
                      <div className="d-center">
                        <p className="table-content small-content pri-field d-center">
                          PRI
                        </p>
                        <p className="table-content small-content sec-field d-center">
                          SEC
                        </p>
                      </div>
                    </th>

                    <th className="table-content small-content amount-width no-space">
                      Amount
                    </th>
                    <th className="table-content small-content">Discount</th>
                    <th className="table-content small-content  vat-field">Tax</th>
                    <th className="table-content small-content amount-width no-space">
                      Net Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productInfo?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center width-3">
                          <div className="check-box-area text-center">
                            <input
                              type="checkbox"
                              onChange={() => {
                                const cloned = [
                                  ...(productInfo as UpdateBillDetailProps[]),
                                ];
                                cloned[index].selected =
                                  !cloned[index].selected;
                                //@ts-ignore
                                setProductInfo(cloned);
                                HandleAmountInfoUpdate();
                                // TotalAmount(index)
                              }}
                              checked={productInfo?.[index].selected}
                            />
                          </div>
                        </td>
                        <td>{productValues?.product_detail[index].name}</td>
                        <td className="d-flex justify-content-between padd-0">
                          <div className="sub-col right-border">
                            <input
                              className="input-field"
                              onKeyPress={(e: any) => {
                                if (
                                  productInfo[index].relation !== 1 &&
                                  !/[0-9]/.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                let val: any = e.target.value;
                                if (productInfo[index].relation === 1) {
                                  val = fixedValue(Number(e.target.value));
                                }
                                UpdateProductInfos(
                                  Number(val),
                                  "primary_unit",
                                  index
                                );
                              }}
                              type="number"
                              onWheel={(e: any) => {
                                (e.target as HTMLInputElement).blur();
                              }}
                              onFocus={(event: any) => {
                                if (parseInt(event.target.value) === 0) {
                                  event.target.value = "";
                                }
                              }}
                              disabled={
                                productInfo[index].selected
                                  ? !productInfo[index].selected
                                  : true
                              }
                              value={productInfo[index].qty.primary_unit}
                            />
                          </div>
                          <div className="sub-col">
                            <input
                              className="input-field"
                              onKeyPress={(e: any) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                // UpdateQuantities(
                                //   index,
                                //   "primary_product_unit",
                                //   e.target.value
                                // )
                                UpdateProductInfos(
                                  e.target.value,
                                  "secondary_unit",
                                  index
                                );
                              }}
                              disabled={
                                productInfo[index].selected
                                  ? DisableSecondaryUnit(
                                      productInfo[index].relation as number,
                                      productInfo[index].selected as boolean
                                    )
                                  : true
                              }
                              value={productInfo[index].qty.secondary_unit}
                            />
                          </div>
                        </td>

                        <td className="text-center amt-width">
                          {fixedValue(productInfo[index].amt)}
                        </td>
                        <td className="text-center amt-width">
                          {fixedValue(productInfo[index].discount)}
                        </td>
                        <td className="text-center amt-width">
                          {productInfo[index].tax > 0 ? "13% Vat" : "No Vat"}
                        </td>
                        <td className="text-center amt-width">
                          {fixedValue(productInfo[index].sub_amt)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="table-footer">
              <div className="note-wrapper">
                <div className="form-group">
                  <label className="input-label">Customer Notes</label>
                  <textarea
                    className="input-field text-area-field"
                    rows={3}
                    placeholder="Enter here"
                    onChange={(e) => {
                      setCustomerNotes(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* <div className="note-wrapper"></div> */}

              <div className="total-card-wrapper">
                <div className="total-card">
                  <table className="total-table">
                    <thead className="table-header">
                      <tr>
                        <th className="content">Sub Total</th>
                        <th className="content">Discount</th>
                        <th className="content">Non-Taxable Amount</th>
                        <th className="content">Taxable Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="content">
                          <input
                            className="input-field"
                            type={"number"}
                            name="sub_total"
                            value={fixedValue(amountInfos?.sub_total as number)}
                            disabled
                          />
                        </td>
                        <td className="content">
                          <input
                            className="input-field"
                            placeholder=""
                            pattern="[0-9]*"
                            value={fixedValue(amountInfos?.discount as number)}
                            disabled
                          />
                        </td>

                        <td className="content">
                          <input
                            className="input-field"
                            placeholder=""
                            pattern="[0-9]*"
                            value={fixedValue(amountInfos?.non_taxable_amount as number)}
                            disabled
                          />
                        </td>

                        <td className="content">
                          <input
                            className="input-field"
                            disabled
                            type={"number"}
                            placeholder=""
                            value={fixedValue(
                              amountInfos?.taxable_amount as number
                            )}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-space-between line-segment no-space">
                  <div>
                    <label className="content">Tax (13% VAT)</label>
                  </div>
                  <div className="input-space">
                    <input
                      className="input-field"
                      type={"number"}
                      name="tax"
                      value={fixedValue(amountInfos?.tax as number)}
                      disabled
                    />
                  </div>
                </div>
                <div className="d-space-between line-segment no-space total-bottom">
                  <div className="content">Total</div>
                  <div className="input-space">
                    <input
                      className="input-field"
                      type={"number"}
                      value={fixedValue(amountInfos?.total_amount as number)}
                      name="total"
                      disabled
                    />
                  </div>
                </div>
                <div className="bank-detail-wrapper">
                  <div className="bank-details">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="header-wrap">
                          <th className="bank-header cash-border content">
                            Cash
                          </th>
                          <th className="bank-header content credit-width">
                            Credit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-btm-0">
                          <td className="border-btm-0">
                            <div className="cash-amt">
                              <label className="input-label">Amount</label>
                              <input
                                className="input-field"
                                type="number"
                                onWheel={(e: any) => {
                                  (e.target as HTMLInputElement).blur();
                                }}
                                onFocus={(event: any) => {
                                  if (parseInt(event.target.value) === 0) {
                                    event.target.value = "";
                                  }
                                }}
                                pattern="[0-9]*"
                                value={fixedValue(amountInfos?.cash as number)}
                                onChange={(e) => {
                                  setAmountInfos((p) => ({
                                    ...p,
                                    cash: Number(e.target.value),
                                  }));
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="cash-amt">
                              <label className="input-label">Amount</label>
                              <input
                                className="input-field"
                                pattern="[0-9]*"
                                disabled
                                placeholder="Enter amount"
                                value={fixedValue(
                                  amountInfos?.credit as number
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-top-0 border-right-width">
                          <td className="border-top-0">
                            <div>
                              <BankDetailsPage
                                bankDetail={bankDetail}
                                setBankDetail={setBankDetail}
                                returnType={returnType}
                              />
                            </div>
                          </td>
                          <td className="vertical-align-0">
                            <div className="form-group">
                              <label className="input-label">
                                Reason for return
                              </label>
                              <textarea
                                className="input-field text-area-field"
                                rows={3}
                                placeholder="Enter here"
                                onChange={(e) => {
                                  setReturnReason(e.target.value);
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="input-label">Narration</label>
                              <textarea
                                className="input-field text-area-field"
                                rows={3}
                                placeholder="Enter here"
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-section">
              <div>
                <button
                  onClick={() => {
                    setCustomer?.({ label: "", value: undefined });
                    setBill?.({ label: "", value: undefined });
                  }}
                  className="light-secondary mr-16"
                >
                  Cancel
                </button>
              </div>

              <div>
                <button
                  disabled={Number(amountInfos?.total_amount) <= 0 || isLoading}
                  onClick={() => {
                    handleSalesReturn();
                  }}
                  className="main-button"
                >
                  {isLoading ? (
                    <div className="">
                      <Loader loaderType="Spinner" universalCenter={false} />
                    </div>
                  ) : (
                    "Return Products"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NoDataPage
            title="No more products to return"
            description="Seems like all product have been returned from this bill."
            iconDirectory={require("../../assets/images/emptyBox.png")}
          />
        </>
      )}
    </>
  );
};
