import React, { useState } from "react"
import { BatchInfoResponse } from "../../ProductDetails/ProductDetails"

interface BatchesContextType {
  activeBatch: number | null
  setActiveBatch: React.Dispatch<React.SetStateAction<number | null>>
  activeProductBatches: ProdBatches[]
  setActiveProductBatches: React.Dispatch<React.SetStateAction<ProdBatches[]>>
  selectedBatches: SelectedBatch
  setSelectedBatches: React.Dispatch<React.SetStateAction<SelectedBatch>>
}

const initialValues = {
  activeBatch: null,
  setActiveBatch: () => { },
  activeProductBatches: [],
  setActiveProductBatches: () => { },
  selectedBatches: [],
  setSelectedBatches: () => { },
}
interface BatchesProps {
  children: any
}

export const BatchesContext = React.createContext<BatchesContextType>(
  //@ts-ignore
  initialValues
)

export type ProdBatches = {
  relation: number
  batch_info: BatchInfoResponse[]
}

export type SelectedBatch =
  BatchInfoResponse[]

export const BatchesContextProvider: React.FC<BatchesProps> = ({
  children,
}) => {
  const [activeBatch, setActiveBatch] = useState<number | null>(null)
  const [activeProductBatches, setActiveProductBatches] = useState<ProdBatches[]>(
    []
  )
  const [selectedBatches, setSelectedBatches] = useState<SelectedBatch>([])

  return (
    <BatchesContext.Provider
      value={{
        activeBatch,
        setActiveBatch,
        activeProductBatches,
        setActiveProductBatches,
        //@ts-ignore
        selectedBatches,
        //@ts-ignore
        setSelectedBatches,
      }}
    >
      {children}
    </BatchesContext.Provider>
  )
}
