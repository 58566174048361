import { useSettingPage } from "./SettingPageWrapper/SettingPageWrapper";
import { ErrorMessage, Field } from "formik";
import { checkNumber } from "../../utils/services";
import { useState } from "react";

const GeneralSetting = () => {
  const { setFieldValue, values } = useSettingPage();

  const [value, setValue] = useState("");
  const handleKeyPress = (e: any) => {
    const input = e.key;
    if (!checkNumber(input)) {
      e.preventDefault();
    } else {
      const newValue = value + input;
      setValue(newValue);
    }
  };
  const fiscalYear = values.fiscal_year;
  const splitValues = fiscalYear && fiscalYear.split("/");
  const firstValue = splitValues && Number(splitValues[0]);
  const lastValue: string = splitValues ? splitValues[1] : "";
  const [frontVal, setFrontVal] = useState(firstValue);
  const [endVal, setEndVal] = useState<string>(lastValue);

  const fiscalFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    setFrontVal(num);
    let endValue = ((Number(num) ?? 0) + 1).toString().substring(1, 5);
    setEndVal(endValue);
    setFieldValue("fiscal_year", `${num}/${endValue}`);
  };

  return (
    <div className="col-md-12">
      <fieldset>
        <legend>General Setting</legend>
        <div className="border-bottom ">
          <label className="black-content">Fiscal Year</label>
          <div className="small-input">
            <input
              type="string"
              maxLength={4}
              className="input-section"
              value={frontVal}
              onChange={(e) => {
                fiscalFunction(e);
              }}
            />
            /{endVal}
          </div>
          <p>
            <ErrorMessage
              name={"fiscal_year"}
              component="div"
              className="error"
            />
          </p>
        </div>
        <p className="bold-text mg-btn-20">Sales Price</p>
        <div>
          <label className="input-label">Retailer Price</label>
          <div className="list-section">
            <Field
              name="retailer_price_percent"
              className="input-section"
              type="number"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
            <p className="content">% of Purchase Price</p>
            <ErrorMessage
              name={"retailer_price_percent"}
              component="div"
              className="error"
            />
          </div>
        </div>
        <div>
          <label className="input-label">Wholesaler Price</label>
          <div className="list-section">
            <Field
              name="wholesaler_price_percent"
              className="input-section"
              type="number"
              onWheel={(e: any) => {
                (e.target as HTMLInputElement).blur();
              }}
              onKeyPress={handleKeyPress}
            />
            <p className="content">% of Purchase Price</p>
            <ErrorMessage
              name={"wholesaler_price_percent"}
              component="div"
              className="error"
            />
          </div>
        </div>
        <div className="tab-btn">
          <div
            onClick={(e: any) => {
              setFieldValue("language", "en");
            }}
            className={
              values.language === "en"
                ? "manual-switch active-bg"
                : "manual-switch unactive"
            }
          >
            English
          </div>
          <div
            onClick={(e: any) => {
              setFieldValue("language", "ne");
            }}
            className={
              values.language !== "en"
                ? "manual-switch active-bg"
                : "manual-switch unactive"
            }
          >
            Nepali
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default GeneralSetting;
