import { useMemo, useState } from "react"
import {
  fixedValue,
  FormatDate,
  handleNoData,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services"
import { useSalesDetailReport } from "../Api/Get/GetApi"
import { SalesDetailReportResponse } from "../Api/types"
import { Table } from "../CustomReactTable/CustomReactTable"
import { FilterDate } from "../SalesList/SalesListPage"

export const SalesDetailReport = () => {
  const [queryPageIndex, setQueryPageIndex] = useState(0)
  const [queryPageSize, setQueryPageSize] = useState(10)
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" })
  const { data: salesDetails, isLoading } = useSalesDetailReport(
    queryPageIndex,
    queryPageSize,
    date
  )
  // const columnArray = [
  //   "SN",
  //   "Date",
  //   "Patron",
  //   "Pan No",
  //   "Product Description",
  //   "Invoice No",
  //   "Primary Unit",
  //   "Secondary Unit",
  //   "Primary Qty",
  //   "Secondary Qty",
  //   "Primary Price",
  //   "Secondary Price",
  //   "Discount",
  //   "Total Discount",
  //   "Total",
  //   "Tax",
  //   "Net Total",
  // ]

  const columns = useMemo(
    () => [
      { Header: "SN", accessor: "sn" },
      { Header: "Date", accessor: "date" },
      { Header: "Invoice No", accessor: "invoice_no", enableRowSpan: true },

      { Header: "Patron", accessor: "patron" },
      { Header: "Pan No", accessor: "pan_no" },
      { Header: "Product Description", accessor: "product_description" },
      { Header: "Primary Unit", accessor: "primary_unit" },
      { Header: "Secondary Unit", accessor: "secondary_unit" },
      { Header: "Primary Qty", accessor: "primary_qty" },
      { Header: "Secondary Qty", accessor: "secondary_qty" },
      { Header: "Primary Price", accessor: "primary_price" },
      { Header: "Secondary Price", accessor: "secondary_price" },
      {
        Header: "Sub Total",
        accessor: "sub_total",
        Footer: () => {
          return (
            <>
              <div>
                Total:{" "}
                {salesDetails &&
                  fixedValue(salesDetails.data.amount_detail.sub_total)}
              </div>
            </>
          )
        },
      },

      { Header: "Discount", accessor: "discount" },
      {
        Header: "Total Discount",
        accessor: "total_discount",
        enableRowSpan: true,
        Footer: () => {
          return (
            <>
              <div>
                Total:{" "}
                {salesDetails &&
                  fixedValue(salesDetails.data.amount_detail.discount)}
              </div>
            </>
          )
        },
      },
      {
        Header: "Tax",
        accessor: "tax",
        Footer: () => {
          return (
            <>
              <div>
                Total:{" "}
                {salesDetails &&
                  fixedValue(salesDetails.data.amount_detail.tax)}
              </div>
            </>
          )
        },
      },

      {
        Header: "Total",
        accessor: "total",
        Footer: () => {
          return (
            <>
              <div>
                Total:{" "}
                {salesDetails &&
                  fixedValue(salesDetails.data.amount_detail.total)}
              </div>
            </>
          )
        },
      },
    ],
    [salesDetails]
  )

  const FormatTable = (
    array: SalesDetailReportResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item, index) => {
        return {
          sn: tableIndexNumber(pageIndex, pageSize, index),
          discount: item.discount ? parenthesisInNegativeValue(item.discount) : 0,
          tax: parenthesisInNegativeValue(item.tax),
          sub_total: parenthesisInNegativeValue(item.sub_total),
          total: parenthesisInNegativeValue(item.total),
          product_description: item.name,
          date: FormatDate(item.miti || ""),
          patron: item.user_info ? item.user_info.name : "-",
          invoice_no: item.document_number,
          primary_unit: item.primary_unit,
          secondary_unit: item.secondary_unit,
          primary_qty: item.primary_qty,
          secondary_qty: item.secondary_qty,
          primary_price: parenthesisInNegativeValue(item.primary_price),
          secondary_price:parenthesisInNegativeValue( item.secondary_price),
          total_discount: item.total_discount ? item.total_discount : 0,
          pan_no: handleNoData(item?.user_info?.pan as number, "None"),
        }
      })
      return formatData
    }
    return []
  }

  return (
    <>
      <div className="purchasesListPage">
        <div className="row">
          <div className="head-section">
            <div className="col-md-6">
              <div className="small-title">Sales Detail Report</div>
            </div>
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          data={
            salesDetails?.data
              ? FormatTable(
                  salesDetails.data.data_list,
                  queryPageIndex,
                  queryPageSize
                )
              : []
          }
          totalPages={
            salesDetails &&
            totalPages(queryPageSize, salesDetails.pagination.total_count)
          }
          showFooter={true}
          showDate={true}
          setDate={setDate}
          totalEntries={salesDetails && salesDetails.pagination.total_count}
          queryPageIndex={queryPageIndex}
          setQueryPageIndex={setQueryPageIndex}
          queryPageSize={queryPageSize}
          setQueryPageSize={setQueryPageSize}
          date={date}
          excelDownload={true}
          showPageSize={true}
        />
      </div>
    </>
  )
}
