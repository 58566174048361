import { useEffect, useMemo, useState } from "react";
import {
  handleNoData,
  parenthesisInNegativeValue,
  tableIndexNumber,
  totalPages,
} from "../../utils/services";
import { useAnusuchiList } from "../Api/Get/GetApi";
import { AnusuchiListResponse } from "../Api/types";
import { Table } from "../../components/CustomReactTable/CustomReactTable";
import { FilterDate } from "../SalesList/SalesListPage";

export const AnusuchiReport = () => {
  const [date, setDate] = useState<FilterDate>({ dateFrom: "", dateTo: "" });
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);

  const {
    data: anusuchiList,
    isLoading,
    refetch,
  } = useAnusuchiList(queryPageIndex, queryPageSize, date);
  const FormatTable = (
    array: AnusuchiListResponse[],
    pageIndex: number,
    pageSize: number
  ) => {
    if (array) {
      const formatData = array?.map((item: AnusuchiListResponse, index) => {
        return {
          s_n: tableIndexNumber(pageIndex, pageSize, index),
          name: handleNoData(item.name),
          pan: handleNoData(item.pan),
          mobile_no: handleNoData(item.mobile_no),
          trans_purchase_goods: parenthesisInNegativeValue(
            item.transaction_info?.trans_purchase_goods
          ),
          trans_sales_goods: parenthesisInNegativeValue(
            item.transaction_info?.trans_sales_goods
          ),
          trans_purchase_service: parenthesisInNegativeValue(
            item.transaction_info?.trans_purchase_service
          ),
          trans_sales_service: parenthesisInNegativeValue(
            item.transaction_info?.trans_sales_service
          ),
          trans_purchase_goods_capital: parenthesisInNegativeValue(
            item?.transaction_info?.trans_purchase_goods_capital
          ),
          trans_purchase_service_capital: parenthesisInNegativeValue(
            item?.transaction_info?.trans_purchase_service_capital
          ),
          trans_opening_balance: parenthesisInNegativeValue(
            item?.transaction_info?.trans_opening_balance
          ),
          trans_closing_balance: parenthesisInNegativeValue(
            item?.transaction_info?.trans_closing_balance
          ),
          total_trans_cal: parenthesisInNegativeValue(
            item.transaction_info?.total_trans_cal
          ),
        };
      });
      return formatData;
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: "PAN",
        accessor: "pan",
      },
      {
        Header: "TradeName",
        accessor: "name",
      },
      {
        Header: "OpeningBalance ",
        accessor: "trans_opening_balance",
      },
      {
        Header: "ServicePurchaseCapital",
        accessor: "trans_purchase_service_capital",
      },
      {
        Header: "ServicePurchaseOthers",
        accessor: "trans_purchase_service",
      },
      {
        Header: "GoodPurchaseCapital",
        accessor: "trans_purchase_goods_capital",
      },
      {
        Header: "GoodPurchaseOthers",
        accessor: "trans_purchase_goods",
      },
      {
        Header: "ServiceSales",
        accessor: "trans_sales_service",
      },
      {
        Header: "GoodSales",

        accessor: "trans_sales_goods",
      },
      {
        Header: "ClosingBalance ",
        accessor: "trans_closing_balance",
      },
    ],
    []
  );
  useEffect(() => {
    refetch();
  }, [date.dateFrom, date.dateTo]);
  return (
    <div>
      <div className="head-section">
        <div className="col-md-6">
          <div className="small-title">Anusuchi Report</div>
        </div>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        data={
          anusuchiList
            ? FormatTable(anusuchiList?.data, queryPageIndex, queryPageSize)
            : []
        }
        totalPages={
          anusuchiList &&
          totalPages(queryPageSize, anusuchiList.pagination.total_count)
        }
        showDate={true}
        setDate={setDate}
        totalEntries={anusuchiList && anusuchiList.pagination.total_count}
        queryPageIndex={queryPageIndex}
        setQueryPageIndex={setQueryPageIndex}
        queryPageSize={queryPageSize}
        setQueryPageSize={setQueryPageSize}
        date={date}
        excelDownload={true}
        description="Looks like there are no record with more than 1 Lakh transaction here yet."
      />
    </div>
  );
};
