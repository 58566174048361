import { useNavigate } from "react-router-dom"
import { LockIcon } from "../../assets/images/svg/svg"
import "./AccessDeniedPage.scss"
export const AccessDeniedPage = () => {
  const Navigate = useNavigate()
  return (
    <div className="access-denied-page">
      <div className="top-section">
        <div className="big-ellipse">
          <LockIcon />
        </div>
      </div>
      <div className="mid-page">
        <div className="text-section">
          <h2 className="small-title error-title">Access Denied</h2>
          <p className="sub-text">
            You don't have permissions to access ths page. Contact an
            administrator to get permission or go to home page and browse other
            page
          </p>
        </div>
        <div className="btn-mid">
          <button
            className="main-button"
            onClick={() => {
              Navigate("/")
            }}
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  )
}
