import { Formik, FormikConfig, useFormikContext } from "formik";
import { useMemo } from "react";
import { HandleError } from "../../../utils/services";
import { useSettingData } from "../../Api/Get/GetApi";
import { UpdateSetting } from "../../Api/Post/PostApi";
import { useToast } from "../../context/ToasterContext/ToasterContext";
import * as Yup from "yup";

export interface SettingPageValues {
  is_stock_validate: boolean | undefined;
  is_reminder: boolean | undefined;
  bill_due_notice_day: number | undefined;
  retailer_price_percent: number | undefined;
  wholesaler_price_percent: number | undefined;
  closing_stock_cal_type: string | undefined;
  fiscal_year: string | undefined;
  depreciation_type: string | undefined;
  cal_depreciation: boolean | undefined;
  asset_pool_A: number | undefined;
  asset_pool_B: number | undefined;
  asset_pool_C: number | undefined;
  asset_pool_D: number | undefined;
  asset_pool_E: number | undefined;
  language: string;
}

export const useSettingPage = () => {
  const formik = useFormikContext<SettingPageValues>();
  if (!formik) {
    throw new Error("useSettingPage must be used within a Formik");
  }
  return formik;
};
interface SettingPageWrapperProps {
  children: React.ReactNode;
}

export const SettingPageWrapper: React.FC<SettingPageWrapperProps> = ({
  children,
}) => {
  const { isLoading, refetch, data: settingData } = useSettingData();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const handleSubmit: FormikConfig<SettingPageValues>["onSubmit"] = (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true);

    const data = { ...values };

    UpdateSetting(data)
      .then((res) => {
        showSuccessMessage("Settings Updated");
        refetch();
      })
      .catch((err: any) => {
        const errorMsg = HandleError(err);
        showErrorMessage(errorMsg || "Something went wrong");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const initialValues = {
    is_stock_validate: false,
    is_reminder: false,
    bill_due_notice_day: 0,
    retailer_price_percent: 0,
    wholesaler_price_percent: 0,
    closing_stock_cal_type: "FIFO_STK",
    depreciation_type: "AUTO",
    fiscal_year: "",
    cal_depreciation: true,
    asset_pool_A: 5,
    asset_pool_B: 25,
    asset_pool_C: 20,
    asset_pool_D: 15,
    asset_pool_E: 5,
    language: "en",
  };
  const validationSchema = Yup.object().shape({
    wholesaler_price_percent: Yup.number()
      .min(0, "Percentage must be greater than or equal to 0")
      .max(100, "Percentage must be less than or equal to 100")
      .required("Percentage is required"),

    retailer_price_percent: Yup.number()
      .min(0, "Percentage must be greater than or equal to 0")
      .max(100, "Percentage must be less than or equal to 100")
      .required("Percentage is required"),

    bill_due_notice_day: Yup.number()
      .min(0, "Percentage must be greater than or equal to 0")
      .required("Percentage is required"),

    fiscal_year: Yup.string()
      .matches(/^(19|20)\d{2}\/[0-9]{3}$/,"Invalid year format. Please use YYYY format.")
      .required("Year is required"),
  });

  const initialValuesFromProps = useMemo(() => {
    if (settingData) {
      return settingData;
    } else return initialValues;
  }, [settingData]);

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Formik
          initialValues={initialValuesFromProps}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {children}
        </Formik>
      )}
    </>
  );
};
